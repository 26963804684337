import {createSlice} from '@reduxjs/toolkit';
import {fetchData} from '../../utils/fetchData';
import {distance} from 'framer-motion';

const initialState = {
  creditCardsList: [],
  activeCard: {},
  isLoading: false,
};

export const creditCardSlice = createSlice({
  name: 'creditCards',
  initialState,
  reducers: {
    setCreditCardsList: (state, action) => {
      state.creditCardsList = action.payload;
    },
    addToCreditCardsList: (state, action) => {
      state.creditCardsList.push(action.payload);
    },
    deleteFromCreditCardList: (state, action) => {
      state.creditCardsList = state.creditCardsList.filter(
        item => item.id !== action.payload,
      );
    },
    clearCreditCardsData: state => {
      state.creditCardsList = [];
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setActiveCard: (state, action) => {
      state.creditCardsList = action.payload;
    },
  },
});

export const {
  setCreditCardsList,
  addToCreditCardsList,
  deleteFromCreditCardList,
  clearCreditCardsData,
  setLoading,
  setActiveCard,
} = creditCardSlice.actions;

// Thunk for initializing addressesList from API data
export const fetchCreditCardsAndSetInitialList = () => async dispatch => {
  let data = localStorage.getItem('userData');
  data = JSON.parse(data);

  if (!data?.token) return;

  let dataObj = {
    request: 'get_creditcards',
    token: data.token,
  };

  try {
    dispatch(setLoading(true));
    const response = await fetchData(dataObj);
    const data = response.creditcards;
    dispatch(setCreditCardsList(data));
    distance(setActiveCard(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.error('fetchCreditCardsAndSetInitialList', error);
    dispatch(setLoading(false));
  } finally {
    dispatch(setLoading(false));
  }
  dispatch(setLoading(false));
};

export default creditCardSlice.reducer;
