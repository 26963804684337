import React, {useState, useEffect} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import magnifying_glass from '../assets/icons/magnifying_glass.svg';
import close_icon from '../assets/icons/close_icon.svg';
import Loader from './Loader';

import PlacesAutocomplete, {geocodeByAddress} from 'react-places-autocomplete';

function GoogleSearch({address, setAddress, resetAddress}) {
  const [text, setText] = useState('');

  useEffect(() => {
    if (address) {
      setText(address);
    }
  }, [address]);

  const handleChange = address => {
    setText(address);
  };

  const handleDelete = () => {
    setText(''); // Clear the local text state
    resetAddress(); // Call the parent reset function
  };

  const handleSelect = async address => {
    try {
      setText(address);

      // Get the lat/lng of the selected address
      const results = await geocodeByAddress(address);
      const {lat, lng} = results[0].geometry.location;
      setAddress(prev => ({
        ...prev,
        address: address,
        lat: lat(),
        lon: lng(),
      }));
    } catch (error) {
      console.error('Error fetching geocode:', error);
    }
  };

  const customInput = ({getInputProps, ...rest}) => (
    <div className="google-search">
      <img src={magnifying_glass} alt="img" className="google-search--icon" />
      <input
        {...getInputProps({
          placeholder: obj_heb.addTheAddressToDelivery,
          className: 'google-search--input',
          ...rest,
        })}
      />

      {text?.length ? (
        <img
          className="google-search--close-icon"
          src={close_icon}
          alt="search"
          onClick={handleDelete}
        />
      ) : (
        ''
      )}
    </div>
  );

  return (
    <PlacesAutocomplete
      value={text.address ? text.address : text} // Use the text state for the PlacesAutocomplete value
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{
        types: ['address'], // Limit results to address types
        componentRestrictions: {country: 'il'}, // Limit results to Israel
      }}>
      {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
        <div className="relative">
          {customInput({getInputProps})}
          <div className="google-search__values">
            {loading && suggestions?.length > 0 && (
              <div className="google-search__loader">
                <Loader />
              </div>
            )}
            {suggestions.map((suggestion, index) => (
              <div
                key={JSON.stringify(suggestion)}
                className="google-search__values--value">
                <div
                  className="google-search__values--value--item"
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion)}>
                  <span title="googleSelectAble">{suggestion.description}</span>
                  <span title="googleSelectAble" className="is-hover" />
                </div>
                {suggestions.length - 1 !== index && (
                  <span className="google-search--separator" />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default GoogleSearch;
