import React, {useState, useEffect} from 'react';
import icons from '../assets/constants/icons';

function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={`scrollToTop ${isVisible ? 'scrollToTop__active' : ''}`}
      onClick={() => scrollToTop()}>
      <img src={icons.scroll_to_top_2} className="scrollToTop__img" />
    </div>
  );
}

export default ScrollToTop;
