import {createSlice} from '@reduxjs/toolkit';
import {fetchData} from '../../utils/fetchData';
import {store} from '../store';

const initialState = {
  selectedAddress: JSON.parse(localStorage.getItem('selectedAddress')) || null,
  addressesList: [],
  isLoading: false,
};

export const addressSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    setSelectedAddress: (state, action) => {
      state.selectedAddress = action.payload;
      localStorage.setItem('selectedAddress', JSON.stringify(action.payload));
    },
    setAddressesList: (state, action) => {
      state.addressesList = action.payload;
    },
    addToAddressesList: (state, action) => {
      state.addressesList.push(action.payload);
    },
    deleteFromAddressesList: (state, action) => {
      state.addressesList = state.addressesList.filter(
        item => item.id !== action.payload,
      );
    },
    clearAddressData: state => {
      state.selectedAddress = null;
      state.addressesList = [];
      localStorage.removeItem('selectedAddress');
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setSelectedAddress,
  addToAddressesList,
  deleteFromAddressesList,
  setAddressesList,
  clearAddressData,
  setLoading,
} = addressSlice.actions;

const dataFormat = async (addressesData, projectsAddressesData) => {
  addressesData = addressesData.map(({address_id, ...rest}) => ({
    id: address_id,
    address_type: 'private',
    is_on_budget: projectsAddressesData.is_on_budget,
    ...rest,
  }));

  projectsAddressesData = projectsAddressesData.map(
    ({project_id, name, address_lat, address_lon, ...rest}) => ({
      id: project_id,
      is_on_budget: projectsAddressesData.is_on_budget,
      address_type: 'project',
      title: name,
      lat: address_lat,
      lon: address_lon,
      ...rest,
    }),
  );

  return [...projectsAddressesData, ...addressesData];
};

// initializing addressesList
export const fetchAddressesAndSetInitialList = () => async dispatch => {
  let data = localStorage.getItem('userData');
  data = JSON.parse(data);

  if (!data?.token) {
    console.log(`no data token`);
    return;
  }
  if (data?.token) {
    let dataObj = {
      request: 'get_addresses',
      token: data.token,
    };
    try {
      dispatch(setLoading(true));
      const response = await fetchData(dataObj);

      let addressesData = response.addresses;
      let projectsAddressesData = response.project_addresses;

      const data = await dataFormat(addressesData, projectsAddressesData);

      dispatch(setAddressesList(data));
      dispatch(setLoading(false));

      const currentSelectedAddress =
        store.getState().addresses?.selectedAddress;
      if (
        currentSelectedAddress &&
        !data.some(i => i.id === currentSelectedAddress.id)
      ) {
        dispatch(setSelectedAddress(null));
      }
    } catch (error) {
      console.error('initializeAddressesList', error);
      dispatch(setLoading(false));
    }
  } else {
    console.log(`no data token`);
    dispatch(setLoading(false));
  }
  dispatch(setLoading(false));
};

export default addressSlice.reducer;
