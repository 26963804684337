import React, {useState, useEffect} from 'react';

//assets
import {images} from '../assets/constants/index.js';
import icons from '../assets/constants/icons.js';
import {Link, useNavigate} from 'react-router-dom';
import {useIntercomContext} from '../utils/IntercomContext';

//components
import ProgressBar from '../components/ProgressBar';
import LoginPhoneNumber from '../components/LoginPhoneNumber';
import LoginOtp from '../components/LoginOtp';
import RegisterPersonalInfo from '../components/RegisterPersonalInfo';
import RegisterUsage from '../components/RegisterUsage';
import RegisterBusiness from '../components/RegisterBusiness';
import RegisterEmail from '../components/RegisterEmail';
import RegisterSuccess from '../components/RegisterSuccess';
import {useSelector} from 'react-redux';

//page
export default function Login() {
  let userData = JSON.parse(localStorage.getItem('userData'));
  const {openIntercom} = useIntercomContext();
  const navigate = useNavigate();
  const [showOtpError, setShowOtpError] = useState(false);
  const [authKey, setAuth_key] = useState('');
  const [flow, setFlow] = useState('login');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberValidation, setPhoneNumberValidation] = useState(false);
  const [phoneNumberValidationError, setPhoneNumberValidationReady] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [step, setStep] = useState(1);
  const [inputSelected, setInputSelected] = useState(0);
  const [businessNumber, setBusinessNumber] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [emailValidation, setEmailValidation] = useState(false);
  const [email, setEmail] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  //functions
  const changeFlow = () => {
    setFlow('register');
    setStep(1);
  };

  const getClassName = (a1, a2, num) => {
    if ((a1 && !a2) || showOtpError) {
      return 'login__error-style';
    } else if (inputSelected === num) {
      return 'login__yellow-style';
    } else {
      return 'login__default-style';
    }
  };

  const nextStep = item => {
    setStep(step + item);
  };

  const selectOption = item => {
    setSelectedOption(item);
  };

  const handleOpenIntercomFromAnotherPage = () => {
    openIntercom();
  };

  useEffect(() => {
    if (userData?.token) {
      navigate('/');
    }
  }, []);

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.bg_imgs.length);
    return images.bg_imgs[randomIndex];
  };

  useEffect(() => {
    setSelectedImage(getRandomImage());
  }, []);

  const stepBack = () => {
    if (step === 2 && flow === 'login') {
      setInputSelected(1);
    }
    if (step !== 1) {
      if (
        (step === 4 && selectedOption === 1) ||
        (step === 3 && flow === 'register')
      ) {
        setStep(2);
      } else {
        setStep(step - 1);
      }
    } else {
      if (flow === 'register') {
        setFlow('login');
      } else {
        navigate('/');
      }
    }
  };

  return (
    <div className="login">
      <img className="login__img" src={selectedImage} alt="background-image" />
      <div className="login__nav-bar">
        {step != 5 && (
          <img
            className="login__nav-bar--goBack-icon"
            src={icons.goBackIcon}
            alt="back-button"
            onClick={() => stepBack()}
          />
        )}

        <Link className="login__nav-bar--logo" to="/">
          <img src={icons.dibble_logo} alt="dibble_logo" />
        </Link>

        <div onClick={() => handleOpenIntercomFromAnotherPage()}>
          <img
            className="login__nav-bar--chat-icon"
            src={icons.white_intercom_icon}
            alt="chat"
          />
        </div>
      </div>

      {flow === 'register' && step !== 5 ? <ProgressBar step={step} /> : ' '}

      {flow === 'login' && step === 1 ? (
        <LoginPhoneNumber
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          setInputSelected={setInputSelected}
          getClassName={getClassName}
          phoneNumberValidation={phoneNumberValidation}
          phoneNumberValidationError={phoneNumberValidationError}
          setPhoneNumberValidation={setPhoneNumberValidation}
          setPhoneNumberValidationReady={setPhoneNumberValidationReady}
          step={step}
          setStep={setStep}
        />
      ) : flow === 'login' && step === 2 ? (
        <LoginOtp
          phoneNumber={phoneNumber}
          phoneNumberValidation={phoneNumberValidation}
          setInputSelected={setInputSelected}
          getClassName={getClassName}
          setShowOtpError={setShowOtpError}
          showOtpError={showOtpError}
          handleOpenIntercomFromAnotherPage={handleOpenIntercomFromAnotherPage}
          changeFlow={changeFlow}
          setAuth_key={setAuth_key}
        />
      ) : flow === 'register' && step === 1 ? (
        <RegisterPersonalInfo
          setInputSelected={setInputSelected}
          getClassName={getClassName}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          nextStep={nextStep}
        />
      ) : flow === 'register' && step === 2 ? (
        <RegisterUsage
          selectedOption={selectedOption}
          selectOption={selectOption}
          nextStep={nextStep}
        />
      ) : flow === 'register' && step === 3 ? (
        <RegisterBusiness
          setInputSelected={setInputSelected}
          nextStep={nextStep}
          getClassName={getClassName}
          setBusinessNumber={setBusinessNumber}
          setBusinessName={setBusinessName}
          businessNumber={businessNumber}
          businessName={businessName}
        />
      ) : flow === 'register' && step === 4 ? (
        <RegisterEmail
          email={email}
          setEmail={setEmail}
          setInputSelected={setInputSelected}
          getClassName={getClassName}
          authKey={authKey}
          firstName={firstName}
          lastName={lastName}
          phoneNumber={phoneNumber}
          chosedOption={selectedOption}
          businessNumber={businessNumber}
          businessName={businessName}
          nextStep={nextStep}
          emailValidation={emailValidation}
          setEmailValidation={setEmailValidation}
        />
      ) : flow === 'register' && step === 5 ? (
        <RegisterSuccess
          email={email}
          airPlane={images.airPlane}
          emailValidation={emailValidation}
          handleOpenIntercomFromAnotherPage={handleOpenIntercomFromAnotherPage}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
}
