import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import moment from 'moment';
import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';
import {useIntercomContext} from '../utils/IntercomContext';
import {icons} from '../assets/constants';
import RadioButton from '../components/RadioButtons';
import PopUpSlots from '../components/PopUpSlots';
import SummaryComponent from '../components/SummaryComponent';
import SummaryMobile from './SummaryMobile';
import AddressInput from '../components/AddressInput';
import CreditCardInput from '../components/CreditCardInput';
import Title from '../components/Title';
import Loader from '../components/Loader';
import InstallmentInput from '../components/InstallmentInput';
import NeedCraneNav from '../components/NeedCraneNav';
import AddNotes from '../components/AddNotes';
import Button from '../components/Button';
import MaterialDate from '../components/MaterialDate';

function Summary() {
  const [isLoading, setIsLoading] = useState(null);
  const [activeHours, setActiveHours] = useState();
  const [isReady, setIsReady] = useState();
  const [timePopUpIsOpen, setTimePopUpIsOpen] = useState(null);
  const [materialOrder, setMaterialOrder] = useState(null);
  const [requestedDeliveryTime, setRequestedDeliveryTime] = useState(null);
  const [materialOrderAddress, setMaterialOrderAddress] = useState(null);
  const [bidPrice, setBidPrice] = useState(null);
  const [bidDeliveryCost, setBidDeliveryCost] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [vat, setVat] = useState(0);
  const [bidId, setBidId] = useState(null);
  const [showFastDelivery, setShowFastDelivery] = useState(true);
  const [form, setForm] = useState({
    slot: '',
    deliveryType: '',
    notes: '',
    creditCard: '',
    secondCreditCard: '',
    installments: 1,
    dibbleCoins: 0,
    canPayWithDibbleCoins: false,
    deliveryCost: 0,
    shoppingCartWeight: 0,
  });

  const shoppingCartProducts = useSelector(
    state => state.shopping_cart.products,
  );

  const selectedAddress = useSelector(
    state => state.addressSlice.selectedAddress,
  );
  const addressesList = useSelector(state => state.addressSlice.addressesList);
  const creditCards = useSelector(state => state.creditCards.creditCardsList);

  const userData = JSON.parse(localStorage.getItem('userData'));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (creditCards && creditCards.length > 0) {
      if (!form.dibbleCoins) {
        let selectedCard =
          form.creditCard ||
          creditCards.find(card => card.is_default === 1) ||
          creditCards[0];
        setForm(prevForm => ({
          ...prevForm,
          creditCard: selectedCard,
        }));
      }
    }
  }, [creditCards, form.creditCard, form.dibbleCoins]);
  const convertStringToNumber = input => {
    const number = parseFloat(input);
    const convertedNumber = number / 100 + 1;
    return convertedNumber;
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(searchParams.entries());
    if (Object.keys(params).length > 0) {
      console.log('Query Params:', params);
      if (params) {
        setMaterialOrder(params.material_type);
        setRequestedDeliveryTime(params.requested_delivery_time);
        setMaterialOrderAddress(params.address);
        setBidPrice(params.price);
        setBidDeliveryCost(params.delivery_cost);
        setOrderId(params.order_id);
        setBidId(params.bid_id);
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (materialOrderAddress) {
      console.log(`materialOrderAddress`, materialOrderAddress);
      console.log(`addressesList`, addressesList);
      const matchingAddress = addressesList.find(
        address => address.address === materialOrderAddress,
      );
      if (matchingAddress) {
        dispatch({
          type: 'addresses/setSelectedAddress',
          payload: matchingAddress,
        });
      }
    }
  }, [materialOrderAddress, addressesList, dispatch]);
  ////////////////////////////////////////////////////////  delivery
  // check if ih hour for fast delivery
  const isCurrentTimeInRange = () => {
    setIsLoading(true);
    let dataObj = {
      request: 'get_active_hours',
    };

    fetchData(dataObj).then(response => {
      const today = moment().day(); // 1 (Sunday) to 7 (Saturday)
      const activeHours = response?.active_hours[today];
      if (today == 6 || today == 5) {
        setActiveHours(response?.active_hours[today - 3]);
      } else {
        setActiveHours(activeHours);
      }
      const currentTime = moment();
      const startTime = moment(activeHours.start_time, 'HH:mm');
      const endTime = moment(activeHours.end_time, 'HH:mm');

      if (
        currentTime > startTime &&
        currentTime < endTime &&
        !containNeedCraneItem() &&
        response?.fast_delivery.enabled == true
      ) {
        setIsLoading(false);

        setShowFastDelivery(true);
      } else {
        setShowFastDelivery(false);

        if (form.deliveryType === 1) {
          setForm(prev => {
            return {
              ...prev,
              deliveryType: '',
            };
          });
        }
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    isCurrentTimeInRange();
  }, [shoppingCartProducts]);

  // change delivery type
  const handleDeliveryType = item => {
    const index = item.index;

    if (index === 3) {
      setTimePopUpIsOpen(true);
      if (form.deliveryType !== index) {
        setForm(prev => {
          return {...prev, deliveryType: null, slot: ''};
        });
      }
    } else if (index === 1) {
      setForm(prev => {
        return {...prev, deliveryType: index, slot: ''};
      });
    }
  };
  ////////////////////////////////////////////////////////  crane
  const containNeedCraneItem = () => {
    // calculate the weight of the whole cart items.
    const weight = shoppingCartProducts
      .map(item => item.weight * item.quantity)
      .reduce((partialSum, a) => partialSum + a, 0);

    if (
      shoppingCartProducts.filter(x => x.need_crane).length ||
      weight >= 1500000
    ) {
      return true;
    } else {
      return false;
    }
  };

  ////////////////////////////////////////////////////////  cart price
  const shoppingCartPrice = () => {
    return Number(
      shoppingCartProducts
        .map(item => {
          if (item.sale) {
            return item.sale_price * item.quantity;
          } else {
            return item.price * item.quantity;
          }
        })
        .reduce((partialSum, a) => partialSum + a, 0),
    );
  };

  ////////////////////////////////////////////////////////  cart weight
  // get the cart weight
  useEffect(() => {
    const weight = shoppingCartProducts
      .map(item => item.weight * item.quantity)
      .reduce((partialSum, a) => partialSum + a, 0);

    setForm(prev => {
      return {...prev, shoppingCartWeight: weight};
    });
  }, [shoppingCartProducts]);

  ////////////////////////////////////////////////////////  delivery cost
  useEffect(() => {
    if (selectedAddress && !materialOrderAddress) {
      const products = shoppingCartProducts.map(item => {
        return {
          ...item,
          product_id: item.product_id,
          amount: item?.amount ? item?.amount : 1,
        };
      });

      if (userData && userData?.token) {
        let dataObj = {
          request: 'get_delivery_cost',
          token: userData.token,
          destination_lat: selectedAddress?.lat,
          destination_lon: selectedAddress?.lon,
          need_crane: containNeedCraneItem(),
          products: products.map(item => ({
            ...item,
            amount: item.quantity,
          })),
          order_type: form.deliveryType,
        };
        fetchData(dataObj).then(response => {
          if (form.shoppingCartWeight >= 15000000) {
            setForm(prev => {
              return {...prev, deliveryCost: 300};
            }); //frontend fix
          } else {
            setForm(prev => {
              return {...prev, deliveryCost: response?.delivery_cost};
            });
          }
        });
      }
    } else {
      setForm(prev => {
        return {...prev, deliveryCost: 0};
      });
    }
  }, [
    selectedAddress,
    shoppingCartProducts,
    form.deliveryType,
    materialOrderAddress,
  ]);

  ////////////////////////////////////////////////////////  dibble coins
  const loadDibbleCoin = async () => {
    if (userData && userData?.token) {
      const dataObj = {
        request: 'dibblecoin_get_available_credit',
        token: userData.token,
      };

      fetchData(dataObj).then(response => {
        if (response?.message === 'success')
          setForm(prev => {
            return {...prev, dibbleCoins: response.available_credit};
          });
      });
    }
  };

  useEffect(() => {
    if (userData) {
      loadDibbleCoin();
    }
  }, []);

  useEffect(() => {
    let dataObj = {
      request: 'get_vat_rate',
      valid_from: requestedDeliveryTime,
    };
    fetchData(dataObj).then(response => {
      if (response?.message === 'success')
        setVat(convertStringToNumber(response.vat_rate.value));
    });
  }, []);

  // check can pay with dibble coins
  useEffect(() => {
    if (form.dibbleCoins && shoppingCartPrice() && !materialOrder) {
      if ((shoppingCartPrice() + form.deliveryCost) * vat <= form.dibbleCoins) {
        setForm(prev => {
          return {...prev, canPayWithDibbleCoins: true};
        });
      } else {
        setForm(prev => {
          return {...prev, canPayWithDibbleCoins: false};
        });
      }
    } else if (form.dibbleCoins && bidDeliveryCost && bidPrice) {
      if (
        form.dibbleCoins >
        Number(bidDeliveryCost) * vat + Number(bidPrice) * vat
      ) {
        setForm(prev => {
          return {...prev, canPayWithDibbleCoins: true};
        });
      } else {
        setForm(prev => {
          return {...prev, canPayWithDibbleCoins: false};
        });
      }
    }
  }, [
    form.dibbleCoins,
    form.shoppingCartWeight,
    form.deliveryCost,
    shoppingCartProducts,
    form.creditCard,
    bidDeliveryCost,
    bidPrice,
  ]);

  ////////////////////////////////////////////////////////  project
  // return if the selected address is project address
  const isProject =
    useSelector(state => state.addressSlice.selectedAddress)?.address_type ===
    'project';

  useEffect(() => {
    if (isProject) {
      setForm(prev => {
        return {...prev, creditCard: '', secondCreditCard: ''};
      });
    }
  }, [isProject]);

  ////////////////////////////////////////////////////////  submit functions
  // check if ready
  useEffect(() => {
    if (selectedAddress && shoppingCartProducts.length) {
      if (
        (form.deliveryType === 1 &&
          !form.canPayWithDibbleCoins &&
          form.secondCreditCard) ||
        (form.deliveryType === 1 && form.canPayWithDibbleCoins) ||
        (form.deliveryType === 1 && form.creditCard) ||
        (form.deliveryType === 3 && form.slot && form.creditCard) ||
        (form.deliveryType === 3 &&
          form.slot &&
          !form.canPayWithDibbleCoins &&
          form.secondCreditCard) ||
        (form.deliveryType === 3 && form.slot && form.canPayWithDibbleCoins) ||
        (form.deliveryType === 3 && form.slot && isProject) ||
        (form.deliveryType === 1 && isProject)
      ) {
        setIsReady(true);
      } else {
        setIsReady(false);
      }
    } else {
      setIsReady(false);
    }
  }, [selectedAddress, form, shoppingCartProducts]);
  // create the correct time format for api
  const getFormatDate = () => {
    const dates = form.slot.split(' ');
    const day = dates[0];
    const formatDay = moment(day, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const time = dates[3];

    const details = time.split(':');
    const diff = new Date(formatDay).getTimezoneOffset() / 60;
    let hour = Number(details[0]) + diff + '';
    return formatDay + ' ' + (hour.length === 1 ? '0' : '') + hour + ':00:00';
  };

  const slotSetter = slot => {
    setForm(prev => {
      return {...prev, slot: slot, deliveryType: 3};
    });
  };
  const createDataObj = () => {
    if (isReady) {
      // create the products list by the correct names
      let productList = [];

      for (let i = 0; i < shoppingCartProducts.length; i++) {
        let productionItem = {
          product_id: shoppingCartProducts[i]['product_id'],
          amount: shoppingCartProducts[i]['quantity'],
          key: shoppingCartProducts[i]['key'],
          isChecked: true,
          option1: shoppingCartProducts[i]['option1_value'],
          option2: shoppingCartProducts[i]['option2_value'],
        };
        productList.push(productionItem);
      }

      return {
        payment_type: form.creditCard?.card_num
          ? 1
          : !form.canPayWithDibbleCoins &&
            form.dibbleCoins &&
            form.secondCreditCard
          ? 21
          : form.dibbleCoins
          ? 3
          : 1,
        request: 'place_order_with_products',
        installments: form.installments ? form.installments : 1,
        token: userData.token,
        order_type: form.deliveryType,
        destination_address: selectedAddress.address,
        destination_lat: selectedAddress.lat,
        destination_lon: selectedAddress.lon,
        notes: form.notes,
        purpose: '',
        project_id: selectedAddress.id,
        products: productList,
        creditcard_id: form.creditCard?.creditcard_id
          ? form.creditCard?.creditcard_id
          : form.dibbleCoins && form.canPayWithDibbleCoins
          ? -1
          : form.secondCreditCard?.creditcard_id,
        delayed_delivery_date: form.deliveryType === 3 ? getFormatDate() : null,
      };
    }
    return {};
  };

  //////////////////////////////////////////////////////// for mobile
  const isMobile = useSelector(state => state.mobile.isMobile);
  const [summaryMobile, setSummaryMobile] = useState(false);

  const NavigateMobileSummary = item => {
    navigate(`${location.pathname}?mobile`);
    setSummaryMobile(true);
  };

  useEffect(() => {
    if (isMobile) {
      if (location.search.includes('mobile')) {
        setSummaryMobile(true);
      } else {
        console.log(` dosent includes mobile!!`);
        setSummaryMobile(false);
      }
    } else {
      setSummaryMobile(prev => false);
      if (location.search.includes('mobile'))
        navigate(location.pathname + location.search);
    }
  }, [window.location.href]);

  return (
    <div className="summary">
      {containNeedCraneItem() && <NeedCraneNav />}
      {isLoading && (
        <div className="loader__container">
          <Loader />
        </div>
      )}
      {!summaryMobile && !materialOrder ? (
        <>
          <div className="summary__form__item summary__title">
            <Title title={obj_heb.delivery_details} />
            <div className="summary__form__item--sub-title">
              <span className="summary__form__item--sub-title--important">
                {obj_heb.important + ' '}
              </span>
              <span>
                {containNeedCraneItem()
                  ? obj_heb.important_pkg
                  : obj_heb.important_crane_pkg}
              </span>
            </div>
          </div>

          {!isMobile && (
            <div className="summary__form">
              {/* slots */}
              <div className="summary__form__item">
                <span className="summary__form__item--title">
                  {obj_heb.date_of_delivery}
                </span>
                {!requestedDeliveryTime ? (
                  <RadioButton
                    defaultValue={form.deliveryType ? form.deliveryType : null}
                    onSelect={handleDeliveryType}
                    color="var(--white)"
                    containerClassName={
                      isMobile && 'summary__form__item--radio-buttons'
                    }
                    itemClassName={
                      isMobile && 'summary__form__item--radio-buttons--item'
                    }
                    data={[
                      {
                        index: 1,
                        label: obj_heb.fast_delivery,
                        disable: requestedDeliveryTime || !showFastDelivery,
                        subLabel: activeHours
                          ? obj_heb.fast_delivery_pkg1 +
                            activeHours.start_time +
                            obj_heb.fast_delivery_pkg2 +
                            activeHours.end_time
                          : '',
                        color:
                          requestedDeliveryTime || !showFastDelivery
                            ? 'var(--nav-bar-gray )'
                            : 'var(--white)',
                      },
                      {
                        index: 3,
                        label: obj_heb.date_delivery,
                        subLabel: form.slot
                          ? `${form.slot.split(' ')[0]}, בשעה ${
                              form.slot.split(' ')[3]
                            }`
                          : obj_heb.here_we_peek,
                        checked: form.slot ? true : false,
                      },
                    ]}
                  />
                ) : (
                  <MaterialDate
                    boxShadow
                    requestedDeliveryTime={requestedDeliveryTime}
                  />
                  // <div>{requestedDeliveryTime}</div>
                )}
              </div>

              {/* address */}
              <div className="summary__form__item">
                <span className="summary__form__item--title">
                  {obj_heb.delivery_address}
                </span>
                <AddressInput
                  boxShadow
                  materialOrderAddress={materialOrderAddress}
                />
              </div>

              {/* notes */}
              <div className="summary__form__item">
                <span className="summary__form__item--title">
                  {obj_heb.business_notes}
                </span>
                <AddNotes
                  showTitle={true}
                  notes={form.notes}
                  setNotes={value =>
                    setForm(prev => {
                      return {...prev, notes: value};
                    })
                  }
                />
              </div>

              {/* credit card */}
              <div className="summary__form__item">
                <span className="summary__form__item--title">
                  {obj_heb.payment_type}
                </span>
                {/* cards container */}

                <CreditCardInput
                  setCreditCard={value =>
                    setForm(prev => {
                      return {...prev, creditCard: value};
                    })
                  }
                  setSecondCreditCard={value =>
                    setForm(prev => {
                      return {...prev, secondCreditCard: value};
                    })
                  }
                  setInstallments={value =>
                    setForm(prev => {
                      return {...prev, installments: value};
                    })
                  }
                  creditCard={form.creditCard}
                  dibbleCoins={form.dibbleCoins}
                  canPayWithDibbleCoins={form.canPayWithDibbleCoins}
                  requestedDeliveryTime={requestedDeliveryTime}
                />
                {/* installments component */}

                {form.creditCard && form.creditCard.card_num && (
                  <InstallmentInput
                    setInstallments={value =>
                      setForm(prev => {
                        return {...prev, installments: value};
                      })
                    }
                  />
                )}
                {!form.canPayWithDibbleCoins &&
                  !isProject &&
                  form.deliveryCost &&
                  form.deliveryCost != 0 &&
                  (form.deliveryType == 1 || form.deliveryType == 3) &&
                  !form.creditCard?.card_num && (
                    <>
                      <div className="summary__form__item--error-msg">
                        {!form.secondCreditCard?.card_num
                          ? obj_heb.dibbleCoinsBalanceError
                          : obj_heb.the_rest_you_pay_with}
                      </div>
                      <CreditCardInput
                        setCreditCard={value =>
                          setForm(prev => {
                            return {...prev, creditCard: value};
                          })
                        }
                        setSecondCreditCard={value =>
                          setForm(prev => {
                            return {...prev, secondCreditCard: value};
                          })
                        }
                        shoppingCartPrice={shoppingCartPrice()}
                        deliveryPrice={form.deliveryCost}
                        // sending the second
                        creditCard={form.secondCreditCard}
                        dibbleCoins={form.dibbleCoins}
                        canPayWithDibbleCoins={form.canPayWithDibbleCoins}
                        setInstallments={value =>
                          setForm(prev => {
                            return {...prev, installments: value};
                          })
                        }
                        second={true}
                        requestedDeliveryTime={requestedDeliveryTime}
                      />
                    </>
                  )}
              </div>
            </div>
          )}

          {/* mobile slots */}
          {isMobile && (
            <div className="summary__form__item">
              <RadioButton
                defaultValue={form.deliveryType ? form.deliveryType : null}
                onSelect={handleDeliveryType}
                color="var(--white)"
                containerClassName={
                  isMobile && 'summary__form__item--radio-buttons'
                }
                itemClassName={
                  isMobile && 'summary__form__item--radio-buttons--item'
                }
                data={[
                  {
                    index: 1,
                    label: obj_heb.fast_delivery,
                    disable: !showFastDelivery,
                    subLabel: activeHours
                      ? obj_heb.fast_delivery_pkg1 +
                        activeHours.start_time +
                        obj_heb.fast_delivery_pkg2 +
                        activeHours.end_time
                      : '',
                    color: !showFastDelivery
                      ? 'var(--nav-bar-gray )'
                      : 'var(--white)',
                  },
                  {
                    index: 3,
                    label: obj_heb.date_delivery,
                    subLabel: form.slot
                      ? `${form.slot.split(' ')[0]}, בשעה ${
                          form.slot.split(' ')[3]
                        }`
                      : obj_heb.here_we_peek,
                    checked: form.slot ? true : false,
                  },
                ]}
              />
            </div>
          )}
          {!isMobile && (
            <SummaryComponent
              action="summary"
              ready={isReady}
              dataObj={createDataObj()}
              setIsLoading={setIsLoading}
              isNeedCrane={containNeedCraneItem()}
              dibbleCoins={form.dibbleCoins}
              canPayWithDibbleCoins={form.canPayWithDibbleCoins}
              deliveryType={materialOrder ? 3 : form.deliveryType}
              payment_type={
                form.creditCard?.card_num
                  ? 1
                  : !form.canPayWithDibbleCoins &&
                    form.dibbleCoins &&
                    form.secondCreditCard
                  ? 21
                  : form.dibbleCoins
                  ? 3
                  : 1
              }
              bidPrice={bidPrice}
              bidDeliveryCost={bidDeliveryCost}
              orderId={orderId}
              bidId={bidId}
              creditcard_id={form.creditCard?.creditcard_id}
              installments={form.installments ? form.installments : 1}
              requestedDeliveryTime={requestedDeliveryTime}
              slot={form.slot}
            />
          )}

          {isMobile && (
            <Button
              text={
                form.deliveryType ? obj_heb.next : obj_heb.pickUpDeliveryType
              }
              backgroundColor={
                form.deliveryType
                  ? 'var(--dibble-yellow)'
                  : 'var(--unclickable-gray)'
              }
              textColor="var(--paragraph-white)"
              className="summary__mobile-btn"
              disable={!form.deliveryType}
              onClick={NavigateMobileSummary}
            />
          )}
        </>
      ) : (
        <div>
          {!isMobile && (
            <span>
              {' '}
              <Title title={obj_heb.delivery_details} />
              <div className="summary__form__item--sub-title">
                <span className="summary__form__item--sub-title--important">
                  {obj_heb.important + ' '}
                </span>
                <span>
                  {containNeedCraneItem()
                    ? obj_heb.important_pkg
                    : obj_heb.important_crane_pkg}
                </span>
              </div>
            </span>
          )}
          <SummaryMobile
            form={form}
            setForm={setForm}
            isProject={isProject}
            shoppingCartPrice={shoppingCartPrice}
            shoppingCartProducts={shoppingCartProducts}
            ready={isReady}
            createDataObj={createDataObj}
            setIsLoading={setIsLoading}
            isNeedCrane={containNeedCraneItem()}
            handleDeliveryType={handleDeliveryType}
            bidPrice={bidPrice}
            bidDeliveryCost={bidDeliveryCost}
            orderId={orderId}
            bidId={bidId}
            materialOrderAddress={materialOrderAddress}
            requestedDeliveryTime={requestedDeliveryTime}
            payment_type={
              form.creditCard?.card_num
                ? 1
                : !form.canPayWithDibbleCoins &&
                  form.dibbleCoins &&
                  form.secondCreditCard
                ? 21
                : form.dibbleCoins
                ? 3
                : 1
            }
            creditcard_id={form.creditCard?.creditcard_id}
          />
        </div>
      )}

      {/* pop uo slots pick up */}
      <PopUpSlots
        isOpen={timePopUpIsOpen}
        setIsOpen={setTimePopUpIsOpen}
        isNeedCrane={containNeedCraneItem()}
        slot={form.slot}
        setSlot={slotSetter}
      />
    </div>
  );
}

export default Summary;
