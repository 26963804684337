import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {fetchData} from '../utils/fetchData';
import Title from './Title';
import {useSearchParams} from 'react-router-dom';
import CategoryBox from './CategoryBox';
import SEO from './SEO';
import Loader from '../components/Loader';

function CategorysList() {
  const [categorys, setCategorys] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();

  const isMobile = useSelector(state => state.mobile.isMobile);

  useEffect(() => {
    let data = localStorage.getItem('userData');
    setUserData(JSON.parse(data));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let dataObj = {
      request: 'get_sub_categories',
      token: userData?.token || '',
      parent_category_id: searchParams.get('id'),
    };
    fetchData(dataObj)
      .then(response => {
        setIsLoading(false);
        const data = response;
        setCategorys(data.categories);
      })
      .catch(error => {
        console.error('Error:', error.message);
        setIsLoading(true);
      });

    // }
  }, []);

  useEffect(() => {
    let dataObj = {
      request: 'get_category',
      token: userData?.token || '',
      category_id: searchParams.get('id'),
    };
    fetchData(dataObj)
      .then(response => {
        const data = response;
        setCategoryDetails(data);
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  }, []);

  const goToCategory = (id, name) => {
    navigate(
      `/category?id=${encodeURIComponent(id)}&name=${encodeURIComponent(name)}`,
    );
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : null}

      <SEO
        pageName={searchParams.get('name')}
        content={categoryDetails.description}
        link="/category"
      />

      <div className="c_results">
        <Title title={searchParams.get('name')} />
        <div className="c_results__container">
          {categorys &&
            categorys.map((category, index) => {
              return (
                <CategoryBox
                  key={'category-llist-' + JSON.stringify(category)}
                  category={category}
                  goToCategory={goToCategory}
                  width={`calc((100% - ${isMobile ? 0.8 : 1.6}rem * ${
                    isMobile ? 2 : 4
                  }) / ${isMobile ? 3 : 5})`}
                />
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default CategorysList;
