import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import TextInput from '../components/TextInput.jsx';
import Button from '../components/Button.jsx';
import Title from '../components/Title';
import Dropdown from '../components/general/DropDown.jsx';
import {useNavigate} from 'react-router-dom';
import img from '../assets/images/shutterstock_567718675.png';
import img2 from '../assets/images/img_bg2.png';
import img3 from '../assets/images/img_bg3.png';
import img4 from '../assets/images/img_bg4.png';
import img5 from '../assets/images/img_bg5.png';
import img7 from '../assets/images/img_bg7.png';
import {fetchData} from '../utils/fetchData.js';

const JoinUsContractors = ({props}) => {
  const isMobile = useSelector(state => state.mobile.isMobile);
  const [companyName, setCompanyName] = useState('');
  const [contactName, setContactName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedNumOfEmployees, setSelectedNumOfEmployees] = useState('');
  const numOfEmployees = ['0-5', '5-20', '20-50', '50-100', '100+'];
  const handleCompanyNameChange = e => setCompanyName(e.target.value);
  const handleContactNameChange = e => setContactName(e.target.value);
  const handleEmailChange = e => setEmail(e.target.value);
  const handlePhoneChange = e => setPhone(e.target.value);
  const isCompanyNameValid = () => companyName.trim() !== '';
  const isContactNameValid = () => contactName.trim() !== '';
  const isEmailValid = () => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isPhoneValid = () => /^\+?\d{7,15}$/.test(phone);
  const resetInput = () => setCompanyName('');
  const resetInput2 = () => setContactName('');
  const resetInput3 = () => setEmail('');
  const resetInput4 = () => setPhone('');
  const navigate = useNavigate();
  const images = [img, img2, img3, img4, img5, img7];

  useEffect(() => {
    setSelectedImage(getRandomImage());
  }, []);

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };

  const formValid = useMemo(() => {
    return (
      isCompanyNameValid() &&
      isContactNameValid() &&
      isEmailValid() &&
      isPhoneValid()
    );
  }, [companyName, contactName, email, phone]);

  const sendEmail = async () => {
    let dataObj = {
      request: 'send_mail',
      to_email: 'meni@dibble.co.il',
      from_email: 'office@dibble.co.il',
      from_name: contactName,
      subject: `Dibble - A new company ״${companyName}״ was registered in the system`,
      message: `A new company named "${companyName}" was registered in the system.<br/>
      <br/>
      Contact name: ${contactName}<br/>
      Email: ${email}<br/>
      Number of employees in the company: ${selectedNumOfEmployees}<br/>
      Contact's mobile number: ${phone}<br/>
      Email of the contact: ${email}<br/>`,
    };

    fetchData(dataObj)
      .then(response => {
        if (response?.message === 'success') {
          console.log(`send_mail API return success`);
          navigate(`/register-success`);
        }
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  };

  return (
    <React.Fragment>
      <div className="login">
        <img
          className="login__img"
          src={selectedImage}
          alt="background-image"
        />
        <div className="login__pop-up">
          <div
            className="login__pop-up__text-wrapper"
            style={{fontWeight: 800}}>
            <Title
              title="Nice to meet you"
              style="login__container--title"
              center={true}
            />
            <div
              className="login__container--text--bold"
              style={{
                textAlign: 'center',
                color: !isMobile ? '#707070' : '',
              }}></div>
          </div>
          <div className="login--gap-24">
            <TextInput
              value={companyName}
              onChange={handleCompanyNameChange}
              onClear={resetInput}
              description="Please enter your company name"
              placeholderText="Company Name"
              errorMsg="Valid company name is required"
              IsError={!isCompanyNameValid()}
              directError={companyName}
              ltr
              isLeft
            />
            <TextInput
              value={contactName}
              onChange={handleContactNameChange}
              onClear={resetInput2}
              description="Please enter a contact name"
              placeholderText="Contact Name"
              errorMsg="Valid contact name is required"
              IsError={!isContactNameValid()}
              directError={contactName}
              ltr
              isLeft
            />

            <div
              className="d-flex justify-content-between align-items-center"
              style={{width: '100%'}}>
              <Dropdown
                key={'s'}
                options={numOfEmployees}
                defaultOption={
                  selectedNumOfEmployees
                    ? selectedNumOfEmployees
                    : numOfEmployees[0]
                }
                onSelect={option => {
                  setSelectedNumOfEmployees(option);
                }}
                outSideClickClosing={true}
                withHoverGradient={true}
                title="Select Number"
                width={100}
                ltr
              />
              <div className="w-50 text-center">
                <span className="Product__content_first-box_product-Text-part_price-box_title">
                  :Number of employees
                </span>
              </div>
            </div>

            <TextInput
              value={email}
              onChange={handleEmailChange}
              onClear={resetInput3}
              description="Please enter an email address"
              placeholderText="Email address"
              errorMsg="Valid email address is required"
              IsError={!isEmailValid()}
              directError={contactName}
              ltr
              isLeft
            />
            <TextInput
              value={phone}
              onChange={handlePhoneChange}
              onClear={resetInput4}
              description="Please add a phone number"
              placeholderText="Phone number"
              errorMsg="Valid phone number is required"
              IsError={!isPhoneValid()}
              directError={contactName}
              ltr
              isLeft
            />
          </div>

          <Button
            text={formValid ? 'Next' : 'Please fill all fields'}
            disable={!formValid}
            onClick={() => sendEmail()}
            textColor="white"
            backgroundColor={formValid ? '#FFCA1A' : '#BDBCBC'}
            className="login--margin-horizontal-16"
          />
        </div>
      </div>
    </React.Fragment>
  );
};
<style></style>;

export default JoinUsContractors;
