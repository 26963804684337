import React, {useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import AddAndEditUserPopUp from './AddAndEditUserPopUp';
import ProjectInvoiceListItem from './ProjectInvoiceListItem';

export default function ProjectInvoicesTab({projectInvoices}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="MyOrdersTab">
        {projectInvoices?.invoices.length === 0 ? (
          <div className="MyOrdersTab_empty-list">
            <div className="MyOrdersTab_empty-list_text">
              <div className="MyOrdersTab_empty-list_text_title">
                {obj_heb.noInvoicesFound}
              </div>
              <div className="MyOrdersTab_empty-list_text_sub-title">
                {obj_heb.noInvoicesFoundDetails}
              </div>
            </div>
          </div>
        ) : (
          <div>
            {projectInvoices?.invoices.map(invoice => (
              <ProjectInvoiceListItem key={invoice.invoice_id} item={invoice} />
            ))}
          </div>
        )}
      </div>
      <AddAndEditUserPopUp isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="qrPopUp">
          <span className="qrPopUp_txt">{obj_heb.transferToAppStroeTxt}</span>
        </div>
      </AddAndEditUserPopUp>
    </>
  );
}
