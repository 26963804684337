import React, {useEffect, useState, useRef} from 'react';
import {days} from '../utils/supportFunctions';
import obj_heb from '../utils/Obj_heb.json';
import classNames from 'classnames';
import ListSelector from './ListSelector';
import arrow_head_down from '../assets/icons/arrow_head_down.svg';

function WidgetTypeListInput({item, setItem, daysOptions, uiNamE}) {
  const [isOpen, setIsOpen] = useState(false);

  const containerRef = useRef();

  let weekDays = days();

  const handleSelect = (data, index) => {
    setIsOpen(false);
    setItem(data, index);
  };

  useEffect(() => {
    // Function to handle click outside the div
    const handleClickOutside = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        // The click is outside the div, do something here
        setIsOpen(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      onClick={() => setIsOpen(prev => !prev)}
      className="question-input"
      ref={containerRef}>
      <div style={{display: 'block', marginBottom: '8px', fontSize: '1.6rem'}}>
        {uiNamE}
      </div>
      <div className="question-input__item">
        <div
          className={classNames(
            'question-input__item--title',
            item && 'question-input__item--selected',
          )}>
          <span style={{color: !item ? 'grey' : 'inherit'}}>
            {!item ? 'בחר' + ' ' + uiNamE : item.name}
          </span>
        </div>
      </div>
      {isOpen && (
        <div onClick={e => e.stopPropagation()}>
          <ListSelector handleSelect={handleSelect} dateOptions={daysOptions} />
        </div>
      )}
      <img
        src={arrow_head_down}
        alt=""
        className={classNames('arrow', isOpen && 'arrow_flip')}
      />
    </div>
  );
}

export default WidgetTypeListInput;
