import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isMobile: true,
  isDownloadAppShown: false,
  isBannerFalseFromSummary: false,
};

export const mobileSlice = createSlice({
  name: 'mobile',
  initialState,
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setIsDownloadAppShown: (state, action) => {
      state.isDownloadAppShown = action.payload;
    },
    setIsBannerFalseFromSummary: (state, action) => {
      state.isDownloadAppShown = action.payload;
    },
  },
});

export const {setIsMobile, setIsDownloadAppShown, setIsBannerFalseFromSummary} =
  mobileSlice.actions;

// Thunk for initializing mobile-related state
export const initialMobile = () => async dispatch => {
  // determine if the screen is considered mobile based on screen width
  const isMobileScreen = window.innerWidth <= 767;

  // get the initial values for isDownloadAppShown and isBannerFalseFromSummary
  const isDownloadAppShown = mobileSlice.getInitialState().isDownloadAppShown;
  const isBannerFalseFromSummary =
    mobileSlice.getInitialState().isBannerFalseFromSummary;

  try {
    // update the isMobile state based on screen size
    dispatch(setIsMobile(isMobileScreen));

    // ff the url contains 'summary', handle banner visibility
    if (window.location.href.includes('summary')) {
      // if isDownloadAppShown was true, hide the banner and possibly show it again later
      if (isDownloadAppShown) {
        dispatch(setIsBannerFalseFromSummary(true));
      }
      // hide the download banner for the summary page
      dispatch(setIsDownloadAppShown(false));
    }
    // if isBannerFalseFromSummary is true, update banner visibility and reset the flag
    else if (isBannerFalseFromSummary) {
      dispatch(setIsDownloadAppShown(isMobileScreen));
      dispatch(setIsBannerFalseFromSummary(false));
    }
    // if isDownloadAppShown is true, update banner visibility based on screen size
    else if (isDownloadAppShown) {
      dispatch(setIsDownloadAppShown(isMobileScreen));
    }
  } catch (error) {
    console.error('setIsMobile', error);
  }
};

export default mobileSlice.reducer;
