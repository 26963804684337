import React from 'react';
import obj_heb from '../utils/Obj_heb.json';
import AddressEdit from './AddressEdit';
import AddressDelete from './AddressDelete';

export default function AddressItemModalChildren({
  address,
  popUpIsOpen,
  setPopUpIsOpen,
  setPopUpContent,
  setAddressEditIsOpen,
  setFinish,
  setFinishText,
}) {
  return (
    <div className="AddressItemModalChildren__mobileModal">
      <div className="AddressItemModalChildren__mobileModal__title">
        {obj_heb.budget}
      </div>
      <div className="AddressItemModalChildren__mobileModal__body">
        <AddressEdit
          address={address}
          popUpIsOpen={popUpIsOpen}
          setAddressEditIsOpen={setAddressEditIsOpen}
          setPopUpIsOpen={setPopUpIsOpen}
          setPopUpContent={setPopUpContent}
          setFinish={setFinish}
          setFinishText={setFinishText}
        />
        <AddressDelete
          address={address}
          setAddressEditIsOpen={setAddressEditIsOpen}
          setPopUpIsOpen={setPopUpIsOpen}
          setPopUpContent={setPopUpContent}
          setFinish={setFinish}
          setFinishText={setFinishText}
        />
      </div>
    </div>
  );
}
