import React from 'react';
import obj_heb from '../utils/Obj_heb.json';
import {icons} from '../assets/constants';
import Button from './Button';

export default function GetDibbleCoinsModalChildren({
  creditValues,
  activeBalanceReq,
  setActiveBalanceReq,
  selectedOption,
  setSelectedOption,
  setIsModalOpen,
}) {
  return (
    <div className="get-dibble-coins-modal__mobileModal">
      <div className="get-dibble-coins-modal__mobileModal__title">
        {obj_heb.budget}
      </div>
      <div className="get-dibble-coins-modal__mobileModal__body">
        {creditValues.map((item, index) => (
          <>
            <div className="get-dibble-coins-modal__mobileModal__body__item">
              <span>
                <img
                  className="get-dibble-coins-modal__mobileModal__body__checkIcon"
                  style={{opacity: '0'}}
                  src={icons.yellow_check}
                  alt="yellow_check"
                  title="yellow_check"
                />
              </span>
              <div
                onClick={e => {
                  setActiveBalanceReq(item);
                  setSelectedOption(item);
                }}
                style={activeBalanceReq === item ? {fontWeight: 600} : {}}>
                {item}
              </div>
              <span>
                <img
                  className="get-dibble-coins-modal__mobileModal__body__checkIcon"
                  style={activeBalanceReq !== item ? {opacity: '0'} : {}}
                  src={icons.yellow_check}
                  alt="yellow_check"
                  title="yellow_check"
                />
              </span>
            </div>
            {creditValues.length !== index + 1 && (
              <div className="hr-fullWidth">hr</div>
            )}
          </>
        ))}
        <div className="get-dibble-coins-modal__btnDiv">
          <Button
            text={selectedOption ? obj_heb.confirm : obj_heb.chooseCreditLine}
            disable={selectedOption ? false : true}
            onClick={() => setIsModalOpen(false)}
            textColor="white"
            backgroundColor={selectedOption ? '#FFCA1A' : '#BDBCBC'}
            className="get-dibble-coins-modal__btnDiv__btn"
          />
        </div>
      </div>
    </div>
  );
}
