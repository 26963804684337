import classNames from 'classnames';
import React from 'react';

const Toggle = ({toggle, handleToggleChange}) => {
  return (
    <div
      className={classNames(
        'toggle-container',
        toggle && 'toggle-container__gradient',
      )}
      onClick={handleToggleChange}>
      <div
        className={classNames(
          `toggle-container__btn`,
          !toggle && 'toggle-container__disable',
        )}></div>
    </div>
  );
};

export default Toggle;
