import React, {Fragment, forwardRef} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import {useSelector, useDispatch} from 'react-redux';
import {
  setSelectedAddress,
  fetchAddressesAndSetInitialList,
} from '../redux/store';

import ButtonAdd from './ButtonAdd';
import MyAddressesItem from './MyAddressesItem';
import Modal from './general/Modal';
import classNames from 'classnames';

const MyAddresses = forwardRef(
  (
    {
      isOpen,
      setIsOpen,
      addAddressIsOpen,
      setAddAddressIsOpen,
      setBgDiv,
      className,
    },
    ref,
  ) => {
    const dispatch = useDispatch();

    // get the selected address and the addresses list
    const selectedAddress = useSelector(
      state => state.addressSlice.selectedAddress,
    );

    const isMobile = useSelector(state => state.mobile.isMobile);

    const addressesList = useSelector(
      state => state.addressSlice.addressesList,
    ).filter(item => item?.id !== selectedAddress?.id);

    // change the selected address
    const handleSelect = async item => {
      // is there is already a selected address- fetching addresses again
      if (selectedAddress) {
        dispatch(fetchAddressesAndSetInitialList())
          .then(() => {
            dispatch(setSelectedAddress(item));
          })
          .catch(error => {
            // Handle errors
            console.log('fetchAddressesAndSetInitialList', error);
          });
      } else {
        dispatch(setSelectedAddress(item));
      }

      setIsOpen(false);
      // setBgDiv(null);
    };

    // open the add address modal
    const handleAddressAddition = () => {
      setAddAddressIsOpen(true);
      setIsOpen(false);
      // setBgDiv(null);
    };

    return (
      <>
        {isMobile ? (
          <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="my-addresses">
              <span className="my-addresses__title">
                {obj_heb.myAddresses}
                {addressesList.length > 8 && (
                  <div className="my-addresses__title--screening"></div>
                )}
              </span>

              {addressesList.length >= 1 ? (
                <div className="my-addresses__list">
                  {addressesList
                    .filter(item => item?.address !== selectedAddress?.address)
                    .map((item, index) => {
                      return (
                        <div
                          className="my-addresses__list--item"
                          key={JSON.stringify(item)}>
                          <MyAddressesItem item={item} onClick={handleSelect} />
                          {index !== [...addressesList].length - 1 && (
                            <span className="my-addresses__list--separator" />
                          )}
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="my-addresses__no-addresses">
                  {obj_heb.thereIsNoMoreAddresses}
                </div>
              )}
              <ButtonAdd
                text={obj_heb.addAddress}
                onClick={handleAddressAddition}
              />
            </div>
          </Modal>
        ) : (
          <div ref={ref} className={classNames('my-addresses', className)}>
            {addressesList.length >= 1 ? (
              <>
                {!className && (
                  <span className="my-addresses__title">
                    {obj_heb.myAddresses}
                  </span>
                )}
                <div className="my-addresses__list">
                  {addressesList
                    .filter(item => item?.address !== selectedAddress?.address)
                    .map((item, index) => {
                      return (
                        <div
                          className="my-addresses__list--item"
                          key={JSON.stringify(item)}>
                          <MyAddressesItem item={item} onClick={handleSelect} />
                          {index !== [...addressesList].length - 1 && (
                            <span className="my-addresses__list--separator" />
                          )}
                        </div>
                      );
                    })}
                </div>
              </>
            ) : (
              <div className="my-addresses__no-addresses">
                {obj_heb.thereIsNoMoreAddresses}
              </div>
            )}
            <ButtonAdd
              text={obj_heb.addAddress}
              onClick={handleAddressAddition}
              className="my-addresses__btn"
            />
          </div>
        )}
      </>
    );
  },
);

export default MyAddresses;
