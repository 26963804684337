import {useSelector, useDispatch} from 'react-redux';
import {setIsDownloadAppShown} from '../redux/store';
import Footer from './Footer';
import MobileFooter from './MobileFooter';
import NavBar from './NavBar';
import ShoppingCart from '../components/shoppingCart/ShoppingCartWidget';
import DownloadAppBanner from '../components/DownloadAppBanner';
import classNames from 'classnames';
import {useState} from 'react';
import {fetchData} from '../utils/fetchData';
import {useEffect} from 'react';

function LayoutCart({
  children,
  needAddress = true,
  cancelFooterOnMobile,
  navBarBack,
  hideDibbleIcon = false,
  navBarCart,
}) {
  const [fastDeliveyStatus, setFastdeliveryStatus] = useState(false);
  const [fastDeliveyMessage, fastDeliveryMessage] = useState(null);

  const isMobile = useSelector(state => state.mobile.isMobile);
  const isDownloadAppShown = useSelector(
    state => state.mobile.isDownloadAppShown,
  );
  const get_fast_delivery_data = () => {
    let dataObj = {
      request: 'get_active_hours',
    };

    fetchData(dataObj).then(response => {
      console.log(response?.fast_delivery);
      if (response?.fast_delivery.enabled) {
        setFastdeliveryStatus(response?.fast_delivery.enabled);
      }
      if (response?.fast_delivery.message) {
        fastDeliveryMessage(response?.fast_delivery.message);
      }
    });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    get_fast_delivery_data();
  }, []);

  return (
    <div
      className={classNames(
        'layout full-page',
        isDownloadAppShown && 'layout--download-banner',
      )}>
      {isDownloadAppShown && (
        <DownloadAppBanner
          setIsShown={() => dispatch(setIsDownloadAppShown(false))}
        />
      )}
      <NavBar
        needAddress={needAddress}
        navBarBack={navBarBack}
        hideDibbleIcon={hideDibbleIcon}
        navBarCart={navBarCart}
        fastDeliveyStatus={fastDeliveyStatus}
        fastDeliveyMessage={fastDeliveyMessage}
      />
      <div className="content">
        {!isMobile ? (
          <div className="content__divide">
            {children}
            <ShoppingCart />
          </div>
        ) : (
          children
        )}
      </div>
      {!isMobile ? <Footer /> : !cancelFooterOnMobile && <MobileFooter />}
    </div>
  );
}

export default LayoutCart;
