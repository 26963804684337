import React, {useState} from 'react';
import obj_heb from '../../utils/Obj_heb.json';
import {icons} from '../../assets/constants';

//Intercom Context
import {useIntercomContext} from '../../utils/IntercomContext';
import {useSelector} from 'react-redux';

const MoreInfo = ({productMainData}) => {
  const [showMoreOpen, setShowMoreOpen] = useState(false);
  const {openIntercom} = useIntercomContext();
  const isMobile = useSelector(state => state.mobile.isMobile);

  return (
    <div
      className={'Product_more_info'}
      style={
        showMoreOpen
          ? {}
          : productMainData?.details?.length > 0
          ? {
              height: '76px',
              overflow: 'hidden',
            }
          : {}
      }>
      {productMainData?.details?.length > 0 && (
        <>
          <div
            className="Product_more_info_title"
            onClick={() => {
              setShowMoreOpen(prev => !prev);
            }}>
            {obj_heb.moreInfo}
          </div>
          <img
            onClick={() => {
              setShowMoreOpen(prev => !prev);
            }}
            className={
              showMoreOpen
                ? 'Product_more_info_icon_close'
                : 'Product_more_info_icon'
            }
            src={icons.arrow_head_down}
            alt="arrow_head_down"
          />
          <div className="Product_more_info_Text-part">
            {productMainData?.details?.map((item, index) => {
              return (
                <React.Fragment key={`${index}_${item.name}`}>
                  <div className="Product_more_info_Text-part_sub-title">
                    {item.name} {item.details}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </>
      )}

      {productMainData?.details?.length > 0 && !showMoreOpen && (
        <div className="Product_more_info_inner" />
      )}
      {productMainData?.details?.length > 0 && (
        <div className="Product_more_info_hr">-</div>
      )}
      <div className="Product_more_info_did-we-forgot">
        <div>
          <div className="Product_more_info_did-we-forgot_title">
            {obj_heb.didWeForgot}
          </div>
          <div className="Product_more_info_did-we-forgot_txt">
            {obj_heb.didWeForgotTxt}
          </div>
        </div>

        {(showMoreOpen || productMainData?.details?.length === 0) && (
          <img
            alt="chat icon"
            className="pointer Product_more_info_chat_icon"
            src={isMobile ? icons.chat_icon_mobile : icons.productChatIcon}
            onClick={() => {
              openIntercom();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MoreInfo;
