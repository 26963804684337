import React from 'react';
import classNames from 'classnames';
import icons from '../assets/constants/icons';

function ButtonAdd({text, onClick, disable, className}) {
  return (
    <div
      className={classNames('button-add', disable ? 'disabled' : '', className)}
      onClick={onClick}>
      <div className="button-add__text">{text}</div>
      <img src={icons.plus_yellow} alt="plus" />
    </div>
  );
}

export default ButtonAdd;
