import React from 'react';
import classNames from 'classnames';

function Button({
  text,
  onClick,
  textColor,
  backgroundColor,
  borderColor,
  disable,
  className,
  textSize = null,
  mobileBottomBackGround = false,
  id = null,
}) {
  return (
    <div
      className={classNames('button', disable ? 'disabled' : '', className)}
      style={{
        backgroundColor: backgroundColor,
        border: borderColor && `2px solid ${borderColor}`,
      }}
      onClick={onClick}
      id={id}>
      <div
        className="button__text"
        style={{color: textColor, fontSize: `${textSize}px`}}>
        {text}
      </div>
      {mobileBottomBackGround && (
        <div className="button--mobileBottomBackGround">--</div>
      )}
    </div>
  );
}

export default Button;
