import React, {useEffect, useState, useRef} from 'react';
import dayCalender from '../assets/icons/dayCalender.svg';
import arrow_head_down from '../assets/icons/arrow_head_down.svg';
import DaysSelector from './DaysSelector';

import {days} from '../utils/supportFunctions';
import obj_heb from '../utils/Obj_heb.json';
import classNames from 'classnames';

function DayInput({day, setDay, daysOptions, slot, setHour, popUpIsOpen}) {
  const [isOpen, setIsOpen] = useState(false);

  const containerRef = useRef();

  let weekDays = days();

  const handleSelect = item => {
    setHour(null);
    setDay(item);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!popUpIsOpen) {
      setIsOpen(false);
      if (!slot) {
        setDay(null);
        setHour(null);
      }
    }
  }, [popUpIsOpen]);

  useEffect(() => {
    // Function to handle click outside the div
    const handleClickOutside = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        // The click is outside the div, do something here
        setIsOpen(prev => false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="day-input" ref={containerRef}>
      <div className="day-input__item" onClick={() => setIsOpen(prev => !prev)}>
        <div
          className={classNames(
            'day-input__item--title',
            day && 'day-input__item--selected',
          )}>
          <img src={dayCalender} alt="" className="day-input__item--icon" />
          <span>
            {!day
              ? 'בחירת תאריך'
              : `${obj_heb.onDay} ${weekDays[day.day]} ${day.date} `}
          </span>
        </div>
        <img
          className={classNames('day-input__item--arrow', isOpen && 'flip')}
          src={arrow_head_down}
          alt="location"
        />
      </div>
      {isOpen && (
        <DaysSelector handleSelect={handleSelect} dateOptions={daysOptions} />
      )}
    </div>
  );
}

export default DayInput;
