// react addons
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

// functions
import obj_heb from '../../utils/Obj_heb.json';

//components
import Dropdown from '../../components/general/DropDown';
import ShoppingCartItemAmount from '../../components/shoppingCart/ShoppingCartItemAmount';
import Gallery from '../../components/Gallery';
import Button from '../Button';
import ShoppingCartPrice from '../shoppingCart/ShoppingCartPrice';

// assets
import {icons} from '../../assets/constants/index';
import ProductShareModal from '../ProductShareModal';
import ProductMultiOptionModal from './ProductMultiOptionModal';
import {fetchData} from '../../utils/fetchData';

export default function ProductContent({
  productMainData,
  pickedOption,
  addToCart,
  productAmount,
  setProductAmount,
  isLoading,
  isLiked = false,
  liked_disLikedFunction,
  userData,
  dropDownOptions1 = null,
  dropDownOptions2 = null,
  updateShoppingCart,
}) {
  const [vat, setVat] = useState(0);
  const [addBtnFullWidth, setAddBtnFullWidth] = useState(false);
  const [displayAddToCart, setDisplayAddToCart] = useState(true);
  // the share modal
  const [IsModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useSelector(state => state.mobile.isMobile);
  // the modal for the multi option 1
  const [multiOptions1ModalIsOpen, setMultiOptions1ModalIsOpen] =
    useState(false);
  // the modal for the multi option 2
  const [multiOptions2ModalIsOpen, setMultiOptions2ModalIsOpen] =
    useState(false);

  const shoppingCart = useSelector(state => state.shopping_cart.products);
  // to set the names 0f the multi options title
  const multiOptionsGroupName = [
    productMainData?.options1_name,
    productMainData?.options2_name,
  ].filter(item => item !== null);

  useEffect(() => {
    let dataObj = {
      request: 'get_vat_rate',
    };
    fetchData(dataObj).then(response => {
      if (response?.message === 'success')
        setVat(convertStringToNumber(response.vat_rate.value));
    });
  }, []);

  useEffect(() => {
    const changeAddToCartBtnWidth = () => {
      const stickyElement = document.getElementById(
        'product-content-first-box',
      );
      if (!isMobile) {
        if (stickyElement) {
          const locationOfTheOffSetDiv = -1 * stickyElement?.clientHeight + 200;
          if (window.scrollY >= 270) {
            setAddBtnFullWidth(true);
          } else if (window.scrollY < 170) {
            stickyElement.style.position = 'relative';
            stickyElement.style.top = '0px';
            setAddBtnFullWidth(false);
          }

          stickyElement.style.position = 'sticky';
          stickyElement.style.top = `${locationOfTheOffSetDiv}px`;
        }
      }
    };
    document.addEventListener('scroll', changeAddToCartBtnWidth);
    return () =>
      document.removeEventListener('scroll', changeAddToCartBtnWidth);
  }, []);
  /**
   * this is to know if the current product is in the shopping cart
   */
  const convertStringToNumber = input => {
    const number = parseFloat(input);
    const convertedNumber = number / 100 + 1;
    return convertedNumber;
  };
  const prod_in_shoppingCart = shoppingCart.filter(item => {
    if (item.product_id === productMainData.product_id) {
      if (item.options1_name && item.options2_name) {
        if (
          item.option1_index === productMainData.option1_index &&
          item.option2_index === productMainData.option2_index
        ) {
          return true;
        }
      } else if (item.options1_name) {
        if (item.option1_index === productMainData.option1_index) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  })[0];
  const shareFunction = () => {
    setDisplayAddToCart(false);
    setIsModalOpen(true);
  };
  // the index of the map
  const multiOptionsModal = index => {
    if (index == 0) {
      setMultiOptions1ModalIsOpen(true);
      setDisplayAddToCart(false);
    } else if (index == 1) {
      setMultiOptions2ModalIsOpen(true);
      setDisplayAddToCart(false);
    }
  };
  const closeOptionsModals = () => {
    setMultiOptions1ModalIsOpen(false);
    setMultiOptions2ModalIsOpen(false);
    setDisplayAddToCart(true);
  };

  const replaceWord = (inputString, wordToReplace, replacement) =>
    inputString.split(wordToReplace).join(replacement);

  return (
    isLoading === false && (
      <React.Fragment>
        <div
          id="product-content-first-box"
          className="Product__content_first-box">
          <div
            className={
              addBtnFullWidth
                ? 'Product__content_first-box_image-carusale_part--hidden'
                : 'Product__content_first-box_image-carusale_part'
            }>
            <div className="Product__content_first-box_image-carusale_part_innerPart">
              {productMainData?.other_images && (
                <Gallery
                  images={[...productMainData?.other_images, productMainData]}
                  leftBtnStyle={{left: '1.6rem'}}
                  left_line_seperaator={isMobile ? false : true}
                  page="product"
                />
              )}
              {userData && (
                <div
                  onClick={() => liked_disLikedFunction()}
                  className="Product__content_first-box_image-carusale_part_innerPart_heartIcon">
                  <img
                    src={isLiked ? icons.full_heart_icon : icons.heart_icon}
                    alt="heart icon"
                  />
                </div>
              )}
              <div
                onClick={() => shareFunction()}
                className="Product__content_first-box_image-carusale_part_innerPart_shareIcon">
                <img src={icons.share_icon} alt="heart icon" />
              </div>
            </div>
          </div>
          <div
            className={
              addBtnFullWidth
                ? 'Product__content_first-box_product-Text-part--fullWidth'
                : 'Product__content_first-box_product-Text-part'
            }>
            <div className="Product__content_first-box_product-Text-part_title">
              {productMainData?.name}
            </div>
            <div className="Product__content_first-box_product-Text-part_sub-title">
              <div
                style={{marginBottom: '0.8rem'}}
                dangerouslySetInnerHTML={{
                  __html: replaceWord(
                    String(productMainData?.description),
                    'href',
                    '',
                  ),
                }}
              />
              {(productMainData?.can_return === 1 ||
                productMainData?.need_crane === 1 ||
                productMainData?.illustration_image === 1) && (
                <ul className="Product__content_first-box_product-Text-part_sub-title_disclaimer">
                  {productMainData?.can_return === 1 && (
                    <li>{obj_heb.itemCantReturn}</li>
                  )}
                  {productMainData?.need_crane === 1 && (
                    <li>{obj_heb.itemWillSendWithCrane}</li>
                  )}
                  {productMainData?.illustration_image === 1 && (
                    <li>{obj_heb.imageToDemostration}</li>
                  )}
                </ul>
              )}
            </div>
            <div className="Product__content_first-box_product-Text-part_hr">
              -
            </div>
            <div className="Product__content_first-box_product-Text-part_price-box">
              <div className="Product__content_first-box_product-Text-part_price-box_title">
                {obj_heb.price}
              </div>
              <div
                className={
                  productMainData?.sale_price &&
                  productMainData?.sale_price < productMainData?.price
                    ? ''
                    : 'Product__content_first-box_product-Text-part_price-box_price_element'
                }>
                <ShoppingCartPrice
                  priceDefaultBlackColor={true}
                  price={
                    productMainData?.price * vat * productMainData?.quantity
                  }
                  sale={
                    productMainData?.sale_price !== 0 &&
                    productMainData?.sale_price < productMainData?.price
                  }
                  salePrice={
                    productMainData?.sale_price *
                    vat *
                    productMainData?.quantity
                  }
                />
              </div>
            </div>
            <div className="Product__contesnt_first-box_product-Text-part_options-container">
              {multiOptionsGroupName?.map(
                (item, index) =>
                  item != '' && (
                    <React.Fragment>
                      <div className="Product__content_first-box_product-Text-part_options-container_hr">
                        -
                      </div>
                      <div className="Product__content_first-box_product-Text-part_options-container_option-box">
                        <div className="Product__content_first-box_product-Text-part_options-container_option-box_title">
                          {item}:
                        </div>
                        {isMobile ? (
                          <>
                            <div
                              className="Product__content_first-box_product-Text-part_options-container_option-box--mobile__model--closeBox"
                              onClick={() => multiOptionsModal(index)}>
                              {
                                productMainData[
                                  'option' + String(index + 1) + '_value'
                                ]
                              }
                              <img
                                src={icons.arrow_head_left}
                                alt="arrow_head_left"
                                title="arrow_head_left"
                              />
                            </div>
                            <ProductMultiOptionModal
                              key={JSON.stringify(
                                productMainData[
                                  'option' + String(index + 1) + '_value'
                                ],
                              )}
                              index={index}
                              multiOptions1ModalIsOpen={
                                multiOptions1ModalIsOpen
                              }
                              multiOptions2ModalIsOpen={
                                multiOptions2ModalIsOpen
                              }
                              setMultiOptions1ModalIsOpen={
                                setMultiOptions1ModalIsOpen
                              }
                              setMultiOptions2ModalIsOpen={
                                setMultiOptions2ModalIsOpen
                              }
                              closeCallback={closeOptionsModals}
                              dropDownOptions1={dropDownOptions1}
                              dropDownOptions2={dropDownOptions2}
                              productMainData={productMainData}
                              title={item}
                              defaultOption={
                                productMainData[
                                  'option' + String(index + 1) + '_value'
                                ]
                              }
                              pickedOption={pickedOption}
                            />
                          </>
                        ) : (
                          <Dropdown
                            key={String(productMainData?.name) + index}
                            options={
                              index == 0 ? dropDownOptions1 : dropDownOptions2
                            }
                            defaultOption={
                              productMainData[
                                'option' + String(index + 1) + '_value'
                              ]
                            }
                            onSelect={selected => {
                              pickedOption(selected);
                            }}
                            outSideClickClosing={true}
                            withHoverGradient={true}
                          />
                        )}
                      </div>
                    </React.Fragment>
                  ),
              )}
            </div>

            <div className="Product__content_third_part">
              <div className="Product__content_third_part_hr">-</div>

              <div className="Product__content_third_part_buttons-section">
                {isMobile ? (
                  <>
                    <div className="w100">
                      <div className="Product__content_first-box_product-Text-part_options-container_option-box_title">
                        {obj_heb.amount}
                      </div>
                      <div className="flexCenter">
                        <div className="Product__content_third_part_buttons-section_counter">
                          <ShoppingCartItemAmount
                            Amount={productAmount}
                            isActive={true}
                            decrementFunction={setProductAmount}
                            incrementFunction={setProductAmount}
                            insertNumberFunction={setProductAmount}
                            setWidth={18}
                            setHight={5.6}
                            btnSize={4}
                            fontSize={2.2}
                            insertNumberFunctionBoolean={true}
                          />
                        </div>
                      </div>
                    </div>
                    <ProductShareModal
                      IsModalOpen={IsModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      setDisplayAddToCart={setDisplayAddToCart}
                      productMainData={productMainData}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      text={
                        prod_in_shoppingCart
                          ? obj_heb.updateCart
                          : obj_heb.addToCart
                      }
                      onClick={() => {
                        prod_in_shoppingCart
                          ? updateShoppingCart(prod_in_shoppingCart?.key)
                          : addToCart();
                      }}
                      textColor={
                        prod_in_shoppingCart
                          ? 'var(--dibble-yellow)'
                          : 'var(--paragraph-white)'
                      }
                      backgroundColor={
                        prod_in_shoppingCart
                          ? 'var(--white)'
                          : 'var(--dibble-yellow)'
                      }
                      borderColor={
                        prod_in_shoppingCart ? 'var(--dibble-yellow)' : null
                      }
                      className="Product__content_third_part_buttons-section_btn"
                    />
                    <div className="Product__content_third_part_buttons-section_counter">
                      <ShoppingCartItemAmount
                        Amount={productAmount}
                        isActive={true}
                        decrementFunction={setProductAmount}
                        incrementFunction={setProductAmount}
                        insertNumberFunction={setProductAmount}
                        setWidth={15.8}
                        setHight={3.6}
                        btnSize={2.8}
                        fontSize={2.2}
                        insertNumberFunctionBoolean={true}
                      />
                    </div>
                  </>
                )}

                {isMobile && displayAddToCart && (
                  <div className="Product__content_third_part_mobile_addTocCartBTN">
                    <Button
                      mobileBottomBackGround={isMobile}
                      text={
                        prod_in_shoppingCart
                          ? obj_heb.updateCart
                          : obj_heb.addToCart
                      }
                      onClick={() => {
                        prod_in_shoppingCart
                          ? updateShoppingCart(prod_in_shoppingCart?.key)
                          : addToCart();
                      }}
                      textColor={
                        prod_in_shoppingCart
                          ? 'var(--dibble-yellow)'
                          : 'var(--paragraph-white)'
                      }
                      backgroundColor={
                        prod_in_shoppingCart
                          ? 'var(--white)'
                          : 'var(--dibble-yellow)'
                      }
                      borderColor={
                        prod_in_shoppingCart ? 'var(--dibble-yellow)' : null
                      }
                      className="Product__content_third_part_buttons-section_btn"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  );
}
