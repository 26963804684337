import React, {useState, useEffect, useRef} from 'react';
import {fetchData} from '../utils/fetchData';
import Title from '../components/Title';
import SEO from '../components/SEO';
import Loader from '../components/Loader';
import obj_heb from '../utils/Obj_heb.json';
import OrderHistoryItem from '../components/OrderHistoryItem';

export default function AllOrders() {
  const [allOrdersList, setAllOrdersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData')),
  );

  useEffect(() => {
    let dataObj = {
      request: 'get_my_orders',
      token: userData?.token || '',
    };
    fetchData(dataObj)
      .then(response => {
        const data = response;
        setAllOrdersList(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  }, [userData]);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : null}
      <SEO
        pageName={obj_heb.my_orders}
        content={obj_heb.my_orders}
        link="/AllOrders"
      />
      <div className="results">
        <Title title={obj_heb.my_orders} />
        {allOrdersList?.orders?.length ? (
          <div className="results__subHeader">
            <div className="results__subHeader__count">
              {allOrdersList?.orders.length} {obj_heb.orders}
            </div>
          </div>
        ) : null}

        <div className="results__container--bgWhite">
          {allOrdersList?.orders?.map(order => {
            return <OrderHistoryItem item={order} />;
          })}
        </div>
      </div>
    </React.Fragment>
  );
}
