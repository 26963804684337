import React, {useState, useEffect} from 'react';
import {fetchData} from '../utils/fetchData';
import Title from './Title';
import FoundProduct from './FoundProduct';
import SEO from './SEO';
import Loader from '../components/Loader';
import obj_heb from '../utils/Obj_heb.json';

export default function LikedProducts() {
  const [likedProductsList, setLikedProductsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData')),
  );

  useEffect(() => {
    let likedProductDataObj = {
      request: 'get_user_liked_products',
      token: userData?.token,
    };
    fetchData(likedProductDataObj)
      .then(response => {
        const data = response;
        if (data.products) {
          setLikedProductsList(data.products);
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : null}
      <SEO
        pageName={obj_heb.likedProducts || '_'}
        content={obj_heb.likedProducts}
        link="/likedProducts"
      />
      <div className="results">
        <Title title={obj_heb.likedProducts || ''} />
        {likedProductsList && likedProductsList.length ? (
          <div className="results__subHeader">
            <div className="results__subHeader__count">
              {likedProductsList.length} {obj_heb.results}
            </div>
          </div>
        ) : null}

        <div className="results__container">
          {likedProductsList &&
            likedProductsList.map((product, index) => {
              return (
                <FoundProduct product={product} key={product.product_id} />
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
}
