import React from 'react';

import obj_heb from '../utils/Obj_heb.json';
import classNames from 'classnames';

function CreditCardEdition({setCardOptionsIsOpen, setPopUpIsOpen}) {
  const handleClick = () => {
    setPopUpIsOpen(true);
    setCardOptionsIsOpen(false);
  };

  return (
    <div className="credit-card__edit--field" onClick={() => handleClick()}>
      <div className={classNames('credit-card__edit--field--text')}>
        {obj_heb.editCardDetails}
        <span className="is-hover" />
      </div>
      <span className="credit-card__edit--field--separator" />
    </div>
  );
}

export default CreditCardEdition;
