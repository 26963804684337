import React from 'react';
import Modal from './general/Modal';

// functions
import obj_heb from '../utils/Obj_heb.json';

// assets
import {icons} from '../assets/constants/index';

//3rd lib
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
} from 'react-share';

import QRCode from 'react-qr-code';
export default function ProductShareModal({
  IsModalOpen,
  setIsModalOpen,
  setDisplayAddToCart,
  productMainData,
}) {
  const currentPageUrl = window.location.href;

  return (
    <Modal
      isOpen={IsModalOpen}
      setIsOpen={setIsModalOpen}
      closeFunction={() => {
        setDisplayAddToCart(true);
      }}>
      <>
        <div className="Product_shareModal_iconsContainer_title">
          {obj_heb.share}
        </div>
        <div className="Product_shareModal_iconsContainer_productMainShare">
          <div className="Product_shareModal_iconsContainer_productMainShare_text">
            {productMainData?.name}
          </div>
          <img
            className="Product_shareModal_iconsContainer_productMainShare_image"
            src={productMainData?.image}
            alt="product img"
            title="product img"
          />
        </div>
        <div className="Product_shareModal_iconsContainer">
          <div className="Product_shareModal_iconsContainer_icons">
            <div className="Product_shareModal_iconsContainer_icons_iconNameGroup">
              <WhatsappShareButton url={currentPageUrl}>
                <WhatsappIcon size={32} />
              </WhatsappShareButton>
              <div className="Product_shareModal_iconsContainer_icons_iconNameGroup_text">
                {obj_heb.whatsapp}
              </div>
            </div>
            <div className="Product_shareModal_iconsContainer_icons_iconNameGroup">
              <FacebookMessengerShareButton url={currentPageUrl}>
                <FacebookMessengerIcon size={32} />
              </FacebookMessengerShareButton>
              <div className="Product_shareModal_iconsContainer_icons_iconNameGroup_text">
                {obj_heb.messenger}
              </div>
            </div>
            <div className="Product_shareModal_iconsContainer_icons_iconNameGroup">
              <EmailShareButton url={currentPageUrl}>
                <EmailIcon size={32} />
              </EmailShareButton>
              <div className="Product_shareModal_iconsContainer_icons_iconNameGroup_text">
                {obj_heb.email}
              </div>
            </div>
            <div className="Product_shareModal_iconsContainer_icons_iconNameGroup">
              <FacebookShareButton url={currentPageUrl}>
                <FacebookIcon size={32} />
              </FacebookShareButton>
              <div className="Product_shareModal_iconsContainer_icons_iconNameGroup_text">
                {obj_heb.facebook}
              </div>
            </div>
            {/*<div
      className=""
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      onClick={() => {
        console.log('123');
      }}>
      <img
        src={icons.qr_code}
        alt="qr code"
        title="qr code"
      />
      QR
    </div> */}
            <div
              className="Product_shareModal_iconsContainer_icons_iconNameGroup"
              onClick={async () => {
                await navigator.clipboard.writeText(currentPageUrl);

                console.log('456');
              }}>
              <img
                className="Product_shareModal_iconsContainer_icons_iconNameGroup_icon"
                src={icons.copy}
                alt="copy"
                title="copy"
              />
              <div className="Product_shareModal_iconsContainer_icons_iconNameGroup_text">
                Copy url
              </div>
            </div>
            {/* qr code snipit for the popup fro the qr  */}
            {/*  <QRCode
      size={256}
      style={{
        height: 'auto',
        maxWidth: '55%',
        width: '55%',
      }}
      value={currentPageUrl}
      viewBox={`0 0 256 256`}
    /> */}
          </div>
        </div>
      </>
    </Modal>
  );
}
