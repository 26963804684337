import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {icons} from '../assets/constants';
import {ShoppingCart_removeProduct} from '../redux/slices/ShoppingCartSlice';

import obj_heb from '../utils/Obj_heb.json';

import Title from '../components/Title';
import SEO from '../components/SEO';
import PopUp from '../components/general/PopUp';
import SummaryComponent from '../components/SummaryComponent';
import RecommendedForYou from '../components/RecommendedForYou';
import Toast from '../components/Toast';
import ShoppingCartItem from '../components/shoppingCart/ShoppingCartItem';
import PopUpConfirm from '../components/PopUpConfirm';
import PopUpValidations from '../components/general/popUpsValidations';
import classNames from 'classnames';

export default function Cart() {
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [removalAnimation, setRemovalAnimation] = useState(false);
  const [product, setProduct] = useState(null);

  const shoppingCartData = useSelector(state => state.shopping_cart.products);

  const hasBlackProduct =
    shoppingCartData.filter(i => i.black_Product).length > 0;

  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData')),
  );

  const isMobile = useSelector(state => state.mobile.isMobile);

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <SEO pageName={obj_heb.my_cart} content={obj_heb.cartDesc} link="/cart" />
      <div className="My_cart">
        {popUpIsOpen && (
          <PopUp isOpen={popUpIsOpen} setIsOpen={setPopUpIsOpen}>
            <PopUpConfirm
              img={icons.card_delete_icon}
              title={obj_heb.sureQuestion}
              subTitle={obj_heb.removeFromCart}
              onConfirm={() => {
                dispatch(ShoppingCart_removeProduct({key: product}));
                setPopUpIsOpen(false);
                setRemovalAnimation(true);
                setTimeout(() => {
                  setRemovalAnimation(false);
                }, 7800);
              }}
              onCancel={() => {
                setPopUpIsOpen(false);
              }}
            />
          </PopUp>
        )}

        <Title title={obj_heb.my_cart} />
        <div
          className={classNames(
            'My_cart__container',
            hasBlackProduct
              ? 'My_cart__container--mt8'
              : 'My_cart__container--mt32',
          )}>
          {hasBlackProduct ? (
            <div className="My_cart__black_mat_div">
              <span className="My_cart__black_mat">{obj_heb.black_mat}</span>
              <span className="My_cart__black_mat2">{obj_heb.black_mat2}</span>
            </div>
          ) : null}
          {shoppingCartData.length === 0 ? (
            <div className="center-container">
              <div className="noProducts">{obj_heb.emptyCartSlogen1}</div>
            </div>
          ) : (
            ''
          )}

          {shoppingCartData.length !== 0 && (
            <div className="cart_parent">
              {isMobile ? (
                <>
                  {shoppingCartData.length !== 0 &&
                    shoppingCartData?.map((item, index) => {
                      return (
                        <ShoppingCartItem
                          key={String('ShoppingCartItem' + index)}
                          item={item}
                          setPopUpIsOpen={setPopUpIsOpen}
                          setProduct={setProduct}
                          mobileCart={isMobile}
                          className="card-shadow"
                        />
                      );
                    })}
                </>
              ) : (
                <div className="My_Shopping-cart">
                  <div className="My_Shopping-cart_inside-elements">
                    <div className="My_Shopping-cart_scroll-Section">
                      {shoppingCartData.length !== 0 &&
                        shoppingCartData?.map((item, index) => {
                          return (
                            <React.Fragment
                              key={String('ShoppingCartItem' + index)}>
                              <div
                                className={
                                  index === 0
                                    ? null
                                    : 'Shopping-cart__items-separator'
                                }></div>
                              <ShoppingCartItem
                                key={String('ShoppingCartItem' + index)}
                                item={item}
                                setPopUpIsOpen={setPopUpIsOpen}
                                setProduct={setProduct}
                                mobileCart={isMobile}
                              />
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </div>
                  <div>
                    <div className=""></div>
                  </div>
                </div>
              )}
              {shoppingCartData.length !== 0 && (
                <SummaryComponent action="cart" />
              )}
            </div>
          )}

          {(!isMobile || (shoppingCartData.length === 0 && isMobile)) && (
            <RecommendedForYou num={6} />
          )}
          {removalAnimation && (
            <Toast text={obj_heb.product_is_deleted_from_cart} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
