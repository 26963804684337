import React, {useState, useEffect, useRef, Fragment} from 'react';
import arrow_head_down from '../../assets/icons/arrow_head_down.svg';
import classNames from 'classnames';

/**
 *
 * @param {string} options - the value inside the drop down
 * @param {string} defaultOption - the base value
 * @param {function} onSelect - the function that runs when choosing
 * @param {boolean} outSideClickClosing - does clicking outside will close the box by default - true
 */
const Dropdown = ({
  options,
  defaultOption,
  onSelect,
  outSideClickClosing = true,
  dropDownWithTitle,
  title,
  placeHolder,
  height,
  width,
  withHoverGradient = false,
  ltr = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = event => {
    if (outSideClickClosing) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
  };

  const handleSelect = option => {
    onSelect(option);
    defaultOption = option;
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div
      className={classNames(
        'dropdown',
        isOpen && 'dropdown_open',
        ltr && 'dropdown_ltr',
      )}
      style={{height: `${height}rem`, width: `${width}%`}}
      ref={dropdownRef}>
      <div className="dropdown-toggle" onClick={handleDropdownClick}>
        {dropDownWithTitle ? (
          <React.Fragment>
            <div className="dropdown__container">
              <div className="dropdown__container__title">{title}</div>

              <div
                className={
                  selectedOption
                    ? 'dropdown__container__content--userPick'
                    : 'dropdown__container__content'
                }>
                {selectedOption ? selectedOption : placeHolder}
              </div>
            </div>
            <img
              className={classNames(
                isOpen && 'flip',
                'dropdown-toggle_inside_icon_flip',
              )}
              src={arrow_head_down}
              alt="arrow_head_down"
            />
          </React.Fragment>
        ) : (
          <div className="dropdown-toggle_inside">
            {ltr ? (
              <>
                <img
                  className={classNames(
                    isOpen && 'flip',
                    'dropdown-toggle_inside_icon_flip',
                  )}
                  src={arrow_head_down}
                  alt="arrow_head_down"
                />
                {defaultOption}
              </>
            ) : (
              <>
                {defaultOption}
                <img
                  className={classNames(
                    isOpen && 'flip',
                    'dropdown-toggle_inside_icon_flip',
                  )}
                  src={arrow_head_down}
                  alt="arrow_head_down"
                />
              </>
            )}
          </div>
        )}
      </div>
      {isOpen && (
        <ul className="dropdown-menu">
          <div className="dropdown-menu_div">
            {options?.map((option, index) => (
              <Fragment key={'dropdown-item-' + JSON.stringify(option)}>
                <li
                  className={classNames(
                    'dropdown-menu_div_li',
                    dropDownWithTitle && 'dropdown-menu_div_li-center',
                    withHoverGradient && 'dropdown-menu_div_li_gradient',
                    ltr && 'dropdown-menu_div_li_ltr',
                  )}
                  onClick={() => handleSelect(option)}
                  key={index}>
                  {option}
                </li>
                {index < options?.length && <hr className="hr" />}
              </Fragment>
            ))}
          </div>
          {/* {options?.length > 4 && <div className="dropdown-menu_blur"></div>} */}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
