import React, {useState, useCallback, useEffect, useMemo} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import leftArrowHead from '../assets/icons/leftArrowHead.svg';
import {useNavigate, useLocation} from 'react-router-dom';
import heavyMaterialsImages from '../assets/images/heavyMaterials/images';
import {icons} from '../assets/constants';
import Title from '../components/Title';
import ShoppingCartItemAmount from '../components/shoppingCart/ShoppingCartItemAmount';
import {useDropzone} from 'react-dropzone';
import classNames from 'classnames';
import Button from './Button';
import ItemQuestion from './ItemQuestion';
import ChooseDateComponent from './ChooseDateComponent';
import {Document, Page, pdfjs} from 'react-pdf';
import {fetchData} from '../utils/fetchData';
import WidgetTypeListInput from './WidgetTypeListInput';
import ProgressBar from '../components/ProgressBar';
import {useSelector} from 'react-redux';
import AddressInput from './AddressInput';
import Loader from '../components/Loader';
import PopUp from '../components/general/PopUp';
import PopUpConfirm from '../components/PopUpConfirm';
import {useIntercomContext} from '../utils/IntercomContext';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function SelectMaterialTab({openMaterial}) {
  const [materialOrderGenre, setMaterialOrderGenre] = useState(
    new URLSearchParams(useLocation().search).get('order_genre'),
  );
  const {openIntercom} = useIntercomContext();
  const [addressError, setAddressError] = useState(false);
  const [qtyPlus, setQtyplus] = useState(false);
  const [amount, setAmount] = useState(1);
  const [fileId, setFileId] = useState([]);
  const location = useLocation();
  const [step, setStep] = useState(0);
  const [materialsData, setMaterialsData] = useState(null);
  const [materialObject, setMaterialObject] = useState(null);
  const [materialObjectElements, setMaterialObjectElements] = useState(null);
  const [files, setFiles] = useState([]);
  const [slot, setSlot] = useState([]);
  const [answerArr, setAnswerArr] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedItemsByKey, setSelectedItemsByKey] = useState({});
  const [loginError, setLoginError] = useState(false);
  const [listOption, setListOption] = useState([
    {
      id: 'question_1',
      question: 'האם יש באפשרותך לפרוק?',
      answer: [
        {label: 'לא, אני צריך מנוף', value: '0'},
        {label: 'כן', value: '1'},
      ],
      extra: '',
    },
    {
      id: 'question_2',
      question: 'האם ישנה מגבלת גובה?',
      answer: [
        {label: 'לא', value: '0'},
        {label: 'כן', value: '1'},
      ],
      extra: '',
    },
    {
      id: 'question_3',
      question: 'כמה נקודות פריקה?',
      answer: [
        {label: 'אחת (מדרכה בלבד)', value: '0'},
        {label: 'אחר', value: '1'},
      ],
      extra: '',
    },
    {
      id: 'question_4',
      question: 'האם יש צורך בפריקה בגובה?',
      answer: [
        {label: 'לא', value: '0'},
        {label: 'כן', value: '1'},
      ],
      extra: '',
    },
  ]);
  const navigate = useNavigate();
  const selectedAddress = useSelector(
    state => state.addressSlice.selectedAddress,
  );
  const isMobile = useSelector(state => state.mobile.isMobile);
  const [isLoading, setIsLoading] = useState(false); // New loading state

  //styles
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 64,
  };

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 150,
    height: 150,
    padding: 4,
    boxSizing: 'border-box',
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
  };
  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('order_genre')) {
      setMaterialOrderGenre(null);
    } else {
      setMaterialOrderGenre(searchParams.get('order_genre'));
    }
  }, [location.search]);
  useEffect(() => {
    if (openMaterial == 7) {
      setAmount(2);
      setQty('qty', 2);
    } else if (openMaterial == 6) {
      setAmount(200);
      setQty('qty', 200);
    }
  }, [openMaterial]);

  useEffect(() => {
    setQty('qty', 1);
  }, []);
  useEffect(() => {
    setStep(0);
  }, [location]);
  useEffect(() => {
    if (selectedItemsByKey['qty_plus']) {
      setQtyplus(true);
    } else {
      setQtyplus(false);
    }
  }, [selectedItemsByKey]);
  useEffect(() => {
    if (materialsData?.materials) {
      const material = materialsData.materials.find(
        material => material.order_genre == materialOrderGenre,
      );

      if (material) {
        setMaterialObject(material);
        setMaterialObjectElements(
          material.elements.map(element => ({
            ...element,
            active: null,
          })),
        );
      }
    }
  }, []);

  const handleOpenIntercom = () => {
    setLoginError(false);

    openIntercom(); // This will open the Intercom chat
  };
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem('userData'));

    let MaterialsDatataObj = {
      request: 'get_material_extra_info',
      token: userData?.token,
    };
    fetchData(MaterialsDatataObj)
      .then(response => {
        const data = response;
        if (data) {
          setMaterialsData(data);

          const material = data.materials.find(
            material => material.order_genre == materialOrderGenre,
          );
          if (material) {
            setMaterialObject(material);
            setMaterialObjectElements(
              material.elements.map(element => ({
                ...element,
                active: null,
              })),
            );
          }
        }
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  }, [location]);
  const bc = value => {
    setAnswerArr(value);
  };
  const removeThumb = name => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== name));
  };
  const orderMaterial = async () => {
    if (materialOrderGenre == 2 && step == 0 && files) {
      setIsLoading(true); // Set loading to true before uploading files
      await uploadFiles();
      setIsLoading(false); // Set loading to false after uploading files
    }
    if (step == 2 && openMaterial == 2) {
    } else {
      if (openMaterial == 2) {
        setStep(step + 1);
      } else {
        if (step == 0) {
          setStep(3);
        } else {
          setStep(step + 1);
        }
      }
    }
  };
  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        {file.type.startsWith('image/') ? (
          <div onClick={() => removeThumb(file.name)}>
            {' '}
            <img src={file.preview} style={img} alt="Uploaded" />
          </div>
        ) : (
          <div onClick={() => removeThumb(file.name)}>
            <Document file={file.preview}>
              <Page pageNumber={1} width={150} />
            </Document>
          </div>
        )}
      </div>
      <div></div>
    </div>
  ));

  const clickHandler = (to, material) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      setLoginError(true);
      return;
    }
    if (!selectedAddress) {
      setAddressError(true);
      return;
    }
    const currentPath = location.pathname;
    const currentParams = new URLSearchParams(location.search);
    currentParams.set('order_genre', material.order_genre);
    currentParams.set('material', to);
    const newUrl = `${currentPath}?${currentParams.toString()}`;
    setMaterialObject(material);
    setMaterialObjectElements(
      material?.elements.map(element => {
        return {...element, active: null};
      }),
    );
    window.location.href = newUrl;
  };
  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };
  const onDrop = useCallback(async acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setFiles(prevFiles => [
        ...prevFiles,
        ...acceptedFiles.map(file => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        }),
      ]);
    }
  }, []);

  const uploadFiles = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      for (const file of files) {
        const beginUploadDataObj = {
          request: 'begin_multipart_file_upload',
          token: userData?.token,
          file_name: file.name,
          upload_to_temp_folder: false,
        };
        const beginUploadResponse = await fetchData(beginUploadDataObj);
        if (beginUploadResponse) {
          const uploadId = beginUploadResponse.upload_id;
          const fileRawBase64 = await convertBase64(file);
          const fileBase64Parts = fileRawBase64.split(',');
          const fileBase64 = fileBase64Parts[1];
          const base64Size = fileBase64.length;
          const numParts = parseInt((base64Size + 19999) / 20000);
          for (let index = 0; index < numParts; index++) {
            const base64Part = fileBase64.substring(
              index * 20000,
              (index + 1) * 20000,
            );
            const uploadPartDataObj = {
              request: 'upload_file_part',
              token: userData?.token,
              upload_id: uploadId,
              part_number: index + 1,
              part_data: base64Part,
              file_name: file.name,
              upload_to_temp_folder: false,
            };
            const uploadPartResponse = await fetchData(uploadPartDataObj);
            if (!uploadPartResponse) {
              throw new Error(
                `Failed to complete file upload for ${file.name}.`,
              );
            }
          }
          const completeUploadDataObj = {
            request: 'end_multipart_file_upload',
            token: userData?.token,
            upload_id: uploadId,
            num_of_parts: numParts,
          };
          const completeUploadResponse = await fetchData(completeUploadDataObj);
          if (completeUploadResponse) {
            setFileId(prevFileId => [
              ...prevFileId,
              {file_id: completeUploadResponse.file_id},
            ]);
          }
        }
      }
    } catch (error) {
      console.error('Error:', error?.message);
    }
  };

  const incrementQty = key => {
    if (amount < 1000) {
      setAmount(amount + 1);
    }
    setSelectedItemsByKey(prevState => {
      const currentQty = prevState[key] || 1;
      return {
        ...prevState,
        [key]: currentQty + 1,
      };
    });
  };

  const decrementQty = key => {
    if (amount > 0) {
      setAmount(amount - 1);
    }
    setSelectedItemsByKey(prevState => {
      const currentQty = prevState[key] || 1;
      return {
        ...prevState,
        [key]: Math.max(currentQty - 1, 1),
      };
    });
  };

  const setQty = (key, value) => {
    setAmount(value);
    const qty = parseInt(value, 10);
    setSelectedItemsByKey(prevState => ({
      ...prevState,
      [key]: qty >= 1 ? qty : 1,
    }));
  };

  const setQtyPlus = (key, value) => {
    setQtyplus(value);
    setSelectedItemsByKey(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSetItem = (index, data) => {
    setSelectedItems(prevState => ({
      ...prevState,
      [index]: data,
    }));
    const key = materialObjectElements[index].key;
    setSelectedItemsByKey(prevState => ({
      ...prevState,
      [key]: data.value,
    }));
  };

  const allAnswered = useMemo(() => {
    if (!materialObjectElements) return false;

    return materialObjectElements.every(
      element =>
        element.key === 'qty' || selectedItemsByKey[element.key] !== undefined,
    );
  }, [selectedItemsByKey, materialObjectElements]);
  const MyDropzone = () => {
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
      onDrop,
    });

    return (
      <div
        className="SelectMaterialTab_content__parent--button"
        {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>{obj_heb.fileUploading}</p>
        ) : (
          <p>{files.length > 0 ? obj_heb.continue : obj_heb.fileUploading}</p>
        )}
        {files.length > 0 && (
          <div className="SelectMaterialTab_content__parent--uploaded_files">
            {files.map((file, index) => (
              <div key={index}>
                <p>{file.name}</p>
                {file.type.startsWith('image/') && (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Uploaded"
                    style={{maxWidth: '100%', maxHeight: '200px'}}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  const AddAnother = () => {
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
      <div
        className="SelectMaterialTab_content__parent--add"
        {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>{obj_heb.addAnotherFile}</p>
        ) : (
          <p>
            <span>
              <img
                className="SelectMaterialTab_content__parent--add--img"
                src={icons.iconAddmodal}></img>
            </span>
            <span className="SelectMaterialTab_content__parent--add--text">
              {' '}
              {obj_heb.addAnotherFile}
            </span>
          </p>
        )}
      </div>
    );
  };
  const getGenreText_trans = m => {
    if (m == 2) {
      return obj_heb.iron;
    } else if (m == 3) {
      return obj_heb.quarry;
    } else if (m == 4) {
      return obj_heb.concrete;
    } else if (m == 5) {
      return obj_heb.rental;
    } else if (m == 6) {
      return obj_heb.fuel;
    } else if (m == 7) {
      return obj_heb.finishes;
    }
  };
  const getGenreText = m => {
    if (m == 2) {
      return `iron`;
    } else if (m == 3) {
      return `quarry`;
    } else if (m == 4) {
      return `concrete`;
    } else if (m == 5) {
      return `rental`;
    } else if (m == 6) {
      return `fuel`;
    } else if (m == 7) {
      return `finishes`;
    }
  };

  return (
    <div className="SelectMaterialTab">
      {isLoading && <Loader />}
      {materialOrderGenre && (
        <div style={{marginBottom: '1.6rem'}}>
          {' '}
          <ProgressBar step={step} openMaterial={openMaterial} />
        </div>
      )}

      {materialsData?.materials &&
        openMaterial == 0 &&
        materialsData.materials.map((material, index) => (
          <div key={index}>
            <div
              onClick={() =>
                clickHandler(getGenreText(material.order_genre), material)
              }
              className="SelectMaterialTab_item">
              <div className="SelectMaterialTab_item--title">
                <img
                  className="SelectMaterialTab_item--title--icon-card"
                  src={heavyMaterialsImages[material.order_genre]}
                  alt="material_image"
                />
                <div>{material.name}</div>
              </div>
              <div className="SelectMaterialTab_item--ui_sub_title">
                {material.ui_sub_title}
              </div>
              <img
                className="SelectMaterialTab_item--title_arrowhead_img"
                src={leftArrowHead}
                alt="leftArrowHead"
              />
            </div>
          </div>
        ))}
      {openMaterial == 2 ? (
        <div className="SelectMaterialTab_content">
          <Title center={true} title={obj_heb.ironPlanOrder} />

          {step == 0 && (
            <div>
              <img
                className="SelectMaterialTab_content--img"
                src={icons.opemFile}
                alt="Open File"
              />
              <div className="SelectMaterialTab_content--title">
                {obj_heb.upOrTake}
              </div>
              <div>{obj_heb.projectPlan}</div>
              <img
                className="SelectMaterialTab_content--img"
                src={icons.userDoc}
                alt="User Doc"
              />
              <div className="SelectMaterialTab_content--title">
                {obj_heb.teamOfExperts}
              </div>
              <div>{obj_heb.teamOfExperts2}</div>
              <div>{obj_heb.teamOfExperts3}</div>
              <img
                className="SelectMaterialTab_content--img"
                src={icons.phoneDoc}
                alt="Phone Doc"
              />
              <div className="SelectMaterialTab_content--title">
                {obj_heb.toPhone}
              </div>
              <div>{obj_heb.bestOffers}</div>
              <div className="SelectMaterialTab_content__parent">
                {!files.length ? (
                  <MyDropzone />
                ) : (
                  <div>
                    {!isLoading && (
                      <div className={'SelectMaterialTab_content__parent'}>
                        {' '}
                        <Button
                          text={obj_heb.continue}
                          textColor="white"
                          backgroundColor="var(--dibble-yellow)"
                          className={
                            'SelectMaterialTab_content__parent--button'
                          }
                          onClick={orderMaterial}
                        />
                      </div>
                    )}

                    {files.length &&
                      files.map((item, index) => (
                        <div
                          key={index}
                          className="SelectMaterialTab_content__parent--files_names">
                          {item.name + ' , '}
                        </div>
                      ))}
                    {files.length && (
                      <div>
                        <span>
                          <AddAnother />
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ) : openMaterial ? (
        <div>
          <Title
            center={true}
            title={obj_heb.order + ' ' + getGenreText_trans(openMaterial)}
          />
          {step == 0 && (
            <div>
              <div
                style={{marginBottom: '1.8rem'}}
                className="SelectMaterialTab_content">
                <span>
                  {' '}
                  {materialObject?.order_genre_type === 'ORDER_GENRE_FUEL'
                    ? obj_heb.descOrderFuel
                    : obj_heb.descOrderConcrete}
                </span>
              </div>
              {materialObjectElements?.map((element, index) =>
                element.key != 'qty' ? (
                  <div className="SelectMaterialTab__qa" key={index}>
                    <WidgetTypeListInput
                      item={selectedItems[index] || null}
                      setItem={data => handleSetItem(index, data)}
                      daysOptions={element.options}
                      popUpIsOpen={true}
                      uiNamE={element.ui_name}
                    />
                  </div>
                ) : element.key == 'qty' ? (
                  <div
                    key={index}
                    className="SelectMaterialTab_content--parent">
                    <span className="SelectMaterialTab_content--amount">
                      <span className="SelectMaterialTab_content--amount--head">
                        כמות
                      </span>{' '}
                    </span>
                    <ShoppingCartItemAmount
                      Amount={amount}
                      isActive={true}
                      decrementFunction={() => decrementQty('qty')}
                      incrementFunction={() => incrementQty('qty')}
                      insertNumberFunction={x => setQty('qty', x)}
                      setWidth={15}
                      setHight={4.6}
                      btnSize={3}
                      fontSize={1.6}
                      insertNumberFunctionBoolean={true}
                      minimumValue={
                        openMaterial == 7 ? 2 : openMaterial == 6 ? 200 : 1
                      }
                    />
                    {element.sub_elements &&
                      element.sub_elements[0].key == 'qty_plus' &&
                      openMaterial == 4 && (
                        <div className="SelectMaterialTab_content--amount">
                          {!qtyPlus ? (
                            <img
                              className="SelectMaterialTab_content--checkbox"
                              src={icons.selectorEmpty}
                              alt="checkbox"
                              onClick={() => setQtyPlus('qty_plus', true)}
                            />
                          ) : (
                            <img
                              className="SelectMaterialTab_content--checkbox"
                              src={icons.checkBoxSelected}
                              alt="checkbox"
                              onClick={() => setQtyPlus('qty_plus', false)}
                            />
                          )}
                          <span className="login__qty__plus">
                            <span className="">כמות פלוס</span>
                          </span>
                        </div>
                      )}
                    {element.sub_elements &&
                      element.sub_elements[0].key == 'qty_plus' && (
                        <div className="SelectMaterialTab_content--amount--qtyPlus">
                          <div>לא בטוחים מה הכמות המדויקת שאתם צריכים? </div>
                          <div>
                            בחירה בכמות פלוס מאפשרת להשאיר את חשבון העסקה פתוח
                            בינתיים, ולקבל כמות נוספת של בטון במידה ותצטרכו.
                          </div>
                        </div>
                      )}
                  </div>
                ) : (
                  <div></div>
                ),
              )}
              <div className="SelectMaterialTab_content__parent">
                <div>
                  <Button
                    text={
                      !allAnswered
                        ? obj_heb.answerError
                        : !selectedAddress
                        ? obj_heb.noAddressError
                        : amount > 999
                        ? 'יש להזין כמות מתאימה'
                        : obj_heb.continue
                    }
                    textColor="white"
                    backgroundColor={
                      allAnswered && selectedAddress && amount < 1000
                        ? 'var(--dibble-yellow)'
                        : 'var(--unclickable-gray)'
                    }
                    disable={!allAnswered || amount > 999}
                    className={'SelectMaterialTab_content__parent--button'}
                    onClick={!selectedAddress ? () => {} : orderMaterial}
                  />
                </div>
              </div>{' '}
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
      {step == 1 && openMaterial == 2 && (
        <div className="SelectMaterialTab_content">
          <span className="SelectMaterialTab_content--title--small">
            {obj_heb.filesUploaded}
          </span>
          <aside style={thumbsContainer}>{thumbs}</aside>
          <AddAnother />{' '}
          <div className="SelectMaterialTab_content__parent">
            <div>
              <Button
                text={obj_heb.continue}
                textColor="white"
                backgroundColor="var(--dibble-yellow)"
                className={'SelectMaterialTab_content__parent--button'}
                onClick={orderMaterial}
              />
            </div>
          </div>
        </div>
      )}

      {step == 2 && openMaterial == 2 && (
        <div>
          <ItemQuestion
            data={listOption}
            setChecked={bc}
            orderMaterial={orderMaterial}
            setListOption={setListOption}
            stepUp={() => setStep(step + 1)}
          />
          <div className="SelectMaterialTab_content__parent">
            <div></div>
          </div>
        </div>
      )}
      {(step == 3 || step == 4) && (
        <div className="SelectMaterialTab_content">
          <ChooseDateComponent
            setSlot={setSlot}
            listOption={listOption}
            files={files}
            fileId={fileId}
            openMaterial={openMaterial}
            extra_info={selectedItemsByKey}
            materialObjectElements={materialObjectElements}
            qtyPlus={qtyPlus}
            setPatentStep={setStep}
            parentStep={step}
          />
        </div>
      )}
      <PopUp isOpen={loginError} setIsOpen={setLoginError}>
        <PopUpConfirm
          img={icons.no_credit_icon}
          title={obj_heb.just_a_moment}
          subTitle={
            <div className="summary-component__credit-error">
              <span>{obj_heb.needToLogin}</span>
              <div
                onClick={handleOpenIntercom}
                className="summary-component__credit-error--contact-us">
                <span>{obj_heb.chatWithUsError}</span>
                <img src={icons.chat_icon} alt="" />
              </div>
            </div>
          }
        />
      </PopUp>
      <PopUp isOpen={addressError} setIsOpen={setAddressError}>
        <PopUpConfirm
          img={icons.no_credit_icon}
          title={obj_heb.just_a_moment}
          subTitle={
            <div className="summary-component__credit-error">
              <span>{obj_heb.neeToPickAddress}</span>
              <div
                onClick={handleOpenIntercom}
                className="summary-component__credit-error--contact-us">
                <span>{obj_heb.chatWithUsError}</span>
                <img src={icons.chat_icon} alt="" />
              </div>
            </div>
          }
        />
      </PopUp>
    </div>
  );
}
