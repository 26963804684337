import React, {useState} from 'react';

import {icons} from '../assets/constants/index.js';

import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';

function ResendEmailSection() {
  const [userData] = useState(JSON.parse(localStorage.getItem('userData')));
  const [isEmailSent, setIsEmailSent] = useState(false);

  const ResendEmailCode = () => {
    setIsEmailSent(true);
    let dataObj = {
      request: 'send_email_code',
      token: userData?.token,
      email: userData?.email,
    };

    fetchData(dataObj)
      .then(response => {})
      .catch(error => {
        console.error('Error:', error?.message);
      });
  };

  if (userData && userData?.is_email_verified != 1) {
    return (
      <div className="resend-email-section">
        <div className="resend-email-section__title">
          {isEmailSent ? (
            <span
              className="__need_crane__text--bold"
              style={{pointerEvents: 'none', textDecoration: 'none'}}>
              {obj_heb.emailWasSent}
            </span>
          ) : (
            <React.Fragment>
              <img
                alt="info-icon"
                src={icons.exclamationMark}
                className="resend-email-section__title--icon"
              />
              <span className="__need_crane__text">
                {obj_heb.emailIsNotVerified}
              </span>
              {/* <span
                  className="__need_crane__text--bold"
                  onClick={() => ResendEmailCode()}>
                  {obj_heb.resendEmailCode}
                </span> */}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  } else return null;
}

export default ResendEmailSection;
