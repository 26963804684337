// IntercomContext.js
import React, {createContext, useContext, useState} from 'react';

const IntercomContext = createContext();

export const useIntercomContext = () => {
  return useContext(IntercomContext);
};

export const IntercomProvider = ({children}) => {
  const [isIntercomOpen, setIsIntercomOpen] = useState(false);

  const openIntercom = () => {
    setIsIntercomOpen(prev => !prev);
  };

  const closeIntercom = () => {
    setIsIntercomOpen(false);
  };

  return (
    <IntercomContext.Provider
      value={{isIntercomOpen, openIntercom, closeIntercom}}>
      {children}
    </IntercomContext.Provider>
  );
};
