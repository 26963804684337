import React, {useState, useEffect, useRef} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import {icons} from '../assets/constants/index';
import BarChart from '../components/BarChart';
import {useSelector} from 'react-redux';
import ProjectPagePie from './general/ProjectPagePie';
import moment from 'moment';
import classNames from 'classnames';
import DepositWithrowPopUp from './DepositWithrowPopUp';
import {useNavigate, useParams} from 'react-router-dom';
import SearchProjectUserPopUp from './SearchProjectUserPopUp';
import {fetchData} from '../utils/fetchData';

export default function ProjectMainTabsComponent({
  projectDetails,
  myProjectsBalance,
  chartData,
  fetchAllData,
}) {
  let params = useParams();
  const actionsButtonRef = useRef(null);
  const floatingBoxRef = useRef(null);
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isPush, setIsPush] = useState(false);
  const [isProjectManager, setIsProjectManager] = useState(false);
  const [isAddWorkerModalOpen, setIsAddWorkerModalOpen] = useState(false);
  const [isActionsOpen, setIsActionsOpen] = useState(false);
  const [dibbleCoinsBalance, setDibbleCoinsBalance] = useState(0);
  const [plusBtnSrc, setPlusBtnSrc] = useState(icons.gray_plus_btn); // State for image src
  const isMobile = useSelector(state => state.mobile.isMobile);
  const [isLoading, setIsLoading] = useState(false);
  let userData = JSON.parse(localStorage.getItem('userData'));
  const navigate = useNavigate();

  const handleAddProject = () => {
    navigate(`/add-project/${projectDetails?.project_info?.project_id}`); // Change this to your add project route
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        floatingBoxRef.current &&
        !floatingBoxRef.current.contains(event.target) &&
        actionsButtonRef.current &&
        !actionsButtonRef.current.contains(event.target)
      ) {
        setIsActionsOpen(false);
      }
    }
    if (isActionsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActionsOpen]);

  const toggleActionsOpen = event => {
    event.stopPropagation();
    setIsActionsOpen(prevState => !prevState);
  };

  const handleAction = action => {
    setIsActionsOpen(false);
    if (action === 'push') {
      setIsPush(true);
      setIsDepositModalOpen(true);
    } else {
      setIsPush(false);
      setIsDepositModalOpen(true);
    }
  };
  const handleAddWorker = action => {
    if (action === 'manager') {
      setIsProjectManager(true);
      setIsAddWorkerModalOpen(true);
    } else {
      setIsProjectManager(false);
      setIsAddWorkerModalOpen(true);
    }
  };

  useEffect(() => {
    if (projectDetails && myProjectsBalance) {
      const dibblecoin_credit = myProjectsBalance.projects.find(
        p => p.project_id === projectDetails?.project_info?.project_id,
      )?.dcoin_budget;

      setDibbleCoinsBalance(dibblecoin_credit);
    }
  }, [projectDetails, myProjectsBalance]);

  const formattedStartDate = moment(
    projectDetails?.project_info?.start_date,
  ).format('DD.MM.YYYY');
  const formattedEndDate = moment(
    projectDetails?.project_info?.end_date,
  ).format('DD.MM.YYYY');

  const removeWorker = (type, id) => {
    let managers = [];
    if (type === 'manager') {
      managers =
        projectDetails?.project_managers?.filter(pm => pm.user_id !== id) || [];
    } else {
      managers =
        projectDetails?.signature_permitted?.filter(sp => sp.user_id !== id) ||
        [];
    }
    managers = managers.map(manager => ({
      ...manager,
      contractor_user_id: manager.user_id,
    }));
    setIsLoading(true);
    let dataObj = {
      request:
        type == 'manager' ? 'set_project_managers' : 'set_project_sig_perms',
      token: userData?.token,
      project_id: projectDetails?.project_info?.project_id,
    };
    dataObj[type == 'manager' ? 'project_managers' : 'signature_permitted'] =
      managers;

    fetchData(dataObj)
      .then(response => {
        if (response.message === 'success') {
          fetchAllData();
          setIsLoading(false);
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error:', error.message);
      });
  };

  return (
    <>
      <div
        style={{display: 'flex', marginTop: '1.6rem'}}
        className="LobbyCreditsTab_container">
        <div style={{marginTop: '1.6rem'}} className="LobbyCreditsTab_part1">
          <div className="LobbyCreditsTab_accountToolTip">
            <div className="LobbyCreditsTab_part1_item-box_centered-content">
              <div className="LobbyCreditsTab_part1_item-box_icon-box_txt">
                <ProjectPagePie
                  prasentageUsed={projectDetails?.project_info?.budget_usage_pc}
                />
              </div>
            </div>
            <div className="LobbyCreditsTab_part1_hr">_horizintalLine</div>

            <div className="LobbyCreditsTab_part1_item-box">
              <div className="LobbyCreditsTab_part1_item-box_text-box">
                <div className="LobbyCreditsTab_part1_item-box_text-box_title">
                  {obj_heb.dibbleCoinsLeft}
                </div>
                <div className="LobbyCreditsTab_part1_item-box_text-box_sub-title"></div>
              </div>
              <div className="LobbyCreditsTab_part1_item-box_icon-box">
                <div className="LobbyCreditsTab_part1_item-box_icon-box_txt">
                  {dibbleCoinsBalance}
                </div>
                <img
                  src={icons.x_icon}
                  className="LobbyCreditsTab_part1_item-box_icon-boxx-icone"
                  alt="x_icon"
                />
                <img
                  src={icons.dibbleCoinIcon}
                  className="LobbyCreditsTab_part1_item-box_icon-box_dibbl-icon"
                  alt="dibbleCoinIcon"
                />
              </div>
            </div>
            <div className="LobbyCreditsTab_part1_hr">_horizintalLine</div>

            <div className="LobbyCreditsTab_part1_item-box">
              <div className="LobbyCreditsTab_part1_item-box_text-box">
                <div className="LobbyCreditsTab_part1_item-box_text-box_title">
                  {obj_heb.used_budget}
                </div>
                <div className="LobbyCreditsTab_part1_item-box_text-box_sub-title"></div>
              </div>
              <div className="LobbyCreditsTab_part1_item-box_icon-box">
                <div className="LobbyCreditsTab_part1_item-box_icon-box_txt">
                  {projectDetails?.total_orders_delivered}
                </div>
                <img
                  src={icons.x_icon}
                  className="LobbyCreditsTab_part1_item-box_icon-boxx-icone"
                  alt="x_icon"
                />
                <img
                  src={icons.dibbleCoinIcon}
                  className="LobbyCreditsTab_part1_item-box_icon-box_dibbl-icon"
                  alt="dibbleCoinIcon"
                />
              </div>
            </div>
            <div className="LobbyCreditsTab_part1_hr">_horizintalLine</div>

            <div className="LobbyCreditsTab_part1_item-box">
              <div className="LobbyCreditsTab_part1_item-box_text-box">
                <div className="LobbyCreditsTab_part1_item-box_text-box_title">
                  {obj_heb.project_frame}
                </div>
                <div className="LobbyCreditsTab_part1_item-box_text-box_sub-title"></div>
              </div>
              <div className="LobbyCreditsTab_part1_item-box_icon-box">
                <div className="LobbyCreditsTab_part1_item-box_icon-box_txt">
                  {projectDetails?.project_info?.estimated_budget}
                </div>
                <img
                  src={icons.x_icon}
                  className="LobbyCreditsTab_part1_item-box_icon-boxx-icone"
                  alt="x_icon"
                />
                <img
                  src={icons.dibbleCoinIcon}
                  className="LobbyCreditsTab_part1_item-box_icon-box_dibbl-icon"
                  alt="dibbleCoinIcon"
                />
              </div>
            </div>
          </div>

          <div className="LobbyCreditsTab_part1_item-box">
            <div className="LobbyCreditsTab_part1_item-box_text-box">
              <div className="LobbyCreditsTab_part1_item-box_text-box_title">
                {obj_heb.project_address}
              </div>
              <div className="LobbyCreditsTab_part1_item-box_text-box_sub-title">
                {projectDetails?.project_info?.address}
              </div>
            </div>
            <div className="LobbyCreditsTab_part1_item-box_icon-box">
              <div className="LobbyCreditsTab_part1_item-box_icon-box_txt"></div>
            </div>
          </div>
          {/* <div className="LobbyCreditsTab_part1_hr">_horizintalLine</div> */}

          {/* <div className="LobbyCreditsTab_part1_item-box">
            <div className="LobbyCreditsTab_part1_item-box_text-box">
              <div className="LobbyCreditsTab_part1_item-box_text-box_title">
                {obj_heb.project_id}
              </div>
              <div className="LobbyCreditsTab_part1_item-box_text-box_sub-title">
                {projectDetails?.project_info?.project_id}
              </div>
            </div>
            <div className="LobbyCreditsTab_part1_item-box_icon-box">
              <div className="LobbyCreditsTab_part1_item-box_icon-box_txt"></div>
            </div>
          </div> */}
          <div className="LobbyCreditsTab_part1_hr">_horizintalLine</div>

          <div className="LobbyCreditsTab_part1_item-box">
            <div className="LobbyCreditsTab_part1_item-box_text-box">
              <div className="LobbyCreditsTab_part1_item-box_text-box_title">
                {obj_heb.dates}
              </div>
              <div className="LobbyCreditsTab_part1_item-box_text-box_sub-title">
                {formattedStartDate} - {formattedEndDate}
              </div>
            </div>
          </div>
          <div className="LobbyCreditsTab_part1_hr">_horizintalLine</div>

          <div className="LobbyCreditsTab_part1_item-box2">
            <div className="LobbyCreditsTab_part1_item-box2_text-box">
              <div className="LobbyCreditsTab_part1_item-box2_text-box_title">
                {obj_heb.project_managers}
              </div>
              <div className="LobbyCreditsTab_part1_item-box2_text-box_sub-title">
                {projectDetails?.project_managers?.map((manager, index) => (
                  <div key={index}>
                    {manager.name}
                    <img
                      onClick={() => removeWorker('manager', manager.user_id)}
                      style={{
                        height: '1.5rem',
                        width: '1.5rem',
                        marginLeft: '2rem',
                        marginRight: '0.5rem',
                      }}
                      src={icons.yellow_minus_btn}></img>

                    {index < projectDetails?.project_managers.length - 1 &&
                      ' , '}
                  </div>
                ))}
                {!projectDetails?.project_managers?.length && 'ללא'}
                <img
                  onClick={() => handleAddWorker('manager')}
                  style={{
                    width: '1.5rem',
                    marginRight: '3px',
                    cursor: 'pointer',
                  }}
                  src={plusBtnSrc}
                  alt="plus"
                  onMouseEnter={() => setPlusBtnSrc(icons.yellow_plus_btn)}
                  onMouseLeave={() => setPlusBtnSrc(icons.gray_plus_btn)}
                />
              </div>
            </div>
          </div>
          <div className="LobbyCreditsTab_part1_hr">_horizintalLine</div>
          <div className="LobbyCreditsTab_part1_item-box2">
            <div className="LobbyCreditsTab_part1_item-box2_text-box">
              <div className="LobbyCreditsTab_part1_item-box2_text-box_title">
                {obj_heb.signature_permitted}
              </div>
              <div className="LobbyCreditsTab_part1_item-box2_text-box_title">
                {projectDetails?.signature_permitted?.map(
                  (signature, index) => (
                    <div key={index}>
                      {signature?.name}
                      <img
                        onClick={() => removeWorker('sign', signature.user_id)}
                        style={{
                          height: '1.5rem',
                          width: '1.5rem',
                          marginLeft: '2rem',
                          marginRight: '0.5rem',
                        }}
                        src={icons.yellow_minus_btn}></img>
                      {index < projectDetails?.signature_permitted.length - 1 &&
                        ' , '}
                    </div>
                  ),
                )}
                {!projectDetails?.signature_permitted.length && 'ללא'}
                <img
                  onClick={() => handleAddWorker('sign')}
                  style={{
                    width: '1.5rem',
                    marginRight: '3px',
                    cursor: 'pointer',
                  }}
                  src={plusBtnSrc}
                  alt="plus"
                  onMouseEnter={() => setPlusBtnSrc(icons.yellow_plus_btn)}
                  onMouseLeave={() => setPlusBtnSrc(icons.gray_plus_btn)}
                />
              </div>
            </div>
          </div>
          <div className="LobbyCreditsTab_part1_hr">_horizintalLine</div>
        </div>
        <div className="LobbyCreditsTab_part2">
          <div className="projectActionsToolTip">
            <div
              onClick={handleAddProject}
              className="projectActionsToolTip__btn">
              {obj_heb.editProject}
            </div>
            <div
              onClick={toggleActionsOpen}
              className="projectActionsToolTip__btn"
              ref={actionsButtonRef}>
              {obj_heb.actions}
              <img
                className={classNames('-arrow', isActionsOpen && 'flip')}
                src={icons.arrow_head_down}
                alt="location"
              />
            </div>
            {isActionsOpen && (
              <div ref={floatingBoxRef} className="floatingBox">
                <div
                  onClick={() => handleAction('push')}
                  className="floatingBox__item">
                  {obj_heb.push}
                </div>
                <div
                  onClick={() => handleAction('pull')}
                  className="floatingBox__item">
                  {obj_heb.pull}
                </div>
              </div>
            )}
          </div>
          <div className="LobbyCreditsTab_part2_container">
            {' '}
            <BarChart chartData={chartData} height={'570px'} />
          </div>
        </div>
      </div>
      {isDepositModalOpen && (
        <DepositWithrowPopUp
          isOpen={isDepositModalOpen}
          setIsOpen={setIsDepositModalOpen}
          isPush={isPush}
          dibbleCoinsCredit={
            myProjectsBalance?.total_credit -
            myProjectsBalance?.available_credit
          }
          myProjectsBalance={myProjectsBalance}
          projectId={Number(params.project_id)}
        />
      )}
      {isAddWorkerModalOpen && (
        <SearchProjectUserPopUp
          isOpen={isAddWorkerModalOpen}
          setIsOpen={setIsAddWorkerModalOpen}
          isProjectManager={isProjectManager}
          projectId={Number(params.project_id)}
          projectManagers={projectDetails?.project_managers}
          signaturePermitted={projectDetails?.signature_permitted}
          fetchAllData={fetchAllData}
        />
      )}
    </>
  );
}
