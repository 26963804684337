import React, {useEffect, useState} from 'react';
import Obj_heb from '../utils/Obj_heb.json';
import {convertTimeFormat, numberWithCommas} from '../utils/supportFunctions';
import Button from '../components/Button';
import {fetchData} from '../utils/fetchData';

function PersonalAreaTable({userOrder, resend_invoice, getProductData}) {
  const [vat, setVat] = useState(0);
  const convertStringToNumber = input => {
    const number = parseFloat(input);
    const convertedNumber = number / 100 + 1;
    return convertedNumber;
  };
  useEffect(() => {
    let dataObj = {
      request: 'get_vat_rate',
    };
    fetchData(dataObj).then(response => {
      if (response?.message === 'success')
        setVat(convertStringToNumber(response.vat_rate.value));
    });
  }, []);

  const loadTotalOrderPrice = () => {
    let total = 0;
    let updated_products = getUpdatedProductList();
    for (let i = 0; i < updated_products?.length; i++) {
      total =
        total + updated_products[i]['amount'] * updated_products[i]['price'];
    }
    return parseFloat(total, 2);
  };
  const getUpdatedProductList = () => {
    const products =
      userOrder.granted_products == null
        ? userOrder.products
        : userOrder.granted_products;
    return products;
  };

  const getDeliveryCost = () => {
    // Logic to get delivery cost
    return parseFloat(userOrder.delivery_cost).toFixed(2);
  };

  const loadGrandTotalOrderPrice = () => {
    const total = (
      (parseFloat(loadTotalOrderPrice()) + parseFloat(getDeliveryCost())) *
      vat
    ).toFixed(2);
    return total;
  };
  const loadOrderTotalOrderPrice = () => {
    const total = (parseFloat(loadTotalOrderPrice()) * vat).toFixed(2);
    return total;
  };

  return (
    <div className="PersonalArea_content_section_father-of_part1">
      <div className="PersonalArea_content_section_father-of_part1_par1">
        <div className="PersonalArea_content_section_father-of_part1_par1_text-box">
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_title">
            {Obj_heb.orderNumber}
          </div>
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_body">
            {userOrder?.order_id}
          </div>
        </div>
        <div className="PersonalArea_content_section_father-of_part1_par1_hr">
          -
        </div>
        <div className="PersonalArea_content_section_father-of_part1_par1_text-box">
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_title">
            {Obj_heb.orderStatus}
          </div>
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_body">
            {userOrder?.statuses[userOrder?.status]}
          </div>
        </div>
        <div className="PersonalArea_content_section_father-of_part1_par1_hr">
          -
        </div>

        <div className="PersonalArea_content_section_father-of_part1_par1_text-box">
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_title">
            {Obj_heb.date}
          </div>
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_body">
            {convertTimeFormat(
              new Date(
                new Date(userOrder?.placed_on).getTime() + 3 * 60 * 60 * 1000,
              ),
            )}
          </div>
        </div>
        <div className="PersonalArea_content_section_father-of_part1_par1_hr">
          -
        </div>

        <div className="PersonalArea_content_section_father-of_part1_par1_text-box">
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_title">
            {Obj_heb.deliveryType}
          </div>
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_body">
            {userOrder.order_types[userOrder.order_type]}
          </div>
        </div>
        <div className="PersonalArea_content_section_father-of_part1_par1_hr">
          -
        </div>

        <div className="PersonalArea_content_section_father-of_part1_par1_text-box">
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_title">
            {Obj_heb.paidWith}
          </div>
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_body">
            {userOrder.payment_type_name}
          </div>
        </div>
        <div className="PersonalArea_content_section_father-of_part1_par1_hr">
          -
        </div>

        <div
          className="PersonalArea_content_section_father-of_part1_par1_text-box"
          style={{overflowY: 'auto'}}>
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_title">
            {Obj_heb.notes}
          </div>
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_body">
            {userOrder.notes}
          </div>
        </div>
        <div className="PersonalArea_content_section_father-of_part1_par1_hr">
          -
        </div>

        <div className="PersonalArea_content_section_father-of_part1_par1_text-box PersonalArea_content_section_father-of_part1_par1_text-box_price">
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_title">
            {Obj_heb.orderPrice}
          </div>
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_body">
            <div className="PersonalArea_content_section_father-of_part1_par1_text-box_body_symbol">
              ₪
            </div>
            {numberWithCommas(loadOrderTotalOrderPrice())}
          </div>
        </div>
        <div className="PersonalArea_content_section_father-of_part1_par1_hr">
          -
        </div>

        <div className="PersonalArea_content_section_father-of_part1_par1_text-box PersonalArea_content_section_father-of_part1_par1_text-box_price">
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_title">
            {Obj_heb.deliveryCost}
          </div>
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_body">
            <div className="PersonalArea_content_section_father-of_part1_par1_text-box_body_symbol">
              ₪
            </div>
            {Number(userOrder.delivery_cost * vat).toFixed(2)}
          </div>
        </div>
        <div className="PersonalArea_content_section_father-of_part1_par1_hr">
          -
        </div>

        <div className="PersonalArea_content_section_father-of_part1_par1_text-box PersonalArea_content_section_father-of_part1_par1_text-box_price PersonalArea_content_section_father-of_part1_par1_text-box_finalPrice">
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_title">
            {Obj_heb.total}
          </div>
          <div className="PersonalArea_content_section_father-of_part1_par1_text-box_bodyLarge">
            <div className="PersonalArea_content_section_father-of_part1_par1_text-box_bodyLarge_symbol">
              ₪
            </div>
            {numberWithCommas(loadGrandTotalOrderPrice())}
          </div>
        </div>
      </div>
      <div>
        {userOrder?.status === 7 && (
          <Button
            text={Obj_heb.resendInvoice}
            onClick={() => resend_invoice()}
            textColor="var(--dibble-yellow)"
            backgroundColor="var(--white)"
            className={'PersonalArea_invoiceBtn'}
          />
        )}
      </div>

      <Button
        text={Obj_heb.orderAgain}
        onClick={() => {
          getProductData({
            product_id: userOrder?.product_id,
            prod: userOrder,
          });
        }}
        backgroundColor="var(--dibble-yellow)"
        textColor="var(--white)"
      />
    </div>
  );
}
export default PersonalAreaTable;
