import React, {useMemo, useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import TextInput from './TextInput';
import RadioButton from './RadioButtons';
import Button from './Button';

export default function ItemQuestion({data, setListOption, stepUp}) {
  const [selectedAnswers, setSelectedAnswers] = useState({
    0: {question: data[0].question, value: 0, answer: 0, extra: ''},
    1: {question: data[1].question, value: 0, answer: 0, extra: ''},
    2: {question: data[2].question, value: 0, answer: 0, extra: ''},
    3: {question: data[3].question, value: 0, answer: 0, extra: ''},
  });

  const checkItem = (answer, item, AnswerIndex, itemIndex) => {
    setSelectedAnswers(prev => ({
      ...prev,
      [itemIndex]: {
        ...prev[itemIndex],
        value: AnswerIndex,
        answer: data[itemIndex].answer[AnswerIndex].label,
      },
    }));
  };

  const consoleNow = () => {
    setListOption(selectedAnswers);
    stepUp();
  };
  const updateExtraArray = (index, value) => {
    setSelectedAnswers(prev => ({
      ...prev,
      [index]: {
        ...prev[index],
        extra: value,
      },
    }));
  };

  const setTextInput = (index, answerIndex) => {
    if (selectedAnswers[index].value == 1 && index == 1) {
      return (
        <div>
          <div className="ItemQuestion--question">
            {obj_heb.describeProblem}
          </div>
          <TextInput
            onChange={e => updateExtraArray(index, e.target.value)}
            placeholderText={obj_heb.examplePlaceHolder}
          />
        </div>
      );
    }
    if (selectedAnswers[index].value == 1 && index == 2) {
      return (
        <div className="ItemQuestion--question">
          <div className="">{obj_heb.how_many_points}</div>
          <TextInput
            onChange={e => updateExtraArray(index, e.target.value)}
            placeholderText={obj_heb.for_example_point_count}
          />
        </div>
      );
    }
    if (selectedAnswers[index].value == 1 && index == 3) {
      return (
        <div className="ItemQuestion--question">
          <div className="">{obj_heb.hieght_wanted}</div>
          <TextInput
            onChange={e => updateExtraArray(index, e.target.value)}
            placeholderText={obj_heb.hieght_wanted_placeholder}
          />
        </div>
      );
    }
  };
  const allAnswersSet = useMemo(() => {
    return Object.values(selectedAnswers).every(answer => answer.answer !== 0);
  }, [selectedAnswers]);

  return (
    <div className="ItemQuestion">
      {data.map((item, index) => {
        return (
          <div key={item.question + index}>
            <div className="ItemQuestion--question">{item.question}</div>
            {item.answer && item.answer.length > 0 && (
              <RadioButton
                data={item.answer.map((answer, answerIndex) => ({
                  index: answerIndex,
                  label: answer.label,
                }))}
                onSelect={answerIndex =>
                  checkItem(answerIndex, item, answerIndex.index, index)
                }
                defaultValue={selectedAnswers[index]}
              />
            )}
            {setTextInput(index, selectedAnswers[index])}{' '}
          </div>
        );
      })}
      <div className="SelectMaterialTab_content__parent">
        <Button
          text={allAnswersSet ? obj_heb.continue : obj_heb.answerError}
          textColor="white"
          backgroundColor={
            allAnswersSet ? 'var(--dibble-yellow)' : 'var(--unclickable-gray)'
          }
          disable={!allAnswersSet}
          className={'SelectMaterialTab_content__parent--button'}
          onClick={consoleNow}
        />
      </div>
    </div>
  );
}
