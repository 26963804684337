import React from 'react';
import {Helmet} from 'react-helmet-async';
import obj_heb from '../utils/Obj_heb.json';

const SEO = ({...props}) => {
  return (
    <Helmet>
      <title>
        {obj_heb.siteName} | {props?.pageName}
      </title>
      <meta name="description" content={props?.content} />
      <link rel="canonical" href={props?.link} />
    </Helmet>
  );
};

export default SEO;
