import React from 'react';
import obj_heb from '../utils/Obj_heb.json';

function MyListItem({item, onClick}) {
  return (
    <div className="days-item" onClick={() => onClick(item)}>
      <span className="is-hover" />
      <div className="days-item__content">{` ${item.name} `}</div>
    </div>
  );
}

export default MyListItem;
