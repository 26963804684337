// BarChart.js
import React from 'react';
import {Bar} from 'react-chartjs-2';
import {useSelector} from 'react-redux';

const BarChart = ({chartData, height, width}) => {
  // Reverse the order of the labels and corresponding data points
  const reversedChartData = chartData ? chartData.slice().reverse() : [];
  const isMobile = useSelector(state => state.mobile.isMobile);

  // Set the chart height based on the isMobile value
  const chartHeight = isMobile ? '250px' : height ? height : '400px';
  const chartWidth = width ? width : '70%';

  const data = {
    labels: reversedChartData.map(data => data.label), // Reverse labels
    datasets: [
      {
        label: 'Dataset',
        data: reversedChartData.map(data => data.value), // Reverse data points
        backgroundColor: '#ffc625',
        borderColor: '#ffc625',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false, // Allows the chart to fill the container
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          },
          font: {
            size: 10,
            family: 'OscarFm',
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: 10,
            family: 'OscarFm',
          },
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.raw.toLocaleString();
          },
        },
      },
    },
  };

  return (
    <Bar
      style={{width: chartWidth, height: chartHeight}}
      data={data}
      options={options}
    />
  );
};

export default BarChart;
