import React, {useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import {
  instagram,
  tiktok,
  facebook,
  appstore,
  googleplay,
} from '../utils/baseValues';
import {useNavigate} from 'react-router-dom';

import man from '../assets/icons/man.svg';
import departments from '../assets/icons/departments.svg';
import pencil_and_ruler from '../assets/icons/pencil_and_ruler.svg';
import instagramPic from '../assets/icons/instagram.svg';
import tiktokPic from '../assets/icons/tiktok.svg';
import facebookPic from '../assets/icons/facebook.svg';
import googlePlay from '../assets/images/googlePlay.svg';
import appStore from '../assets/images/appStore.svg';
import wheelChair from '../assets/icons/wheelChair.svg';
import AccessibilityModal from '../components/AccessibilityModal';

function Footer() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="footer">
      <AccessibilityModal setIsOpen={setIsOpen} isOpen={isOpen} />
      <div className="footer__img--dark" />
      <div className=" footer__container">
        <div className="footer__content">
          <div className="footer__content--nav">
            {/* <div className="footer__content--nav--button pointer ">
            <img
              className="footer__content--nav--button__icon"
              src={pencil_and_ruler}
              alt="pencil_and_ruler"
            />
            <span>{obj_heb.materials_click}</span>
          </div> */}
            {/* <div className="footer__content--nav--button pointer"> */}
            {/* <img
                className="footer__content--nav--button__icon"
                src={man}
                alt="man"
              />
              <span>חומרים בקליק</span> */}
            {/* </div> */}
            <div
              onClick={() => navigate('/personal-area')}
              className="footer__content--nav--button pointer">
              <img
                className="footer__content--nav--button__icon"
                src={man}
                alt="man"
              />
              <span>איזור אישי</span>
            </div>
          </div>
          {/* <div className="footer__content--info">
            <span className="footer__content--info--item">
              {obj_heb.aboutUs}
            </span>
            <span className="footer__content--info--item">
              {'איך זה עובד?'}
            </span>
            <span className="footer__content--info--item">
              {'ניהול פרוייקטים'}
            </span>
          </div> */}
          <div className="footer__content--social-media">
            <span className="footer__content--social-media--title">
              {obj_heb.joinOnMedia}
            </span>
            <a href={instagram} target="_blank">
              <img className="pointer" src={instagramPic} alt="instagram" />
            </a>
            <a href={tiktok} target="_blank">
              <img className="pointer" src={tiktokPic} alt="tiktok" />
            </a>
            <a href={facebook} target="_blank">
              <img className="pointer" src={facebookPic} alt="facebook" />
            </a>
          </div>
          <div className="footer__content--download">
            <span className="footer__content--download--title">
              {obj_heb.downloadOnAppStore}
            </span>
            <a href={googleplay} target="_blank">
              <img className="pointer" src={googlePlay} alt="googlePlay" />
            </a>
            <a href={appstore} target="_blank">
              <img className="pointer" src={appStore} alt="appStore" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="footer__bottom--right-side">
          <div
            className="footer__bottom--right-side--accessibility pointer "
            onClick={() => setIsOpen(true)}>
            <img src={wheelChair} alt="wheelChair" />
            <span>{obj_heb.accessibility}</span>
          </div>
          <span className="pointer">
            <a href={'/privacy-policy'}>{obj_heb.privacy}</a>
          </span>
          <span onClick={() => navigate('/terms')} className="pointer">
            {obj_heb.termsOfUse}
          </span>
          <span onClick={() => navigate('/about')} className="pointer">
            {obj_heb.about}
          </span>
        </div>

        <div>
          {' '}
          <span>{obj_heb.web_version}</span>
        </div>
        <span className="footer__bottom--rights">
          {obj_heb.companyRightsReserved}
        </span>
      </div>
    </div>
  );
}

export default Footer;
