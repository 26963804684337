import React, {useState, useEffect} from 'react';
import Obj_heb from '../utils/Obj_heb.json';
import {useSelector} from 'react-redux';
import Title from './Title';
import TextInput from './TextInput';
import Button from './Button';

/**
 * RegisterPersonalInfo
 *
 * This component allows the user to enter their personal details and register on the website.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.setInputSelected - Object representing the input selection state.
 * @param {Object} props.setBusinessName - sets the Business name
 * @param {Object} props.setBusinessNumber - sets the Business number
 * @param {string} [props.businessName] - The current business name input value.
 * @param {number} [props.businessNumber] - The current business number input value.
 * @param {Function} [props.nextStep] - Callback function to proceed to the next step.
 * @param {Function} [props.getClassName] - Callback function to get CSS class name.
 */

function RegisterBusiness(props) {
  const isMobile = useSelector(state => state.mobile.isMobile);
  const [businessApprove, setBusinessApprove] = useState(false);

  const resetInput = () => {
    props.setBusinessName('');
  };

  const resetInput2 = () => {
    props.setBusinessNumber('');
  };

  const handleBusinessNameChange = event => {
    if (event.target.value[0] != ' ') {
      if (event.target.value.length < 20) {
        props.setBusinessName(event.target.value);
      }
    }
  };

  const handleBusinessNumberChange = event => {
    if (event.target.value[0] != ' ') {
      props.setBusinessNumber(event.target.value.replace(/[^0-9]/g, ''));
    }
  };

  const isBusinessNumberValid = () => {
    if (/^\d+$/.test(props.businessNumber)) {
      if (props.businessNumber.length >= 9) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isBusinessNameValid = () => {
    if (props.businessName[0] != ' ') {
      if (props.businessName.length < 20) {
        if (/^[0-9a-zA-Zא-ת\s'"״׳`]+$/.test(props.businessName)) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (
      props.businessName !== '' &&
      isBusinessNameValid() &&
      props.businessNumber !== '' &&
      isBusinessNumberValid()
    ) {
      setBusinessApprove(true);
    } else {
      setBusinessApprove(false);
    }
  }, [props.businessName, props.businessNumber]);

  return (
    <div className="login__pop-up">
      <div className="login__pop-up__text-wrapper">
        <Title
          title={Obj_heb.business_details}
          style="login__container--title"
          center={!isMobile}
        />

        <div className="login__container--text">
          {Obj_heb.business_details_placeholder}
        </div>
      </div>
      <div className="login--gap-24">
        <TextInput
          value={props.businessName}
          onChange={e => handleBusinessNameChange(e)}
          onClear={() => resetInput()}
          description={Obj_heb.business_details_placeholder_2}
          placeholderText={Obj_heb.registerBusinessNamePlaceHolder}
          errorMsg={Obj_heb.business_details_error}
          IsError={!isBusinessNameValid()}
          directError={props.businessName}
        />

        <TextInput
          value={props.businessNumber}
          onChange={handleBusinessNumberChange}
          onClear={() => resetInput2()}
          description={Obj_heb.business_num}
          placeholderText={Obj_heb.registerBusinessPlaceHolder}
          errorMsg={Obj_heb.business_details_error2}
          IsError={props.businessNumber && !isBusinessNumberValid()}
          type="tel"
          max={16}
        />
      </div>

      <Button
        text={businessApprove ? Obj_heb.next : Obj_heb.fill_all_fields}
        disable={!businessApprove}
        onClick={() => props.nextStep(1)}
        textColor="white"
        backgroundColor={businessApprove ? '#FFCA1A' : '#BDBCBC'}
        className="login--margin-horizontal-16"
      />
    </div>
  );
}
export default RegisterBusiness;
