import React, {useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import {convertTimeFormat} from '../utils/supportFunctions';
import leftArrowHead from '../assets/icons/leftArrowHead.svg';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

export default function ProjectInvoiceListItem({item}) {
  const navigate = useNavigate();
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const getStatusColor = status => {
    if (status === 7) {
      return 'inherit'; // Keep the same color
    } else if (status === 9) {
      return 'red';
    } else {
      return 'black';
    }
  };

  const isMobile = useSelector(state => state.mobile.isMobile);
  const loadGrandTotalOrderPrice = orderItem => {
    return parseFloat(item.total_payment, 2).toFixed(2);
  };
  return (
    <>
      <div className="ProjectOrderHistoryItem__container">
        {isMobile ? (
          <div
            className={
              'ProjectOrderHistoryItem ProjectOrderHistoryItem_pointer'
            }
            style={item?.status > 6 ? {cursor: 'pointer'} : {}}>
            <div className="ProjectOrderHistoryItem_title">
              {item?.month}/{item?.year}
              <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime">
                <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime_txt"></div>
                <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime_txt">
                  {item?.num_of_invoices + ' ' + 'הזמנות'}
                </div>
              </div>
            </div>
            <div className="ProjectOrderHistoryItem_centerGroup">
              <div className="ProjectOrderHistoryItem_centerGroup_price">
                <span className="ProjectOrderHistoryItem_centerGroup_price_symbol">
                  ₪
                </span>
                {item?.total_price}
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              'ProjectOrderHistoryItem ProjectOrderHistoryItem_pointer'
            }
            style={item?.status > 6 ? {cursor: 'pointer'} : {}}>
            <div className="ProjectOrderHistoryItem_centerGroup">
              <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime">
                <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime_txt">
                  {item.month}/ {item?.year}
                </div>
              </div>
              <div className="ProjectOrderHistoryItem_centerGroup_price">
                {item?.num_of_invoices + ' ' + 'הזמנות'}
              </div>
            </div>

            <div className="ProjectOrderHistoryItem_activeOrder">
              <div
                className={
                  !item.textInMinsClient
                    ? 'ProjectOrderHistoryItem_activeOrder_txt'
                    : 'ProjectOrderHistoryItem_activeOrder_txt_black'
                }>
                {item.textInMinsClient
                  ? ' ' + item.textInMinsClient + ' ' + obj_heb.minutes
                  : ''}
                <span style={{color: getStatusColor(item?.status)}}>
                  {' '}
                  {item?.total_price} ₪
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="ProjectOrderHistoryItem_hr">-</div>
      </div>
    </>
  );
}
