import React from 'react';
import Obj_heb from '../utils/Obj_heb.json';
import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Title from './Title';
import TextInput from './TextInput';
import Button from './Button';
import {icons} from '../assets/constants';

/**
 * RegisterPersonalInfo
 *
 * This component allows the user to enter their personal details and register on the website.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.setInputSelected - Object representing the input selection state.
 * @param {Function} [props.getClassName] - Optional callback to get CSS class name.
 * @param {string} [props.firstName] - The current first name input value.
 * @param {string} [props.lastName] - The current last name input value.
 * @param {Object} props.readConditions - Object representing the user's consent to read conditions.
 * @param {Function} [props.nextStep] - Optional callback to proceed to the next step.
 * @param {Function} [props.setLastName] - Optional callback to proceed to the next step.
 */

function RegisterPersonalInfo(props) {
  const [readConditions, setReadConditions] = useState(false);
  const [approve, setApprove] = useState(false);
  const isMobile = useSelector(state => state.mobile.isMobile);

  const resetInput = () => {
    props.setFirstName('');
  };

  const resetInput2 = () => {
    props.setLastName('');
  };

  const handleFirstNameChange = event => {
    if (event.target.value[0] != ' ') {
      if (event.target.value.length < 15) {
        props.setFirstName(event.target.value);
      }
    }
  };

  const handleLastNameChange = event => {
    if (event.target.value[0] != ' ') {
      if (event.target.value.length < 15) {
        props.setLastName(event.target.value);
      }
    }
  };

  const isFirstNameValid = () => {
    if (props.firstName[0] != ' ') {
      if (props.firstName.length < 15) {
        if (/^[א-ת\s]+$/.test(props.firstName)) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  const isLastNameValid = () => {
    if (props.lastName[0] != ' ') {
      if (props.lastName.length < 15) {
        if (/^[א-ת\s]+$/.test(props.lastName)) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (
      props.firstName !== '' &&
      isFirstNameValid() &&
      props.lastName !== '' &&
      isLastNameValid() &&
      readConditions
    ) {
      setApprove(true);
    } else {
      setApprove(false);
    }
  }, [props.firstName, props.lastName, readConditions]);

  return (
    <div className="login__pop-up">
      <div className="login__pop-up__text-wrapper">
        <Title
          title={Obj_heb.nice_to_meet}
          style="login__container--title"
          center={!isMobile}
        />
        <div
          className="login__container--text--bold"
          style={!isMobile ? {color: '#707070'} : {}}>
          {Obj_heb.we_are_dibble}
        </div>
      </div>
      <div className="login--gap-24">
        <TextInput
          value={props.firstName}
          onChange={handleFirstNameChange}
          onClear={() => resetInput()}
          description={Obj_heb.name_input}
          placeholderText={Obj_heb.first_name_placeholder}
          errorMsg={Obj_heb.name_input_error}
          IsError={!isFirstNameValid()}
          directError={props.firstName}
        />
        <TextInput
          value={props.lastName}
          onChange={handleLastNameChange}
          onClear={() => resetInput2()}
          description={Obj_heb.last_name_input}
          placeholderText={Obj_heb.last_name_placeholder}
          errorMsg={Obj_heb.last_name_input_error}
          IsError={!isLastNameValid()}
          directError={props.lastName}
        />
      </div>
      <div className="login--gap-24">
        <div
          className="login__container--text"
          style={!isMobile ? {color: '#707070', textAlign: 'right'} : {}}>
          {Obj_heb.register_deets}
        </div>
        <div className="login__agree_to_conditions">
          {!readConditions ? (
            <img
              className="login__checkbox"
              src={icons.selectorEmpty}
              alt="checkbox"
              onClick={() => setReadConditions(true)}
            />
          ) : (
            <img
              className="login__checkbox"
              src={icons.checkBoxSelected}
              alt="checkbox"
              onClick={() => setReadConditions(false)}
            />
          )}

          <span className="login__conditions_span">
            <span> {Obj_heb.i_read_and_approve} </span>
            <span
              onClick={() => window.open('/terms', '_blank')}
              className="login__conditions">
              {Obj_heb.conditions}
            </span>
          </span>
        </div>
      </div>
      <Button
        text={!approve ? Obj_heb.fill_all_fields : Obj_heb.next}
        disable={!approve}
        onClick={() => props.nextStep(1)}
        textColor="white"
        backgroundColor={approve ? '#FFCA1A' : '#BDBCBC'}
        className="login--margin-horizontal-16"
      />
    </div>
  );
}
export default RegisterPersonalInfo;
