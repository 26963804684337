import {createSlice, current} from '@reduxjs/toolkit';
import {updateLocalStorage} from '../../utils/supportFunctions';
import {store} from '../store';

const initialState = {
  products: [],
};

export const shopping_cart = createSlice({
  name: 'shopping_cart_invetory',
  initialState,
  reducers: {
    ShoppingCart_removeProduct: (state, action) => {
      state.products = state.products.filter(
        item => item.key !== action.payload?.key,
      );
      updateLocalStorage('shoppingCart', state.products);
    },
    ShoppingCart_clearAllProducts: state => {
      updateLocalStorage('shoppingCart', []);

      state.products = [];
    },
    ShoppingCart_changeQuantity: (state, action) => {
      state.products = state.products.map(item => {
        if (item.key === action.payload.key) {
          if (action.payload.oparation === 'increase') {
            item.quantity = item.quantity + 1;
          } else if (action.payload.oparation === 'decrease') {
            item.quantity = item.quantity - 1;
          } else if (action.payload.oparation === 'insert') {
            item.quantity = action.payload.amount;
          }
        }
        return item;
      });
      updateLocalStorage('shoppingCart', state.products);
    },
    ShoppingCart_addProduct: (state, action) => {
      const {product, oparation} = action.payload;

      const existingProductIndex = state.products.filter(
        item =>
          item.product_id === product.product_id &&
          item.option1_index === product.option1_index &&
          item.option2_index === product.option2_index,
      )[0];
      if (existingProductIndex === undefined) {
        const updatedProducts = [
          ...state.products,
          {...product, key: Math.random().toString(36).substr(2, 11)},
        ];
        state.products = updatedProducts;
        updateLocalStorage('shoppingCart', updatedProducts);
      } else {
        const updatedProducts = [...state.products];

        if (oparation === 'increase') {
          state.products.filter(
            item =>
              item.product_id === product.product_id &&
              item.option1_index === product.option1_index &&
              item.option2_index === product.option2_index,
          )[0].quantity += product.quantity;
        } else if (oparation === 'decrease') {
          state.products.filter(
            item =>
              item.product_id === product.product_id &&
              item.option1_index === product.option1_index &&
              item.option2_index === product.option2_index,
          )[0].quantity -= product.quantity;
        }
        state.products = updatedProducts;
        updateLocalStorage('shoppingCart', updatedProducts);
      }
    },
    ShoppingCart_updateProductPrice: (state, action) => {
      const {productId, newPrice, key} = action.payload;
      let product;
      if (key) {
        product = state.products.find(p => p.key === key);
      } else {
        product = state.products.find(p => p.product_id === productId);
      }
      if (product) {
        product.price = newPrice;
      }
      updateLocalStorage('shoppingCart', state.products);
    },
    ShoppingCart_updateProductSalePrice: (state, action) => {
      const {productId, newSalePrice, onSale, key} = action.payload;
      let product;
      if (key) {
        product = state.products.find(p => p.key === key);
      } else {
        product = state.products.find(p => p.product_id === productId);
      }
      if (product) {
        product.sale_price = newSalePrice;
        product.onSale = onSale;
      }
      updateLocalStorage('shoppingCart', state.products);
    },

    //** Update if the product needs crane **/
    ShoppingCart_updateProductCrane: (state, action) => {
      const {productId, need_crane, key} = action.payload;
      let product;
      if (key) {
        product = state.products.find(p => p.key === key);
      } else {
        product = state.products.find(p => p.product_id === productId);
      }
      if (product) {
        product.need_crane = need_crane;
      }
      updateLocalStorage('shoppingCart', state.products);
    },

    //** Update black Product (can return) **/
    ShoppingCart_updateProductBlackMaterial: (state, action) => {
      const {productId, black_Product, key} = action.payload;
      let product;
      if (key) {
        product = state.products.find(p => p.key === key);
      } else {
        product = state.products.find(p => p.product_id === productId);
      }
      if (product) {
        product.black_Product = black_Product;
      }
      updateLocalStorage('shoppingCart', state.products);
    },

    //** Update the product weight **/
    ShoppingCart_updateProductWeight: (state, action) => {
      const {productId, weight, key} = action.payload;
      let product;
      if (key) {
        product = state.products.find(p => p.key === key);
      } else {
        product = state.products.find(p => p.product_id === productId);
      }
      if (product) {
        product.weight = weight;
      }
      updateLocalStorage('shoppingCart', state.products);
    },

    /**
     *to set the shopping cart as the data you passed
     */
    ShoppingCart_setProducts: (state, action) => {
      state.products = [...action.payload];
    },
    ShoppingCart_removeProductByID: (state, action) => {
      state.products = state.products.filter(
        item => item.product_id !== action.payload,
      );
      updateLocalStorage('shoppingCart', state.products);
    },
    /**
     *
     * to over write the product with new data
     * need to pass also a key value
     */
    ShoppingCart_updateProduct: (state, action) => {
      const {key} = action.payload;
      let products = current(state).products;
      let product = current(state).products.find(p => p.key === key);
      let new_array = products.filter(i => i.key !== product.key);
      product = action.payload;
      new_array = [...new_array, product];
      state.products = [...new_array];
      updateLocalStorage('shoppingCart', state.products);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  ShoppingCart_addProduct,
  ShoppingCart_removeProduct,
  ShoppingCart_clearAllProducts,
  ShoppingCart_changeQuantity,
  ShoppingCart_setProducts,
  ShoppingCart_updateProductPrice,
  ShoppingCart_updateProductSalePrice,
  ShoppingCart_removeProductByID,
  ShoppingCart_updateProduct,
  ShoppingCart_updateProductCrane,
  ShoppingCart_updateProductBlackMaterial,
  ShoppingCart_updateProductWeight,
} = shopping_cart.actions;

export default shopping_cart.reducer;
