import React from 'react';
import {useSelector} from 'react-redux';

import Carousel from '../components/Carousel';
import ProductMinCard from './general/ProductMinCard';
import ScrollView from './ScrollView';
import obj_heb from '../utils/Obj_heb.json';
import {useNavigate} from 'react-router-dom';

/**
 *
 * @param {[{
 * imgLink - string
 * product_id - number
 * txtBeforeThePrice - string
 * productOnSale - boolean
 * salePrice - number
 * price - number
 * title - string
 * descount
 * {number} YPositionNavButtons - the offset of the y exe of the nuv buttons of the Carousel
 * {string} headerNavigation - if empty nothing happens, if passed and strig, onClick on the headr it will redirect to this address
 * }]} productList  - need to be list of object in the next format
 * @returns
 */
export default function MoreProductsCarusel({
  productList,
  header,
  num = 5,
  YPositionNavButtons = null,
  headerNavigation = null,
}) {
  const isMobile = useSelector(state => state.mobile.isMobile);
  const navigate = useNavigate();
  return (
    <div className="MoreProductsCarusel">
      {productList.length >= 1 ? (
        <>
          <div
            className="MoreProductsCarusel_header"
            style={headerNavigation ? {cursor: 'pointer'} : {}}
            onClick={
              headerNavigation
                ? () => {
                    navigate(headerNavigation);
                  }
                : () => {}
            }>
            {header}
          </div>
          {isMobile ? (
            <ScrollView
              items={productList.map((product, index) => {
                return (
                  <ProductMinCard
                    imgLink={product.imgLink}
                    product_id={product.product_id}
                    txtBeforeThePrice={product.txtBeforeThePrice}
                    productOnSale={product.productOnSale}
                    salePrice={product.salePrice}
                    price={product.price}
                    title={product.title}
                  />
                );
              })}
            />
          ) : (
            <Carousel
              YPositionNavButtons={YPositionNavButtons}
              visibleImageCount={num}
              items={productList.map((product, index) => {
                return (
                  <ProductMinCard
                    imgLink={product.imgLink}
                    product_id={product.product_id}
                    txtBeforeThePrice={product.txtBeforeThePrice}
                    productOnSale={product.productOnSale}
                    salePrice={product.salePrice}
                    price={product.price}
                    title={product.title}
                    num={num}
                  />
                );
              })}
              className="department-list__carousel"
            />
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
}
