import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {icons} from '../assets/constants';
import close_icon from '../assets/icons/close_icon.svg';

function SearchInput({
  className = '',
  searchedText,
  setSearchedText,
  placeholderText = '',
  color = 'white',
  onBlur = () => ({}),
  onKeyDown = () => ({}),
}) {
  const isMobile = useSelector(state => state.mobile.isMobile);
  return (
    <div
      className={classNames('search-input', className)}
      style={{backgroundColor: color}}>
      <img
        className="search-input--icon"
        src={isMobile ? icons.magnifying_glass_gray : icons.magnifying_glass}
        alt="search"
      />
      <input
        className="search-input--input"
        placeholder={placeholderText}
        value={searchedText}
        onBlur={e => onBlur(e)}
        onChange={e => {
          setSearchedText(e.target.value);
        }}
        style={{backgroundColor: color}}
        onKeyDown={onKeyDown}
      />
      {searchedText && (
        <img
          className="search-input--close-icon"
          src={close_icon}
          alt="search"
          onClick={() => setSearchedText('')}
        />
      )}
    </div>
  );
}

export default SearchInput;
