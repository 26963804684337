// AddProjectCard.js
import React from 'react';
import {useNavigate} from 'react-router-dom';
import obj_heb from '../utils/Obj_heb.json';
import icons from '../assets/constants/icons';

export default function AddProjectCard() {
  const navigate = useNavigate();

  const handleAddProject = () => {
    navigate('/add-project'); // Change this to your add project route
  };

  return (
    <div className="project add-project-card" onClick={handleAddProject}>
      <span className="add-project-card--header">{obj_heb.add_project}</span>
      <span className="add-project-card--header">
        <img src={icons.gray_plus_btn}></img>
      </span>
    </div>
  );
}
