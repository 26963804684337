import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import PopUp from './general/PopUp';
import Button from './Button';
import {useSelector} from 'react-redux';
import {fetchData} from '../utils/fetchData';
import obj_heb from '../utils/Obj_heb.json';
import moment from 'moment';
import {useNavigate, useLocation} from 'react-router-dom';

function SeeOffer({isOpen, setIsOpen, offer, callback = null}) {
  const [bids, setBids] = useState([]);
  const [vat, setVat] = useState(0);

  const navigate = useNavigate();
  const convertStringToNumber = input => {
    const number = parseFloat(input);
    const convertedNumber = number / 100 + 1;
    return convertedNumber;
  };
  const handleSubmit = (price, delivery_cost, bid_id) => {
    console.log(`handleSubmit is running...`, offer);
    const params = new URLSearchParams({
      order_id: offer.order_id,
      project_id: offer.project_id,
      order_genre: offer.status,
      material_type: offer.material_type,
      address: offer.address,
      requested_delivery_time: offer.requested_delivery_time,
      price: price,
      delivery_cost: delivery_cost,
      bid_id: bid_id,
    });
    navigate(`/summary?${params.toString()}`);
  };

  const getOrderBids = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    let dataObj = {
      request: 'get_material_order_bids',
      token: userData?.token,
      order_id: offer.order_id,
    };
    try {
      fetchData(dataObj).then(response => {
        console.log(JSON.stringify(response));
        setBids(response.bids);
      });
    } catch (error) {
      console.error('Error:', error?.message);
      return null;
    }
  };

  useEffect(() => {
    getOrderBids();
  }, []);
  useEffect(() => {
    let dataObj = {
      request: 'get_vat_rate',
      valid_from: offer?.requested_delivery_time,
    };
    fetchData(dataObj).then(response => {
      if (response?.message === 'success')
        setVat(convertStringToNumber(response.vat_rate.value));
    });
  }, []);

  return (
    <PopUp isOpen={isOpen} setIsOpen={setIsOpen} closeCallback={callback}>
      <div className="see-offer">
        <span className="see-offer__title">{obj_heb.priceOffers}</span>
        {bids.length > 0 && (
          <div className="see-offer--scrollable">
            {bids.map(bid => (
              <div key={bid.id} className="see-offer__extra-details">
                <span className="see-offer__extra-details--title">
                  {obj_heb.priceBeforeVAT} {/* {JSON.stringify(bid)} */}
                  <span className="see-offer__extra-details--title-black">
                    ₪
                    {(
                      parseFloat(bid.total_order_price * vat) +
                      parseFloat(bid.delivery_cost) * vat
                    ).toFixed(2)}
                  </span>
                </span>
                <span className="see-offer__extra-details--title">
                  {obj_heb.deliveryDataTime}
                  <span className="see-offer__extra-details--title-black">
                    {moment.utc(bid?.ready_time).local().format('DD.MM.YYYY')}
                  </span>
                </span>
                <span className="see-offer__extra-details--title">
                  {obj_heb.hour}
                  <span className="see-offer__extra-details--title-black">
                    {moment.utc(bid?.ready_time).local().format('HH:mm')}
                  </span>
                </span>
                <Button
                  text={obj_heb.get}
                  disable={false}
                  onClick={() =>
                    handleSubmit(
                      bid.total_order_price,
                      bid.delivery_cost,
                      bid.bid_id,
                    )
                  }
                  textColor="white"
                  backgroundColor={'#FFCA1A'}
                  className="see-offer__btn"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </PopUp>
  );
}

export default SeeOffer;
