import React from 'react';
import {useNavigate} from 'react-router-dom';

function SearchedItem({item, setValues}) {
  const navigate = useNavigate();

  const navigateToProduct = id => {
    setValues(null);
    navigate(`/product/${id}`);
  };

  return (
    <div
      onClick={() => navigateToProduct(item.product_id)}
      className="searched-item">
      <span className="is-hover" />
      <div className="searched-item__content">
        <img
          src={item.image}
          alt="img"
          className="searched-item__content--img"
        />
        <div>{item?.name ? item.name : item}</div>
      </div>
    </div>
  );
}

export default SearchedItem;
