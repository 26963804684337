import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

//COMPONENTS
import MoreProductsCarusel from '../components/MoreProductsCarusel';

//UTILITIES
import {fetchData} from '../utils/fetchData';
import obj_heb from '../utils/Obj_heb.json';

const RecommendedForYou = ({num}) => {
  const navigate = useNavigate();
  const [recommendationProducts, setRecommendationProducts] = useState([]);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      navigate('/login');
    }

    let recommendedProductDataObj = {
      request: 'get_app_main_scrren_banners',
      token: userData?.token || '',
    };
    fetchData(recommendedProductDataObj)
      .then(response => {
        const data = response;
        if (data?.items?.products) {
          setRecommendationProducts(data.items.products);
        }
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  }, []);
  return (
    <MoreProductsCarusel
      header={obj_heb.somthing_else}
      productList={
        recommendationProducts?.map(item => {
          return {
            imgLink: item.image,
            product_id: item.product_id,
            txtBeforeThePrice: obj_heb.priceStartAt,
            productOnSale: false,
            salePrice: 0,
            price: item.price,
            title: item.name,
          };
        }) || []
      }
      num={num}
    />
  );
};

export default RecommendedForYou;
