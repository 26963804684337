import React, {useState} from 'react';
import Obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';
import {useSelector} from 'react-redux';
import Title from './Title';
import TextInput from './TextInput';
import Button from './Button';

/**
 * LOGIN PHONE NUMBER COMPONENT
 *
 * This component represents a popup for confirming a phone number and sending an SMS code.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.phoneNumber - The current phone number input value.
 * @param {Function} [props.setInputSelected] - Optional callback to set input selection state.
 * @param {Function} [props.getClassName] - Optional callback to get CSS class name.
 * @param {Function} [props.phoneNumberValidation] - Optional callback for phone number validation.
 * @param {Function} [props.phoneNumberValidationError] - Optional callback for phone number validation error.
 * @param {Function} [props.sendSmsCode] - Optional callback to initiate sending an SMS code.
 * @param {Function} [props.step] - step of login.
 * @param {Function} [props.setStep] - set the step in login.
 */

function LoginPhoneNumber(props) {
  const isMobile = useSelector(state => state.mobile.isMobile);

  const resetInput = () => {
    props.setPhoneNumber('');
  };

  const handlePhoneNumberChange = event => {
    if (event.type == 'change' && props.phoneNumber && !event.target.value) {
      if (props.phoneNumber.length == 1) {
        props.setPhoneNumber(null);
      }
      return;
    }
    let inputValue;
    if (event.type === 'change') {
      inputValue =
        event.target.value !== '' ? event.target.value : props.phoneNumber;
    } else {
      event.preventDefault(); // Prevent default form submission behavior
      inputValue = props.phoneNumber; // For ENTER click, use props.phoneNumber
    }
    if (inputValue.length <= 10) {
      const phoneNumberRegex = /^0\d*$/;
      if (inputValue.length >= 2) {
        if (inputValue[1] === '5' && inputValue[0] === '0') {
          props.setPhoneNumberValidation(true);
        } else {
          props.setPhoneNumberValidation(false);
        }
      } else if (inputValue.length > 10) {
        props.setPhoneNumberValidation(false);
      } else if (phoneNumberRegex.test(inputValue)) {
        props.setPhoneNumberValidation(true);
      } else {
        props.setPhoneNumberValidation(false);
      }
      if (
        inputValue.length === 10 &&
        inputValue[0] === '0' &&
        inputValue[1] === '5' &&
        phoneNumberRegex.test(inputValue)
      ) {
        props.setPhoneNumberValidationReady(true);
      } else {
        props.setPhoneNumberValidationReady(false);
      }
      props.setPhoneNumber(inputValue.replace(/[^0-9]/g, ''));
    }
    if (
      event.type == 'submit' &&
      props.phoneNumber &&
      props.phoneNumberValidationError
    ) {
      sendSmsCode();
    }
  };

  const sendSmsCode = async () => {
    if (props.step === 1) {
      props.setStep(props.step + 1);
    }
    let dataObj = {
      request: 'send_sms_code',
      phone_num: props.phoneNumber,
    };
    fetchData(dataObj)
      .then(response => {})
      .catch(error => {
        console.error('Error:', error?.message);
      });
  };

  return (
    <div className="login__pop-up">
      <div className="login__pop-up__text-wrapper">
        <Title
          title={Obj_heb.register_or_login}
          style="login__container--title"
          center={!isMobile}
        />
        <div className="login__container--text">{Obj_heb.first_time_q}</div>
      </div>
      <div className="login--gap-32">
        <form onSubmit={e => handlePhoneNumberChange(e)}>
          <TextInput
            value={props.phoneNumber}
            onChange={handlePhoneNumberChange}
            onClear={() => resetInput()}
            description={Obj_heb.your_phone_number}
            placeholderText={Obj_heb.loginPhonePlaceHolder}
            errorMsg={Obj_heb.phone_number_error}
            IsError={!props.phoneNumberValidation}
            max={10}
            directError={props.phoneNumber}
            type="tel"
          />
        </form>
        <Button
          text={
            props.phoneNumber && props.phoneNumberValidationError
              ? Obj_heb.register_or_login
              : Obj_heb.phone_number_btn_error
          }
          disable={!props.phoneNumber || !props.phoneNumberValidationError}
          onClick={sendSmsCode}
          textColor="white"
          backgroundColor={
            props.phoneNumber && props.phoneNumberValidationError
              ? '#FFCA1A'
              : '#BDBCBC'
          }
        />
      </div>
    </div>
  );
}
export default LoginPhoneNumber;
