import React, {useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import {convertTimeFormat} from '../utils/supportFunctions';
import leftArrowHead from '../assets/icons/leftArrowHead.svg';
import {useSelector} from 'react-redux';
import {fetchData} from '../utils/fetchData';
import CancelProjectOrderModal from './CancelProjectOrderModal';

export default function OrderHistoryItem({item, projectOrders}) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const getStatusColor = status => {
    if (status === 7) {
      return 'inherit'; // Keep the same color
    } else if (status === 9) {
      return 'red';
    } else {
      return 'black';
    }
  };
  const approve = () => {
    let dataObj2 = {
      request: 'approve_pending_order',
      order_id: item.order_id,
    };
    fetchData(dataObj2)
      .then(response => {
        console.log(`response`, response);
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const isMobile = useSelector(state => state.mobile.isMobile);
  const loadGrandTotalOrderPrice = orderItem => {
    return parseFloat(item.total_payment, 2).toFixed(2);
  };
  return (
    <>
      <div
        className={`ProjectOrderHistoryItem__container ${
          isCollapsed ? 'collapsed' : ''
        }`}
        onClick={toggleCollapse} // Toggle collapse on click
      >
        {' '}
        {isMobile ? (
          <div
            className={
              'ProjectOrderHistoryItem ProjectOrderHistoryItem_pointer'
            }
            style={item?.status > 6 ? {cursor: 'pointer'} : {}}>
            <div className="ProjectOrderHistoryItem_title">
              {item?.destination_address?.length > 23
                ? item?.destination_address.slice(0, 20) + '...'
                : item?.destination_address}

              <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime">
                <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime_txt">
                  {item?.purpose}
                </div>
                <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime_txt">
                  {convertTimeFormat(
                    new Date(
                      new Date(item?.placed_on).getTime() + 3 * 60 * 60 * 1000,
                    ),
                  )}
                </div>
              </div>
            </div>
            <div className="ProjectOrderHistoryItem_centerGroup">
              <div className="ProjectOrderHistoryItem_centerGroup_price">
                <span className="ProjectOrderHistoryItem_centerGroup_price_symbol">
                  ₪
                </span>
                {loadGrandTotalOrderPrice(item)}
              </div>
            </div>
            {item?.status ? (
              <div className="ProjectOrderHistoryItem_activeOrder">
                <div
                  className={
                    !item.textInMinsClient
                      ? 'ProjectOrderHistoryItem_activeOrder_txt'
                      : 'ProjectOrderHistoryItem_activeOrder_txt_black'
                  }>
                  {item.textInMinsClient
                    ? ' ' + item.textInMinsClient + ' ' + obj_heb.minutes
                    : ''}
                  <span style={{color: getStatusColor(item?.status)}}>
                    {projectOrders?.order_statuses[item?.status]}
                  </span>
                </div>
              </div>
            ) : (
              <div className="ProjectOrderHistoryItem_leftArrowBox">
                <img
                  className="ProjectOrderHistoryItem_leftArrowBox_img"
                  src={leftArrowHead}
                  alt="leftArrowHead"
                />
              </div>
            )}
          </div>
        ) : (
          <div
            className={
              'ProjectOrderHistoryItem ProjectOrderHistoryItem_pointer'
            }
            style={item?.status > 6 ? {cursor: 'pointer'} : {}}>
            <div className="ProjectOrderHistoryItem_centerGroup">
              <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime">
                <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime_txt">
                  {item?.purpose}
                </div>
                <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime_txt">
                  {convertTimeFormat(
                    new Date(
                      new Date(item?.placed_on).getTime() + 3 * 60 * 60 * 1000,
                    ),
                  )}
                </div>
              </div>
              <div className="ProjectOrderHistoryItem_centerGroup_price">
                <span className="ProjectOrderHistoryItem_centerGroup_price_symbol">
                  ₪
                </span>
                {loadGrandTotalOrderPrice(item)}
              </div>
            </div>
            <div className="ProjectOrderHistoryItem_title">
              {item?.destination_address?.length > 40
                ? item?.destination_address.slice(0, 37) + '...'
                : item?.destination_address}
            </div>

            <div className="ProjectOrderHistoryItem_activeOrder">
              <div
                className={
                  !item.textInMinsClient
                    ? 'ProjectOrderHistoryItem_activeOrder_txt'
                    : 'ProjectOrderHistoryItem_activeOrder_txt_black'
                }>
                {item.textInMinsClient
                  ? ' ' + item.textInMinsClient + ' ' + obj_heb.minutes
                  : ''}
                <span style={{color: getStatusColor(item?.status)}}>
                  {projectOrders?.order_statuses[item?.status]}
                </span>
              </div>
            </div>
          </div>
        )}
        {!isCollapsed && <div className="ProjectOrderHistoryItem_hr">-</div>}
      </div>
      {isCollapsed && (
        <div
          onClick={toggleCollapse} // Toggle collapse on click
          className="ProjectOrderHistoryItem__extraContent">
          <div className="ProjectOrderHistoryItem__extraContent_centerGroup">
            <div className="ProjectOrderHistoryItem__extraContent_centerGroup_orderDateTime">
              <div className="ProjectOrderHistoryItem__extraContent_centerGroup_orderDateTime_txt">
                סוג המשלוח :
              </div>
              <div className="ProjectOrderHistoryItem__extraContent_centerGroup_orderDateTime_txt">
                <span style={{color: 'gray'}}>
                  {item?.order_type == 3 ? 'משלוח מתוזמן' : 'משלוח מהיר'}
                </span>
              </div>
              <div
                style={{marginTop: '3.2rem'}}
                className="ProjectOrderHistoryItem__extraContent_centerGroup_orderDateTime_txt">
                הערות :
              </div>
              <div className="ProjectOrderHistoryItem__extraContent_centerGroup_orderDateTime_txt">
                <span style={{color: 'gray'}}>
                  {item?.notes ? item?.notes : '-'}
                </span>
              </div>
            </div>
          </div>
          <div className="ProjectOrderHistoryItem__extraContent_centerGroup_orderDateTime_txt">
            {item?.order_genre == 1 ? (
              item?.products?.map(product => (
                <div>
                  <div>{product.product_name + ' '}</div>
                  <span style={{color: 'gray', marginRight: '0.8rem'}}>
                    {product.amount + 'x'}{' '}
                  </span>
                  <span style={{marginRight: '1.6rem'}}>{'מחיר:'}</span>
                  <span style={{color: 'gray'}}>
                    {'     ' + '₪' + '     ' + product?.price}
                  </span>
                </div>
              ))
            ) : (
              <div>הזמנת חומרים</div>
            )}
            {}
          </div>
          <div className="ProjectOrderHistoryItem_activeOrder">
            <div
              className={
                'ProjectOrderHistoryItem_activeOrder_txt_black_bigger'
              }>
              <div style={{marginTop: '0.8rem'}}>
                <span> סכום הזמנה:</span>
                <span style={{marginRight: '0.8rem', color: 'gray'}}>
                  {item?.granted_total_price
                    ? item?.granted_total_price
                    : item?.total_price + ' ₪'}
                </span>
              </div>
              <div style={{marginTop: '0.8rem'}}>
                <span> דמי משלוח:</span>
                <span style={{marginRight: '0.8rem', color: 'gray'}}>
                  {item?.delivery_cost + ' ₪'}
                </span>
              </div>
              <div style={{marginTop: '0.8rem'}}>
                <span>מע״מ</span>
                <span style={{marginRight: '0.8rem', color: 'gray'}}>
                  {item?.vat + ' ₪'}
                </span>
              </div>
              <div style={{marginTop: '0.8rem'}}>
                {' '}
                <span>סה״כ:</span>
                <span style={{marginRight: '0.8rem', color: 'gray'}}>
                  {item?.total_payment + ' ₪'}
                </span>
              </div>
              {/* {item && item?.status == 10 && (
                <div className="actions_btns_box" style={{marginTop: '0.8rem'}}>
                  <div className="actions_btns_a">אשר</div>
                  <div className="actions_btns_c">בטל</div>
                </div>
              )} */}
              {item && item?.status == 10 && (
                <div className="actions_btns_box" style={{marginTop: '0.8rem'}}>
                  <div onClick={approve} className="actions_btns_a">
                    אשר
                  </div>
                  <div
                    onClick={() => setIsModalOpen(true)}
                    className="actions_btns_c">
                    בטל
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <CancelProjectOrderModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          orderId={item.order_id}
        />
      )}
      <div className="OrderHistoryItem_hr">-</div>
    </>
  );
}
