import {apiUrl, rc_token_expire} from './baseValues';
import axios from 'axios';
import {changeUrl} from './supportFunctions';
import {deleteOnLogOut} from '../redux/store';

export function fetchData(requestObject) {
  try {
    if (navigator.onLine) {
      let headers = {
        'Dibble-App': 'web',
        'Accept-Language': 'he',
      };
      let data = localStorage.getItem('userData');
      data = JSON.parse(data);
      if (data?.token) {
        let bearer = 'Bearer ' + data.token;
        headers['Authorization'] = bearer;
      }
      const request = requestObject.request;
      delete requestObject['request'];
      delete requestObject['token'];
      const url = apiUrl[process.env.REACT_APP_ENV || 'development'] + request;
      return axios
        .post(url, requestObject, {
          headers: headers,
        })
        .then(response => {
          // Handle the data returned by the API
          const data = response.data;
          if (data.rc == rc_token_expire) {
            deleteOnLogOut();
            changeUrl('/login');
            return;
          } else {
            return data;
          }
        })
        .catch(error => {
          if (error.response) {
            // Return the error response instead of throwing it
            return error.response.data;
          } else {
            // Handle any other errors (e.g., network errors)
            console.error('Error:', error);
            console.log('error fetch data');
            return {message: 'An unexpected error occurred'};
          }
        });
    } else {
      console.warn('No internet connection.');
      // Handle no internet connection here
    }
  } catch (error) {
    console.error('Error:', error);
    // Handle other errors (e.g., network errors) here
  }
}
