import React, {useState, useEffect} from 'react';
import {fetchData} from '../utils/fetchData';
import Gallery from './Gallery';
import {useNavigate} from 'react-router-dom';

function Banner() {
  const [bannerPics, setBannerPics] = useState([]);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData')),
  );
  useEffect(() => {
    let dataObj = {
      request: 'get_app_main_scrren_banners',
      token: userData?.token || '',
    };

    fetchData(dataObj) // Don't assign the result to x, instead handle it using the 'then' method
      .then(response => {
        const data = response.items;
        const banners = [];

        Object.keys(data).map((key, index) => {
          if (key.includes('banner')) banners.push(data[key]);
          return key;
        });

        banners.map(banner => {
          if (banner?.category_id) {
            banner.onClick = () =>
              navigate(
                `/category?id=${encodeURIComponent(banner.category_id)}`,
              );
          } else if (
            banner?.link.includes('http') ||
            banner?.link.includes('www')
          ) {
            let link = banner.link;
            if (banner.link.includes('www')) {
              link = 'http://' + link; // Adding the protocol for www links
            }

            banner.onClick = () => {
              window.open(link, '_blank');
            };
          } else {
            banner.onClick = () =>
              navigate(`/product/${encodeURIComponent(banner.link)}`);
          }

          return banner;
        });

        setBannerPics(banners);
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  }, []);

  return <Gallery images={bannerPics} />;
}

export default Banner;
