import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import obj_heb from '../utils/Obj_heb.json';
import icons from '../assets/constants/icons';
import classNames from 'classnames';
import calenderIcon from '../assets/icons/icon_calender.png';
import moment from 'moment';

function MaterialDate({boxShadow = false, requestedDeliveryTime = null}) {
  const [isOpen, setIsOpen] = useState(false);
  const addressesRef = useRef(null);
  const isMobile = useSelector(state => state.mobile.isMobile);
  const selectedAddress = useSelector(
    state => state.addressSlice.selectedAddress,
  );

  const handleDateValue = () => {
    const dateMoment = moment(requestedDeliveryTime, 'YYYY-MM-DD');
    const parsedDate = dateMoment.format('DD.MM.YYYY');
    return parsedDate;
  };
  const handleTimeValue = () => {
    const dateMoment = moment(requestedDeliveryTime, 'DD-MM-YYYY HH:mm:ss'); // Adjust format as needed
    const hour = dateMoment.hour(); // Extract the hour from the date
    const diff = new Date().getTimezoneOffset() / 60; // Get the timezone offset in hours
    const time = hour + Math.abs(Number(diff)) + ':00'; // Calculate the time including the offset
    return time;
  };

  useEffect(() => {
    // Function to handle click outside the div
    const handleClickOutside = event => {
      if (
        addressesRef.current &&
        !addressesRef.current.contains(event.target)
      ) {
        setIsOpen(prev => false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="address-input"
      style={{boxShadow: boxShadow ? 'var(--card-shadow)' : 'none'}}
      ref={addressesRef}>
      <div className="address-input__item">
        <div className="address-input__item--title">
          <img
            src={calenderIcon}
            alt="location"
            className="address-input__icon"
          />
          <span
            className={
              isMobile && !selectedAddress
                ? 'address-input__item--title--placeholder'
                : ''
            }>
            <span className="address-input--date">
              תאריך:
              <span className="address-input--date--value">
                {' '}
                {handleDateValue()}
              </span>
            </span>
            <span style={{marginRight: '20px'}} className="address-input--date">
              {' '}
              בשעה:
              <span className="address-input--date--value">
                {' '}
                {handleTimeValue()}
              </span>
            </span>
          </span>
        </div>
        {!requestedDeliveryTime && (
          <img
            className={classNames(
              'address-input__item--arrow',
              isOpen && 'flip',
            )}
            src={isMobile ? icons.arrow_head_left : icons.arrow_head_down}
            alt=""
          />
        )}
      </div>
    </div>
  );
}

export default MaterialDate;
