import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import icons from '../assets/constants/icons';
import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';
import {INTERCOM_APP_ID} from '../utils/baseValues';
import man from '../assets/icons/man.svg';
import lobbyIcon from '../assets/icons/lobbyIcon.svg';
import lobbyIconFull from '../assets/icons/lobbyIconFull.svg';
import departments from '../assets/icons/departments.svg';
import man_yellow from '../assets/icons/man_yellow.svg';
import departments_yellow from '../assets/icons/departments_yellow.svg';
import dibble_logo from '../assets/icons/dibble_logo.svg';
import SearchNavBar from '../components/SearchNavBar';
import Address from '../components/Address';
import NavBarDropDown from '../components/NavBarDropDown';
import ResendEmailSection from '../components/ResendEmailSection';
import {useIntercomContext} from '../utils/IntercomContext';
import {
  clearActiveOrdersCount,
  clearAddressData,
  clearCreditCardsData,
} from '../redux/store';
import classNames from 'classnames';

function NavBar({
  needAddress = true,
  navBarBack,
  hideDibbleIcon,
  navBarCart,
  fastDeliveyStatus,
  fastDeliveyMessage,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {openIntercom} = useIntercomContext();

  const isMobile = useSelector(state => state.mobile.isMobile);

  // user data
  const data = JSON.parse(localStorage.getItem('userData'));

  const activeOrdersCount = useSelector(
    state => state.activeOrders.activeOrdersCount,
  );

  const shoppingCartProducts = useSelector(
    state => state.shopping_cart.products,
  );

  const [bgDiv, setBgDiv] = useState(null);
  const [isScroll, setIsScroll] = useState(false);

  const [departmentsIsDropdownOpen, setDepartmentsIsDropdownOpen] =
    useState(false);
  const [lobbyIsDropdownOpen, setLobbyIsDropdownOpen] = useState(false);
  const [materialClick, setMaterialClick] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [personalAreaIsDropdownOpen, setPersonalAreaIsDropdownOpen] =
    useState(false);

  let PersonalArea = [
    {
      name: obj_heb.Benefits_and_Advantages,
      location: 'personal-area',
      available: data ? true : false,
    },
    {
      name: obj_heb.my_orders,
      location: 'personal-area?tab=my_orders',
      available: data ? true : false,
    },
    {
      name: obj_heb.likedProducts,
      location: 'likedProducts',
      available: data ? true : false,
    },
    {
      name: obj_heb.My_Payment_Methods,
      location: 'credit-cards',
      available: data ? true : false,
    },
    {
      name: obj_heb.My_Addresses,
      location: 'my-address',

      available: data ? true : false,
    },
    {
      name: obj_heb.Personalization,
      location: 'personal-area?tab=personal_preference',
      available: data ? true : false,
    },
    {
      name: obj_heb.materials,
      location: 'materials',
      available: data ? true : false,
    },
  ];
  let Lobby = [
    {
      name: obj_heb.income_center,
      location: 'lobby',
      available: data ? true : false,
    },
    {
      name: obj_heb.projects,
      location: 'lobby?tab=projects',
      available: data ? true : false,
    },
    {
      name: obj_heb.users,
      location: 'lobby?tab=my_users',
      available: data ? true : false,
    },
  ];

  !data
    ? PersonalArea.push({
        name: obj_heb.Log_in,
        location: 'login',
        available: true,
      })
    : PersonalArea.push({
        name: obj_heb.Log_Out,
        location: '',
        onClick: () => logout(),
        available: true,
      });

  useEffect(() => {
    try {
      let dataObj = {
        request: 'get_categories',
        token: '',
      };
      fetchData(dataObj)
        .then(response => {
          const {categories} = response;
          //Filter the hidden categories
          const activeCategories = Object.values(categories).filter(
            category => {
              if (category.is_active === 1 && category.num_of_products > 0) {
                if (category.children) {
                  // Filter the children (sub_category)
                  category.children = Object.values(category.children).filter(
                    child => child.is_active === 1 && child.num_of_products > 0,
                  );
                }
                return true;
              }
              return false;
            },
          );
          setCategoriesList(Object.values(activeCategories));
        })
        .catch(error => console.log('cant fetch categories in nav bar', error));
    } catch {
      console.log('cant fetch categories in nav bar');
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 1) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const materialClickEnter = () => {
    setMaterialClick(true);
    setDepartmentsIsDropdownOpen(false);
    setBgDiv(null);
  };
  const materialClickLeave = () => {
    setMaterialClick(false);
  };
  const logout = () => {
    setBgDiv(null);
    if (!data?.token) return;
    let dataObj = {
      request: 'logout',
      token: data.token,
    };
    fetchData(dataObj).then(response => {
      localStorage.setItem('userData', null);
      dispatch(clearAddressData());
      dispatch(clearCreditCardsData());
      dispatch(clearActiveOrdersCount());
      navigate('/');
      window.Intercom('shutdown');
      window.Intercom('reset');
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
      });
      window.Intercom('update', {name: '', email: '', phone: '', userId: ''});
      window.location.reload();
    });
  };

  return (
    <>
      <div className="nav-bar">
        {(!isMobile || (!navBarBack && !hideDibbleIcon)) && (
          <div className="nav-bar__top">
            {!isMobile && (
              <div
                className="nav-bar__top--button"
                onMouseEnter={() => {
                  setDepartmentsIsDropdownOpen(true);
                  setBgDiv(<div className="bg-blur" />);
                }}
                onMouseLeave={() => {
                  setDepartmentsIsDropdownOpen(false);
                  setBgDiv(null);
                }}>
                <div className="nav-bar__top--button--style">
                  {departmentsIsDropdownOpen ? (
                    <img
                      onMouseEnter={() => {
                        setDepartmentsIsDropdownOpen(true);
                        setBgDiv(<div className="bg-blur" />);
                      }}
                      className="nav-bar__top--button__icon"
                      src={departments_yellow}
                      alt="departments"
                      loading="eager"
                    />
                  ) : (
                    <img
                      onMouseEnter={() => {
                        setDepartmentsIsDropdownOpen(true);
                        setBgDiv(<div className="bg-blur" />);
                      }}
                      className="nav-bar__top--button__icon"
                      src={departments}
                      alt="departments"
                      loading="eager"
                    />
                  )}
                  <span
                    onMouseEnter={() => {
                      setDepartmentsIsDropdownOpen(true);
                      setBgDiv(<div className="bg-blur" />);
                    }}
                    className="nav-bar__top--button__text"
                    style={
                      departmentsIsDropdownOpen
                        ? {color: 'var(--dibble-yellow)'}
                        : {}
                    }>
                    {obj_heb.departments}
                  </span>
                  <span
                    className="nav-bar__top--button--style"
                    onClick={() => navigate('/materials')}>
                    {' '}
                    <img
                      style={{marginRight: '1.6rem'}}
                      onMouseEnter={() => {
                        materialClickEnter();
                      }}
                      onMouseLeave={() => {
                        materialClickLeave();
                      }}
                      className="nav-bar__top--button__icon"
                      src={
                        materialClick
                          ? icons.icon_footer_heavy
                          : icons.icon_footer_heavy_unselected
                      }
                      alt="departments"
                      loading="eager"
                    />
                    <span
                      onMouseEnter={() => {
                        materialClickEnter();
                      }}
                      onMouseLeave={() => {
                        materialClickLeave();
                      }}
                      className="nav-bar__top--button__text"
                      style={
                        materialClick ? {color: 'var(--dibble-yellow)'} : {}
                      }>
                      חומרים בקליק{' '}
                    </span>
                  </span>
                </div>
                {departmentsIsDropdownOpen && (
                  <NavBarDropDown items={categoriesList} right />
                )}
              </div>
            )}
            {/* <div className="nav-bar__top--button">
            <img
              className="nav-bar__top--button__icon"
              src={pencil_and_ruler}
              alt="pencil_and_ruler"
            />
            <span className="nav-bar__top--button__text">
              {obj_heb.materials_click}
            </span>
          </div> */}
            <span
              onClick={() => navigate('/')}
              className="nav-bar__top__image"
              to="/">
              <span className="nav-bar__top__image--beta">Beta</span>
              <img src={dibble_logo} alt="dibble_logo" />
            </span>
            {!isMobile && (
              <div>
                {' '}
                <div
                  className="nav-bar__top--button nav-bar__top--personal-area"
                  onMouseEnter={() => {
                    setPersonalAreaIsDropdownOpen(true);
                    setBgDiv(<div className="bg-blur" />);
                  }}
                  onMouseLeave={() => {
                    setPersonalAreaIsDropdownOpen(false);
                    setBgDiv(null);
                  }}>
                  <div
                    className="nav-bar__top--button--style nav-bar__top--button--left"
                    onClick={
                      data
                        ? () => navigate('/personal-area')
                        : () => navigate('/login')
                    }>
                    <div style={{position: 'relative'}}>
                      {personalAreaIsDropdownOpen ? (
                        <img
                          className="nav-bar__top--button__icon"
                          src={man_yellow}
                          alt="man"
                          loading="eager"
                        />
                      ) : (
                        <img
                          className="nav-bar__top--button__icon"
                          src={man}
                          alt="man"
                          loading="eager"
                        />
                      )}

                      {activeOrdersCount > 0 && (
                        <div className="nav-bar__top--button__active-orders">
                          <div className="nav-bar__top--button__active-orders__text">
                            {activeOrdersCount}
                          </div>
                        </div>
                      )}
                    </div>
                    <span
                      className="nav-bar__top--button__text"
                      style={
                        personalAreaIsDropdownOpen
                          ? {color: 'var(--dibble-yellow)'}
                          : {}
                      }>
                      {obj_heb.personal_area}
                    </span>
                  </div>
                  {personalAreaIsDropdownOpen && (
                    <NavBarDropDown items={PersonalArea} left />
                  )}
                </div>
                <div
                  className="nav-bar__top--button nav-bar__top--personal-area"
                  onMouseEnter={() => {
                    setPersonalAreaIsDropdownOpen(true);
                    setBgDiv(<div className="bg-blur" />);
                  }}
                  onMouseLeave={() => {
                    setPersonalAreaIsDropdownOpen(false);
                    setBgDiv(null);
                  }}>
                  <div
                    className="nav-bar__top--button--style nav-bar__top--button--left"
                    onClick={
                      data
                        ? () => navigate('/personal-area')
                        : () => navigate('/login')
                    }>
                    <div style={{position: 'relative'}}>
                      {personalAreaIsDropdownOpen ? (
                        <img
                          className="nav-bar__top--button__icon"
                          src={man_yellow}
                          alt="man"
                          loading="eager"
                        />
                      ) : (
                        <img
                          className="nav-bar__top--button__icon"
                          src={man}
                          alt="man"
                          loading="eager"
                        />
                      )}

                      {activeOrdersCount > 0 && (
                        <div className="nav-bar__top--button__active-orders">
                          <div className="nav-bar__top--button__active-orders__text">
                            {activeOrdersCount}
                          </div>
                        </div>
                      )}
                    </div>
                    <span
                      className="nav-bar__top--button__text"
                      style={
                        personalAreaIsDropdownOpen
                          ? {color: 'var(--dibble-yellow)'}
                          : {}
                      }>
                      {obj_heb.personal_area}
                    </span>
                  </div>
                  {personalAreaIsDropdownOpen && (
                    <NavBarDropDown items={PersonalArea} left />
                  )}
                </div>
                <div
                  className="nav-bar__top--button nav-bar__top--lobby"
                  onMouseEnter={() => {
                    setLobbyIsDropdownOpen(true);
                    setBgDiv(<div className="bg-blur" />);
                  }}
                  onMouseLeave={() => {
                    setLobbyIsDropdownOpen(false);
                    setBgDiv(null);
                  }}>
                  <div
                    className="nav-bar__top--button--style nav-bar__top--button--left"
                    onClick={
                      data && data?.has_lobby
                        ? () => navigate('/lobby')
                        : data
                        ? () => navigate('/lobby-register')
                        : () => navigate('/login')
                    }>
                    <div style={{position: 'relative'}}>
                      {lobbyIsDropdownOpen ? (
                        <img
                          className="nav-bar__top--button__icon"
                          src={lobbyIconFull}
                          alt="man"
                          loading="eager"
                        />
                      ) : (
                        <img
                          className="nav-bar__top--button__icon"
                          src={lobbyIcon}
                          alt="man"
                          loading="eager"
                        />
                      )}
                    </div>
                    <span
                      className="nav-bar__top--button__text"
                      style={
                        lobbyIsDropdownOpen
                          ? {color: 'var(--dibble-yellow)'}
                          : {}
                      }>
                      {obj_heb.lobby_navbar}
                    </span>
                  </div>
                  {lobbyIsDropdownOpen && data?.has_lobby && (
                    <NavBarDropDown items={Lobby} left />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {isMobile ? (
          <>
            <div className="flex-row-16 margin-16">
              {navBarBack && (
                <img
                  className="nav-bar__bottom--back pointer"
                  src={icons.goBackIcon}
                  alt="back"
                  onClick={() => navigate(-1)}
                />
              )}
              <SearchNavBar
                setBgDiv={setBgDiv}
                className="nav-bar__bottom--search"
              />
              {navBarCart ? (
                <div className="relative">
                  <div className="badge-indicator">
                    {shoppingCartProducts.length}
                  </div>
                  <img
                    src={icons.wheelbarrow}
                    alt=""
                    className="nav-bar__bottom--cart pointer"
                    onClick={() => {
                      navigate('/cart');
                    }}
                  />
                </div>
              ) : (
                <img
                  src={icons.notes_icon}
                  alt=""
                  className="nav-bar__bottom--chat pointer"
                  onClick={() => {
                    openIntercom();
                  }}
                />
              )}
            </div>
            {needAddress && <Address setBgDiv={setBgDiv} />}
          </>
        ) : (
          <div className="nav-bar__bottom">
            <div>
              <SearchNavBar
                setBgDiv={setBgDiv}
                className="nav-bar__bottom--search"
              />
            </div>
            <Address setBgDiv={setBgDiv} />
          </div>
        )}
        <ResendEmailSection />
        {fastDeliveyStatus == false && fastDeliveyMessage && (
          <div className="nav-bar__bottom--client_text">
            <div className="nav-bar__bottom--client_text--header">
              {' '}
              {obj_heb.Please_Notice}
              <img
                className="nav-bar__bottom--client_text--icon"
                src={icons.red_heart}
                alt="back"
                onClick={() => navigate(-1)}
              />
            </div>
            <div className="nav-bar__bottom--client_text--content">
              {' '}
              {fastDeliveyMessage ? fastDeliveyMessage : ''}
            </div>
          </div>
        )}

        {isScroll && !bgDiv && (
          <span
            className={classNames(
              'screening-top-to-bottom ',
              data &&
                data?.is_email_verified != 1 &&
                'nav-bar__bottom--email-note',
            )}></span>
        )}
      </div>

      {bgDiv && bgDiv}
    </>
  );
}

export default NavBar;
