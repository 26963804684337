import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/style.css';
import {store} from './redux/store';
import {Provider} from 'react-redux';
import {HelmetProvider} from 'react-helmet-async';
import {IntercomProvider} from './utils/IntercomContext';
import CookiesComp from './components/Cookies';
import ScrollToTop from './components/ScrollToTop';
import ReactPixel from 'react-facebook-pixel';

const root = ReactDOM.createRoot(document.getElementById('root'));

const options = {
  autoConfig: true, 
  debug: false,   
};
ReactPixel.init('845687502998207', {}, options);
ReactPixel.pageView(); // For initial page load

root.render(
  <HelmetProvider>
    <IntercomProvider>
      <Provider store={store}>
        <ScrollToTop />
        <App />
        <CookiesComp />
      </Provider>
    </IntercomProvider>
  </HelmetProvider>,
);
