import React, {useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import {convertTimeFormat} from '../utils/supportFunctions';
import leftArrowHead from '../assets/icons/leftArrowHead.svg';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

export default function ProjectTransactionListItem({item, projectOrders}) {
  const navigate = useNavigate();
  const statuses = projectOrders?.projectOrders?.order_statuses || {};

  const getStatusColor = status => {
    if (status === 7) {
      return 'inherit'; // Keep the same color
    } else if (status === 9) {
      return 'red';
    } else {
      return 'black';
    }
  };
  const pressRedirect = () => {
    navigate(`/OrderHistorySummary/${item?.order_id}}`);
  };
  const isMobile = useSelector(state => state.mobile.isMobile);

  return (
    <>
      <div className="ProjectOrderHistoryItem__container">
        {isMobile ? (
          <div
            className={
              'ProjectOrderHistoryItem ProjectOrderHistoryItem_pointer'
            }
            style={item?.status > 6 ? {cursor: 'pointer'} : {}}>
            <div className="ProjectOrderHistoryItem_title">
              {item.trans_action}
              <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime">
                <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime_txt">
                  {item?.purpose}
                </div>
                <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime_txt">
                  {convertTimeFormat(
                    new Date(
                      new Date(item.trans_date).getTime() + 3 * 60 * 60 * 1000,
                    ),
                  )}
                </div>
              </div>
            </div>
            <div className="ProjectOrderHistoryItem_centerGroup">
              <div className="ProjectOrderHistoryItem_centerGroup_price">
                <span className="ProjectOrderHistoryItem_centerGroup_price_symbol">
                  ₪
                </span>
                {item.trans_amount}
              </div>
            </div>
            {item ? (
              <div className="ProjectOrderHistoryItem_activeOrder">
                <div
                  className={
                    !item.textInMinsClient
                      ? 'ProjectOrderHistoryItem_activeOrder_txt'
                      : 'ProjectOrderHistoryItem_activeOrder_txt_black'
                  }>
                  {item.performed_by_name}

                  <span style={{color: getStatusColor(item?.status)}}>
                    {statuses[item?.status]}
                  </span>
                </div>
              </div>
            ) : (
              <div className="ProjectOrderHistoryItem_leftArrowBox">
                <img
                  className="ProjectOrderHistoryItem_leftArrowBox_img"
                  src={leftArrowHead}
                  alt="leftArrowHead"
                />
              </div>
            )}
          </div>
        ) : (
          <div
            className={
              'ProjectOrderHistoryItem ProjectOrderHistoryItem_pointer'
            }>
            <div className="ProjectOrderHistoryItem_centerGroup">
              <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime">
                <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime_txt">
                  {item?.purpose}
                </div>
                <div className="ProjectOrderHistoryItem_centerGroup_orderDateTime_txt">
                  {item.trans_action}
                </div>
              </div>
              <div className="ProjectOrderHistoryItem_centerGroup_price">
                {item.trans_date}
              </div>
            </div>
            <div className="ProjectOrderHistoryItem_title">
              {item.trans_amount} ₪{' '}
            </div>

            <div className="ProjectOrderHistoryItem_activeOrder">
              <div
                style={{color: 'black'}}
                className={'ProjectOrderHistoryItem_activeOrder_txt'}>
                {item.performed_by_name}
              </div>
            </div>
          </div>
        )}
        <div className="ProjectOrderHistoryItem_hr">-</div>
      </div>
    </>
  );
}
