import React, {useEffect, useMemo, useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import UserListItem from './UserListItem';
import AddUser from './AddUser';
import {fetchData} from '../utils/fetchData';
import {useNavigate} from 'react-router-dom';
import SearchInput from './SearchInput';
import ButtonAdd from './ButtonAdd';
import AddAndEditUserPopUp from './AddAndEditUserPopUp';

export default function MyUsersTab({
  currentPaginationPage,
  PageSize,
  onPageChange,
}) {
  const navigate = useNavigate();
  const [usersList, setUsersList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRole, setSelectedRole] = useState('משתמשים');
  const [isOpen, setIsOpen] = useState(false);

  const dataToDisplay = useMemo(() => {
    let filteredUsers = usersList?.users;

    if (selectedRole !== 'משתמשים') {
      filteredUsers = filteredUsers?.filter(item => item.roles == selectedRole);
    }

    if (searchText) {
      filteredUsers = filteredUsers?.filter(item => {
        const fullName = `${item.first_name} ${item.last_name}`.toLowerCase();
        return (
          item.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
          fullName.includes(searchText.toLowerCase())
        );
      });
    }

    console.log('Filtered Users:', filteredUsers);
    return filteredUsers;
  }, [searchText, usersList, selectedRole]);

  useEffect(() => {
    onPageChange(1);
  }, [onPageChange]);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      navigate('/login');
    }
    let dataObj = {
      request: 'get_contractor_users',
      token: userData?.token,
    };
    fetchData(dataObj)
      .then(response => {
        setUsersList(response);
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  }, [navigate]);

  const handleOnChange = value => {
    setSearchText(value);
  };

  const handleRoleChange = role => {
    console.log(`handleRoleChange`, role);
    setSelectedRole(role);
  };

  return (
    <>
      <div className="MyOrdersTab">
        <div className="ToolTipTab">
          <span
            className={`ToolTipTab--header ${
              selectedRole === 'משתמשים' ? 'selected' : ''
            }`}
            onClick={() => handleRoleChange('משתמשים')}>
            משתמשים ({usersList?.total_users})
          </span>
          <span
            className={`ToolTipTab--header ${
              selectedRole === 'מורשה חתימה' ? 'selected' : ''
            }`}
            onClick={() => handleRoleChange('מורשה חתימה')}>
            מורשי חתימה ({usersList?.num_signature_permitted})
          </span>
          <span
            className={`ToolTipTab--header ${
              selectedRole === 'מנהל פרוייקט' ? 'selected' : ''
            }`}
            onClick={() => handleRoleChange('מנהל פרוייקט')}>
            מנהלי פרוייקט ({usersList?.num_project_managers})
          </span>
          <SearchInput
            searchedText={searchText}
            setSearchedText={handleOnChange}
            placeholderText={obj_heb.search_user}
          />
        </div>

        {dataToDisplay?.length === 0 ? (
          <div className="MyOrdersTab_empty-list">
            <div className="MyOrdersTab_empty-list_text">
              <div className="MyOrdersTab_empty-list_text_title">
                {obj_heb.timeToMakeHistory}
              </div>
              <div className="MyOrdersTab_empty-list_text_sub-title">
                {obj_heb.hereWillShowenYourParches}
              </div>
            </div>
          </div>
        ) : (
          <div>
            {dataToDisplay?.map(user => (
              <UserListItem key={user.id} item={user} />
            ))}
          </div>
        )}
        <div className="MyOrdersTab-add">
          <ButtonAdd
            text={obj_heb.addUser}
            className="credit-cards__btn"
            onClick={() => setIsOpen(true)}
          />
        </div>
      </div>
      <AddAndEditUserPopUp isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="qrPopUp">
          <span className="qrPopUp_txt">{obj_heb.transferToAppStroeTxt}</span>
        </div>
      </AddAndEditUserPopUp>
    </>
  );
}
