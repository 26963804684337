import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import SEO from '../components/SEO';
import Title from '../components/Title';
import {useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import ShoppingCartItem from '../components/shoppingCart/ShoppingCartItem';
import {
  ShoppingCart_addProduct,
  ShoppingCart_updateProduct,
  ShoppingCart_updateProductPrice,
} from '../redux/slices/ShoppingCartSlice';
import Loader from '../components/Loader';
import Button from '../components/Button';
import PopUp from '../components/general/PopUp';
import {icons} from '../assets/constants';
import PersonalAreaTable from '../components/PersonalAreaTable';
import classNames from 'classnames';
import airPlane from '../assets/images/airplane.svg';

export default function OrderHistorySummary() {
  const navigate = useNavigate();

  // grab the params and the query selector
  let params = useParams();

  const Dispatch = useDispatch();

  const [userOrder, setUserOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [accordionIsOpen, setAccordionIsOpen] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const shoppingCart = useSelector(state => state.shopping_cart.products);

  useEffect(() => {
    // get avible dibble coin
    let userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      navigate('/login');
    }

    //getting user order
    let ActiveOrderDataDataObj = {
      request: 'get_my_order',
      token: userData?.token,
      order_id: params?.orderId || 0,
    };

    fetchData(ActiveOrderDataDataObj)
      .then(response => {
        const data = response;
        if (data.products) {
          setUserOrder(data);
          if (data.products.length) {
            data.products?.forEach(prod => {
              let productsDataDataObj = {
                request: 'get_product',
                token: userData?.token,
                product_id: prod.product_id,
              };
              fetchData(productsDataDataObj)
                .then(response => {
                  const reqData = response;
                  if (reqData) {
                    setProductsData(prev => [...prev, reqData]);
                  }
                })
                .catch(error => {
                  console.error('Error:', error.message);
                });
            });
          } else {
            let productsDataDataObj = {
              request: 'get_product',
              token: userData?.token,
              product_id: data?.product_id,
            };
            fetchData(productsDataDataObj)
              .then(response => {
                const reqData = response;
                if (reqData) {
                  setProductsData(prev => [...prev, reqData]);
                }
              })
              .catch(error => {
                console.error('Error:', error.message);
              });
          }
        }
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  }, [params]);
  const isMobile = useSelector(state => state.mobile.isMobile);

  /**
   * for passing product object to redux
   * @param {*} data  the data of the product form the server
   * @param {*} prod tje data of the produxt from the order history
   * @param {*} key the key form the product object from redux - to overwrite it
   * @returns
   */
  const productToRedux_multiOptions = (data, prod, key) => {
    const filterOptions1 = data.options_prices.filter(
      i => i.option1 == prod.option1,
    );
    let price;
    if (filterOptions1.length) {
      const filteredOption2 = filterOptions1.find(
        j => j.option2 === prod.option2,
      );
      price = filteredOption2 ? filteredOption2.price : filterOptions1[0].price;
    } else {
      price = 0;
    }
    const returnObject = {
      product_id: data?.product_id,
      name: data?.name,
      image: data?.image,
      catalog_number: data?.catalog_number,
      weight: data?.weight,
      price: price,
      sale: data?.sale_Price !== 0 && data?.sale_Price < data?.price,
      sale_Price: data?.sale_Price,
      black_Product: data?.black_Product,
      quantity: prod?.amount,
      option1_value: prod?.option1,
      option1_index: data.options_prices.filter(
        i => i.option1 == prod.option1,
      )[0]?.option1_index,
      option2_value: prod?.option2,
      option2_index: data.options_prices.filter(
        i => i.option2 == prod.option2,
      )[0]?.option2_index,
      options1_name: data?.option_name1,
      options2_name: data?.option_name2,
      need_crane: data?.need_crane,
      key: key,
    };
    return returnObject;
  };

  /**
   * for passing product object to redux
   * @param {*} data  the data of the product form the server
   * @param {*} prod tje data of the produxt from the order history
   * @param {*} key the key form the product object from redux - to overwrite it
   * @returns
   */
  const productToRedux_notMulti = (data, prod, key) => ({
    product_id: data?.product_id,
    name: data?.name,
    image: data?.image,
    catalog_number: data?.catalog_number,
    weight: data?.weight,
    price: data.price,
    sale: data?.sale_price !== 0 && data?.sale_price < data?.price,
    sale_price: data?.sale_price,
    black_Product: data?.black_Product,
    quantity: prod?.amount,
    option1_value: prod?.option1,
    option1_index: undefined,
    option2_value: undefined,
    option2_index: undefined,
    options1_name: undefined,
    options2_name: undefined,
    need_crane: data?.need_crane,
    key: key,
  });

  const getProductData = () => {
    setLoading(true);
    let userData = JSON.parse(localStorage.getItem('userData'));

    userOrder?.products.map(prod => {
      let getProduct = {
        request: 'get_product',
        token: userData?.token,
        product_id: Number(prod?.product_id),
      };
      let data;
      fetchData(getProduct)
        .then(response => {
          data = response;
          // if the history order product IS NOT in the shopping cart
          const sameProductIDProducts = shoppingCart.filter(
            i => i.product_id == prod?.product_id,
          );
          if (!sameProductIDProducts[0]) {
            if (data) {
              let productToRedux;
              if (data.options_prices.length) {
                productToRedux = productToRedux_multiOptions(data, prod);
              } else {
                productToRedux = productToRedux_notMulti(data, prod);
              }
              Dispatch(
                ShoppingCart_addProduct({
                  product: productToRedux,
                  oparation: 'increase',
                }),
              );
            }
            // if the history order product id IS in the shopping cart but NOT multi option
          } else if (sameProductIDProducts[0].option1_value === '') {
            Dispatch(
              ShoppingCart_updateProduct(
                productToRedux_notMulti(
                  data,
                  prod,
                  sameProductIDProducts[0].key,
                ),
              ),
            );
            // if the history order product id IS in the shopping cart but IS multi option
          } else if (sameProductIDProducts[0].option1_value !== '') {
            const sameMultiOptionsProductsCartVsOrder =
              sameProductIDProducts.filter(
                j =>
                  j.option1_value == prod.option1 &&
                  j.option2_value == prod.option2,
              );
            // found that a product with 2 multi option are the same as the shopping cart - exect match
            if (sameMultiOptionsProductsCartVsOrder[0]) {
              Dispatch(
                ShoppingCart_updateProduct(
                  productToRedux_multiOptions(
                    data,
                    prod,
                    sameMultiOptionsProductsCartVsOrder[0].key,
                  ),
                ),
              );
            } else {
              const productToRedux = {
                product_id: data?.product_id,
                name: data?.name,
                image: data?.image,
                catalog_number: data?.catalog_number,
                weight: data?.weight,
                price: data.options_prices.filter(
                  i => i.option1 == prod.option1,
                )[0]?.price,
                sale: data?.sale_Price !== 0 && data?.sale_Price < data?.price,
                sale_Price: data?.sale_Price,
                black_Product: data?.black_Product,
                quantity: prod?.amount,
                option1_value: prod?.option1,
                option1_index: data.options_prices.filter(
                  i => i.option1 == prod.option1,
                )[0]?.option1_index,
                option2_value: prod?.option2,
                option2_index: data.options_prices.filter(
                  i => i.option2 == prod.option2,
                )[0]?.option2_index,
                options1_name: data?.option_name1,
                options2_name: data?.option_name2,
                need_crane: data?.need_crane,
              };

              Dispatch(
                ShoppingCart_addProduct(
                  productToRedux_multiOptions(data, prod),
                ),
              );
            }
          }
        })
        .catch(error => {
          console.error('Error:', error.message);
        });
    });
    setLoading(false);
    navigate('/cart');
  };

  const resend_invoice = () => {
    let userData = JSON.parse(localStorage.getItem('userData'));

    let re_send_invice = {
      request: 'resend_invoice',
      token: userData?.token,
      order_id: Number(userOrder.order_id),
    };
    setPopUpIsOpen(true);
    fetchData(re_send_invice)
      .then(response => {
        const data = response;
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  };
  const SEOLoader = () => {
    const orderSummeryLink = `/OrderHistorySummary}`;
    return (
      <SEO
        pageName={obj_heb.orderSummery}
        content={obj_heb.orderSummery}
        link={orderSummeryLink}
      />
    );
  };

  const openAcordion = () => {
    setAccordionIsOpen(prev => !prev);
  };
  return (
    <>
      <SEOLoader />

      <div className="PersonalArea ">
        {loading && (
          <div className="loader__container">
            <Loader />
          </div>
        )}
        {userOrder !== null && (
          <>
            <div className="PersonalArea_content">
              <Title title={userOrder?.destination_address} />
              <div className="PersonalArea_content_section">
                <PersonalAreaTable
                  userOrder={userOrder}
                  resend_invoice={resend_invoice}
                  getProductData={getProductData}
                />
                {!isMobile && (
                  <div className="PersonalArea_content_section_par2">
                    <div
                      className="PersonalArea_content_section_par2_toggle"
                      onClick={openAcordion}>
                      <div className="PersonalArea_content_section_par2_toggle_text">
                        {obj_heb.orderItems}
                      </div>
                      <img
                        className={classNames(
                          accordionIsOpen && 'flip',
                          'PersonalArea_content_section_par2_toggle_icon_flip',
                        )}
                        src={icons.arrow_head_down}
                        alt=""
                      />
                    </div>
                    {accordionIsOpen && (
                      <div className="Shopping-cart_scroll-Section--orderHistoryAccordion">
                        {(userOrder.granted_products &&
                        userOrder.granted_products.length !== 0
                          ? userOrder.granted_products
                          : userOrder.products
                        )

                          .slice()
                          .reverse()
                          .map((item, index) => {
                            return (
                              <React.Fragment key={item.product_id}>
                                <div className="d-flex justify-content-center">
                                  {index !== 0 && <hr className={'hr'}></hr>}
                                </div>
                                <ShoppingCartItem
                                  item={{
                                    key: String('ShoppingCartItem' + index),
                                    product_id: item.product_id,
                                    name: item?.product_name,
                                    image: item?.product_image,
                                    price: Number(item.price),
                                    sale: false,
                                    newPrice: 9999999,
                                    blackProduct: false,
                                    quantity: item.amount,
                                    option1_value: item?.option1,
                                    option1_index: productsData[
                                      index
                                    ]?.options_prices?.filter(
                                      i => i.option1 == item.option1,
                                    )[0]?.option1_index,
                                    option2_value: item?.option2,
                                    option2_index: productsData[
                                      index
                                    ]?.options_prices?.filter(
                                      i => i.option2 == item.option2,
                                    )[0]?.option2_index,
                                    options1_name: item.option_name1,
                                    options2_name: item.option_name2,
                                  }}
                                  orderHistory
                                />
                              </React.Fragment>
                            );
                          })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {popUpIsOpen && (
        <PopUp isOpen={popUpIsOpen} setIsOpen={setPopUpIsOpen}>
          <div className="PersonalArea_popup_content">
            <img className="PersonalArea_popup_content_img" src={airPlane} />
            <div className="PersonalArea_popup_content_text">
              <div className="PersonalArea_popup_content_text_firstLine">
                {obj_heb.weSendIt}
              </div>
              <div className="PersonalArea_popup_content_text_secondLine">
                {obj_heb.reInvoiceSendText}
              </div>
            </div>
            <Button
              text={obj_heb.great}
              onClick={() => setPopUpIsOpen(false)}
              textColor="var(--white)"
              backgroundColor="var(--dibble-yellow)"
              className={'PersonalArea_popup_content_button'}
            />
          </div>
        </PopUp>
      )}
    </>
  );
}
