import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import obj_heb from '../utils/Obj_heb.json';

import CreditCard from './CreditCard';
import CreditCardAddition from './CreditCardAddition';
import DibbleCoin from './DibbleCoin';
import ButtonAdd from './ButtonAdd';
import Loader from './Loader';
import Modal from './general/Modal';

const CreditCardSelector = ({
  creditCards,
  handleSelect,
  dibbleCoins,
  creditCard,
  setInstallments,
  second,
  isProject,
  setFinish,
  setFinishText,
  isLoading,
  setIsLoading,
  isOpen,
  setIsOpen,
  setCardAddedId = () => {},
}) => {
  const [addCardIsOpen, setAddCardIsOpen] = useState(false);

  const navigate = useNavigate();
  const isMobile = useSelector(state => state.mobile.isMobile);

  const SelectorContent = (
    <div className="credit-card-selector">
      {isMobile && (
        <span className="credit-card-selector__title">
          {obj_heb.myCreditCards}
          {creditCards.length > 8 && (
            <div className="credit-card-selector--screening"></div>
          )}
        </span>
      )}
      {isLoading ? (
        <div className="full-page relative margin-8">
          <Loader />
        </div>
      ) : (
        <div className="credit-card-selector__list">
          {!second && dibbleCoins > 0 && (
            <>
              <DibbleCoin
                dibbleCoins={dibbleCoins}
                list="summary"
                listAction={handleSelect}
                cardSelected={creditCard}
                setInstallments={setInstallments}
                isProject={isProject}
                className="credit-card-selector__list--item"
              />
              {creditCards.length !== 0 && (
                <div className="credit-card-selector__list--separator"> -</div>
              )}
            </>
          )}
          {creditCards.length !== 0 ? (
            <>
              <div className="summary_hr"></div>
              {creditCards.map((card, index) => {
                return (
                  <Fragment
                    key={'credit-card-selector-' + JSON.stringify(card)}>
                    <CreditCard
                      card={card}
                      list="summary"
                      onSelect={handleSelect}
                      cardSelected={creditCard}
                      className="credit-card-selector__list--item"
                    />
                    {creditCards.length - 1 !== index && (
                      <div className="credit-card-selector__list--separator">
                        -
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </>
          ) : (
            <div className="credit-card-selector__no-credit-cards">
              <span>{obj_heb.thereIsNoCreditCards}</span>
              <span>{obj_heb.youCanAddHere}</span>
            </div>
          )}
        </div>
      )}
      <ButtonAdd
        text={obj_heb.addCreditCard}
        className={!isMobile && 'margin-8'}
        onClick={() => setAddCardIsOpen(true)}
      />
    </div>
  );

  return (
    <>
      {isMobile ? (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
          {SelectorContent}
        </Modal>
      ) : (
        SelectorContent
      )}

      {addCardIsOpen && (
        <CreditCardAddition
          isOpen={addCardIsOpen}
          setIsOpen={setAddCardIsOpen}
          setFinish={setFinish}
          setFinishText={setFinishText}
          setListIsLoading={setIsLoading}
          setCardAddedId={setCardAddedId}
        />
      )}
    </>
  );
};

export default CreditCardSelector;
