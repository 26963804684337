import React, {useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import {convertTimeFormat, numberWithCommas} from '../utils/supportFunctions';
import leftArrowHead from '../assets/icons/leftArrowHead.svg';
import {useNavigate} from 'react-router-dom';
import PopUp from './general/PopUp';
import qrCodeAppStore from '../assets/icons/qrCodeAppStore.png';
import qrCodePlayStore from '../assets/icons/qrCodePlayStore.png';
import Button from './Button';
import {useSelector} from 'react-redux';
import icons from '../assets/constants/icons';
export default function OrderHistoryItem({item}) {
  const navigate = useNavigate();
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  let qrImage = qrCodePlayStore;
  let application_store =
    'https://play.google.com/store/apps/details?id=com.dibble.client&hl=en&gl=US';

  if (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/macintosh/i) ||
    navigator.userAgent.match(/mac os x/i)
  ) {
    qrImage = qrCodeAppStore;
    application_store =
      'https://apps.apple.com/us/app/dibble-%D7%97%D7%A0%D7%95%D7%AA-%D7%97%D7%95%D7%9E%D7%A8%D7%99-%D7%91%D7%A0%D7%99%D7%99%D7%9F/id1521796518';
  }

  const pressRedirect = () => {
    navigate(`/OrderHistorySummary/${item?.order_id}}`);
  };
  const isMobile = useSelector(state => state.mobile.isMobile);

  const loadTotalOrderPrice = orderItem => {
    let total = 0;
    if ('order_genre' in orderItem && orderItem?.order_genre != 1) {
      total = orderItem?.total_order_price;
    } else {
      let updated_products = getUpdatedProductList(orderItem);
      for (let i = 0; i < updated_products?.length; i++) {
        total =
          total + updated_products[i]['amount'] * updated_products[i]['price'];
      }
    }
    return parseFloat(total, 2);
  };
  const getUpdatedProductList = orderItem => {
    return orderItem?.granted_products == null
      ? orderItem?.products
      : orderItem?.granted_products;
  };
  const loadGrandTotalOrderPrice = orderItem => {
    return (
      loadTotalOrderPrice(orderItem) +
      parseFloat(orderItem?.delivery_cost) +
      loadVatAmount(orderItem)
    ).toFixed(2);
  };
  const loadVatAmount = orderItem => {
    const vat =
      ((parseFloat(loadTotalOrderPrice(orderItem)) +
        parseFloat(orderItem?.delivery_cost)) *
        orderItem?.vat_percent) /
      100;
    return vat;
  };
  return (
    <>
      <div className="OrderHistoryItem__container">
        {isMobile ? (
          <div
            className={'OrderHistoryItem OrderHistoryItem_pointer'}
            onClick={item?.status <= 6 ? () => {} : pressRedirect}
            style={item?.status > 6 ? {cursor: 'pointer'} : {}}>
            <div
              className="OrderHistoryItem_title"
              onClick={item?.status <= 6 ? pressRedirect : () => {}}>
              {item?.destination_address.length > 23
                ? item?.destination_address.slice(0, 20) + '...'
                : item?.destination_address}

              <div className="OrderHistoryItem_centerGroup_orderDateTime">
                <div className="OrderHistoryItem_centerGroup_orderDateTime_txt">
                  {item?.purpose}
                </div>
                <div className="OrderHistoryItem_centerGroup_orderDateTime_txt">
                  {convertTimeFormat(
                    new Date(
                      new Date(item?.placed_on).getTime() + 3 * 60 * 60 * 1000,
                    ),
                  )}
                </div>
              </div>
            </div>

            <div
              className="OrderHistoryItem_centerGroup"
              onClick={item?.status <= 6 ? pressRedirect : () => {}}>
              <div className="OrderHistoryItem_centerGroup_price">
                <span className="OrderHistoryItem_centerGroup_price_symbol">
                  ₪
                </span>
                {loadGrandTotalOrderPrice(item)}
              </div>
            </div>
            {item?.status <= 6 ? (
              <div className="OrderHistoryItem_activeOrder">
                <div
                  className={
                    !item.textInMinsClient
                      ? 'OrderHistoryItem_activeOrder_txt'
                      : 'OrderHistoryItem_activeOrder_txt_black'
                  }>
                  {item.textInMinsClient
                    ? ' ' + item.textInMinsClient + ' ' + obj_heb.minutes
                    : obj_heb.activeOrder}
                </div>
                <div
                  onClick={() => {
                    setPopupIsOpen(true);
                  }}
                  className="OrderHistoryItem_activeOrder_seeInApp">
                  {obj_heb.toTrack}
                </div>
              </div>
            ) : (
              <div className="OrderHistoryItem_leftArrowBox">
                <img
                  className="OrderHistoryItem_leftArrowBox_img"
                  src={leftArrowHead}
                  alt="leftArrowHead"
                />
              </div>
            )}
          </div>
        ) : (
          <div
            className={'OrderHistoryItem OrderHistoryItem_pointer'}
            onClick={item?.status <= 6 ? () => {} : pressRedirect}
            style={item?.status > 6 ? {cursor: 'pointer'} : {}}>
            <div
              className="OrderHistoryItem_title"
              onClick={item?.status <= 6 ? pressRedirect : () => {}}>
              {item?.destination_address.length > 40
                ? item?.destination_address.slice(0, 37) + '...'
                : item?.destination_address}
            </div>
            <div
              className="OrderHistoryItem_centerGroup"
              onClick={item?.status <= 6 ? pressRedirect : () => {}}>
              <div className="OrderHistoryItem_centerGroup_orderDateTime">
                <div className="OrderHistoryItem_centerGroup_orderDateTime_txt">
                  {item?.purpose}
                </div>
                <div className="OrderHistoryItem_centerGroup_orderDateTime_txt">
                  {convertTimeFormat(
                    new Date(
                      new Date(item?.placed_on).getTime() + 3 * 60 * 60 * 1000,
                    ),
                  )}
                </div>
              </div>
              <div className="OrderHistoryItem_centerGroup_price">
                <span className="OrderHistoryItem_centerGroup_price_symbol">
                  ₪
                </span>
                {loadGrandTotalOrderPrice(item)}
              </div>
            </div>
            {item?.status <= 6 ? (
              <div className="OrderHistoryItem_activeOrder">
                <div
                  className={
                    !item.textInMinsClient
                      ? 'OrderHistoryItem_activeOrder_txt'
                      : 'OrderHistoryItem_activeOrder_txt_black'
                  }>
                  {item.textInMinsClient
                    ? ' ' + item.textInMinsClient + ' ' + obj_heb.minutes
                    : obj_heb.activeOrder}
                </div>
                <div
                  onClick={() => {
                    setPopupIsOpen(true);
                  }}
                  className="OrderHistoryItem_activeOrder_seeInApp">
                  {obj_heb.seeOrderInApp}
                </div>
              </div>
            ) : (
              <div className="OrderHistoryItem_leftArrowBox">
                <img
                  className="OrderHistoryItem_leftArrowBox_img"
                  src={leftArrowHead}
                  alt="leftArrowHead"
                />
              </div>
            )}
          </div>
        )}
        <div className="OrderHistoryItem_hr">-</div>
      </div>
      {popupIsOpen && (
        <PopUp isOpen={popupIsOpen} setIsOpen={setPopupIsOpen}>
          <div className="qrPopUp">
            {!isMobile && (
              <span className="qrPopUp_txt">
                {obj_heb.transferToAppStroeTxt}
              </span>
            )}
            <img
              className="qrPopUp_img"
              src={isMobile ? icons.dark_dibble_logo : qrImage}
              alt="qrImage"
            />
            {isMobile && (
              <span className="qrPopUp_txt">
                {obj_heb.transferToAppStroeTxt}
              </span>
            )}
            {/* <div className="add-address__extra-details"></div> */}
            <Button
              text={obj_heb.transferToAppStore}
              onClick={() => {
                window.location.href = application_store;
              }}
              textColor="white"
              backgroundColor="var(--dibble-yellow)"
              className="OrderHistoryItem_button"
            />
          </div>
        </PopUp>
      )}
    </>
  );
}
