import React, {useState} from 'react';
import classNames from 'classnames';

const RadioButton = ({
  data,
  onSelect,
  defaultValue,
  color,
  containerClassName,
  itemClassName,
}) => {
  const [isActive, setIsActive] = useState(
    defaultValue !== undefined ? defaultValue : data[0]?.index,
  );

  const handleSelect = item => {
    setIsActive(item.index);
    onSelect && onSelect(item);
  };

  return (
    <div className={classNames('radio-buttons', containerClassName)}>
      {data.map(item => {
        return (
          <div
            onClick={() => handleSelect(item)}
            key={'radio-button-' + JSON.stringify(item)}
            className={classNames(
              'radio-buttons__btn',
              isActive == item.index &&
                (item?.checked === undefined || item?.checked) &&
                'radio-buttons__btn--selected',
              item?.disable && 'radio-buttons__btn--disable',
              itemClassName,
            )}
            style={{
              backgroundColor: item?.color ? item.color : color && color,
            }}>
            <span>{item.label}</span>
            {item?.subLabel && (
              <span className="radio-buttons__btn--sub-label">
                {item.subLabel}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RadioButton;
