import React from 'react';
import SEO from '../components/SEO';
import obj_heb from '../utils/Obj_heb.json';

export default function Terms() {
  return (
    <React.Fragment>
      <SEO pageName={obj_heb.TOU} content={obj_heb.TOUDesc} link="/terms" />
      <div className="Terms">
        <div>
          {/* p1 */}
          <p>
            <strong>
              <u>תקנון ותנאי שימוש</u>
            </strong>
          </p>
          <p>
            תקנון השימוש המפורט להלן יחד עם "מדיניות הפרטיות"{' '}
            <a href="/privacy-policy">בקישור זה</a>{' '}
            <strong>("תנאי השירות")</strong>, מסדירים את תנאי השימוש בשירותים של
            <strong> דיבל אפ בע"מ </strong>
            ח.פ
            <strong> 516637006 </strong>
            מ-
            <strong> המרץ 4, תל אביב </strong>
            (להלן:
            <strong> "דיבל" </strong>
            או
            <strong> "החברה"</strong>),
            <br />
            ובעת השימוש בשירותי החברה
            <strong> הינך מסכים לתנאי השירות </strong>
            .נא עיין בתנאי השירות בתשומת לב בטרם ביצוע כל שימוש בשירותי החברה.
            עצם השימוש בשירותי החברה מהווה הסכמה לתנאי השירות,
            <br />
            ללא כל הגבלה או הסתייגות והחברה רואה במשתמש כמי שקרא את תנאי השירות
            במלואם. על כן, אם אינך מסכים לתנאי השירות, אנא הימנע מכל שימוש
            בשירותי החברה.
            <br />
            להלן הגדרות משמעויותיהם של מונחים המופיעים תקנון שימוש ומפרט קניין
            רוחני ואלמנט סודיות זה:
          </p>

          <ol>
            {/* 1  */}
            <li>
              <strong>
                <u>הגדרות</u>
              </strong>

              <ol>
                {/* 1.1 */}
                <li>
                  <p>
                    <strong> "משתמש" </strong>
                    או
                    <strong> "אתה" </strong>
                    משמעו כל משתמש בשירותי החברה, לרבות אדם או חברה או כל ישות
                    המפעילים רכיב אלקטרוני המשתמש בשירותי החברה מטעמם או עבורם,
                    באמצעות כל מחשב או אמצעי תקשורת אחר (כדוגמת טלפון נייד,
                    טאבלט וכיו"ב), לרבות שימוש בשירותי החברה בין באמצעות רשת
                    האינטרנט ובין באמצעות כל רשת או אמצעי תקשורת אחר.
                  </p>
                </li>

                {/* 1.2*/}
                <li>
                  <p>
                    <strong> "שירותי החברה" </strong>
                    משמעו יישומון החברה ואתר האינטרנט וכן כל ערוץ תקשורת אחר
                    דרכו המשתמש יכול להתקשר בהסכם רכישה ו/או לקבל גישה למידע
                    המוצג ביישומון החברה ואתר האינטרנט.
                  </p>
                </li>

                {/* 1.3 */}
                <li>
                  <p>
                    <strong> "אתר האינטרנט" </strong>
                    או
                    <strong> "האתר" </strong>
                    אתר האינטרנט של החברה בכתובת
                    <a href="/">www.dibble.co.il</a>, לרבות אתרים נוספים
                    המוזכרים באתר זה.
                  </p>
                </li>

                {/* 1.4 */}
                <li>
                  <p>
                    <strong> "יישומון החברה" </strong>
                    משמעו, היישום בשם dibble, אשר מסופק למשתמש על-ידי החברה, על
                    מנת להזמין מוצרים מבתי העסק.
                  </p>
                </li>

                {/* 1.5 */}
                <li>
                  <p>
                    <strong> "מוצרים" </strong>
                    משמעו מוצרים קמעונאיים, מוצרים מתכלים, מוצרי שירות וכל מוצר
                    צריכה אחר המוצעים על ידי בתי העסק.
                  </p>
                </li>

                {/* 1.6 */}
                <li>
                  <p>
                    <strong> "בית העסק" </strong>
                    או
                    <strong> "בתי העסק" </strong>
                    משמעו, בית עסק שחתם על הסכם עם דיבל ומציע למכירה את מוצריו
                    ו/או את שירותיו דרך עיסוק, באמצעות שירותי החברה. למעט אם
                    נאמר אחרת, כל בית עסק הינו "עוסק" כהגדרתו בחוק הגנת הצרכן,
                    תשמ"א-1981.
                  </p>
                </li>

                {/* 1.7 */}
                <li>
                  <p>
                    <strong> "הסכם רכישה" </strong>
                    משמעו הסכם לרכישת מוצרים של בית עסק ו/או שירותים אפשריים
                    אחרים במסגרת הזמנה שתתבצע על ידי משתמש תוך שימוש בשירותי
                    החברה.
                  </p>
                </li>

                {/* 1.8 */}
                <li>
                  <p>
                    <strong> "שליח" </strong>
                    משמעו האדם שמבצע את שירות המשלוח עבור החברה, ככל שהוזמן
                    משלוח מהחברה באמצעות שירותי החברה.
                  </p>
                </li>

                {/* 1.9 */}
                <li>
                  <p>
                    <strong> "תוכן" </strong>
                    משמעו מידע מכל מין וסוג, לרבות כל תוכן מילולי, חזותי, קולי
                    או כל שילוב שלהם, וכן עיצובם, עיבודם, עריכתם, הפצתם ודרך
                    הצגתם לרבות (אך לא רק): כל תמונה, צילום, איור, הנפשה, תרשים,
                    דמות, הדמיה, סרטון, קובץ קולי וקובץ מוסיקלי; כל תוכנה, קובץ,
                    קוד מחשב, יישום, פרוטוקול, מאגר נתונים וממשק, וכן כל תו,
                    סימן, סמל וצלמית (icon).
                  </p>
                </li>
              </ol>
            </li>

            {/* 2 */}
            <li>
              <strong>
                <u>כללי</u>
              </strong>

              <ol>
                {/* 2.1 */}
                <li>
                  <p>
                    תנאי השירות מסדירים את היחסים בין החברה לבין כל אדם הגולש
                    ו/או הצופה ו/או הרוכש מוצרים באמצעות שירותי החברה.
                  </p>
                </li>

                {/* 2.2*/}
                <li>
                  <p>
                    תנאי השירות נתונים לשינוי מעת לעת ועל פי שיקול דעתה הבלעדי
                    של החברה וזאת ללא כל צורך במתן הודעה מוקדמת. היה ויוחלט על
                    שינוי הוראות תנאי השירות, החל ממועד שינוי התקנון, יחייב
                    הנוסח המעודכן המתפרסם ביישומון החברה ו/או באתר האינטרנט, לפי
                    העניין. החברה תיידע את המשתמש על כל שינוי מהותי בתנאי
                    השירות, בשירותי החברה או בכתובת הדוא"ל שנמסרה לחברה על ידי
                    המשתמש. אם המשתמש אינו מסכים לתנאי השירות המתוקנים של החברה,
                    עליו לחדול להשתמש בשירותי החברה.
                  </p>
                </li>

                {/* 2.3 */}
                <li>
                  <p>
                    החברה רשאית לשנות ו/או להפסיק, לפי שיקול דעתה הבלעדי, ובכל
                    עת, את שירותי החברה, וזאת ללא כל צורך במתן הודעה מוקדמת לכך.
                    שינויים מסוג זה עלולים להיות כרוכים בתקלות או לעורר בתחילה
                    אי נוחות זמנית. לא תהא למשתמש כל טענה, תביעה או דרישה כלפי
                    החברה בגין ביצוע שינויים כאמור או תקלות שיתרחשו אגב ביצועם.
                  </p>
                </li>
              </ol>
            </li>

            {/* 3 */}
            <li>
              <strong>
                <u>תיאור השירות</u>
              </strong>

              <ol>
                {/* 3.1 */}
                <li>
                  <p>
                    החברה מספקת פלטפורמה טכנולוגית לקישור בין משתמשים ובתי עסק
                    שונים, כאשר הרכישה של מוצרים מתבצעת באופן ישיר מול מהחברה
                    באמצעות שירותי החברה. שירותי החברה מאפשרים למשתמשים לרכוש
                    מוצרים דרך שירותי החברה לפי בחירתם, כמו גם שירותי משלוחים
                    מהחברה. החברה עשויה לגבות דמי משלוח ו/או דמי שירות ו/או כל
                    תשלום אחר מהמשתמשים בשיעורים אשר עשויים להיות שונים מהזמנה
                    להזמנה בהתאם לפרמטרים שונים. מידע אודות דמי משלוח ו/או דמי
                    שירות ו/או כל תשלום אחר כאמור העשויים לחול בהזמנה מסוימת
                    יוצגו לפני ביצוע ההזמנה באמצעות שירותי החברה. מובהר כי החברה
                    תהיה רשאית לשנות את גובה דמי המשלוח ו/או דמי השירות ו/או כל
                    תשלום אחר לחברה מעת לעת לפי שיקול דעתה וללא צורך במתן הודעה
                    מוקדמת.
                  </p>
                </li>

                {/* 3.2*/}
                <li>
                  <p>
                    במידה ויש לך שאלה או חשש כלשהם בנוגע לכל עניין הקשור למוצרים
                    הנמכרים ומוצעים דרך שירותי החברה, ניתן לפנות אלינו ואנו
                    ניצור קשר מול בית העסק, הרלוונטי, שיספק מידע ספציפי יותר
                    בנוגע לפי בקשה. לידיעתך, מכירת ורכישת המוצרים ו/או השירותים
                    של בית עסק מסוים עשויים להיות כפופים לתנאים והגבלות נוספים
                    של בית העסק או של החברה כמפורט באמצעות שירותי החברה.
                  </p>
                </li>

                {/* 3.3 */}
                <li>
                  <p>
                    החברה רשאית להטיל הגבלות מסוימות על הזמנתך, לרבות אך לא רק,
                    מגבלות כמות וגודל להזמנה. החברה תיידע אותך על כל מגבלה
                    הקשורה לזמינות של מוצרים כלשהם לפני ביצוע המשלוח של ההזמנה,
                    על-ידי יצירת קשר בצ'אט שירות הלקוחות או בטלפון.
                  </p>
                </li>
              </ol>
            </li>

            {/* 4 */}
            <li>
              <strong>
                <u>המוצרים ומחיריהם</u>
              </strong>

              <ol>
                {/* 4.1 */}
                <li>
                  <p>
                    באמצעות שירותי החברה נמכרים מוצרי צריכה ושירות, והכל כמפורט
                    ברשימת המוצרים המוצגים בשירותי החברה.
                  </p>
                </li>

                {/* 4.2*/}
                <li>
                  <p>
                    תמהיל המוצרים המוצג בשירותי החברה ואשר מוצע למכירה נתון
                    לשיקול דעת החברה. החברה אינה מתחייבת למגוון מוצרים ספציפי
                    ולכך שכל המוצרים המוצגים באמצעות שירותי החברה אכן קיימים
                    בפועל במלאי בכל עת. החברה רשאית לעדכן את המוצרים, להחליפם,
                    לחדול מהצעתם, להסיר מוצרים שאזלו וכד'.
                  </p>
                </li>

                {/* 4.3 */}
                <li>
                  <p>
                    מחירי המוצרים המוצגים באמצעות שירותי החברה נקובים בשקלים
                    חדשים (ש"ח) וכוללים מע"מ (לפי שיעורו הנוכחי), ואינם כוללים
                    דמי משלוח, אשר מפורטים בנפרד בהזמנה.
                  </p>
                </li>

                {/* 4.4 */}
                <li>
                  <p>
                    המידע המוצג באמצעות שירותי החברה מוצע על בסיס "כמות שהוא"
                    ("AS IS") כפי שהתקבל מבית העסק שממנו יסופקו המוצרים.
                  </p>
                </li>

                {/* 4.5 */}
                <li>
                  <p>
                    החברה רשאית לעדכן, מעת לעת ולפי שיקול דעתה, את תנאי ותעריפי
                    המשלוחים ו/או כל תשלום אחר לחברה, וזאת מבלי שתידרש ליתן על
                    כך הודעה מוקדמת. המחירים והתנאים המחייבים הם אלו שנמסרו
                    למשתמש בעת השלמת הליך ההזמנה.
                  </p>
                </li>

                {/* 4.6 */}
                <li>
                  <p>
                    החברה רשאית לערוך באתר מבצעים ו/או להציע הנחות וכל הטבה אחרת
                    בתנאים שיקבעו על ידה ולפי שיקול דעתה הבלעדי.
                  </p>
                </li>

                {/* 4.7 */}
                <li>
                  <p>
                    החברה פועלת על מנת להבטיח כי המחירים שמוצגים באתר נכונים.
                    אולם, יתכנו אי דיוקים ו/או שגיאות. במקרה שמתגלה שגיאה במחירו
                    של מוצר, החברה תציע למשתמש לרכוש את המוצר במחיר הנכון או
                    לחילופין לבטל את ההזמנה.
                  </p>
                </li>

                {/* 4.8 */}
                <li>
                  <p>
                    מחירי המוצרים המוצגים באמצעות שירותי החברה, אינם בהכרח זהים
                    למחירי המוצרים במקום אחר (ובכלל זה משווקים, ספקים וחנויות
                    אחרות של בית העסק ושל אחרים). בכל מקרה, המחירים המוצגים
                    באמצעות שירותי החברה הם המחירים הקובעים והמחייבים בהתייחס
                    לכל רכישה המבוצעת באמצעות שימוש בשירותי החברה.
                  </p>
                </li>

                {/* 4.9 */}
                <li>
                  <p>
                    המידע הכלול בשירותי החברה הינו למטרות כלליות ואינפורמטיביות
                    בלבד, הוא אינו מהווה המלצה ו/או חוות דעת לרכישת מוצר כלשהו.
                    אין ודאות כי המוצר או השירות יהיו זמינים, או ששם, תיאור,
                    מראה או מפרט המוצר או השירות הכלולים בשירותי החברה, יהיו
                    זהים למצב במציאות. המשתמש מודע ומסכים לכך שכל הסתמכות על
                    הצהרות, הבעות עמדה, עצות, תמונות או כל מידע אחר המוצג
                    באמצעות שירותי החברה נעשה על פי שיקול דעת המשתמש ובאחריותו
                    הבלעדית. המידע המלא לגבי כל מוצר מופיע על גבי אריזתו ועל כן
                    יש לבחון את האריזה טרם השימוש במוצר.
                  </p>
                </li>

                {/* 4.10 */}
                <li>
                  <p>
                    זולת אם נאמר במפורש אחרת, תכונות, דגמים, עיצוב, צבעים או
                    חזות של מוצרים המתוארים או מוצגים באמצעות שירותי החברה,
                    לרבות כל תמונה או הצגה של מוצר הכלולים בשירותי החברה, הינם
                    לצרכי המחשה בלבד, אין הם מחייבים, אין להסתמך עליהם, ויתכנו
                    הבדלים בינם לבין מוצרי בית העסק כפי שהם במציאות.
                  </p>
                </li>

                {/* 4.11 */}
                <li>
                  <p>
                    אין לעשות כל שימוש בשירותי החברה למטרות שאינן חוקיות, ואין
                    לקשר בין האתר לכל אתר אחר המכיל תכנים פורנוגראפיים, תכנים
                    המעודדים גזענות, אפליה או תכנים אחרים כלשהם המנוגדים להוראות
                    כל דין ומעודדים עבירה על חוק.
                  </p>
                </li>

                {/* 4.12 */}
                <li>
                  <p>
                    במוצרים אשר צויין בדף המוצר שהתמונה הינה ״להמחשה בלבד״
                    ייתכנו שינויים במותג ובצבע של המוצר. במידה והלקוח לא יהיה
                    מעוניין במוצר הלקוח רשאי לפנות לחברה בצ׳אט ולבקש להחזיר את
                    המוצר או להחליפו בהתאים לתנאי ההחזר של החברה
                  </p>
                </li>
              </ol>
            </li>

            {/* 5 */}
            <li>
              <strong>
                <u>חשבונות משתמשים</u>
              </strong>

              <ol>
                {/* 5.1 */}
                <li>
                  <p>
                    לצורך שימוש בשירותי החברה, חייב המשתמש לפתוח חשבון מקוון
                    באתר או ביישומון החברה (להלן: "החשבון"). פתיחת החשבון מחייבת
                    מסירת פרטים מדויקים, עדכניים ומלאים, כפי שידרשו מעת לעת.
                  </p>
                </li>

                {/* 5.2*/}
                <li>
                  <p>
                    החברה רשאית שלא לאשר פתיחת חשבון או לסגור חשבון באופן מיידי
                    אם הפרטים שהמשתמש מסר בעת פתיחת החשבון הנם חסרים, בלתי
                    מדויקים, או בלתי מעודכנים, בהתאם לשיקול דעתה הבלעדי של
                    החברה.
                  </p>
                </li>

                {/* 5.3 */}
                <li>
                  <p>
                    במסגרת פתיחת החשבון, יתבקש המשתמש להזין מספר טלפון, אשר ישמש
                    אותו במסגרת שימושו בשירותי החברה. לאחר מכן יתבקש המשתמש
                    להזין כתובת דוא"ל לצורך קבלת חשבוניות ועדכונים מהחברה.
                    המשתמש אחראי באופן בלעדי לשמור על חשבונו מאובטח ותחת סודיות.
                    החברה לא תהא אחראית לכל נזק שייגרם למשתמש ו/או מי מטעמו עם
                    פעילויות לא מורשות בחשבון, שלא כתוצאה ממחדל רשלני של החברה.
                  </p>
                </li>

                {/* 5.4 */}
                <li>
                  <p>
                    במידה והמשתמש מעונין למחוק את חשבונו עליו לפנות לחברת דיבל
                    במייל
                    <a href="mailto:office@dibble.co.il ">
                      office@dibble.co.il
                    </a>
                    .
                  </p>
                </li>

                {/* 5.5 */}
                <li>
                  <p>
                    לצורך פתיחת החשבון, על כל משתמש למסור לחברה פרטים של כרטיס
                    אשראי תקף, או פרטים של שיטת תשלום אחרת, בהתאם למפורט בשירותי
                    החברה. בעת השימוש בשירותי החברה, המשתמש מסכים לשלם עבור כל
                    הרכישות הנובעות משימושו בשירותי החברה. על המשתמש לוודא כי
                    פרטי אמצעי התשלום המקושר לחשבון המשתמש מעודכנים. על כל משתמש
                    לעשות שימוש בכרטיס אשראי ו/או אמצעי תשלום אחר הנושא את שמו
                    בלבד. היה והמשתמש עושה שימוש בפרטי אמצעי תשלום של צד שלישי
                    כלשהו, על השימוש באמצעי התשלום כאמור להיעשות על דעתו
                    ובהסכמתו המלאה של הבעלים הרשום של אמצעי התשלום. המשתמש יישא
                    בלעדית באחריות המלאה וישפה את החברה באופן מיידי בגין כל
                    תביעה ו/או דרישה של הבעלים של אמצעי התשלום או צד שלישי כלשהו
                    בקשר לשימוש של המשתמש באמצעי התשלום כאמור.
                  </p>
                </li>
              </ol>
            </li>

            {/* 6 */}
            <li>
              <strong>
                <u>המוצרים ומחיריהם</u>
              </strong>

              <ol>
                {/* 6.1 */}
                <li>
                  <p>
                    השימוש בשירותי החברה מותר אך ורק לבגירים (אנשים מעל גיל 18).
                    החברה שומרת לעצמה את הזכות לסרב להעניק שירות, לבטל חשבון
                    ולבטל הזמנות, על פי שיקול דעתה הבלעדי בכל מקרה שהמשתמש הינו
                    מתחת לגיל 18.
                  </p>
                </li>

                {/* 6.2*/}
                <li>
                  <p>
                    בעת בחירת מוצרים ושירותים שהמשתמש מעוניין לרכוש באמצעות
                    שימוש בשירותי החברה, המשתמש מבצע הזמנה מחייבת לרכוש מבית
                    העסק או ממספר בתי עסק את המוצרים והשירותים בתנאים המוצגים
                    למשתמש בשירותי החברה וזאת לפני ביצוע ההזמנה
                    <strong> ("ההזמנה")</strong>. לאחר שהמשתמש ביצע את ההזמנה,
                    החברה מקבלת את ההזמנה ומעבירה את פרטי ההזמנה לבית העסק או
                    מספר בתי העסק הרלוונטיים. כשההזמנה מתקבלת על ידי החברה,
                    ולאחר שהחברה העבירה למשתמש אישור הזמנה, המשתמש ייחשב לכל דבר
                    ועניין כמתקשר בהסכם הרכישה מול החברה. החברה תמציא למשתמש
                    חשבונית מס/קבלה שתכלול פירוט של המוצרים שהוזמנו על ידי
                    המשתמש מהחברה ובמידת הצורך, פירוט של שירותי המשלוחים ו/או
                    שירותים אחרים אם הוזמנו. החברה שומרת לעצמה את הזכות, לפי
                    שיקול דעתה הבלעדי, לבטל או לא לקבל הזמנה שבוצעה על ידי
                    המשתמש באמצעות שירותי החברה.
                  </p>
                </li>

                {/* 6.3 */}
                <li>
                  <p>
                    ההזמנה תאושר על ידי החברה בכפוף לזמינות הפריטים המסוימים
                    שהוזמנו במלאי. מובהר, כי ייתכנו מצבים בהם על אף שמוצר מסוים
                    מוצג באמצעות שירותי החברה כזמין לרכישה - בפועל הוא לא ימצא
                    במלאי או שלא ניתן לספק אותו. במצבים אלה החברה תנסה ליצור קשר
                    עם המשתמש באמצעות צ'אט שירות הלקוחות או במספר הטלפון שהוזן
                    בחשבון, כדי לקבל את אישור המשתמש לביצוע ההזמנה ללא המוצר שלא
                    ניתן לספקו, אך במידה והחברה לא תצליח ליצור עם המשתמש קשר כדי
                    לעדכנו, המשתמש מאשר ש החברה תבצע את ההזמנה מבלי לכלול את
                    המוצר שלא ניתן לספקו בהזמנה. לא תחויב בגין מוצרים שאינם
                    זמינים בעת ביצוע ההזמנה. אם יחויב המשתמש בגין מוצר שאינו
                    במלאי החברה תשיב למשתמש את הסכום ששולם. מובהר בזאת כי הזמנה
                    שלא אושרה באמצעות קבלת אישור הזמנה כאמור לעיל לא תחייב את
                    החברה בכל דרך שהיא.
                  </p>
                </li>

                {/* 6.4 */}
                <li>
                  <p>
                    בית העסק יכין ו/או יאסוף ויארוז את המוצרים המפורטים בהזמנה.
                    החברה תהיה אחראית להעברת התמורות שישולמו על ידי המשתמש לבית
                    העסק הרלוונטי בגין ההזמנה, וכן תהיה אחראית כלפי המשתמש
                    לביצוע ההולם של ההזמנה על ידי בית העסק.
                  </p>
                </li>

                {/* 6.5 */}
                <li>
                  <p>
                    המשתמש אחראי באופן בלעדי על בדיקה ואישור של התאמת המוצרים
                    אשר נשלחו אליו באמצעות שירותי החברה. השליח אשר מוסר את
                    המוצרים למשתמש ימסור במעמד המסירה למשתמש טופס לאישור בכתב של
                    קבלת ההזמנה והתאמת המוצרים, ואישור הטופס על ידי המשתמש עם
                    מילוי פרטי תעודת זהות וחתימה תחשב אישור לתקינות ההזמנה.
                  </p>
                </li>

                {/* 6.6 */}
                <li>
                  <p>
                    כשלמשתמש נמסר אישור הזמנה, המשתמש והחברה כורתים הסכם מחייב
                    לרכישת שירותי המשלוחים שהוזמנו על ידי המשתמש באמצעות שירותי
                    החברה.
                  </p>
                </li>

                {/* 6.7 */}
                <li>
                  <p>
                    על המשתמש להקפיד על מסירת כל הפרטים באופן נכון, מלא, מעודכן
                    ומדויק. החברה אינה אחראית על הזנת פרטים שגויים ועל תקלות
                    כלשהן שהתרחשו כתוצאה מכך. במקרה שהמוצרים יוחזרו לחברה או
                    לבית העסק בגלל פרטים מוטעים, יחויב המשתמש בתשלום בגין דמי
                    משלוח וטיפול.
                  </p>
                </li>

                {/* 6.8 */}
                <li>
                  <p>
                    ביצוע הזמנה באמצעות שירותי החברה אפשרי בשעות פעילות שירותי
                    החברה, למעט אם היא מושבתת לצורך תחזוקתה ו/או מכל סיבה אחרת.
                    מועדי וזמני האספקה של ההזמנה, כפופים לתנאים המפורטים בתנאי
                    השירות. בשירותי החברה מוצגים מועדי האספקה הזמינים למשלוח
                    בהתאם לאזור בו מתבצע ההזמנה ובכפוף לאזור השירות.
                  </p>
                </li>

                {/* 6.9 */}
                <li>
                  <p>
                    במקרה שהמשתמש מעוניין לשאול שאלות לגבי הזמנה, לשנות ו/או
                    לבטל ההזמנה לאחר הרכישה וטרם המשלוח, עליו לפנות ללא דיחוי
                    לשירות הלקוחות של החברה באמצעות הדרכים המפורטות בסעיף 9.4
                    מטה.
                  </p>
                </li>

                {/* 6.10 */}
                <li>
                  <p>
                    זולת אם נאמר במפורש אחרת, תכונות, דגמים, עיצוב, צבעים או
                    חזות של מוצרים המתוארים או מוצגים באמצעות שירותי החברה,
                    לרבות כל תמונה או הצגה של מוצר הכלולים בשירותי החברה, הינם
                    לצרכי המחשה בלבד, אין הם מחייבים, אין להסתמך עליהם, ויתכנו
                    הבדלים בינם לבין מוצרי בית העסק כפי שהם במציאות.
                  </p>
                </li>
              </ol>
            </li>

            {/* 7 */}
            <li>
              <strong>
                <u>תשלום</u>
              </strong>

              <ol>
                {/* 7.1 */}
                <li>
                  <p>
                    המשתמש ישלם את התשלום עבור המוצרים והשירותים כמפורט בהזמנה,
                    על ידי אמצעי התשלום שהוזן בחשבון, או על ידי בחירה בין מספר
                    חלופות תשלום שהוזנו בחשבון. חובת התשלום של המשתמש לחברה
                    תיווצר עם ביצוע ההזמנה באמצעות שירותי החברה.
                  </p>
                </li>

                {/* 7.2*/}
                <li>
                  <p>
                    המשתמש רשאי לקיים את חובת התשלום שלו רק באמצעות שימוש בשיטות
                    התשלום שהוזנו בחשבון, ובהתאם לתנאי התשלום שהוצעו למשתמש בקשר
                    עם ההזמנה בשירותי החברה. לידיעת המשתמש כי אפשרויות התשלום
                    הזמינות בקשר עם ההזמנה עלולות להשתנות בהתאם לשירותי החברה
                    הרלוונטיים, לסך ההזמנה ולמאפיינים נוספים אחרים.
                  </p>
                </li>

                {/* 7.3 */}
                <li>
                  <p>
                    התשלום עבור הזמנה יחויב באופן אוטומטי מאמצעי התשלום הרלוונטי
                    שהוזן עם אספקת ההזמנה. החברה רשאית לבקש אישור החזקה ממנפיק
                    אמצעי התשלום עם ביצוע ההזמנה באמצעות שירותי החברה.
                  </p>
                </li>

                {/* 7.4 */}
                <li>
                  <p>
                    בעבור מוצרים הנמכרים לפי משקל, כפי שניתן יהיה לראות בעת
                    ביצוע ההזמנה, המשקל למוצר המוצג באמצעות שירותי החברה (והמחיר
                    המבוסס על המשקל) הוא אומדן. במקרים כאלה, המחיר הסופי של
                    המוצר המבוסס על משקל בהזמנה שלך ייקבע ויחויב על סמך המשקל
                    בפועל של המוצר שתקבל כפי שיישקל על ידי בית העסק שיספק את
                    המוצרים במועד הכנת ההזמנה. כדי לכסות על התאמות מחיר אפשריות
                    שנגרמות עקב פער בין האומדן למשקל בפועל, החברה תחזיק באופן
                    זמני הרשאה לחיוב בחשבון, בשיעור של עד 10% מהמחיר המוצג
                    בשירותי החברה של מוצרים מבוססי משקל הכלולים בהזמנה. במקרה
                    שהמוצר שיסופק למשתמש בפועל שוקל יותר ממשקל המוצר המצוין
                    באישור ההזמנה, החברה תחייב אותך בסכום הפרש המחירים בין המוצר
                    שסופק בפועל לבין המוצר שהוצג בהזמנה באמצעות ההרשאה הזמנית
                    שנעשה עבור מוצרים מבוססי משקל ( להלן:
                    <strong>"התאמת המחיר"</strong>) . כל חלק מסכום ההרשאה הזמנית
                    או החיוב שאינו נדרש לטובת כיסוי התאמת המחיר יוחזר למסגרת
                    אמצעי התשלום שלך. במקרה שהמוצר שסופק לך שוקל פחות מהמצוין
                    באישור ההזמנה, יוחזר לך ההפרש במחיר.
                  </p>
                </li>

                {/* 7.5 */}
                <li>
                  <p>
                    אם יתברר שכרטיס האשראי או אמצעי התשלום בחשבון המשתמש אינו
                    תקף, או שחברת כרטיסי האשראי אינה מכבדת את העסקה, תודיע החברה
                    למשתמש בדבר סירוב חברת האשראי לעסקה כאמור. מובהר כי החברה
                    ו/או בית העסק לא יישאו באחריות כלשהי לעיכוב או אי-מסירה של
                    הזמנה במקרה כאמור.
                  </p>
                </li>
              </ol>
            </li>

            {/* 8 */}
            <li>
              <strong>
                <u>הטבות</u>
              </strong>

              <ol>
                <p>
                  החברה רשאית להציע ביישומון החברה מבצעים ו/או הנחות ו/או כל
                  הטבה אחרת בתנאים שנקבעו על ידה ולפי שיקול דעתה הבלעדי. המבצעים
                  הינם מוגבלים בזמן ו/או בכמות ובהתאם למלאי הקיים. החברה רשאית
                  להפסיקם, להחליפם או לשנותם בכל רגע נתון וללא צורך לתת כל הודעה
                  מוקדמת על כך. יישומון החברה רשאי להציע מבצעים מסוימים רק
                  ללקוחות שנרשמו למועדון הלקוחות שלה. ככלל לא יינתנו כפל
                  הנחות/הטבות על פריטים הנמכרים באתר (אלא אם יצוין אחרת). תנאי
                  המימוש של קופונים ו/או מבצעים מתחלפים כפי שיפורט עם פרסום
                  הקופון ו/או המבצע.
                </p>
              </ol>
            </li>

            {/* 9 */}
            <li>
              <strong>
                <u>אספקת והובלת מוצרים</u>
              </strong>

              <ol>
                {/* 9.1 */}
                <li>
                  <p>
                    המוצרים יסופקו לכתובת שאושרה על ידי המשתמש בהזמנה. המשתמש
                    מתחייב להיות זמין לקבלת שיחות במספר הטלפון שנמסר בשירותי
                    החברה, במועד וסמוך למועד קבלת המשלוח. אם לא ניתן ליצור קשר
                    עם מספר הטלפון שהמשתמש מסר, החברה רשאית לבטל את המשלוח
                    והמשתמש עלול להיות מחויב במלוא מחיר ההזמנה
                  </p>
                </li>

                {/* 9.2*/}
                <li>
                  <p>
                    אלא אם הוסכם אחרת בנוגע להזמנה ספציפית, החברה תספק את
                    המוצרים עד לפתח הכתובת שאושרה בהזמנה והאחריות על פריקת
                    המוצרים מאמצעי השילוח תהיה באחריותו המלאה של המשתמש. המשתמש
                    מתחייב לפרוק את המוצרים שהוזמנו בתוך עד לא יותר מ-15 דקות
                    מהגעת המשלוח של המוצרים שהוזמנו אם ההזמנה היא למוצרים שמשקלם
                    הכולל לא עולה על טון וחצי, ובתוך עד לא יותר מ-20 דקות מהגעת
                    המשלוח של המוצרים שהוזמנו אם ההזמנה היא למוצרים שמשקלם הכולל
                    עולה על טון וחצי. ככל שההזמנה לא נפרקה מאמצעי השילוח במסגרת
                    הזמן שלעיל, יחויב המשתמש בשקל נוסף עבור כל דקת איחור וכן
                    החברה רשאית לבטל את המשלוח והמשתמש עלול להיות מחויב במלוא
                    מחיר ההזמנה.
                  </p>
                </li>

                {/* 9.3 */}
                <li>
                  <p>
                    המשתמש רשאי לבצע הזמנה מיידית אשר תסופק בהקדם האפשרי (שיטת
                    משלוח רגילה) או על ידי הזמנה מראש לאספקה במועד מסוים, ככל
                    שאפשרויות אלו זמינות בשירותי החברה בעת ביצוע ההזמנה.
                  </p>
                </li>

                {/* 9.4 */}
                <li>
                  <p>
                    משלוח רגיל: המשתמש, או מי מטעמו, חייב להיות נוכח במיקום
                    המאושר לאספקה המפורט בהזמנה בטווחי זמן ההגעה המצוינים
                    באפליקציה ועד מועד קבלת המוצרים המפורטים בהזמנה בפועל. אם
                    המשתמש אינו זמין במיקום שאישר בתוך עשר דקות מהגעת המשלוח של
                    המוצרים שהוזמנו והמשתמש אינו מגיב לאחר שני ניסיונות ליצור
                    קשר של השליח שמבצע את המשלוח, החברה רשאית לבטל את המשלוח
                    והמשתמש עלול להיות מחויב במלוא מחיר ההזמנה.
                  </p>
                </li>

                {/* 9.5 */}
                <li>
                  <p>
                    משלוח ב"הזמנה מראש": המשתמש, או מי מטעמו, חייב להיות נוכח
                    במיקום המאושר שמסר כ- עשר דקות לפני זמן האספקה שנקבע
                    בהזמנה-מראש ועד מועד מסירת המשלוח על מנת לקבל את ההזמנה.
                  </p>
                </li>

                {/* 9.6 */}
                <li>
                  <p>
                    אספקת המוצרים באמצעות שירותי החברה מוענקת באזורי השירות
                    המוגדרים על ידי שירותי החברה בלבד וכפופה לנגישות סבירה
                    (תשתיות, הגבלות ביטחוניות וכיוצ"ב, "אזור השירות"). על מנת
                    לברר אם מען מסוים מצוי באזור השירות, עליך למלא את כתובת המען
                    המבוקש למשלוח, ושירותי החברה יאשרו באם המען המבוקש זמין
                    למשלוח או לא.
                  </p>
                </li>

                {/* 9.7 */}
                <li>
                  <p>
                    הזמנה אשר מאופן מהותה דורשת הובלה ומעורבות של מנוף באספקתה
                    (למשל, הזמנת מוצרים ממשקל מסוים, או אספקה למיקום מסוים),
                    כפופה לאישור החברה. במקרה של הזמנה עם מעורבות מנוף, המשתמש
                    עלול להיות מחויב במחיר גבוה יותר על אספקת המשלוח. החברה
                    פועלת מול ספקים של צד ג' לצורך תיאום השירות ולכן עלות השירות
                    והזמנים כפופים לאישור הספק הרלוונטי.
                  </p>
                </li>

                {/* 9.8 */}
                <li>
                  <p>
                    כל זמן אספקה או אומדן זמן אחר שהחברה מסרה למשתמש באמצעות
                    שירותי החברה הינם אומדני זמנים בלבד. אין התחייבות שההזמנה
                    תסופק, או תהיה מוכנה לאיסוף או לצריכה באומדן הזמן. זמני
                    האספקה של המוצרים עשויים להיות מושפעים מגורמים כמו פקקי
                    תנועה, שעות עומס ותנאי מזג אוויר. אי עמידת החברה במועד
                    האספקה המבוקש מכל סיבה שהיא, לא יפטור את המשתמש מחובת התשלום
                    בגין האספקה.
                  </p>
                </li>

                {/* 9.9 */}
                <li>
                  <p>
                    אספקת המוצרים באמצעות מנוף תהיה כפופה לתנאי השינוע במקום
                    המסירה. על המשתמש לוודא שלמנוף יש יכולת להיכנס לרחוב ללא
                    הפרעה. חברת דיבל מספקת בכל שינוע הנעשה ע״י מנוף ״פריקת
                    ריצפה״ בלב. במידה והלקוח יבקש פריקה בקומות עליו לשלם תוספת
                    בטווח של 300-500 שקלים.
                  </p>
                </li>
              </ol>
            </li>

            {/* 10 */}
            <li>
              <strong>
                <u>ביטול עסקה ומדיניות החזרת מוצרים </u>
              </strong>

              <ol>
                {/* 10.1 */}
                <li>
                  <p>
                    המשתמש אינו יכול לבטל או למשוך הזמנה של מוצרים או שירותים
                    לאחר ביצועה, אלא אם צוין אחרת כמפורט בתנאי השירות, או בהתאם
                    להוראות חוק הגנת הצרכן תשמ"א- 1981 (
                    <strong>"חוק הגנת הצרכן"</strong>
                    ). ככל שהמשתמש הינו צרכן כהגדרתו בחוק הגנת הצרכן, דע שאין
                    לשלול ממך את הזכויות המוענקות לך לפי חוק הגנת הצרכן.
                  </p>
                </li>

                {/* 10.2*/}
                <li>
                  <p>
                    הזכות לביטול ו/או שינוי עסקה עומדת לזכות המשתמש שביצע את
                    הרכישה בלבד (גם במידה שהמשתמש הזמין את המוצר עבור אדם אחר
                    ו/או אדם אחר הוא הנמען למשלוח).
                  </p>
                </li>

                {/* 10.3 */}
                <li>
                  <p>
                    לידיעתך, ייתכן שלבית העסק תנאי אחריות נפרדים החלים בנוסף
                    לתנאים אלה. במקרה כזה, בית העסק מספק מידע על תנאים כאלה
                    בנפרד למשתמש.
                  </p>
                </li>

                {/* 10.4 */}
                <li>
                  <p>
                    <u style={{marginTop: 0}}> אופן ביטול עסקה:</u> על מנת לממש
                    את זכות הביטול של המשתמש, על המשתמש למסור לחברה הודעה ברורה
                    בכתב על כך בתוך 14 יום מיום קבלת המוצרים (או בתקופת זמן
                    ארוכה יותר במידה והמשתמש הינו משתמש כמפורט בסעיף 9.6.2) בכל
                    אחת מהדרכים המפורטים להלן: (1) צ'אט שירות הלקוחות בשירותי
                    החברה (מומלץ) ; (2) בדוא"ל
                    <a href="mailto:office@dibble.co.il ">
                      office@dibble.co.il
                    </a>
                    ;(3) בדואר רשום לכתובת של החברה כמפורט לעיל. הודעת הביטול
                    תכלול באופן ברור שם מלא וכתובת הדוא"ל של המשתמש שביצע את
                    העסקה, פרטי העסקה לרבות מס' ההזמנה ומועדה, המוצר בהזמנה
                    שמעוניינים לבטל רכישתו או כל פרט נוסף שהחברה באופן סביר
                    מבקשת לצורך ביצוע הביטול. אם המשתמש אינו זמין במיקום שאישר
                    בתוך עשר דקות מהגעת המשלוח של המוצרים שהוזמנו והמשתמש אינו
                    מגיב לאחר שני ניסיונות ליצור קשר של השליח שמבצע את המשלוח,
                    החברה רשאית לבטל את המשלוח והמשתמש עלול להיות מחויב במלוא
                    מחיר ההזמנה.
                  </p>
                </li>

                {/* 10.5 */}
                <li>
                  <p>
                    <u style={{marginTop: 0}}>מוצר שחור:</u> חלק מהמוצרים אשר
                    ניתן לרכוש דרך שירותי החברה הינם מוצרים שעל פי מהותם וכן על
                    פי החוק לא ניתן להחזירם לאחר ההזמנה (מלט, חול וכו'). חלק
                    מהמוצרים אשר ניתן לרכוש דרך שירותי החברה הינם מוצרים שלפי
                    טבעם, וכן לפי חוק הגנת הצרכן, נחשבים ל"טובין פסידים" (כגון
                    מלט, חול וכו'). על פי החוק, הצרכן אינו רשאי לבטל עסקאות בקשר
                    למוצרים האלו ואינו רשאי להחזירם.
                  </p>
                </li>

                {/* 10.6 */}
                <li>
                  <p>
                    במקרה של ביטול ההזמנה, החזר תשלומי המשתמש עבור המוצר באמצעות
                    אותו אמצעי תשלום בו המשתמש ביצע את ההזמנה ולאותו חשבון בנק,
                    אלא אם הוסכם עם המשתמש במפורשות אחרת.
                  </p>
                </li>

                {/* 10.7 */}
                <li>
                  <p>
                    <strong>מועדים ותנאי ביטול עסקה-כללי </strong>
                    כל משתמש רשאי לבטל עסקה, במועדים ובתנאים הבאים:
                  </p>

                  <ol>
                    {/* 10.7.1 */}
                    <li>
                      <p>
                        ברכישת מוצר בתוך 14 (ארבעה עשר) יום ממועד קבלת המוצר או
                        קבלת מסמך הגילוי בהתאם להוראות סעיף 14 לחוק הגנת הצרכן,
                        המאוחר מבניהם,
                      </p>
                      <p>
                        וברכישת שירות - בתוך 14 (ארבעה עשר) ממועד ביצוע העסקה או
                        קבלת מסמך הגילוי בהתאם להוראות סעיף 14 לחוק הגנת הצרכן,
                        המאוחר מבניהם; והכל - על-ידי מסירת הודעה לחברה, ובמקרה
                        כזה תשיב החברה לידי המשתמש את התמורה ששילם בגין המוצר,
                        והחברה תהיה רשאית לנכות מסך זה דמי ביטול בסך של 5% ממחיר
                        המוצר נושא העסקה או 100 ש"ח, לפי הנמוך ביניהם.
                      </p>
                    </li>

                    {/* 10.7.2 */}
                    <li>
                      <p>
                        משתמש שהינו אדם עם מוגבלות (כהגדרתו בחוק שוויון זכויות
                        לאנשים עם מוגבלות, התשנ"ח – 1998 (להלן:
                        <strong>"אדם עם מוגבלות"</strong>) או מי שמלאו לו 65
                        שנים (להלן:
                        <strong>"אזרח ותיק"</strong>)
                      </p>
                      <p>
                        או מי שטרם חלפו חמש שנים מיום שניתנה לו תעודת עולה או
                        תעודת זכאות כעולה ממשרד העלייה והקליטה (להלן:
                        <strong>"עולה חדש"</strong>) יהיה רשאי לבטל עסקה לרכישת
                        מוצר בתוך ארבעה חודשים מיום עשיית העסקה, מיום קבלת המוצר
                        או מיום קבלת מסמך הגילוי בהתאם להוראות חוק הגנת הצרכן,
                        לפי המאוחר, ובלבד שההתקשרות בעסקה כללה שיחה בין החברה
                        ו/או בית העסק למשתמש, ובכלל זה שיחה באמצעות תקשורת
                        אלקטרונית. במקרה כאמור המשתמש יודיע על כך לחברה ויתואם
                        המועד לאיסוף המוצר, והכל בהתאם להוראות הדין.
                      </p>
                    </li>

                    {/* 10.7.3 */}
                    <li>
                      <p>
                        ביקש משתמש שהינו אדם עם מוגבלות או אזרח ותיק או עולה
                        חדש, לבטל עסקה כאמור לעיל, יהיו רשאים החברה ו/או בית
                        העסק לדרוש מאותו משתמש להציג תעודה
                      </p>
                      <p>
                        המוכיחה שהינו אדם עם מוגבלות או אזרח ותיק או עולה חדש.
                      </p>
                    </li>

                    {/* 10.7.4 */}
                    <li>
                      <p>
                        בוטלה עסקה על-ידי משתמש שהינו אדם עם מוגבלות או אזרח
                        ותיק או עולה חדש על-ידי מסירת הודעה לחברה בתוך ארבעה
                        חודשים מיום עשיית העסקה,
                      </p>
                      <p>
                        מיום קבלת המוצר או מיום קבלת מסמך הגילוי בהתאם להוראות
                        חוק הגנת הצרכן, לפי המאוחר, כאמור, תשיב החברה לידי אותו
                        משתמש את התמורה ששילם בגין המוצר, והחברה תהא רשאי לנכות
                        מסך זה דמי ביטול בסך של 5% ממחיר המוצר נושא העסקה או 100
                        ש"ח.
                      </p>
                    </li>

                    {/* 10.7.5 */}
                    <li>
                      <p>
                        על אף האמור, החברה לא תגבה דמי ביטול היה והביטול נובע
                        מפגם במוצר, אי התאמה בין המוצר לבין הפרטים שנמסרו לגביו
                        בהתאם להוראות הדין,
                      </p>
                      <p>
                        עקב אי אספקת המוצר במועד שנקבע לכך או עקב כל הפרה אחרת
                        של החברה כלפי המשתמש בקשר עם ההזמנה. במקרה כאמור המשתמש
                        יודיע על כך לחברה ויתואם המועד לאיסוף המוצר, והכל בהתאם
                        להוראות הדין וכאמור להלן בתנאים אלה.בכל מקרה של ביטול
                        והחזרה מכל סוג שהוא, המוצרים יוחזרו לבית העסק על ידי
                        המשתמש באריזתם המקורית וללא פגיעה ו/או פגם מכל מין וסוג
                        שהוא, במצב תקין ומבלי שנעשה בהם שימוש כלשהו. החברה היא
                        זאת שתכריע על פי שיקול דעתה אם המוצר שהוחזר עומד בתנאים
                        הנ"ל. לא ניתן להחזיר מוצר מבלי שהוחזרו כל חלקיו (אם
                        מדובר בסט / מארז).
                      </p>
                    </li>

                    {/* 10.7.6 */}
                    <li>
                      <p>
                        על אף האמור לעיל, הזכות לביטול כמפורט בסעיף זה לא תחול
                        במקרים המנויים בסעיף 14ג (ד) לחוק הגנת הצרכן, ובכלל זה,
                        ללא הגבלה, עסקאות לרכישת טובין פסידים,
                      </p>
                      <p>
                        ו/או טובין שבעקבותיהן הטובין יוצרו במיוחד בעבור המשתמש
                        ו/או טובין הניתנים להקלטה, לשעתוק או לשכפול, שהמשתמש פתח
                        את אריזתם המקורית.
                      </p>
                    </li>
                  </ol>
                </li>

                {/* 10.8 */}
                <li>
                  <p>
                    <strong>
                      <u style={{marginTop: 0}}>
                        ביטול עסקה עקב פגם או אי התאמה
                      </u>
                    </strong>
                  </p>

                  <ol>
                    {/* 10.8.1 */}
                    <li>
                      <p>
                        על המשתמש לבדוק את תכולת המשלוח עם קבלתו ולערוך השוואה
                        הן להזמנה והן לחשבונית שתימסר לו בעת האספקה.
                      </p>
                    </li>

                    {/* 10.8.2 */}
                    <li>
                      <p>
                        במקרה שתתגלה אי התאמה של המוצרים, או פגם במוצרים מצד
                        המשתמש לעניין המוצרים המסופקים, על המשתמש להודיע על כך,
                        למוקד שירות הלקוחות של החברה בהקדם,
                      </p>
                      <p>
                        ויהיה רשאי להחזיר את המוצרים בהתאם לאמור בסעיף 9 להלן.
                        מוקד שירות הלקוחות יתאם עם המשתמש את השלמת/ החלפת/ החזרת
                        המוצרים ו/או את החיוב ו/או את הזיכוי הכספי של המשתמש,
                        לפי העניין וככל שמתחייב על פי כל דין. אי מתן הודעה בפרק
                        הזמן כאמור מהווה ויתור של המשתמש בדבר כל טענה ו/או תביעה
                        בקשר לאי התאמה ו/או אי שביעות רצון מצדו.
                      </p>
                    </li>

                    {/* 10.8.3 */}
                    <li>
                      <p>
                        יש להודיע על הפגמים או ליקויים תוך זמן סביר מגילויים.
                        לידיעתך, במקרה של פגמים במוצרים פסידים, חשוב להודיע על
                        הפגם בהקדם האפשרי על מנת לאפשר בדיקה ואימות של הפגם.
                      </p>
                    </li>

                    {/* 10.8.4 */}
                    <li>
                      <p>
                        לידיעתך, החברה עשויה לבקש ממך לשלוח תמונה של המוצר
                        המדובר על מנת לתעד ולאמת את אי ההתאמה או הפגם.
                      </p>
                    </li>
                  </ol>
                </li>

                {/* 10.9 */}
                <li>
                  <p>
                    <strong>
                      <u style={{marginTop: 0}}>תוצאות נוספות של ביטול עסקה</u>
                    </strong>
                  </p>
                  <ol>
                    {/* 10.9.1  */}
                    <li>
                      <p>
                        במקרה של ביטול עסקה שבוצעה בהנחה/הטבה, הסכום שיוחזר
                        למשתמש יהיה הסכום ששילם בפועל לאחר הנחה/הטבה.
                      </p>
                    </li>

                    {/* 10.9.2 */}
                    <li>
                      <p>
                        יש להחזיר מוצרים ברי החזרה בהקדם האפשרי לאחר ביטול
                        ההזמנה, ולא יאוחר מ- 14 יום ממועד הודעת הביטול, בתיאום
                        עם החברה על ידי שליח שהחברה תשלח לאיסוף המוצר.
                      </p>
                      <p>
                        עלות המשלוח תהיה על המשתמש. אין לעשות שימוש במוצרים
                        המוחזרים ויש לשמור על המוצר בצורה נאותה ובאריזתו
                        המקורית. במקרה של ביטול עסקה עקב פגם או אי התאמה תבוצע
                        בדיקה מול המשתמש ובמידת הצורך, יתואם מועד לאיסוף המוצר.
                      </p>
                    </li>

                    {/* 10.9.3 */}
                    <li>
                      <p>
                        המשתמש אחראי לשלם את העלויות שנגרמו מהחזרת המוצרים. אין
                        להחזיר מוצר שנעשה בו שימוש והוא צריך להיות במצב שיאפשר
                        את מכירתו מחדש.
                      </p>
                      <p>
                        יש לשמור על המוצר בצורה נאותה ולארוז אותו באריזתו
                        המקורית או באופן דומה. אתה תשא באחריות בגין מוצרים
                        מוחזרים שנגרם להם נזק או שערכם פחת בזמן שהיו ברשותך,
                        לרבות במקרה של החזרת מוצר שנעשה בו שימוש, שאריזתו נפתחה
                        או נפגמה, שניזוק, שנפגם, שהתקלקל ו/או שספג פגיעה כלשהי.
                        אין בזכותו של צרכן לבטל עסקה כדי לגרוע מזכותם של בית
                        העסק ו/או החברה לתבוע את נזקיהם במקרה של החזרת מוצרים
                        שערכם פחת כאמור לעיל.
                      </p>
                    </li>
                    {/* 10.9.4 */}
                    <li>
                      <p>
                        החזרת מוצרים - המוצר יוחזר באריזותו המקורית בלבד, לאחר
                        שלא נעשה בו שימוש או פגמים.{' '}
                      </p>
                      <p>
                        על המשתמש ליצור קשר עם שירות הלקוחות בצ׳אט על מנת לתאם
                        החזרה. בפני המשתמש עומדות 2 אפשרויות- החזרה באופן עצמאי,
                        או החזרה ע״י שירות המשלוחים שאיתו עובדת החברה בעלות של
                        100 שח. במידה והמוצר הינו מוצר ששינועו נעשה ע״י מנוף-
                        המשתמש יחוייב בדמי משלוח של מנוף שהינם 350 שח ומעלה
                        (בהתחשבות במרחק, סוג ההנפה וסוג המנוף)
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            {/* 11 */}
            <li>
              <strong>
                <u>ביטול עסקה על ידי החברה</u>
              </strong>
              <br />
              <p>
                {' '}
                החברה שומרת לעצמה את הזכות להפסיק בכל עת, על פי שיקול דעתה
                הבלעדי, את שירותי החברה, לבטל עסקת רכישה שביצע משתמש ו/או לא
                לאשר הזמנה של משתמש, לרבות, אך לא רק, בכל אחד מהמקרים המפורטים
                להלן: (א) אם לא נקלטו במערכת פרטי כרטיס האשראי ו/או פרטיו המלאים
                של המשתמש; (ב) בכל מקרה שבו הופר או נעשתה פעולה בניגוד לתנאי
                השירות, ובכל מקרה בו המשתמש ביצע מעשה הנחזה כבלתי חוקי ו/או עבר
                על הוראות הדין; (ג) אם יתברר כי המשתמש מסר פרטים שגויים; (ד) אם
                קיים חשש שהתמורה בגין הרכישה לא תתקבל ו/או שכרטיס האשראי שברשות
                המשתמש נחסם ו/או הוגבל לשימוש ו/או אם העסקה לא אושרה על ידי חברת
                האשראי; (ה) אם ישנו למשתמש חוב כספי לחברה או לחברות הקשורות עמו
                והחוב לא נפרע למרות שחלף המועד לתשלומו; (ו) אם אזל המוצר מהמלאי
                לאחר או לפני שהתבצעה הרכישה. בוטלה ההזמנה כאמור, החברה לא תהא
                אחראית ולא תישא בכל נזק שנגרם למשתמש ו/או לצד שלישי, לרבות אך לא
                רק נזק בגין רכישת המוצר אצל צד שלישי במחיר גבוה יותר; (ז) בכל
                מקרה אשר מחמת כח עליון אין באפשרות החברה לבצע ניהול תקין של
                שירותי החברה ו/או לספק למשתמש את המוצרים שנרכשו על ידו ו/או
                לעמוד בהתחייבות אחרת. בוטלה העסקה כאמור, החברה לא תהא אחראית ולא
                תישא בכל נזק ישיר או עקיף שנגרם למשתמש ו/או לצד שלישי; (ח) במקרה
                בו נפלה טעות ו/או תקלה במהלך הצעת המוצר למכירה באתר, ובכלל זה,
                טעות במחיר, בתיאור ו/או בפרטי המשלוח; (ט) בשל מעשה או מחדל של
                המשתמש שיש או שעלול להיות בו פגיעה בחברה ו/או במי מטעמה ו/או
                בפעילותו התקינה של שירותי החברה ו/או בצד ג' אחר כלשהו; (י) בכל
                מקרה של תקלה בשרשרת האספקה המונעת ו/או מעכבת את הגעתו של המוצר
                בזמן או בכלל.
              </p>
            </li>

            {/* 12 */}
            <li>
              <strong>
                <u>קישורים בשירותי החברה לאתרים ברשת האינטרנט</u>
              </strong>

              <ol>
                {/* 12.1 */}
                <li>
                  <p>
                    בשירותי החברה עשויים להיכלל קישורים ו/או הפניות לעמודים,
                    לתכנים ולאתרי אינטרנט שונים ברשת האינטרנט אשר אינם מופעלים
                    על ידי החברה או מטעמה והחברה אינה שולטת ו/או מפקחת עליהם
                    ("לינקים"). החברה לא תישא באחריות ללינקים ואין לראות בקישור
                    לאתר כלשהו משום המלצה על אותו אתר או הסכמת החברה לתוכנו.
                  </p>
                </li>

                {/* 12.2*/}
                <li>
                  <p>
                    הלינקים נועדו אך ורק לנוחיותם ולידיעתם של המשתמשים ואין
                    החברה ערבה לאמינותם, לעדכניותם, לחוקיותם ו/או לנוהג בעלי
                    התכנים האמורים בתחום הפרטיות או בעל היבט אחר הקשור בתפעולם.
                    החברה אינה אחראית לשמירה על זכויות היוצרים או זכויות צדדים
                    שלישיים באתרים אליהם היא מאפשרת קישור מהאתר. כן, החברה אינה
                    אחראית לתכנים שאליהם מוליכים הלינקים, אינה אחראית לכל תוצאה
                    שתיגרם מהשימוש בתכנים כאמור ו/או מהסתמכות עליהם ולא תישא בכל
                    אחריות לכל נזק שייגרם למשתמש או לכל צד שלישי כתוצאה מהשימוש
                    בלינקים או תכנים כאמור ו/או מהסתמכות עליהם.
                  </p>
                </li>
              </ol>
            </li>

            {/* 13 */}
            <li>
              <strong>
                <u>זכויות יוצרים וקניין רוחני</u>
              </strong>

              <ol>
                {/* 13.1 */}
                <li>
                  <p>
                    כל זכויות הקניין הרוחני בקשר עם שירותי החברה, מכל מין וסוג
                    שהוא, לרבות סימני מסחר, פטנטים, מדגמים, סודות מסחריים, אופן
                    הצגת שירותי החברה ועיצובם, בסיסי הנתונים בהם, קוד המחשב של
                    שירותי החברה, ה-Domain, וכל פרט אחר הקשור בשירותי החברה
                    ובהפעלתם הם רכושה הבלעדי של החברה.
                  </p>
                </li>

                {/* 13.2*/}
                <li>
                  <p>
                    זכויות קניין רוחני בקשר למוצרים אשר פורסמו בשירותי מטעמם של
                    מפרסמים ו/או צדדים שלישיים אשר התירו לחברה לפרסם מידע ו/או
                    זכויות כאמור הם קניינם הבלעדי של המפרסמים ו/או של הצדדים
                    השלישיים ואין לעשות בהן כל שימוש בלא קבלת הסכמתה מראש ובכתב
                    של החברה.
                  </p>
                </li>

                {/* 13.3*/}
                <li>
                  <p>
                    אין להעתיק, לשכפל, לפרסם, להפיץ, לשדר, להציג, לבצע, להנפיק
                    רישיון, ליצור עבודות נגזרות, למכור, לשווק ולתרגם כל סוגי
                    הטקסטים ו/או קודים ו/או תמונות ו/או סימני מסחר ו/או כל מידע
                    ו/או תוכן אחר המופיע בשירותי החברה, אלא אם התקבל אישור לכך
                    מראש ובכתב מבעלי הזכויות כאמור.
                  </p>
                </li>

                {/* 13.4*/}
                <li>
                  <p>
                    אין לעשות כל שימוש מסחרי או אחר במידע ו/או בנתונים המתפרסמים
                    בשירותי החברה, בבסיס הנתונים בשירותי החברה, במוצרים, התמונות
                    וברשימות המוצרים המופיעים בהם או בפרטים אחרים בלא קבלת
                    הסכמתה של החברה מראש במפורש ובכתב, ואין לעשות כל פעולה
                    העלולה לפגוע בזכויות הקניין של החברה, מכל סוג שהוא.
                  </p>
                </li>

                {/* 13.5*/}
                <li>
                  <p>
                    אין להפעיל ו/או לאפשר הפעלת כל יישום מחשב או כל אמצעי אחר,
                    לרבות תוכנות מסוג Crawlers, Robots וכד', לשם חיפוש, סריקה,
                    העתקה או אחזור אוטומטי של תוכן שירותי החברה. אין להציג תכנים
                    מהאתר בכל דרך שהיא, באמצעות כל תוכנה ו/או מכשיר ו/או אביזר
                    ו/או אמצעי מדיה אחר, לרבות בתוך מסגרת (Frame) גלויה או
                    סמויה, המשנים ו/או הגורעים מעיצוב שירותי החברה ו/או מהמלל
                    הקיים הם ו/או מהתכנים המופיעים בהם (לרבות תכנים שיווקיים
                    ו/או מסחריים), דבר כלשהו, בלא קבלת הסכמתה המפורשת של החברה
                    ו/או מי מטעמה בכתב ומראש.
                  </p>
                </li>
              </ol>
            </li>

            {/* 14 */}
            <li>
              <strong>
                <u>אחריות החברה</u>
              </strong>

              <ol>
                {/* 14.1 */}
                <li>
                  <p>
                    החברה אינה אחראית לנזק ו/או הפסד כלשהו שייגרם למשתמש בשירותי
                    החברה ו/או לצד שלישי אחר כתוצאה משימוש ו/או רכישה המתבצעת
                    באמצעות שירותי החברה.
                  </p>
                </li>

                {/* 14.2*/}
                <li>
                  <p>
                    החברה אינה אחראית לנזק ו/או הפסד כלשהו שייגרם למשתמש בשירותי
                    החברה ו/או לצד שלישי אחר בגין טעות שביצע המשתמש בהקלדת
                    הנתונים, לרבות טעות בהקלדת כתובת ההזמנה ו/או טעות בהקלדת
                    פרטי כרטיס האשראי והמשתמש יישא באחריות מלאה לנזק שייגרם
                    לחברה בגין כך.
                  </p>
                </li>

                {/* 14.3*/}
                <li>
                  <p>
                    כל התכנים בשירותי החברה מסופקים כמות שהם ("AS IS") החברה לא
                    מביעה עמדה לגבי המידע המוצג בשירותי החברה ולא ,תישא באחריות,
                    ישירה ו/או עקיפה, לטעויות, לאי דיוקים, להשמטות, לפגמים
                    וכיוצ"ב בגין השימוש בשירותי החברה וכן לא תהיה אחראית לנזקי
                    תכנה, חומרה או כל נזק אחר, ישיר ו/או עקיף, כתוצאה מהשימוש
                    בשירותי החברה ו/או תוך כדי השימוש בשירותי החברה ו/או כתוצאה
                    מהמידע המוצג ו/או עקב מניעה כלשהי מהגישה או השימוש בשירותי
                    החברה, לרבות לנזקים העלולים להיגרם על ידי וירוסים.
                  </p>
                </li>

                {/* 14.4*/}
                <li>
                  <p>
                    השימוש בשירותי החברה ובתכנים הינו באחריותו הבלעדית והמלאה של
                    המשתמש. לא תישמע כל טענה מכל סוג שהוא כלפי החברה ו/או מי
                    מטעמה בגין אובדן, הפסד או נזק הנובעים מהסתמכות או שימוש
                    במידע הניתן בשירותי החברה.
                  </p>
                </li>

                {/* 14.5*/}
                <li>
                  <p>
                    החברה לא תישא בכל אחריות לנזק שייגרם למשתמש במישרין או
                    בעקיפין, וזאת עקב איחור במועד האספקה או מכך שסופקו מוצרים
                    פגומים או לא תקינים, למעט אם הפגם או הקלקול נבעו מרשלנות של
                    החברה. למען הסר ספק, מובהר כי האחריות תהא מוגבלת בכל מקרה עד
                    לגובה סכום המוצר בלבד.
                  </p>
                </li>

                {/* 14.6*/}
                <li>
                  <p>
                    החברה לא תישא בכל אחריות לנזק שייגרם למזמין, במישרין או
                    בעקיפין, עקב אי אספקת מוצר שאינו נמצא במלאי.
                  </p>
                </li>

                {/* 14.7*/}
                <li>
                  <p>
                    החברה לא תישא בכל אחריות לנזק שייגרם למשתמש, במישרין או
                    בעקיפין, עקב תאריך התפוגה המצוין על המוצר שסופק, ובלבד
                    שתאריך התפוגה כאמור הנו בפרק זמן סביר לאחר יום קבלת המוצר,
                    וזאת בהתאם לשיקול הדעת הבלעדי של החברה.
                  </p>
                </li>

                {/* 14.8*/}
                <li>
                  <p>
                    החברה לא תישא באחריות כלשהי להפרעות, תקלות, וירוסים או כל
                    גורם אחר אשר עלול להפריע או לפגום בשירותי החברה ו/או לגרום
                    לנזקים כלשהם. כמו כן, החברה לא תישא באחריות כלשהי לנזקים
                    שייגרמו למערכת המחשב ו/או לתשתיות של המשתמש ו/או לאובדן מידע
                    או כל נזק אחר הנובע משימוש בשירותי החברה, לרבות במקרה ויעלה
                    בידי צד שלישי לחדור למידע בשירותי החברה או במערכות החברה או
                    להשתמש בו שימוש בלתי מורשה.
                  </p>
                </li>

                {/* 14.9*/}
                <li>
                  <p>
                    התכנים המוצגים בשירותי החברה הינם כלליים ולא נועדו לתת יעוץ
                    מקצועי. אין בתכנים המוצגים כדי ליצור הצעה למכירה, ניסיון
                    לשכנוע לרכוש מוצר מסוים או קבוצת מוצרים על ידי החברה ו/או צד
                    שלישי כלשהו, לרבות בתי העסק. מוצע למשתמש לבצע בעצמו את כל
                    הערכותיו ובדיקותיו לגבי המוצר אותו הוא מעונין לרכוש, ובכל
                    מקרה המשתמש מוותר על כל טענה ו/או תביעה בעניין.
                  </p>
                </li>

                {/* 14.10*/}
                <li>
                  <p>
                    החברה מנהלת ומפעילה את שירותי החברה כמיטב יכולתה, באופן שוטף
                    וללא הפרעות ככל הניתן. יחד עם זאת, יתכנו הפרעות ו/או הפסקות
                    ו/או תקלות בפעילות שירותי החברה. המשתמש מודע כי ייתכנו
                    ליקויים, עיכובים והפרעות באיכות השירות ובזמינותו וכי החברה
                    לא תהיה אחראית לכל הפרעה ו/או תקלה ו/או לכל נזק שייגרם בשל
                    כך – ללא קשר לסיבת התקלה.
                  </p>
                </li>
              </ol>
            </li>

            {/* 15 */}
            <li>
              <strong>
                <u>אחריות בית העסק</u>
              </strong>
              <br />
              <p>
                {' '}
                בית העסק נושא באחריות הבלעדית לתכולה, לאיכות, לבטיחות ולאריזה של
                מוצריו הנמכרים באמצעות שירותי החברה ויש לו אחריות סטטוטורית לכל
                פגם במוצריו בהתאם לדין החל. המשתמש אחראי לבדוק את המוצרים בהזמנה
                ללא דיחוי לאחר קבלת ההזמנה. אם יש פגמים או ליקויים במוצרים
                שבהזמנה, על המשתמש לפנות לשירות הלקוחות של החברה, ולכלול תיאור
                ברור של הפגמים או הליקויים.
              </p>
            </li>

            {/* 16 */}
            <li>
              <strong>
                <u>שיפוי</u>
              </strong>
              <br />
              <p>
                {' '}
                המשתמש בשירותי החברה מתחייב לשפות ולפצות את החברה ו/או מי מטעמה
                בגין כל נזק, הפסד אבדן רווח, תשלום ו/או הוצאה שייגרמו להם, ובכלל
                זה שכ"ט עו"ד והוצאות משפט, עקב הפרת תנאי השירות. בנוסף, המשתמש
                ישפה את החברה בגין כל טענה ו/או תביעה ו/או דרישה שתועלה נגדה על
                ידי צד שלישי כלשהו כתוצאה משימוש של המשתמש בשירותי החברה.
              </p>
            </li>

            {/* 17 */}
            <li>
              <strong>
                <u> שונות</u>
              </strong>

              <ol>
                {/* 17.1 */}
                <li>
                  <p>
                    תנאי השירות מהווים את ההסכם המשפטי המלא בין המשתמש לבין
                    החברה ביחס לשימוש בשירותי החברה, והם מחליפים כל הסכם קודם
                    אחר בין המשתמש ובין החברה ביחס לשימוש בשירותי החברה. במקרה
                    של סתירה ו/או אי התאמה בין הוראות תנאי השירות לבין פרסומים
                    אחרים, תגברנה הוראות תנאי השירות. במקרה של סתירה ו/או אי
                    התאמה בין הוראות תקנון זה לבין הוראות מדיניות הפרטיות
                    בשירותי החברה, תגברנה הוראות מדיניות הפרטיות.
                  </p>
                </li>

                {/* 17.2*/}
                <li>
                  <p>
                    המשתמש לא (א) ישתמש או ינסה להשתמש בחשבון אישי של אדם אחר
                    אצל החברה ו/או לגשת לנתוני התשלום האישיים של אדם אחר באמצעות
                    שירותי החברה או להשתמש בכרטיסי התשלום האישיים של אדם אחר בעת
                    השימוש בשירותי החברה, ללא הסכמתו של האדם האחר; (ב( ישתמש
                    בבוטים או בשיטות אוטומטיות אחרות על מנת להשתמש בשירותי
                    החברה; (ג) ייצור חשבון בזהות מזויפת או זהות של אדם אחר; או
                    (ד) יכנס לשירותי החברה שלא באמצעות הממשק שסופק במפורש על ידי
                    החברה.
                  </p>
                </li>

                {/* 17.3*/}
                <li>
                  <p>
                    החברה שומרת לעצמה את הזכות הבלעדית לחסום חשבון ו/או להפסיק
                    באופן מיידי את שימוש המשתמש בשירותי החברה, בכל עת בו הפר
                    המשתמש את תנאי השירות ו/או כל דין ו/או הוראה אחרת ו/או באם
                    תחליט החברה כי ביצע המשתמש שימוש בלתי סביר או בלתי ראוי
                    בשירותי החברה. במקרים אלו לא תהיה החברה אחראית לכל הפסד ו/או
                    הוצאה ו/או נזק לרבות עוגמת נפש שייגרמו (ככל שיגרמו) למשתמש.
                    כן שומרת לעצמה הנהלת החברה את הזכות להסיר כל תוכן או לבטל כל
                    פעולה אשר בוצעה על ידי משתמש ואשר יש בה משום הפרה של תנאי
                    מתנאי השירות או בכלל.
                  </p>
                </li>

                {/* 17.4*/}
                <li>
                  <p>
                    ייתכן ויחולו על המשתמש תנאי שימוש נוספים בקשר לשימוש בשירותי
                    החברה, לרבות אך לא רק, תנאי שימוש של בית העסק ממנו המשתמש
                    יבצע הזמנה, ספקי שירותי תשלום בקשר לביצוע ההזמנה וצדדים
                    שלישיים נוספים. המשתמש מצהיר כי מודע שייתכן ויחולו עליו
                    תנאים הסכמיים עם צדדים שלישיים נוספים, וכי הוא מתחייב לציית
                    להם, ככל שיחולו עליו בקשר עם שימוש בשירותי החברה.
                  </p>
                </li>

                {/* 17.5*/}
                <li>
                  <p>
                    החברה זכאית להמחות את זכויותיה וחובותיה, כולן או מקצתן, לפי
                    הסכם זה באופן מלא או חלקי לצד קשור או לחליף ו/או קונה ו/או
                    רוכש של נכסיה העסקיים הנוגעים לשירותי החברה ללא צורך בהסכמה
                    מוקדמת של המשתמש.
                  </p>
                </li>

                {/* 17.6*/}
                <li>
                  <p>
                    המשתמש אינו זכאי להמחאות כל זכות מזכויותיו או כל התחייבות
                    מהתחייבויותיו לפי הסכם זה, באופן מלא או חלקי.
                  </p>
                </li>

                {/* 17.7*/}
                <li>
                  <p>
                    תקנון זה נכתב, מטעמי נוחות בלבד, בלשון זכר. האמור בו בלשון
                    זכר נכון גם בלשון נקבה. כותרות הסעיפים בתקנון זה נועדו
                    לנוחיות המשתמשים בלבד ולא ישמשו לפרשנות התקנון ו/או לכל מטרה
                    אחרת.
                  </p>
                </li>

                {/* 17.8*/}
                <li>
                  <p>
                    על תקנון זה יחולו דיני מדינת ישראל בלבד וסמכות השיפוט בכל
                    דבר ועניין הקשורים לתקנון זה ו/או הנובעים ממנו, מוקנית לבית
                    המשפט המוסמך בתל אביב באופן בלעדי, תוך שלילת סמכות השיפוט
                    מכל בית משפט אחר.
                  </p>
                </li>

                {/* 17.9*/}
                <li>
                  <p>
                    אם בית משפט מוסמך ייקבע כי תנאי מתנאי תקנון זה אינו תקף, או
                    שאינו ניתן לאכיפה מכוח הוראות החוקים הרלוונטיים, אזי יוסר
                    תנאי זה מבלי להשפיע על תוקפם של יתר התנאים בתקנון זה.
                  </p>
                </li>

                {/* 17.10*/}
                <li>
                  <p>
                    אין להעתיק, להציג בפומבי ולהשתמש או לאפשר לאחרים להשתמש,
                    בתכנים המתפרסמים באתר לכל מטרה, מסחרית או אחרת, שאיננה שימוש
                    אישי ופרטי. החברה שומרת על הזכות, לבטל או להפחית כמות של כל
                    מוצר שיסופק למשתמש, אשר לפי שיקול דעתה הבלעדי, עלול להביא
                    להפרה של תנאי השירות.
                  </p>
                </li>
              </ol>
            </li>
          </ol>

          <br />
        </div>
      </div>
    </React.Fragment>
  );
}
