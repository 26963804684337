import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {motion} from 'framer-motion';
import classnames from 'classnames';

import left_arrow_btn from '../assets/icons/left_arrow_btn.svg';
import right_arrow_btn from '../assets/icons/right_arrow_btn.svg';
import classNames from 'classnames';

const Gallery = ({
  images,
  rightBtnStyle = null,
  leftBtnStyle = null,
  left_line_seperaator = false,
  page,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const slideVariants = {
    hiddenRight: {
      x: '-100%',
      y: '0',
    },
    hiddenLeft: {
      x: '100%',
      y: '0',
    },
    visible: {
      x: '0',
      y: '0',
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const dotsVariants = {
    initial: {
      scale: 1,
    },
    animate: {
      scale: 1.3,
      transition: {type: 'spring', stiffness: 1000, damping: '10'},
    },
    exit: {
      scale: 1,
      transition: {duration: 0.2},
    },
  };

  const handleNext = () => {
    setDirection('right');
    setCurrentIndex(prevIndex =>
      prevIndex + 1 === images.length ? 0 : prevIndex + 1,
    );
  };

  const handlePrevious = () => {
    setDirection('left');
    setCurrentIndex(prevIndex =>
      prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1,
    );
  };

  const handleDotClick = index => {
    setDirection(index > currentIndex ? 'right' : 'left');
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext(); // This will make the sliding infinite
    }, 5000); // change image every 6 seconds

    // This will clear the interval when the component is unmounted
    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]);

  //////////////////////////////////////////////////////// for mobile
  const isMobile = useSelector(state => state.mobile.isMobile);

  let touchStartX = 0;

  const handleTouchStart = event => {
    touchStartX = event.touches[0].clientX;
  };

  const handleTouchMove = event => {
    if (isMobile) {
      const touchMoveX = event.touches[0].clientX;
      const deltaX = touchMoveX - touchStartX;

      if (deltaX > 0) {
        handleNext();
      } else {
        handlePrevious();
      }
    }
  };

  return (
    <React.Fragment>
      {left_line_seperaator && (
        <div className="gallery__content_left-separetor-for-product-page"></div>
      )}
      <div className="gallery">
        <div className="gallery__content">
          {images.length > 1 && !isMobile && (
            <div
              className="right"
              onClick={handlePrevious}
              style={rightBtnStyle ? rightBtnStyle : {}}>
              <img src={right_arrow_btn} alt="right" />
            </div>
          )}
          <div
            className="gallery__content__images"
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}>
            {images[currentIndex]?.image ? (
              <motion.img
                key={currentIndex}
                src={images[currentIndex]?.image}
                className={classNames(
                  page === 'product'
                    ? 'gallery__content__images--top-img--product'
                    : 'gallery__content__images--top-img',
                  images[currentIndex]?.onClick && 'pointer',
                )}
                initial={direction === 'right' ? 'hiddenRight' : 'hiddenLeft'}
                animate="visible"
                variants={slideVariants}
                onClick={
                  images[currentIndex]?.onClick
                    ? images[currentIndex].onClick
                    : () => {}
                }
              />
            ) : (
              <div className="gallery__content__images--top-img" />
            )}
          </div>
          {images.length > 1 && !isMobile && (
            <div
              className="left"
              onClick={handleNext}
              style={leftBtnStyle ? leftBtnStyle : {}}>
              <img src={left_arrow_btn} alt="left" />
            </div>
          )}
        </div>
        <div
          className={
            page === 'product'
              ? 'gallery__indicator_product'
              : 'gallery__indicator'
          }>
          {images.length > 1 &&
            images.map((_, index) => (
              <motion.div
                key={index}
                className={classnames(
                  'gallery__indicator__dot',
                  currentIndex === index ? 'gallery__indicator__active' : '',
                )}
                onClick={() => handleDotClick(index)}
                initial="initial"
                animate={currentIndex === index ? 'animate' : ''}
                variants={dotsVariants}
              />
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Gallery;
