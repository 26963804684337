import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import TextInput from '../components/TextInput.jsx';
import Button from '../components/Button.jsx';
import Title from '../components/Title';
import Dropdown from '../components/general/DropDown.jsx';
import {useNavigate} from 'react-router-dom';
import img from '../assets/images/shutterstock_567718675.png';
import img2 from '../assets/images/img_bg2.png';
import img3 from '../assets/images/img_bg3.png';
import img4 from '../assets/images/img_bg4.png';
import img5 from '../assets/images/img_bg5.png';
import img7 from '../assets/images/img_bg7.png';
import {fetchData} from '../utils/fetchData.js';

const JoinUsSuppliers = ({props}) => {
  const isMobile = useSelector(state => state.mobile.isMobile);
  const [categories, setCategories] = useState([
    'Appliances',
    'Flooring',
    'Hand Tools',
    'All',
    'Plumbing',
    'Paint',
    'Sanitary Devices',
    'Technical Supplies',
    'Containers',
    'Cranes',
    'Electricity',
    'Rentals',
    'Cement',
    'Plaster Boards',
    'Concrete',
    'Deck boards',
  ]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [companyName, setCompanyName] = useState('');
  const [contactName, setContactName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  const handleCompanyNameChange = e => setCompanyName(e.target.value);
  const handleContactNameChange = e => setContactName(e.target.value);
  const handleEmailChange = e => setEmail(e.target.value);
  const handlePhoneChange = e => setPhone(e.target.value);
  const handleAddressChange = e => setAddress(e.target.value);

  const isCompanyNameValid = () => companyName.trim() !== '';
  const isContactNameValid = () => contactName.trim() !== '';
  const isAddressValid = () => address.trim() !== '';
  const isEmailValid = () => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isPhoneValid = () => /^\+?\d{7,15}$/.test(phone);

  const resetInput = () => setCompanyName('');
  const resetInput2 = () => setContactName('');
  const resetInput3 = () => setEmail('');
  const resetInput4 = () => setPhone('');
  const resetInput5 = () => setAddress('');

  const navigate = useNavigate();
  const images = [img, img2, img3, img4, img5, img7];

  useEffect(() => {
    setSelectedImage(getRandomImage());
  }, []);
  useEffect(() => {
    if (
      selectedCategories.length === categories.length - 1 &&
      !selectedCategories.includes('All')
    ) {
      setSelectedCategories([...selectedCategories, 'All']);
    }
  }, [selectedCategories]);

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };

  const formValid = useMemo(() => {
    return (
      isCompanyNameValid() &&
      isContactNameValid() &&
      isEmailValid() &&
      isPhoneValid() &&
      selectedCategories.length
    );
  }, [companyName, contactName, email, phone, selectedCategories]);

  const sendEmail = async () => {
    const categoriesToSend = selectedCategories.includes('All')
      ? categories.filter(cat => cat !== 'All')
      : selectedCategories;
    let dataObj = {
      request: 'send_mail',
      to_email: 'meni@dibble.co.il',
      from_email: 'office@dibble.co.il',
      from_name: contactName,
      subject: `Dibble - A new supplier ״${companyName}״ was registered in the system`,
      message: `A new supplier named "${companyName}" was registered in the system.<br/>
      <br/>
      Address: ${address}<br/>
      Contact name: ${contactName}<br/>
      Email: ${email}<br/>
      Contact's mobile number: ${phone}<br/>
      Email of the contact: ${email}<br/>
      We deliver: ${categoriesToSend.join(', ')}<br/>
      `,
    };

    fetchData(dataObj)
      .then(response => {
        if (response?.message === 'success') {
          console.log(`send_mail API return success`);
          navigate(`/register-success`);
        }
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  };

  const handleCategoryChange = category => {
    if (category === 'All') {
      if (selectedCategories.includes('All')) {
        setSelectedCategories([]);
      } else {
        setSelectedCategories(categories.filter(cat => cat !== 'All'));
      }
    } else {
      setSelectedCategories(prevSelectedCategories => {
        const newSelectedCategories = prevSelectedCategories.includes(category)
          ? prevSelectedCategories.filter(c => c !== category)
          : [...prevSelectedCategories, category];

        if (newSelectedCategories.includes('All')) {
          return newSelectedCategories.filter(c => c !== 'All');
        }

        return newSelectedCategories;
      });
    }
  };

  return (
    <React.Fragment>
      <div className="join">
        <img
          className="login__img"
          src={selectedImage}
          alt="background-image"
        />
        <div className="login__pop-up">
          <div
            className="login__pop-up__text-wrapper"
            style={{fontWeight: 800}}>
            <Title
              title="Nice to meet you"
              style="login__container--title"
              center={true}
            />
            <div
              className="login__container--text--bold"
              style={{
                textAlign: 'center',
                color: !isMobile ? '#707070' : '',
              }}></div>
          </div>
          <div className="login--gap-24">
            <TextInput
              value={companyName}
              onChange={handleCompanyNameChange}
              onClear={resetInput}
              description="Please enter your company name"
              placeholderText="Company Name"
              errorMsg="Valid company name is required"
              IsError={!isCompanyNameValid()}
              directError={companyName}
              ltr
              isLeft
            />
            <TextInput
              value={address}
              onChange={handleAddressChange}
              onClear={resetInput5}
              description="Please enter your address"
              placeholderText="Address"
              errorMsg="Valid company name is required"
              IsError={!isAddressValid()}
              directError={companyName}
              ltr
              isLeft
            />
            <TextInput
              value={contactName}
              onChange={handleContactNameChange}
              onClear={resetInput2}
              description="Please enter a contact name"
              placeholderText="Contact Name"
              errorMsg="Valid contact name is required"
              IsError={!isContactNameValid()}
              directError={contactName}
              ltr
              isLeft
            />
            <TextInput
              value={email}
              onChange={handleEmailChange}
              onClear={resetInput3}
              description="Please enter an email address"
              placeholderText="Email address"
              errorMsg="Valid email address is required"
              IsError={!isEmailValid()}
              directError={contactName}
              ltr
              isLeft
            />
            <TextInput
              value={phone}
              onChange={handlePhoneChange}
              onClear={resetInput4}
              description="Please add a phone number"
              placeholderText="Phone number"
              errorMsg="Valid phone number is required"
              IsError={!isPhoneValid()}
              directError={contactName}
              ltr
              isLeft
            />
            <div className="w-50 text-center mb-2">
              <Title
                title=": We deliver"
                style="login__container--title"
                center={true}
              />

              <div className="categories-container">
                {categories.map((category, index) => (
                  <div key={category} className="category-item">
                    <label className="center">{category}</label>
                    <input
                      type="checkbox"
                      id={category}
                      checked={selectedCategories.includes(category)}
                      onChange={() => handleCategoryChange(category)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Button
            text={formValid ? 'Next' : 'Please fill all fields'}
            disable={!formValid}
            onClick={() => sendEmail()}
            textColor="white"
            backgroundColor={formValid ? '#FFCA1A' : '#BDBCBC'}
            className="login--margin-horizontal-16"
          />
        </div>
      </div>
      <style>
        {`
          .categories-container {
            display: flex;
            flex-wrap: wrap;
            margin-top: 15px;

          }
          .category-item {
            flex: 1 1 25%;
            margin-bottom: 20px;
            padding-bottom: 10px;
            font-size:15px;
          }
          .category-item label {
            margin-left: 5px;
          }
          @media (max-width: 600px) {
            .category-item label {
              color: white;
            }
          }
        `}
      </style>
    </React.Fragment>
  );
};

export default JoinUsSuppliers;
