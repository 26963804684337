import React from 'react';
import obj_heb from '../utils/Obj_heb.json';
import CreditCardFavorite from './CreditCardFavorite';
import CreditCardDelete from './CreditCardDelete';
import CreditCardEdition from './CreditCardEdition';

export default function CreditCardModalChildren({
  card,
  setCardOptionsIsOpen,
  setPopUpIsOpen,
  setPopUpContent,
  setEditCardIsOpen,
  setFinish,
  setFinishText,
  setIsModalOpen,
}) {
  return (
    <div className="CreditCardModal__mobileModal">
      <div className="CreditCardModal__mobileModal__title">
        {obj_heb.budget}
      </div>
      <div className="CreditCardModal__mobileModal__body">
        <CreditCardFavorite
          card={card}
          setCardOptionsIsOpen={setCardOptionsIsOpen}
          setPopUpIsOpen={setPopUpIsOpen}
          setPopUpContent={setPopUpContent}
        />
        <CreditCardEdition
          setCardOptionsIsOpen={setCardOptionsIsOpen}
          setPopUpIsOpen={setEditCardIsOpen}
          setFinish={setFinish}
          setFinishText={setFinishText}
        />
        <CreditCardDelete
          card={card}
          setCardOptionsIsOpen={setCardOptionsIsOpen}
          setPopUpIsOpen={setPopUpIsOpen}
          setPopUpContent={setPopUpContent}
          setFinish={setFinish}
          setFinishText={setFinishText}
        />
      </div>
    </div>
  );
}
