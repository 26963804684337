import React from 'react';
import Obj_heb from '../utils/Obj_heb.json';
import Title from './Title';
import {useSelector} from 'react-redux';
import {icons} from '../assets/constants';
import Button from './Button';

/**
 * RegisterUsage COMPONENT
 *
 * This component represents the user's chosen usage type during registration.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.selectedOption - Object representing the chosen option by the user.
 * @param {Function} props.selectOption - Callback function to choose an option.
 * @param {Function} props.nextStep - Callback function to proceed to the next step.
 */

function RegisterUsage(props) {
  const isMobile = useSelector(state => state.mobile.isMobile);
  return (
    <div className="login__pop-up">
      <div className="login__pop-up__text-wrapper">
        <Title
          title={Obj_heb.goal_of_usage}
          style="login__container--title"
          center={!isMobile}
        />
        <div className="login__container--text">
          {Obj_heb.goal_of_usage_text}
        </div>
      </div>

      <div className="login__choose_user_type">
        <div
          style={{
            border:
              props.selectedOption === 1
                ? '2px solid var(--dibble-yellow, #FFCA1A) '
                : '2px solid transparent',
          }}
          onClick={() => props.selectOption(1)}
          className="login__type_box">
          {props.selectedOption === 1 && isMobile && (
            <img
              className="login__type_box--v"
              src={icons.V}
              alt="v-sign"
              title="v-sign"
            />
          )}
          <div className="login__user-type-text-header">{Obj_heb.private}</div>
          <div
            style={{
              color: props.selectedOption === 1 ? 'black' : '',
            }}
            className="login__user-type-text">
            {Obj_heb.private_deets}
          </div>
        </div>
        <div
          style={{
            border:
              props.selectedOption === 2
                ? '2px solid var(--dibble-yellow, #FFCA1A)'
                : '2px solid transparent',
          }}
          onClick={() => props.selectOption(2)}
          className="login__type_box">
          {props.selectedOption === 2 && isMobile && (
            <img className="login__type_box--v" src={icons.V} />
          )}
          <div className="login__user-type-text-header">
            {' '}
            {Obj_heb.business}
          </div>
          <div
            style={{
              color: props.selectedOption === 2 ? 'black' : '',
            }}
            className="login__user-type-text">
            {Obj_heb.business_deets}
          </div>
        </div>
      </div>
      <div className="login--gap-32">
        <Button
          text={
            !props.selectedOption
              ? Obj_heb.choose_from_options_btn
              : Obj_heb.next
          }
          disable={!props.selectedOption}
          onClick={() => props.nextStep(props.selectedOption === 1 ? 2 : 1)}
          textColor="white"
          backgroundColor={props.selectedOption ? '#FFCA1A' : '#BDBCBC'}
        />
      </div>
    </div>
  );
}
export default RegisterUsage;
