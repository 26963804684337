import React from 'react';
import CategoryProductsList from '../components/CategoryProductsList';

export default function Category() {
  return (
    <div>
      <CategoryProductsList />
    </div>
  );
}
