import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import cross from '../../assets/icons/cross.svg';

import {useDispatch} from 'react-redux';
import {
  ShoppingCart_changeQuantity,
  ShoppingCart_removeProduct,
} from '../../redux/slices/ShoppingCartSlice';

import Obj_heb from '../../utils/Obj_heb.json';

import ShoppingCartItemAmount from './ShoppingCartItemAmount';
import ShoppingCartPrice from './ShoppingCartPrice';
import {fetchData} from '../../utils/fetchData';

/**
 * ShoppingCartItem Component.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.item - Object with all product parameters described below.
 * @param {boolean} props.item.black_Product - Indicates if the product is black. 1 for true, 0 for false.
 * @param {string} props.item.catalog_number - The catalog number of the product.
 * @param {string} props.item.image - URL of the product image.
 * @param {string} props.item.key - Unique key for the product.
 * @param {string} props.item.name - Name of the product.
 * @param {boolean} props.item.need_crane - Indicates if a crane is needed. 0 for false, 1 for true.
 * @param {boolean} props.item.sale - Indicates if the product is on sale.
 * @param {string} props.item.options1_name - Name of the first option for the product.
 * @param {string} props.item.options2_name - Name of the second option for the product.
 * @param {number} props.item.price - Price of the product.
 * @param {number} props.item.product_id - ID of the product.
 * @param {number} props.item.quantity - Quantity of the product.
 * @param {number} props.item.sale_price - Sale price of the product.
 * @param {number} props.item.weight - Weight of the product in grams.
 * @param {number} props.widget - if widget is true the css will be according home page.
 * @param {number} props.setPopUpIsOpen - sets show state on deletion modal.
 *
 *@param {boolean} props.insertNumberFunctionBoolean if true can insert number text to the amount component
 */

export default function ShoppingCartItem({
  item,
  widget,
  orderHistory,
  mobileCart,
  className,
  backgroundColor,
  setPopUpIsOpen,
  setProduct,
  insertNumberFunctionBoolean = false,
}) {
  const [isShown, setIsShown] = useState(false);
  const [vat, setVat] = useState(0);
  const convertStringToNumber = input => {
    const number = parseFloat(input);
    const convertedNumber = number / 100 + 1;
    return convertedNumber;
  };
  const Dispatch = useDispatch();
  const navigate = useNavigate();

  const removeProduct = () => {
    setProduct(item.key);
    setPopUpIsOpen(true);
  };

  const incrementFunction = () => {
    Dispatch(
      ShoppingCart_changeQuantity({
        key: item.key,
        oparation: 'increase',
      }),
    );
  };

  const decrementFunction = amount => {
    if (amount >= 1) {
      Dispatch(
        ShoppingCart_changeQuantity({
          key: item.key,
          oparation: 'decrease',
        }),
      );
    } else if (amount == 0) {
      setProduct(item.key);
      setPopUpIsOpen(true);
    }
  };

  const insertNumberFunction = amount => {
    ShoppingCart_changeQuantity({
      key: item.key,
      oparation: 'insert',
      amount: amount,
    });
  };
  const navigateToProduct = () => {
    navigate(`/product/${item.product_id}`);
  };
  useEffect(() => {
    let dataObj = {
      request: 'get_vat_rate',
    };
    fetchData(dataObj).then(response => {
      if (response?.message === 'success')
        setVat(convertStringToNumber(response.vat_rate.value));
    });
  }, []);

  return (
    <div
      className={classNames(
        'my-shopping-cart',
        isShown && !orderHistory && !mobileCart && 'my-shopping-cart--hover',
        (widget || orderHistory) && 'my-shopping-cart__widget',
        className,
      )}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}>
      <div className="my-shopping-cart__item">
        <div className="my-shopping-cart__item__img-box">
          <img
            onClick={() => navigateToProduct()}
            className={classNames(
              'my-shopping-cart__item__img-box__img',
              isShown &&
                !orderHistory &&
                'my-shopping-cart__item__img-box--shown',
            )}
            src={item.image}
            alt={item.name}
          />
          {mobileCart && (
            <ShoppingCartItemAmount
              Amount={item.quantity}
              isActive={true}
              product_id={item.product_id}
              incrementFunction={incrementFunction}
              decrementFunction={decrementFunction}
              setWidth={10.2}
              setHight={3.2}
              btnSize={2.286}
              fontSize={2.2}
              className="my-shopping-cart__item__amount--counter"
              minusIconTurnGray={false}
            />
          )}
        </div>
        <div
          className={classNames(
            'my-shopping-cart__item_vr',
            widget || (orderHistory && 'my-shopping-cart__item_vr__widget'),
          )}></div>
        <div className="my-shopping-cart__item__text-wrapper">
          <div
            className={classNames(
              'my-shopping-cart__item__text-wrapper__product-name',
              isShown &&
                !orderHistory &&
                'my-shopping-cart__item__text-wrapper__product-name--shown',
              widget &&
                'my-shopping-cart__item__text-wrapper__product-name--margin',
            )}
            onClick={() => navigateToProduct()}>
            {/* {item?.name?.length > 32 && widget
              ? item.name.slice(0, 32) + '...'
              : item.name} */}
            {item.name.length > 29 ? item.name.slice(0, 26) + '...' : item.name}
          </div>
          <div className="my-shopping-cart__item__text-wrapper">
            {item.options1_name ? (
              <div className="my-shopping-cart__item__text-wrapper__multi">
                {item.options1_name}: {item.option1_value}
              </div>
            ) : null}
            {item.options2_name ? (
              <div className="my-shopping-cart__item__text-wrapper__multi">
                {item.options2_name}: {item.option2_value}
              </div>
            ) : null}
            {mobileCart && (
              <div className="my-shopping-cart__item__text-wrapper__price-mobile">
                <span className="my-shopping-cart__item__text-wrapper__multi">
                  מחיר:
                </span>
                <ShoppingCartPrice
                  price={item.price * vat * item.quantity}
                  sale={item.sale_price !== 0 && item?.sale_price < item?.price}
                  salePrice={item.sale_price * vat * item.quantity}
                  widget={widget}
                  mobileCart={mobileCart}
                />
              </div>
            )}
          </div>

          {widget || orderHistory ? (
            <div className="my-shopping-cart__item__amount">
              <span>{Obj_heb.amount}</span>
              <ShoppingCartItemAmount
                Amount={item.quantity}
                isActive={orderHistory ? !orderHistory : isShown}
                product_id={item.product_id}
                incrementFunction={incrementFunction}
                decrementFunction={decrementFunction}
                setWidth={8.3}
                setHight={2.6}
                btnSize={1.8}
                fontSize={1.2}
                className="my-shopping-cart__item__amount--counter"
                minusIconTurnGray={false}
              />
            </div>
          ) : null}
        </div>
      </div>
      {!mobileCart && (
        <div
          className={classNames(
            'my-shopping-cart__price-wrapper',
            widget && 'my-shopping-cart__price-wrapper__price_widget',
          )}>
          <ShoppingCartPrice
            price={item.price * vat * item.quantity}
            sale={item.sale_price !== 0 && item?.sale_price < item?.price}
            salePrice={item.sale_price * vat * item.quantity}
            widget={widget}
          />

          {!widget && !orderHistory && (
            <ShoppingCartItemAmount
              Amount={item.quantity}
              isActive={isShown && !orderHistory}
              product_id={item.product_id}
              incrementFunction={incrementFunction}
              decrementFunction={decrementFunction}
              insertNumberFunction={
                insertNumberFunctionBoolean && insertNumberFunction
              }
              setWidth={8.8}
              setHight={2.6}
              btnSize={1.8}
              fontSize={1.2}
              className="Shopping-Cart-Item_part-1_text-Box_amount--counter"
              minusIconTurnGray={false}
            />
          )}
        </div>
      )}

      {item.black_Product ? (
        <div className="non-refundable-label">
          <div className="non-refundable-label__text">
            {Obj_heb.black_product}
          </div>
        </div>
      ) : null}

      {!mobileCart && isShown && !orderHistory ? (
        <div
          className="my-shopping-cart__closing-section"
          onClick={() => {
            removeProduct();
          }}>
          <img
            className="my-shopping-cart_section_img"
            src={cross}
            alt="close button"
          />
        </div>
      ) : null}

      {mobileCart && (
        <img
          className="my-shopping-cart__mobile-delete"
          src={cross}
          alt="close button"
          onClick={() => {
            removeProduct();
          }}
        />
      )}
    </div>
  );
}
