import React, {useEffect, useState} from 'react';
import {numberWithCommas} from '../utils/supportFunctions';
import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';

/**
 *
 * @param {number} price the original price
 * @param {boolean} sale - true if the product on sale
 * @param {number} newPrice - the new price- will show only of the product on sale
 * @param {number} font_size_big, - insert the values of the text in rem unit
 * @param {number}   font_size_small, - insert the values of the text in rem unit
 * @returns
 */
export default function Price({
  txtBeforeThePrice,
  price,
  sale = false,
  newPrice,
  font_size_big,
  font_size_small,
}) {
  const [vat, setVat] = useState(0);

  const convertStringToNumber = input => {
    const number = parseFloat(input);
    const convertedNumber = number / 100 + 1;
    return convertedNumber;
  };
  useEffect(() => {
    let dataObj = {
      request: 'get_vat_rate',
    };
    fetchData(dataObj).then(response => {
      if (response?.message === 'success')
        setVat(convertStringToNumber(response.vat_rate.value));
    });
  }, []);
  return (
    <div className="PriceForCardItem">
      <div className="foundProduct__price_text">
        {!newPrice ? <div className="foundProduct__text_inner"></div> : ''}
        <span className="categoryProduct__price_number">
          {newPrice > 0 || newPrice > 0 ? (
            <div className="price_onSalenewPrice">
              <span className="price_onSalenewPrice_currency">₪</span>
              {numberWithCommas((newPrice * vat).toFixed(2))}
            </div>
          ) : null}
          {!newPrice > 0 && !newPrice > 0 ? (
            <span className="price_regular-number">
              <span className="price_regular-number_currency">₪</span>
              {numberWithCommas((price * vat).toFixed(2))}
            </span>
          ) : (
            <span className="price_onSale">
              <span className="price_onSale_oldPrice">
                <span className="price_onSale_oldPrice_currency">₪</span>
                {numberWithCommas((price * vat).toFixed(2))}
                <div className="price_onSale_oldPricered-line"></div>
              </span>
            </span>
          )}
        </span>
      </div>
    </div>
  );
}
