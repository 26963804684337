import React, {useState, useEffect, useRef} from 'react';
import clock from '../assets/icons/clock.svg';
import arrow_head_down from '../assets/icons/arrow_head_down.svg';
import HourSelector from './HourSelector';
import classNames from 'classnames';

function HourInput({hour, setHour, hours, popUpIsOpen}) {
  const [isOpen, setIsOpen] = useState(false);

  const containerRef = useRef();

  // decide which value to show in the main address value
  const handleSelect = item => {
    setIsOpen(false);
    setHour(item.time_frame);
  };

  useEffect(() => {
    if (!popUpIsOpen) setIsOpen(false);
  }, [popUpIsOpen]);

  useEffect(() => {
    // Function to handle click outside the div
    const handleClickOutside = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        // The click is outside the div, do something here
        setIsOpen(prev => false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="day-input" ref={containerRef}>
      <div className="day-input__item" onClick={() => setIsOpen(prev => !prev)}>
        <div
          className={classNames(
            'day-input__item--title',
            hour && 'day-input__item--selected',
          )}>
          <img src={clock} alt="" className="day-input__item--icon" />
          <span>{!hour ? 'בחירת שעה' : hour}</span>
        </div>
        <img
          className={classNames('day-input__item--arrow', isOpen && 'flip')}
          src={arrow_head_down}
          alt="location"
        />
      </div>
      {isOpen && <HourSelector hours={hours} handleSelect={handleSelect} />}
    </div>
  );
}

export default HourInput;
