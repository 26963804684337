import {Fragment, useState} from 'react';
import classnames from 'classnames';

import left_arrow_btn from '../assets/icons/left_arrow_btn.svg';
import right_arrow_btn from '../assets/icons/right_arrow_btn.svg';

/**
 *
 * @param  {number} YPositionNavButtons - the offset of the y exe of the nuv buttons of the Carousel
 */

function Carousel({
  items,
  visibleImageCount = 2,
  className = '',
  YPositionNavButtons = null,
}) {
  const [startIndex, setStartIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(visibleImageCount);
  if (!items || items.length === 0) return <div></div>;

  const handleNext = () => {
    if (currentIndex + 1 <= items.length) {
      setCurrentIndex(prevIndex => prevIndex + 1);
      setStartIndex(prevI => prevI + 1);
    }
  };

  const handlePrevious = () => {
    if (startIndex - 1 >= 0) {
      setCurrentIndex(prevIndex => prevIndex - 1);
      setStartIndex(prevI => prevI - 1);
    }
  };

  return (
    <div className={classnames('carousel', className)}>
      <div className="carousel__content">
        <div
          className="right"
          onClick={handlePrevious}
          style={
            YPositionNavButtons
              ? {transform: `translate(-50%, ${YPositionNavButtons}%)`}
              : {}
          }>
          {startIndex !== 0 && <img src={right_arrow_btn} alt="right" />}
        </div>
        <div className="carousel__content--items">
          {items.slice(startIndex, currentIndex).map((item, index) => (
            <Fragment key={item + index}>{item}</Fragment>
          ))}
        </div>
        <div
          className="left"
          onClick={handleNext}
          style={
            YPositionNavButtons
              ? {transform: `translate(-50%, ${YPositionNavButtons}%)`}
              : {}
          }>
          {currentIndex < items.length && (
            <img src={left_arrow_btn} alt="left" />
          )}
        </div>
      </div>
    </div>
  );
}

export default Carousel;
