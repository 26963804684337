import React, {useEffect, useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';
import {useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import SEO from '../components/SEO';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import GetDibbleCoinModal from '../components/GetDibbleCoinsModal';
import Toast from '../components/Toast';
import 'firebase/firestore';
import {initializeApp} from 'firebase/app';
import {fireBaseConfig, INTERCOM_APP_ID} from '../utils/baseValues';
import {getDatabase} from 'firebase/database';
import {clearActiveOrdersCount} from '../redux/slices/activeOrdersSlice';
import {icons} from '../assets/constants';
import {clearAddressData, clearCreditCardsData} from '../redux/store';
import Loader from '../components/Loader';
import ProjectOrdersTab from './ProjectOrdersTab';
import ProjectInvoicesTab from './ProjectInvoicesTab';
import ProjectTransactionsTab from './ProjectTransactionsTab';
import LobbyPagination from './LobbyPagination';

export default function LobbyTabsContainer() {
  let app = initializeApp(fireBaseConfig);
  const db = getDatabase(app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();

  // State management for project orders
  const [projectOrders, setProjectOrders] = useState(null);
  const [projectTransactions, setProjectTransactions] = useState(null);
  const [projectInvoices, setProjectInvoices] = useState(null);

  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [TabOptions, setTabOptions] = useState([
    {
      name: obj_heb.proj_orders,
      key: 0,
      tab_name: 'proj_orders',
    },
    {
      name: obj_heb.proj_invoices,
      key: 1,
      tab_name: 'proj_invoices',
    },
    {name: obj_heb.proj_transactions, key: 2, tab_name: 'proj_transactions'},
  ]);

  const [openTabIndex, setOpenTabIndex] = useState(0);
  const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const isMobile = useSelector(state => state.mobile.isMobile);
  let userData = JSON.parse(localStorage.getItem('userData'));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabName = queryParams.get('tab');

  useEffect(() => {
    if (tabName === 'proj_invoices') {
      setOpenTabIndex(1);
    } else if (tabName === 'proj_transactions') {
      setOpenTabIndex(2);
    } else setOpenTabIndex(0);
  }, [tabName]);

  useEffect(() => {
    setIsLoading(true);
    let dataObj2 = {
      request: 'get_my_project_orders',
      token: userData?.token || '',
      project_id: Number(params.project_id),
      page: currentPaginationPage - 1,
      page_size: 10,
    };
    fetchData(dataObj2)
      .then(response => {
        let data = response;
        setProjectOrders(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error:', error?.message);
        setIsLoading(false);
      });
    let dataObj6 = {
      request: 'dibblecoin_get_project_transactions',
      token: userData?.token || '',
      project_id: Number(params.project_id),
      page_size: 10,
      page: currentPaginationPage - 1,
    };
    fetchData(dataObj6)
      .then(response => {
        let data = response;
        setProjectTransactions(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error:', error?.message);
        setIsLoading(false);
      });
    setIsLoading(true);
    let dataObj5 = {
      request: 'get_my_project_invoices',
      token: userData?.token || '',
      project_id: Number(params.project_id),
      page_size: 10,
      page: currentPaginationPage - 1,
    };
    fetchData(dataObj5)
      .then(response => {
        let data = response;
        setProjectInvoices(data);
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  }, [currentPaginationPage]);

  const pickTabHandler = item => {
    setOpenTabIndex(item.key);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tab', item.tab_name);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const logout = () => {
    if (!userData?.token) return;
    let dataObj = {
      request: 'logout',
      token: userData.token,
    };
    fetchData(dataObj).then(response => {
      localStorage.setItem('userData', null);
      dispatch(clearAddressData());
      dispatch(clearCreditCardsData());
      dispatch(clearActiveOrdersCount());
      window.Intercom('shutdown');
      window.Intercom('reset');
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
      });
      window.Intercom('update', {name: '', email: '', phone: '', userId: ''});
      window.location.reload();
      navigate('/');
    });
  };

  const SEOLoader = () => {
    const LobbyLink = `/lobby`;
    return (
      <SEO
        pageName={obj_heb.personal_area}
        content={obj_heb.personal_area}
        link={LobbyLink}
      />
    );
  };

  const handlePaginationPageChange = page => {
    setCurrentPaginationPage(page);
  };

  const getPageCount = () => {
    if (projectOrders && openTabIndex === 0) {
      return projectOrders?.num_of_orders_pages || 1;
    } else if (projectInvoices && openTabIndex === 1) {
      return projectInvoices?.num_of_invoices_pages || 1;
    } else if (projectTransactions) {
      return projectTransactions?.num_of_pages || 1;
    }
    return 1;
  };

  return (
    <>
      <SEOLoader />
      <div className="PersonalArea">
        <div className="PersonalArea_content">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="PersonalArea_content_section1">
              <div className="PersonalArea_content_section1_tab-headers">
                {TabOptions.map(item => (
                  <div
                    key={item.key}
                    className={classNames(
                      'PersonalArea_content_section1_tab-headers_tab_head',
                      openTabIndex === item.key &&
                        'PersonalArea_content_section1_tab-headers_tab_head--selected',
                    )}
                    onClick={() => {
                      pickTabHandler(item);
                    }}>
                    <div
                      className="PersonalArea_content_section1_tab-headers_tab_head_title"
                      style={
                        openTabIndex === item.key ? {fontWeight: '700'} : {}
                      }>
                      {item.name}
                    </div>
                  </div>
                ))}
              </div>
              <div className="PersonalArea_content_section1_tab-body">
                {openTabIndex === 0 ? (
                  <div className="PersonalArea_content_section1_tab_margin">
                    <ProjectOrdersTab projectOrders={projectOrders} />
                  </div>
                ) : openTabIndex === 1 ? (
                  <ProjectInvoicesTab projectInvoices={projectInvoices} />
                ) : (
                  <ProjectTransactionsTab
                    projectTransactions={projectTransactions}
                  />
                )}
              </div>
            </div>
          )}

          {openTabIndex === 1 && isMobile && (
            <div
              onClick={() => {
                logout();
              }}
              className="PersonalSettingsTab__logOut">
              <div className="PersonalSettingsTab__logOut__titleBox">
                <span className="PersonalSettingsTab__logOut__titleBox__textRed">
                  {obj_heb.exit}
                </span>
                <span>{obj_heb.exitText}</span>
              </div>
              <img
                className="PersonalSettingsTab_item--title_arrowhead_img"
                src={icons.red_left_arrow_head}
                alt="leftArrowHead"
              />
            </div>
          )}
          <div className="pagination-bar-projects">
            <LobbyPagination
              currentPage={currentPaginationPage}
              totalPageCount={getPageCount()}
              siblingCount={1}
              pageSize={pageSize}
              onPageChange={page => handlePaginationPageChange(page)}
            />
          </div>
        </div>

        <GetDibbleCoinModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          closeCallback={() => setIsOpen(false)}
          setIsFinish={setIsFinish}
        />
        {isFinish && <Toast text={obj_heb.creditLineSuccessMessage} />}
      </div>
    </>
  );
}
