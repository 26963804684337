import React from 'react';
import MyFilterItem from './MyFilterItem';

const FilterBySelector = ({
  options,
  handleSelect,
  setIsOpen,
  isOpen,
  selected,
}) => {
  return (
    <div className="days-selector">
      {options.length !== 0 && (
        <>
          <div
            className="days-selector__list"
            style={{maxHeight: 'max-content'}}>
            {options.map((item, index) => {
              return (
                <div
                  className="days-selector__list--item"
                  key={JSON.stringify(item.key)}>
                  <MyFilterItem
                    item={item}
                    onClick={handleSelect}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    isSelected={selected === item.key}
                  />
                  {index !== options.length - 1 && (
                    <span className="days-selector__list--separator" />
                  )}
                </div>
              );
            })}
          </div>
          {options.length > 8 && <div className="screening-gray"></div>}
        </>
      )}
    </div>
  );
};

export default FilterBySelector;
