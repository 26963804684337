import React, {useEffect, useState, useRef} from 'react';
import classNames from 'classnames';

import CreditCardIMG from '../assets/icons/CreditCards/creditCard.svg';
import dibbleCoinsimg from '../assets/icons/dibbleCoins.svg';

import CreditCardSelector from './CreditCardSelector';
import {useSelector} from 'react-redux';

import {icons} from '../assets/constants';
import PopUpConfirm from './PopUpConfirm';
import PopUp from './general/PopUp';
import Toast from './Toast';

import obj_heb from '../utils/Obj_heb.json';
import {numberWithCommas} from '../utils/supportFunctions';
import {fetchData} from '../utils/fetchData';

function CreditCardInput({
  dibbleCoins,
  canPayWithDibbleCoins,
  creditCard,
  setCreditCard,
  setSecondCreditCard,
  shoppingCartPrice,
  deliveryPrice,
  second,
  requestedDeliveryTime,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmSecondCardPopUpIsOpen, setConfirmSecondCardPopUpIsOpen] =
    useState(false);
  const [vat, setVat] = useState(0);
  const [finish, setFinish] = useState(false);
  const [finishText, setFinishText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [cardAddedId, setCardAddedId] = useState(null);
  const creditsRef = useRef(null);
  const creditCards = useSelector(state => state.creditCards.creditCardsList);
  const isMobile = useSelector(state => state.mobile.isMobile);
  const selectedAddress = useSelector(
    state => state.addressSlice.selectedAddress,
  );

  // return if the selected address is project address
  const isProject = selectedAddress?.address_type === 'project';

  const cardImage = creditCard?.brand
    ? require(`../assets/icons/CreditCards/${creditCard?.brand}.svg`)
    : CreditCardIMG;

  const convertStringToNumber = input => {
    const number = parseFloat(input);
    const convertedNumber = number / 100 + 1;
    return convertedNumber;
  };

  // decide which value to show in the main address value
  const handleSelect = item => {
    if (item?.card_num) {
      if (second) {
        setSecondCreditCard(item);
        setConfirmSecondCardPopUpIsOpen(true);
      } else {
        setCreditCard(item);
        setSecondCreditCard(null);
      }
    } else {
      setCreditCard(item);
      setSecondCreditCard(null);
    }

    setIsOpen(false);
  };

  // make favorite card as selected card
  useEffect(() => {
    if (cardAddedId) {
      const cardDetails = creditCards.find(
        card => card.creditcard_id === cardAddedId,
      );
      if (cardDetails) {
        setCreditCard(cardDetails);
        setCardAddedId(null);
      }
    } else if (!dibbleCoins) {
      creditCards.map(card => {
        if (card.is_default) {
          setCreditCard(card);
        }
      });
    }
  }, [cardAddedId, creditCards]);
  useEffect(() => {
    if (creditCards) {
    }
    if (dibbleCoins) {
      handleSelect(dibbleCoins);
    }
  }, [creditCards, dibbleCoins]);
  useEffect(() => {
    let dataObj = {
      request: 'get_vat_rate',
      valid_from: requestedDeliveryTime,
    };
    fetchData(dataObj).then(response => {
      if (response?.message === 'success') setVat(response.vat_rate.value);
    });
  }, []);
  useEffect(() => {
    // Function to handle click outside the div
    const handleClickOutside = event => {
      if (
        creditsRef.current &&
        !creditsRef.current.contains(event.target) &&
        event.target.id !== 'credit-card-add__btn'
      ) {
        // The click is outside the div, do something here
        setIsOpen(prev => false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="credit-card-input" ref={creditsRef}>
        <div
          className="credit-card-input__card"
          onClick={() => setIsOpen(prev => !prev)}>
          <div className="credit-card-input__card--title">
            <img
              src={
                dibbleCoins &&
                dibbleCoins !== 0 &&
                !creditCard?.card_num &&
                (second === undefined || second === false)
                  ? dibbleCoinsimg
                  : cardImage
              }
              alt=""
            />
            <span className={'credit-card-input__card--title--name'}>
              {!second && !creditCard?.card_num ? (
                <>
                  <div className="credit-card-input__card--title--name--bold">
                    dibble coins
                  </div>

                  <span className="credit-card-input__card--title--name--sub-title">
                    {!selectedAddress?.is_on_budget && (
                      <img
                        src={icons.dibble_coins_x_icon}
                        alt=""
                        className="credit-card-input__card--title--name--sub-title--x"
                      />
                    )}
                    {!selectedAddress?.is_on_budget
                      ? numberWithCommas(dibbleCoins.toFixed(2))
                      : 'תקציב פרוייקט - ' + selectedAddress?.title}
                  </span>
                </>
              ) : !creditCard && !second ? (
                <div className="credit-card-input__card--title--name credit-card-input__card--title--name--gray">
                  יש לבחור אמצעי תשלום
                </div>
              ) : creditCard?.card_num ? (
                <>
                  <span className="credit-card-input__card--title--name--bold">
                    {creditCard?.name || obj_heb.creditCard}
                  </span>
                  <span>
                    {obj_heb.endsWithDigits} ****{creditCard?.card_num}
                  </span>
                </>
              ) : !second ? (
                <>
                  <div className="credit-card-input__card--title--name--bold">
                    dibble coins
                  </div>
                  <span className="credit-card-input__card--title--name--sub-title">
                    <img src={icons.dibble_coins_x_icon} alt="" />
                    {dibbleCoins}
                  </span>
                </>
              ) : (
                <div className="credit-card-input__card--title--name credit-card-input__card--title--name--gray">
                  יש לבחור אמצעי תשלום נוסף
                </div>
              )}
            </span>
          </div>
          {!isProject ? (
            <img
              className={classNames(
                'credit-card-input__card--arrow',
                isOpen && 'flip',
              )}
              src={isMobile ? icons.arrow_head_left : icons.arrow_head_down}
              alt="location"
            />
          ) : (
            <span />
          )}
        </div>
        {!isProject && isOpen && (
          <CreditCardSelector
            creditCards={creditCards}
            handleSelect={handleSelect}
            dibbleCoins={dibbleCoins}
            creditCard={creditCard}
            setInstallments={props.setInstallments}
            second={second}
            isProject={isProject}
            setFinish={setFinish}
            setFinishText={setFinishText}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setCardAddedId={setCardAddedId}
          />
        )}
      </div>
      {!second &&
      // dibbleCoins &&
      dibbleCoins !== 0 &&
      !creditCard?.card_num &&
      selectedAddress &&
      selectedAddress?.is_on_budget ? (
        <div className="credit-card-input__card--error-msg">
          {selectedAddress &&
            selectedAddress.is_on_budget &&
            obj_heb.project_budjet_islimited}
        </div>
      ) : (
        ''
      )}

      {/* pop up confirm the second credit card details */}
      <PopUp
        isOpen={confirmSecondCardPopUpIsOpen}
        setIsOpen={setConfirmSecondCardPopUpIsOpen}>
        <PopUpConfirm
          title={
            obj_heb.this_will_pay_with_card_num +
            '  ' +
            creditCard?.card_num +
            '****'
          }
          subTitle={
            <div className="credit-card-input__confirm-second">
              <div className="credit-card-input__confirm-second--item">
                <span className="credit-card-input__confirm-second--item--text">
                  {obj_heb.dibble_coin_payment_annount}
                </span>
                <span>
                  {numberWithCommas(dibbleCoins)} {obj_heb.coins}
                </span>
              </div>
              <div className="credit-card-input__confirm-second--item">
                <span className="credit-card-input__confirm-second--item--text">
                  {obj_heb.credit_card_end_with_payment +
                    ' ' +
                    creditCard?.card_num +
                    '****'}
                  :
                </span>
                <span>
                  <span className="shekel-sign">₪</span>
                  {numberWithCommas(
                    (
                      (shoppingCartPrice + deliveryPrice) *
                        convertStringToNumber(vat) -
                      dibbleCoins
                    ).toFixed(2),
                  )}
                </span>
              </div>
            </div>
          }
          onConfirm={() => {
            setConfirmSecondCardPopUpIsOpen(false);
          }}
          onCancel={() => {
            setConfirmSecondCardPopUpIsOpen(false);
            setSecondCreditCard(null);
          }}
        />
      </PopUp>

      {finish && <Toast text={finishText} />}
    </div>
  );
}

export default CreditCardInput;
