import React from 'react';

function SearchProjectUserItem({
  item,
  onItemClick, // Receive the click handler as a prop
}) {
  const fullName = item.first_name + ' ' + item.last_name;

  const handleClick = () => {
    onItemClick(item); // Call the parent’s function when clicked
  };

  return (
    <div className="searched-item" onClick={handleClick}>
      <span className="is-hover" />
      <div className="searched-item__content">
        <div>{fullName}</div>
      </div>
    </div>
  );
}

export default SearchProjectUserItem;
