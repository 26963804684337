import React, {useEffect} from 'react';

import {cancel_scrolling} from '../../utils/supportFunctions';
import icons from '../../assets/constants/icons';

export default function Modal({
  isOpen = false,
  setIsOpen,
  children,
  closeFunction = () => {},
}) {
  useEffect(() => {
    cancel_scrolling(isOpen);
    return () => {
      cancel_scrolling(false);
    };
  }, [isOpen]);
  return isOpen ? (
    <div className="modal">
      <div className="modal__content">
        <img
          src={icons.close}
          alt="x"
          className={'modal__content--close'}
          onClick={() => {
            setIsOpen(false);
            closeFunction();
          }}
        />
        {children}
      </div>
    </div>
  ) : (
    ''
  );
}
