import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import airPlane from '../assets/images/airplane.svg';
import img from '../assets/images/shutterstock_567718675.png';
import img2 from '../assets/images/img_bg2.png';
import img3 from '../assets/images/img_bg3.png';
import img4 from '../assets/images/img_bg4.png';
import img5 from '../assets/images/img_bg5.png';
import img7 from '../assets/images/img_bg7.png';

import Title from '../components/Title';
import Button from '../components/Button';

export default function Login() {
  const navigate = useNavigate();
  const images = [img, img2, img3, img4, img5, img7];

  const isMobile = useSelector(state => state.mobile.isMobile);

  const [selectedImage, setSelectedImage] = useState('');

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };
  useEffect(() => {
    setSelectedImage(getRandomImage());
  }, []);

  return (
    <>
      {isMobile ? (
        <div className="success">
          <div className="success__content">
            <img className="success__img" src={selectedImage} />
            <div className="success__img--dark"></div>
            <img className="success__content--img" src={airPlane} />
            <Title title={'הבקשה נשלחה!'} center color="var(--white)" />

            <div className="success__content--text">
              ממש עכשיו הצוות שלנו עובר על ההזמנה ודואג שתקבלו הצעת מחיר סופר
              משתלמת בקרוב. מעדכן אתכם ברגע שיהיה חדש, אתם יכולים לבנות עלינו!
            </div>
          </div>

          <Button
            text="חזרה לעמוד הבית"
            backgroundColor="var(--dibble-yellow)"
            textColor="var(--white)"
            onClick={() => navigate('/')}
          />
        </div>
      ) : (
        <div className="success">
          <img className="success__img" src={selectedImage} />
          <div className="success__img--dark"></div>
          <div className="success__content">
            <Title title={'ההזמנה נשלחה!'} center />

            <div className="success__content--text">
              ממש עכשיו הצוות שלנו עובר על ההזמנה ודואג שתקבלו הצעת מחיר סופר
              משתלמת בקרוב. מעדכן אתכם ברגע שיהיה חדש, אתם יכולים לבנות עלינו!
            </div>

            <img className="success__content--img" src={airPlane} />

            <Button
              text="חזרה לעמוד הבית"
              backgroundColor="var(--dibble-yellow)"
              textColor="var(--white)"
              onClick={() => navigate('/')}
              className="margin-16"
            />
          </div>
        </div>
      )}
    </>
  );
}
