import React, {useState, useEffect, useRef} from 'react';
import locationIcon from '../assets/icons/location.svg';
import icons from '../assets/constants/icons';
import MyAddresses from './MyAddresses';
import AddAddress from './AddAddress';
import Toast from './Toast';

import {useSelector, useDispatch} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import {setSelectedAddress} from '../redux/store';

import obj_heb from '../utils/Obj_heb.json';
import classNames from 'classnames';

function Address({setBgDiv}) {
  const [isAddressesOpen, setIsAddressesOpen] = useState(false);
  const [isAddAddressOpen, setIsAddAddressOpen] = useState(false);
  const [finish, setFinish] = useState(false);

  const addressesRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let userData = JSON.parse(localStorage.getItem('userData'));
  const queryParams = new URLSearchParams(location.search);
  const bidId = queryParams.get('bid_id');

  // get the selected address and the addresses list
  const addressesList = useSelector(state => state.addressSlice.addressesList);
  const selectedAddress = useSelector(
    state => state.addressSlice.selectedAddress,
  );

  const isMobile = useSelector(state => state.mobile.isMobile);

  // open the correct modal
  const handleClick = () => {
    if (!userData?.token) {
      navigate('/login');
      return;
    }
    if (addressesList.length > 0 || selectedAddress) {
      setIsAddressesOpen(prev => !prev);
      // setBgDiv(<div className="bg-blur" />);
    } else {
      setIsAddAddressOpen(prev => !prev);
    }
  };

  // decide which value to show in the main address value
  const handleValue = () => {
    if (selectedAddress) {
      return selectedAddress.address;
    }
    if (addressesList.length == 1) {
      dispatch(setSelectedAddress(addressesList[0]));
      const selectedAddressText = addressesList[0].address;
      return selectedAddressText;
    } else if (userData?.token) {
      return obj_heb.whereToSendThisTime;
    } else {
      return obj_heb.whereToSendThisTime;
    }
  };

  // close my addresses popup
  const handleClickOutside = event => {
    if (addressesRef.current && !addressesRef.current.contains(event.target)) {
      setIsAddressesOpen(prev => false);
      // setBgDiv(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsAddressesOpen(false);
    setIsAddAddressOpen(false);
  }, [location.pathname]);

  return (
    <div className="address" ref={addressesRef}>
      <div className="address__top" onClick={() => handleClick()}>
        {!isMobile && (
          <img
            className="address__top--icon"
            src={locationIcon}
            alt="location"
          />
        )}
        <span
          className={
            bidId ? 'address__top--text--unclick' : 'address__top--text'
          }>
          {handleValue()}
        </span>

        {isMobile && !bidId && (
          <img
            style={{cursor: bidId ? 'default' : 'pointer'}}
            className={classNames(
              'address__top--arrow',
              isAddressesOpen && 'flip',
            )}
            src={icons.arrow_head_down}
            alt="location"
          />
        )}
      </div>
      {isAddressesOpen &&
        (bidId ? (
          <div></div>
        ) : (
          <MyAddresses
            isOpen={isAddressesOpen}
            setIsOpen={setIsAddressesOpen}
            addAddressIsOpen={isAddAddressOpen}
            setAddAddressIsOpen={setIsAddAddressOpen}
            setBgDiv={setBgDiv}
          />
        ))}
      {isAddAddressOpen && (
        <AddAddress
          isOpen={isAddAddressOpen}
          setIsOpen={setIsAddAddressOpen}
          setFinish={setFinish}
        />
      )}
      {finish && <Toast text={obj_heb.addressSave} />}
    </div>
  );
}

export default Address;
