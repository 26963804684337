import img from '../../assets/images/shutterstock_567718675.png';
import img2 from '../../assets/images/img_bg2.png';
import img3 from '../../assets/images/img_bg3.png';
import img4 from '../../assets/images/img_bg4.png';
import img5 from '../../assets/images/img_bg5.png';
import img7 from '../../assets/images/img_bg7.png';
import airPlane from '../../assets/images/airplane.svg';
import dibble_coins_bg from '../../assets/images/dibble_coin_bg.svg';

export default {
  airPlane,
  bg_imgs: [img, img2, img3, img4, img5, img7],
  dibble_coins_bg,
};
