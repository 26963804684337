import React from 'react';

function MyHrItem({item, onClick}) {
  return (
    <div className="days-item" onClick={() => onClick(item)}>
      <span className="is-hover" />
      <div className="days-item__content">{item.time_frame}</div>
    </div>
  );
}

export default MyHrItem;
