import React, {useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import classNames from 'classnames';
import EditAddress from './EditAddress';

export default function AddressEdit({
  address,
  setAddressEditIsOpen,
  setFinish,
  setFinishText,
}) {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const handlePress = () => {
    setPopUpOpen(prev => !prev);
  };
  const callback = () => {
    setAddressEditIsOpen(false);
  };

  return (
    <>
      <div
        onClick={() => handlePress()}
        className={classNames('Address-Item__edit--field')}>
        <div className={classNames('address-Item__edit--field--text')}>
          {obj_heb.edit_address}
          <span className="is-hover" />
        </div>
        <hr className="hr" />
      </div>
      {popUpOpen && (
        <div>
          <EditAddress
            callback={callback}
            isOpen={true}
            setIsOpen={setPopUpOpen}
            setFinish={setFinish}
            setFinishText={setFinishText}
            apiName={'update_address'}
            fullAddressProp={address}
            defultState={{
              address: address?.address || '',
              lat: address?.lat || '',
              lon: address?.lon || '',
              title: address?.title || '',
              floor: address?.floor || '',
              appartment: address?.appartment || '',
              type: address?.type || 1,
              notes: address?.notes || '',
              color: address?.color || '',
            }}
          />
        </div>
      )}
    </>
  );
}
