import React from 'react';
import obj_heb from '../utils/Obj_heb.json';
import classNames from 'classnames';

import {icons} from '../assets/constants';

function DibbleCoin({
  dibbleCoins,
  list,
  listAction,
  cardSelected,
  setInstallments,
  isProject,
  className,
}) {
  const handleSelect = dibbleCoins => {
    setInstallments(Number(1));
    listAction(dibbleCoins);
  };

  return (
    <div className="credit-card">
      <div
        className={classNames(
          'credit-card__card',
          dibbleCoins && !cardSelected && 'credit-card__card--selected',
          className && className,
        )}
        onClick={() => handleSelect(dibbleCoins)}>
        <div className="credit-card__card__details">
          <img src={icons.dibble_coins_icon} alt="" />
          <div
            className={classNames(
              'credit-card__card__details--info',
              dibbleCoins && !cardSelected && 'credit-card__card--selected',
            )}>
            <span className="credit-card__card__details--info--type">
              {obj_heb.dibbleCoins}
            </span>
            {!isProject && (
              <span className="credit-card__card__details--sub-details">
                <img
                  src={
                    dibbleCoins && !cardSelected
                      ? icons.dibble_coins_x_icon
                      : icons.dibble_coins_x_icon_gray
                  }
                  className="credit-card__card__details--sub-details--x"
                  alt="dibble coins"
                />
                <span> {dibbleCoins}</span>
              </span>
            )}
          </div>
          {list === 'summary' && <span className="is-hover" />}
        </div>
      </div>
    </div>
  );
}

export default DibbleCoin;
