import React from 'react';
import classnames from 'classnames';
import left_arrow_btn from '../assets/icons/left_arrow_btn.svg';
import right_arrow_btn from '../assets/icons/right_arrow_btn.svg';
import {DOTS, useLobbyPagination} from '../hooks/useLobbyPagination';

const LobbyPagination = props => {
  const {
    onPageChange,
    totalPageCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = useLobbyPagination({
    currentPage,
    totalPageCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames('pagination-container', {[className]: className})}>
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}>
        <img
          style={{marginRight: '7rem'}}
          className="arrow icons"
          src={left_arrow_btn}
          alt=""
        />
      </li>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
            key={pageNumber}
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}>
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}>
        <img className="arrow icons" src={right_arrow_btn} alt="" />
      </li>
    </ul>
  );
};

export default LobbyPagination;
