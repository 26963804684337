import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {fetchData} from '../utils/fetchData';
import obj_heb from '../utils/Obj_heb.json';

import Title from './Title';
import Carousel from './Carousel';
import ScrollView from './ScrollView';
import CategoryBox from './CategoryBox';

function DepartmentsLists() {
  const [departments, setDepartments] = useState([]);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData')),
  );
  const navigate = useNavigate();

  const isMobile = useSelector(state => state.mobile.isMobile);

  useEffect(() => {
    let dataObj = {
      request: 'client_get_cat_screen_items',
      token: userData?.token || '',
    };

    fetchData(dataObj)
      .then(response => {
        const data = response.categories;

        setDepartments(data);
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  }, []);

  const goToCategory = (id, name) => {
    navigate(
      `/category?id=${encodeURIComponent(id)}&name=${encodeURIComponent(name)}`,
    );
  };

  const goToCategorys = (id, name) => {
    navigate(
      `/categories?id=${encodeURIComponent(id)}&name=${encodeURIComponent(
        name,
      )}`,
    );
  };

  return (
    <div className="department-list__container">
      {departments.map(category => {
        return (
          <div key={JSON.stringify(category)} className="department-list">
            <Title key={JSON.stringify(category)} title={category.name} />
            <div
              onClick={() => goToCategorys(category.category_id, category.name)}
              className="department-list__all-products">
              {obj_heb.allTheProducts}
            </div>
            {isMobile ? (
              <ScrollView
                items={category.sub_categories.map((category, index) => {
                  return (
                    <CategoryBox
                      key={JSON.stringify(category)}
                      category={category}
                      goToCategory={goToCategory}
                      className="department-list__all-products--item"
                    />
                  );
                })}
                className="department-list__carousel"
              />
            ) : (
              <Carousel
                visibleImageCount={5}
                items={category.sub_categories.map((category, index) => {
                  return (
                    <CategoryBox
                      key={JSON.stringify(category)}
                      category={category}
                      goToCategory={goToCategory}
                    />
                  );
                })}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default DepartmentsLists;
