import React, {useState} from 'react';

//UTILITIES
import {numberWithCommas} from '../utils/supportFunctions';
import obj_heb from '../utils/Obj_heb.json';

//COMPONENTS
import {icons} from '../assets/constants/index';
import BarChart from '../components/BarChart';
import Button from './Button';
import {useSelector} from 'react-redux';

export default function LobbyCreditsTab({
  projectSummary,
  chartData,
  projects,
  totalDibbleCoin,
  availableDibbleCoin,
  myActiveDiscounts,
  setCreditReqModalIsOpen,
}) {
  const [myDiscountSectionOpen, setMyDiscountSectionOpen] = useState(false);

  const isMobile = useSelector(state => state.mobile.isMobile);

  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  return (
    <>
      <div className="creditsTab_part1">
        <div className="creditsTab_part1_item-box">
          <div className="creditsTab_part1_item-box_text-box">
            <div className="creditsTab_part1_item-box_text-box_title">
              {obj_heb.num_of_projects}
            </div>
            <div className="creditsTab_part1_item-box_text-box_sub-title">
              {obj_heb.num_of_projects_exp}
            </div>
          </div>
          <div className="creditsTab_part1_item-box_icon-box">
            <div className="creditsTab_part1_item-box_icon-box_txt">
              {projectSummary.num_of_projects}
            </div>
          </div>
        </div>
        <div className="creditsTab_part1_hr">_horizintalLine</div>

        <div className="creditsTab_part1_item-box">
          <div className="creditsTab_part1_item-box_text-box">
            <div className="creditsTab_part1_item-box_text-box_title">
              {obj_heb.count_orders}
            </div>
            <div className="creditsTab_part1_item-box_text-box_sub-title">
              {obj_heb.count_orders_exp}
            </div>
          </div>
          <div className="creditsTab_part1_item-box_icon-box">
            <div className="creditsTab_part1_item-box_icon-box_txt">
              {projectSummary.num_of_order}
            </div>
          </div>
        </div>
        <div className="creditsTab_part1_hr">_horizintalLine</div>

        <div className="creditsTab_part1_item-box">
          <div className="creditsTab_part1_item-box_text-box">
            <div className="creditsTab_part1_item-box_text-box_title">
              {obj_heb.dibble_coins}
            </div>
            <div className="creditsTab_part1_item-box_text-box_sub-title">
              {obj_heb.dibbleCoinsAmount2}
            </div>
          </div>
          <div className="creditsTab_part1_item-box_icon-box">
            <div className="creditsTab_part1_item-box_icon-box_txt">
              {numberWithCommas(totalDibbleCoin)}
            </div>
            <img
              src={icons.x_icon}
              className="creditsTab_part1_item-box_icon-boxx-icone"
              alt="x_icon"
            />
            <img
              src={icons.dibbleCoinIcon}
              className="creditsTab_part1_item-box_icon-box_dibbl-icon"
              alt="dibbleCoinIcon"
            />
          </div>
        </div>
        <div className="creditsTab_part1_hr">_horizintalLine</div>
        <div className="creditsTab_part1_item-box">
          <div className="creditsTab_part1_item-box_text-box">
            <div className="creditsTab_part1_item-box_text-box_title">
              {obj_heb.used_dibble_coins}
            </div>
            <div className="creditsTab_part1_item-box_text-box_sub-title">
              {obj_heb.dibbleCoinsAmountUsed}
            </div>
          </div>
          <div className="creditsTab_part1_item-box_icon-box">
            <div className="creditsTab_part1_item-box_icon-box_txt">
              {numberWithCommas(totalDibbleCoin - availableDibbleCoin)}
            </div>
            <img
              src={icons.x_icon}
              className="creditsTab_part1_item-box_icon-boxx-icone"
              alt="x_icon"
            />
            <img
              src={icons.dibbleCoinIcon}
              className="creditsTab_part1_item-box_icon-box_dibbl-icon"
              alt="dibbleCoinIcon"
            />
          </div>
        </div>
        <div className="creditsTab_part1_hr">_horizintalLine</div>

        <div className="projects-display">
          {projects.length > 0 &&
            projects.map((project, index) =>
              project.dcoin_budget && project.dcoin_budget != 0 ? (
                <div>
                  <span style={{fontSize: '14px', marginBottom: '20px'}}>
                    {obj_heb.on_budget_projects}
                  </span>

                  <div
                    key={index}
                    className="project-item"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}>
                    <div
                      className="color-square"
                      style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: getRandomColor(),
                        marginLeft: '10px',
                      }}
                    />
                    <span className="project-name" style={{flexGrow: 1}}>
                      {project.project_name}
                    </span>
                    <div className="creditsTab_part1_item-box_icon-box">
                      <div className="creditsTab_part1_item-box_icon-box_txt_small">
                        {numberWithCommas(project.dcoin_budget)}
                      </div>
                      <img
                        src={icons.x_icon}
                        className="creditsTab_part1_item-box_icon-boxx-icone"
                        alt="x_icon"
                        style={{width: '5px', height: '5px'}}
                      />
                      <img
                        src={icons.dibbleCoinIcon}
                        className="creditsTab_part1_item-box_icon-box_dibbl-icon"
                        alt="dibbleCoinIcon"
                        style={{width: '10px', height: '10px'}}
                      />
                    </div>
                  </div>
                  <div className="creditsTab_part1_hr">_horizintalLine</div>
                </div>
              ) : null,
            )}
        </div>
        {myDiscountSectionOpen && (
          <div className="creditsTab_part1_my-descount-open-section">
            {myActiveDiscounts.map(item => (
              <div className="creditsTab_part1_my-descount-open-section_descount-item">
                <img src={icons.present_icon} alt="present_icon" />
                <div className="creditsTab_part1_my-descount-open-section_descount-item_txt">
                  {item.text}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="creditsTab_part2">
        {' '}
        <BarChart chartData={chartData} />
      </div>
    </>
  );
}
