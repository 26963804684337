import React from 'react';
import {fetchData} from '../utils/fetchData';
import {useSelector} from 'react-redux';
import Obj_heb from '../utils/Obj_heb.json';
import Title from './Title';
import TextInput from './TextInput';
import Button from './Button';

/**
 * RegisterPersonalInfo
 *
 * This component handles email-related functionalities.
 *
 * @param {Object} props - Component properties.
 * @param {Function} [props.handleEmailChange] - Callback function to handle email changes.
 * @param {string} [props.email] - The current email input value.
 * @param {Object} [props.setInputSelected] - Object representing the input selection state.
 */

function RegisterEmail(props) {
  const isMobile = useSelector(state => state.mobile.isMobile);

  const sendEmail = async () => {
    let dataObj = {
      request: 'register_with_phone',
      auth_key: props.authKey,
      first_name: props.firstName,
      last_name: props.lastName,
      email: props.email,
      is_business_acount: props.chosedOption === 2 ? true : false,
      app_version: '1.7.18',
      os_type: 3,
    };
    if (props.businessNumber !== '') {
      dataObj.llc_id_number = props.businessNumber;
    }
    if (props.businessName !== '') {
      dataObj.llc_name = props.businessName;
    }
    fetchData(dataObj)
      .then(response => {
        if (response?.message === 'success') {
          saveUserInfo(response);
        }
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  };

  const saveUserInfo = dataJson => {
    let dataObj = {
      token: dataJson.token,
      first_name: props.firstName,
      last_name: props.lastName,
      email: props.email,
      phoneNumber: props.phoneNumber,
      is_email_verified: false,
    };
    fetchData(dataObj)
      .then(response => {
        localStorage.setItem('userData', JSON.stringify(dataObj));
        props.nextStep(1);
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  };

  const resetInput = () => {
    props.setEmail('');
  };

  const handleEmailChange = event => {
    if (event.target.value[0] !== ' ') {
      const inputValue = event.target.value;
      if (inputValue.length < 50) {
        props.setEmail(inputValue);
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        const consecutiveDotsRegex = /\.{2,}/;
        const singleAtRegex = /@[^@]*@/;
        const hasConsecutiveDots = consecutiveDotsRegex.test(inputValue);
        const hasSingleAt = !singleAtRegex.test(inputValue);
        if (emailRegex.test(inputValue) && !hasConsecutiveDots && hasSingleAt) {
          props.setEmailValidation(true);
        } else {
          props.setEmailValidation(false);
        }
      }
    }
  };

  return (
    <div className="login__pop-up">
      <div className="login__pop-up__text-wrapper">
        <Title
          title={Obj_heb.where_to_send}
          style="login__container--title"
          center={!isMobile}
        />

        <div className="login__container--text">
          {Obj_heb.where_to_send_info}
        </div>
      </div>
      <div className="login--gap-32">
        <TextInput
          value={props.email}
          onChange={handleEmailChange}
          onClear={() => resetInput()}
          description={Obj_heb.main_mail}
          placeholderText={Obj_heb.emailPlaceHolder}
          errorMsg={Obj_heb.main_mail_error}
          IsError={!props.emailValidation}
          directError={/[א-ת\s'"״׳:,;*&^%$#(){}']/.test(props.email)}
        />

        <Button
          text={props.emailValidation ? Obj_heb.next : Obj_heb.main_mail_error2}
          disable={!props.emailValidation}
          onClick={() => sendEmail()}
          textColor="white"
          backgroundColor={props.emailValidation ? '#FFCA1A' : '#BDBCBC'}
        />
      </div>
    </div>
  );
}
export default RegisterEmail;
