import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {fetchData} from '../utils/fetchData';
import obj_heb from '../utils/Obj_heb.json';

import CategoryBox from '../components/CategoryBox';
import Loader from '../components/Loader';
import Title from '../components/Title';

function Departments() {
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData')),
  );
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    let dataObj = {
      request: 'client_get_cat_screen_items',
      token: userData?.token || '',
    };

    fetchData(dataObj)
      .then(response => {
        const data = response.categories;

        setDepartments(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  }, []);

  const goToCcategories = (id, name) => {
    navigate(
      `/categories?id=${encodeURIComponent(id)}&name=${encodeURIComponent(
        name,
      )}`,
    );
  };

  return (
    <div>
      {isLoading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : null}

      <div className="departments">
        <Title title={obj_heb.departments} />
        <div className="departments__items">
          {departments.map((category, index) => {
            return (
              <CategoryBox
                key={'departments-item-' + category.name}
                category={category}
                goToCategory={goToCcategories}
                className="departments__items--item"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Departments;
