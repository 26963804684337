import React from 'react';
import SEO from '../components/SEO';
import obj_heb from '../utils/Obj_heb.json';

export default function Terms() {
  return (
    <React.Fragment>
      <SEO pageName={obj_heb.TOU} content={obj_heb.TOUDesc} link="/terms" />
      <div className="Terms">
        <div>
          אנחנו שמחים להציג את אזורי המשלוחים המעודכנים שלנו!
          <br />
          <br />
          ערים חדשות - קרית מלאכי,באר טוביה, בני עי״ש, גן יבנה, אשדוד, גדרה,
          יבנה, מזכרת בתיה, רחובות, נס ציונה, באר יעקב, נתניה, אבן יהודה, קדימה
          צורן, כפר יונה, בית ינאי, מכמורת, חדרה, פרדס חנה כרכור, שדות ים, אור
          עקיבא, חריש, בנימינה, קיסריה.
          <br />
          <br />
          אזור המרכז - תל אביב, רמת גן, גבעתיים,בני ברק,פתח תקווה, בת ים, אור
          יהודה,יהוד,קרית אונו,חולון,ראשון לציון, רמלה,לוד.
          <br />
          <br />
          איזור השרון - הרצליה,רמת השרון,הוד השרון,כפר סבא,רעננה.
          <br />
          <br />
          מודיעין והסביבה - מודיעין, מכבים רעות, שוהם, מבוא מודיעיןֿ, מודיעין
          עילית.
          <br />
          <br />
          איזור דרום - באר שבע, עומר, מיתר, להבים כמובן שאנחנו עובדים על התרחבות
          בעוד אזורים, הישארו מעודכנים😀
        </div>
      </div>
    </React.Fragment>
  );
}
