import React from 'react';
import {useSelector} from 'react-redux';

import house from '../assets/icons/house.svg';
import project from '../assets/icons/project.svg';
import classNames from 'classnames';

function MyAddressesItem({item, onClick}) {
  const isMobile = useSelector(state => state.mobile.isMobile);

  return (
    <div className="my-addresses-item" onClick={() => onClick(item)}>
      <span className="is-hover" />
      <div className="my-addresses-item__content">
        {!isMobile &&
          (item.address_type === 'project' ? (
            <img
              src={project}
              alt="project"
              className="my-addresses-item__icon"
            />
          ) : (
            <img src={house} alt="house" className="my-addresses-item__icon" />
          ))}
        <div
          className={classNames(
            'full-page',
            isMobile
              ? 'flex-column'
              : item.title?.length >= 63
              ? 'flex-column'
              : 'flex-row-4',
          )}>
          {item?.title && (
            <>
              <div className="my-addresses-item__title my-addresses-item__text-ellipsis">
                {item.title} {' | '}
              </div>
            </>
          )}
          <div className="my-addresses-item__address my-addresses-item__text-ellipsis">
            {item.address?.length >= 63
              ? item.address?.slice(0, 60) + '...'
              : item.address}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyAddressesItem;
