import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import PopUp from '../components/general/PopUp';
import TextInput from './TextInput';
import Button from './Button';
import Loader from '../components/Loader';
import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';

function AddAndEditUserPopUp({isOpen, setIsOpen, isEdit = false, user = null}) {
  let userData = JSON.parse(localStorage.getItem('userData'));

  const [userDetails, setUserDetails] = useState({
    first_name: isEdit ? user?.first_name : '',
    last_name: isEdit ? user?.last_name : '',
    id_number: isEdit ? user?.id_number : '',
    phone_number: isEdit ? user?.phone_number : '',
    avatar: isEdit ? user?.avatar : '',
    role: isEdit ? user?.role : 1,
    is_active: isEdit ? user?.is_active : false, // Add is_active to state
  });

  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    let dataObj = {
      request: isEdit ? 'update_contractor_user' : 'add_contractor_user',
      contractor_user_id: isEdit ? user.contractor_user_id : null,
      token: userData?.token,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      id_number: userDetails.id_number,
      phone_number: userDetails.phone_number,
      is_project_manager: 0,
      is_signature_permitted: 0,
      is_active: userDetails.is_active,
      avatar: `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg==`,
    };
    fetchData(dataObj)
      .then(response => {
        if (response.message === 'success') {
          setIsLoading(false);
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error:', error.message);
      });
  };

  const handleNumberInput = (e, field) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setUserDetails({...userDetails, [field]: value});
    }
  };

  const handleTextInput = (e, field) => {
    const value = e.target.value;
    const hebrewRegex = /^[\u0590-\u05FF\s]*$/; // Allows only Hebrew characters and spaces
    if (hebrewRegex.test(value)) {
      setUserDetails({...userDetails, [field]: value});
    }
  };

  const handleCheckboxChange = e => {
    setUserDetails({...userDetails, is_active: e.target.checked});
  };

  return (
    <>
      <PopUp isOpen={isOpen} setIsOpen={setIsOpen}>
        {isLoading ? (
          <div className="user-add__loading">
            <Loader />
            <span>{obj_heb.waitingForConfirmation}</span>
          </div>
        ) : (
          <div className="user-add">
            <span className="user-add__title">
              {isEdit ? obj_heb.editUser : obj_heb.addUser}
            </span>

            {errors.length > 0 && (
              <div className="user-add__invalid-user">
                {errors.map((error, index) => (
                  <p key={index}>{error}</p>
                ))}
              </div>
            )}

            <div className="user-add__fields">
              <TextInput
                description={obj_heb.firstName}
                placeholderText={obj_heb.putFirstName}
                onChange={e => handleTextInput(e, 'first_name')}
                value={userDetails.first_name}
                errorMsg="שם פרטי לא תקין"
                IsError={!!errors.find(error => error.includes('פרטי'))}
              />
              <TextInput
                description={obj_heb.lastName}
                placeholderText={obj_heb.putLastName}
                onChange={e => handleTextInput(e, 'last_name')}
                value={userDetails.last_name}
                errorMsg="שם משפחה לא תקין"
                IsError={!!errors.find(error => error.includes('משפחה'))}
              />
              <TextInput
                description={obj_heb.id}
                placeholderText={obj_heb.putId}
                onChange={e => handleNumberInput(e, 'id_number')}
                value={userDetails.id_number}
                errorMsg="תעודת זהות לא תקינה"
                IsError={!!errors.find(error => error.includes('תעודת'))}
                type="text"
                max={9}
              />
              <TextInput
                description={obj_heb.phone}
                placeholderText={obj_heb.putPhone}
                onChange={e => handleNumberInput(e, 'phone_number')}
                value={userDetails.phone_number}
                errorMsg="מספר טלפון לא תקין"
                IsError={!!errors.find(error => error.includes('טלפון'))}
                max={10}
                type="text"
              />

              {isEdit && (
                <div className="user-add__checkbox">
                  <label>
                    <input
                      type="checkbox"
                      checked={userDetails.is_active}
                      onChange={handleCheckboxChange}
                    />
                    <span className="user-add__checkbox--title">
                      {obj_heb.active}
                    </span>
                  </label>
                </div>
              )}
            </div>

            <Button
              text={isEdit ? obj_heb.save : obj_heb.add}
              disable={errors.length > 0}
              onClick={handleConfirm}
              textColor="white"
              backgroundColor={errors.length === 0 ? '#FFCA1A' : '#BDBCBC'}
              className="user-add__btn"
              id="user-add__btn"
            />
          </div>
        )}
      </PopUp>
    </>
  );
}

export default AddAndEditUserPopUp;
