import React, {useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import ProjectOrderListItem from './ProjectOrderListItem';
import AddAndEditUserPopUp from './AddAndEditUserPopUp';

export default function ProjectOrdersTab({projectOrders}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="MyOrdersTab">
        {projectOrders?.orders.length === 0 ? (
          <div className="MyOrdersTab_empty-list">
            <div className="MyOrdersTab_empty-list_text">
              <div className="MyOrdersTab_empty-list_text_title">
                {obj_heb.noOrdersFound}
              </div>
              <div className="MyOrdersTab_empty-list_text_sub-title">
                {obj_heb.noOrdersFoundDetails}
              </div>
            </div>
          </div>
        ) : (
          <div>
            {projectOrders?.orders.map(order => (
              <ProjectOrderListItem
                key={order.order_id}
                item={order}
                projectOrders={projectOrders ? projectOrders : ''}
              />
            ))}
          </div>
        )}
      </div>
      <AddAndEditUserPopUp isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="qrPopUp">
          <span className="qrPopUp_txt">{obj_heb.transferToAppStroeTxt}</span>
        </div>
      </AddAndEditUserPopUp>
    </>
  );
}
