import React from 'react';
import classNames from 'classnames';

/**
 *
 * @param {*} CustomTitle - to display the title in a pricier way
 *
 * @returns
 */
function Title({
  title,
  center = false,
  color,
  style = '',
  CustomTitle = null,
  cancelTitleLines = false,
}) {
  return (
    <div className="title">
      {!cancelTitleLines && (
        <div
          className={classNames(
            'title__line',
            center ? 'title__center' : 'title--1',
          )}
        />
      )}
      <div
        style={{color: color && color}}
        className={classNames('title__text', style)}>
        {CustomTitle ? CustomTitle : title}
      </div>
      {!cancelTitleLines && (
        <div
          className={
            center ? 'title__center title__line' : 'title__line title--2'
          }
        />
      )}
    </div>
  );
}

export default Title;
