// Project.js
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import obj_heb from '../utils/Obj_heb.json';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import ProjectPie from './general/ProjectPie';

export default function Project(props) {
  const navigate = useNavigate();
  const {project} = props;
  const [isShown, setIsShown] = useState(false);
  const formattedEndDate = moment
    .utc(project.end_date)
    .local()
    .format('D.M.YY');

  const isMobile = useSelector(state => state.mobile.isMobile);

  const navigateToProject = () => {
    navigate(`/project-details/${project.project_id}`);
  };

  return (
    <div
      className="project"
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      onClick={navigateToProject}>
      <div className="project--header">
        {project.name.length > 19
          ? project.name.slice(0, 18) + '...'
          : project.name}
      </div>
      <span className="project--text">{project.address}</span>
      <div className="project--header">{obj_heb.project_end_date}</div>
      <span className="project--text">{formattedEndDate}</span>

      <div className="project--header">{project.total_payments} ₪</div>

      <div className="project--budget">
        <div className="">
          <ProjectPie prasentageUsed={project.budget_usage_pc} />
        </div>
      </div>
    </div>
  );
}
