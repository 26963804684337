import React from 'react';
import MyListItem from './MyListItem';

const ListSelector = ({dateOptions, handleSelect}) => {
  return (
    <div className="days-selector">
      {dateOptions.length !== 0 && (
        <>
          <div className="days-selector__list">
            {dateOptions.map((item, index) => {
              return (
                <div
                  className="days-selector__list--item"
                  key={JSON.stringify(item)}>
                  <MyListItem item={item} onClick={handleSelect} />
                  {index !== dateOptions.length - 1 && (
                    <span className="days-selector__list--separator" />
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ListSelector;
