import React from 'react';
import GoogleMapReact from 'google-map-react';
import {googleLocationApiKey} from '../utils/baseValues';
import marker_icon from '../assets/images/dibble_location.png';

const MapLayout = ({address, setAddress}) => {
  // Define the custom map style
  const mapStyle = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#F5F5F5',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#F5F5F5',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#BDBDBD',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#EEEEEE',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#EEEEEE',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#9BD164',
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#E5E5E5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9E9E9E',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#FFFFFF',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#FFFFFF',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#DADADA',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9E9E9E',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#E5E5E5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#EEEEEE',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#C9C9C9',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#64B1D1',
        },
        {
          lightness: 50,
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9E9E9E',
        },
      ],
    },
  ];

  // Function to handle when the map is clicked
  const onMapClick = e => {
    // Get the latitude and longitude of the clicked point
    const lat = e.lat;
    const lng = e.lng;

    // Create a new Geocoder object
    const geocoder = new window.google.maps.Geocoder();

    // Perform the reverse geocoding request
    geocoder.geocode({location: {lat, lng}}, (results, status) => {
      if (status === 'OK') {
        // Get the formatted address from the results
        const addressName = results[0].formatted_address;
        setAddress(prev => {
          return {
            ...prev,
            address: addressName,
            lat: lat,
            lon: lng,
          };
        });
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });
  };

  const isAddressExist = () => {
    return address.address && address.lat && address.lon;
  };

  // Set the initial map center and zoom level
  const defaultCenter = {
    lat: 32.0853,
    lng: 34.7818,
  };

  const addressPoint = () => {
    if (isAddressExist())
      return {
        lat: address.lat,
        lng: address.lon,
      };
    else return defaultCenter;
  };

  const defaultZoom = isAddressExist() ? 17 : 15;

  const MapWithGoogleMapReact = () => (
    <div className="map">
      <GoogleMapReact
        bootstrapURLKeys={{key: googleLocationApiKey}}
        defaultCenter={addressPoint()}
        defaultZoom={defaultZoom}
        onClick={onMapClick}
        options={{
          styles: mapStyle,
        }}>
        {isAddressExist() && (
          <img
            lat={address.lat}
            lng={address.lon}
            src={marker_icon}
            alt="location"
            className="map__marker"
          />
        )}
      </GoogleMapReact>
    </div>
  );

  return <MapWithGoogleMapReact />;
};

export default MapLayout;
