import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  generalData: {mobileAppRedirectHeaderOpen: false},
};

export const generalDataSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setUserDataAction: (state, action) => {
      state.userData = action.payload;
    },
    clearUserDataAction: state => {
      state.userData = null;
    },
  },
});

export const {setUserDataAction, clearUserDataAction} =
  generalDataSlice.actions;

export default generalDataSlice.reducer;
