import React, {useRef} from 'react';
import Obj_heb from '../utils/Obj_heb.json';
import {useState, useEffect} from 'react';
import {fetchData} from '../utils/fetchData';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  fetchAddressesAndSetInitialList,
  fetchCreditCardsAndSetInitialList,
  fetchActiveOrdersAndSetInitialList,
  setIsDownloadAppShown,
} from '../redux/store';
import {setUserDataAction} from '../redux/slices/userInfoSlice';
import {app_version_test} from '../utils/baseValues.js';
import Title from './Title';
import classNames from 'classnames';
import {INTERCOM_APP_ID} from '../utils/baseValues';

/**
 * LOGIN OTP
 *
 * This component represents a popup for confirming a phone number and sending an SMS code.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.handleKeyPress - Callback function to handle key presses.
 * @param {string} props.phoneNumber - The current phone number input value.
 * @param {Function} [props.setInputSelected] - Optional callback to set input selection state.
 * @param {Function} [props.getClassName] - Optional callback to get CSS class name.
 * @param {Function} [props.handleKeyDown] - Optional callback to handle key down events.
 * @param {Function} [props.handleOpenIntercomFromAnotherPage] - Optional callback to open Intercom from another page.
 * @param {Object} [props.showOtpError] - Object representing OTP error state.
 * @param {Object} [props.phoneNumberValidation] - Object representing phone number validation.
 */

function LoginOtp(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [n1, setN1] = useState('');
  const [n2, setN2] = useState('');
  const [n3, setN3] = useState('');
  const [n4, setN4] = useState('');
  const [n5, setN5] = useState('');
  const [lastNum, setLastNum] = useState(1);
  const codeInputRef = useRef(null);
  const secondCodeInputRef = useRef(null);
  const thirdCodeInputRef = useRef(null);
  const fourCodeInputRef = useRef(null);
  const fiveCodeInputRef = useRef(null);
  const isMobile = useSelector(state => state.mobile.isMobile);
  const formatPhoneNumber = number => {
    const formattedNumber = number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    return formattedNumber;
  };
  const isNumber = value => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };
  const handleClick = num => {
    fiveCodeInputRef.current.focus();
    if (lastNum === 1) {
      codeInputRef.current.focus();
    } else if (lastNum === 2) {
      secondCodeInputRef.current.focus();
    } else if (lastNum === 3) {
      thirdCodeInputRef.current.focus();
    } else if (lastNum === 4) {
      fourCodeInputRef.current.focus();
    } else if (lastNum === 5) {
      fiveCodeInputRef.current.focus();
    } else {
      codeInputRef.current.focus();
    }
  };
  const handleFocus = num => {
    props.setInputSelected(num);
  };
  const handleKeyDown = number => event => {
    if (event.key === 'v') {
      navigator.clipboard.readText().then(
        clipboardText => {
          if (
            clipboardText.length >= 5 &&
            isNumber(clipboardText[0]) &&
            isNumber(clipboardText[1]) &&
            isNumber(clipboardText[2]) &&
            isNumber(clipboardText[3]) &&
            isNumber(clipboardText[4])
          ) {
            // If all clipboard text is numbers, set them to variables
            setN1(clipboardText[0]);
            setN2(clipboardText[1]);
            setN3(clipboardText[2]);
            setN4(clipboardText[3]);
            setN5(clipboardText[4]);
            setLastNum(5);
            fiveCodeInputRef.current.focus();
          }
        },
        error => {
          console.error(`Clipboard read error: ${error}`);
        },
      );
    }
    const key = event.key;
    if (key === 'Backspace') {
      if (number === 1) {
        setN1('');
        codeInputRef.current.focus();
      } else if (number === 2) {
        setN2('');
        props.setInputSelected(1);
        codeInputRef.current.focus();
      } else if (number === 3) {
        setN3('');
        props.setInputSelected(2);
        secondCodeInputRef.current.focus();
      } else if (number === 4) {
        setN4('');
        props.setInputSelected(3);
        thirdCodeInputRef.current.focus();
      } else if (number === 5) {
        if (fiveCodeInputRef.current.value === '') {
          setN5('');
          props.setInputSelected(4);
          fourCodeInputRef.current.focus();
        } else {
          setN5('');
          props.setInputSelected(5);
          fiveCodeInputRef.current.focus();
        }
      }
    } else if (key >= '0' && key <= '9') {
      if (number && number != 5) {
      }
      if (number === 1) {
        setN1(key);
        props.setInputSelected(2);
        secondCodeInputRef.current.focus();
      } else if (number === 2) {
        setN2(key);
        props.setInputSelected(3);
        thirdCodeInputRef.current.focus();
      } else if (number === 3) {
        setN3(key);
        props.setInputSelected(4);
        fourCodeInputRef.current.focus();
      } else if (number === 4) {
        setN4(key);
        props.setInputSelected(5);
        fiveCodeInputRef.current.focus();
      } else if (number === 5) {
        if (!fiveCodeInputRef.current.value) {
          setN5(key);
          //here i want to move the curder 1 letter right
          const inputElement = fiveCodeInputRef.current;
          const currentPosition = inputElement.selectionStart;
          const newPosition = currentPosition + 1;

          inputElement.setSelectionRange(newPosition, newPosition);
        }
      }
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const verifyCode = async () => {
    if (n1 !== '' && n2 !== '' && n3 !== '' && n4 !== '' && n5 !== '') {
      let verifyCode = n1 + n2 + n3 + n4 + n5;
      let dataObj = {
        request: 'verify_sms_code',
        phone_num: props.phoneNumber,
        verification_code: verifyCode,
      };
      fetchData(dataObj)
        .then(response => {
          if (response.message === 'success') {
            props.setShowOtpError(false);
            if (response.auth_key) {
              props.setAuth_key(response.auth_key);
            }
            if (response.is_registered) {
              loginWithPhone(response.auth_key);
            } else {
              props.changeFlow();
            }
          } else if ((response.rc = 232)) {
            props.setShowOtpError(true);
          }
        })
        .catch(error => {
          console.error('Error:', error?.message);
        });
    }
  };

  const loginWithPhone = async authKey => {
    let dataObj = {
      request: 'login_with_phone',
      auth_key: authKey,
      app_version: app_version_test,
      os_type: 7,
    };
    fetchData(dataObj)
      .then(response => {
        if (response.message === 'success') {
          let res = response;
          res.phone = props.phoneNumber;
          localStorage.setItem('userData', JSON.stringify(res));
          dispatch(fetchAddressesAndSetInitialList());
          dispatch(fetchCreditCardsAndSetInitialList());
          dispatch(fetchActiveOrdersAndSetInitialList());
          dispatch(setUserDataAction(res));
          dispatch(setIsDownloadAppShown(true));
          navigate('/');
          window.Intercom('shutdown');
          window.Intercom('reset');
          window.Intercom('boot', {
            app_id: INTERCOM_APP_ID,
          });
          window.Intercom('update', {
            name: res.first_name + ' ' + res.last_name,
            email: res.email,
            phone: res.phone,
            userId: res.phone,
          });
        }
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  };

  useEffect(() => {
    if (n1 !== '' && n2 !== '' && n3 !== '' && n4 !== '' && n5 !== '') {
      verifyCode();
    } else {
      props.setShowOtpError(false);
    }
    if (n5 === '' && n4 != '' && n3 != '' && n2 != '' && n1 != '') {
      setLastNum(5);
    } else if (n5 === '' && n4 === '' && n3 != '' && n2 != '' && n1 != '') {
      setLastNum(4);
    } else if (n5 === '' && n4 === '' && n3 === '' && n2 != '' && n1 != '') {
      setLastNum(3);
    } else if (n5 === '' && n4 === '' && n3 === '' && n2 === '' && n1 != '') {
      setLastNum(2);
    } else if (n5 === '' && n4 === '' && n3 === '' && n2 === '' && n1 === '') {
      setLastNum(1);
    }
  }, [n1, n2, n3, n4, n5]);

  useEffect(() => {
    if (codeInputRef.current) {
      codeInputRef.current.focus();
    }
  }, []);

  return (
    <div className="login__pop-up">
      <div className="login__pop-up__text-wrapper">
        <Title
          title={Obj_heb.phone_number_validation}
          style="login__container--title"
          center={!isMobile}
        />
        <div className="login__container--text">
          {Obj_heb.phone_number_validation_sms}
          <span className="login__container--text--bold">
            {' '}
            {formatPhoneNumber(props.phoneNumber)}
          </span>
          <br />
          {Obj_heb.enter_validation_number}
        </div>
      </div>
      <div className="login__pop-up__text-wrapper" style={{margin: '0 auto'}}>
        <div className="OTP">
          {/* OTP 5 */}
          <input
            ref={fiveCodeInputRef}
            onChange={() => {}}
            onMouseDown={handleKeyDown(5)}
            onKeyDown={handleKeyDown(5)}
            value={n5}
            type={isMobile ? 'tel' : ''}
            className={classNames(
              `login__sms-digits ${props.getClassName(
                props.phoneNumber,
                props.phoneNumberValidation,
                5,
              )}`,
            )}
            onFocus={() => handleFocus(5)}
            onClick={() => handleClick(5)}
          />

          {/* OTP 4 */}
          <input
            type={isMobile ? 'tel' : ''}
            ref={fourCodeInputRef}
            onChange={() => {}}
            onMouseDown={handleKeyDown(4)}
            onKeyDown={handleKeyDown(4)}
            value={n4}
            className={classNames(
              `login__sms-digits ${props.getClassName(
                props.phoneNumber,
                props.phoneNumberValidation,
                4,
              )}`,
            )}
            onFocus={() => handleFocus(4)}
            onClick={() => handleClick(4)}
          />

          {/* OTP 3 */}
          <input
            type={isMobile ? 'tel' : ''}
            ref={thirdCodeInputRef}
            onChange={() => {}}
            onMouseDown={handleKeyDown(3)}
            onKeyDown={handleKeyDown(3)}
            value={n3}
            className={classNames(
              `login__sms-digits ${props.getClassName(
                props.phoneNumber,
                props.phoneNumberValidation,
                3,
              )}`,
            )}
            onFocus={() => handleFocus(3)}
            onClick={() => handleClick(3)}
          />

          {/* OTP 2 */}
          <input
            type={isMobile ? 'tel' : ''}
            ref={secondCodeInputRef}
            onChange={() => {}}
            onMouseDown={handleKeyDown(2)}
            onKeyDown={handleKeyDown(2)}
            value={n2}
            className={classNames(
              `login__sms-digits ${props.getClassName(
                props.phoneNumber,
                props.phoneNumberValidation,
                2,
              )}`,
            )}
            onFocus={() => handleFocus(2)}
            onClick={() => handleClick(2)}
          />

          {/* OTP 1 */}
          <input
            type={isMobile ? 'tel' : ''}
            onChange={() => {}}
            onMouseDown={handleKeyDown(1)}
            onKeyDown={handleKeyDown(1)}
            value={n1}
            className={classNames(
              `login__sms-digits ${props.getClassName(
                props.phoneNumber,
                props.phoneNumberValidation,
                1,
              )}`,
            )}
            ref={codeInputRef}
            autoFocus
            onFocus={() => handleFocus(1)}
            onClick={() => handleClick(1)}
          />
        </div>
        <div className="otp_error">
          {props.showOtpError && Obj_heb.verification_is_incorrect}
        </div>
      </div>
      <div className="login__resend">
        <span className="login__container--text">{Obj_heb.didnt_get_code}</span>
        <span
          onClick={() => props.handleOpenIntercomFromAnotherPage()}
          className="login__container--text--yellow">
          {Obj_heb.reach_us}
        </span>
      </div>
    </div>
  );
}
export default LoginOtp;
