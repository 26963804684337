// userInfoSlice.js

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  userData: null,
};

export const userInfoSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDataAction: (state, action) => {
      state.userData = action.payload;
    },
    clearUserDataAction: state => {
      state.userData = null;
    },
  },
});

export const {setUserDataAction, clearUserDataAction} = userInfoSlice.actions;

export default userInfoSlice.reducer;
