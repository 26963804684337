import React, {useState} from 'react';
import Obj_heb from '../utils/Obj_heb.json';
import Title from './Title';
import {fetchData} from '../utils/fetchData';

/**
 * RegisterPersonalInfo
 *
 * This component handles email-related functionalities.
 *
 * @param {Object} props - Component properties.
 * @param {Function} [props.handleEmailChange] - Callback function to handle email changes.
 * @param {Function} [props.handleOpenIntercomFromAnotherPage] - open Intercom in another page.
 * @param {string} [props.email] - The current email input value.
 * @param {Object} [props.emailValidation] - Object representing email validation state.
 * @param {Object} [props.sendEmail] - Object representing the action to send an email.
 * @param {string} [props.airPlane] - image.
 */

function RegisterSuccess(props) {
  const [chatWithUsLayout, setChatWithUsLayout] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const reSendEmailCodeHandler = () => {
    sendEmail();
    chatWithUsHandler();
  };

  const sendEmail = async () => {
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    let dataObj = {
      request: 'send_email_code',
      token: userData.token,
      email: userData.email,
    };
    fetchData(dataObj)
      .then(response => {
        if (response?.message === 'success') {
        }
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  };

  const chatWithUsHandler = () => {
    setIsPressed(true);
    setTimeout(() => {
      setChatWithUsLayout(true);
    }, 3000);
  };

  const ResendEmailTextHandler = () => {
    return (
      <div className="login--order-4">
        <div className="login__send-code">
          {isPressed ? (
            <span className="login__send-code-parshe--no-mt">
              {Obj_heb.fingersCrossed}
            </span>
          ) : (
            <React.Fragment>
              <span className="login__send-code-parshe--no-mt">
                {Obj_heb.didnt_get_mail}
              </span>
              <span
                onClick={() => reSendEmailCodeHandler()}
                className="login__send-again--no-mt">
                {Obj_heb.resendEmailCode}
              </span>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="login__pop-up order">
      <div className="login--order-1">
        <Title
          title={Obj_heb.last_validation}
          style="login__container--title"
          center
        />
      </div>
      <div className="login--order-2">
        <div className="login__text_container--text--no-mb">
          <div className="login__container--text center">
            {Obj_heb.we_send_mail_for}
          </div>
          <div className="login__container--text--bold center">
            {props.email}
          </div>
          <div className="login__container--text center">
            {Obj_heb.we_send_mail_info}
          </div>
        </div>
      </div>

      <div className="login--order-3">
        <img className="login__airplane" src={props.airPlane}></img>
      </div>

      <ResendEmailTextHandler />
      {chatWithUsLayout && (
        <div className="login--order-4">
          <div className="login__send-code">
            <span className="login__send-code-parshe--no-mt">
              {Obj_heb.somethingWentWrong}
            </span>
            <span
              onClick={() => props.handleOpenIntercomFromAnotherPage()}
              className="login__send-again--no-mt">
              {Obj_heb.reach_us}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
export default RegisterSuccess;
