import React, {useState, useRef, useEffect, Fragment, useCallback} from 'react';
import classNames from 'classnames';
import {fetchData} from '../utils/fetchData';
import obj_heb from '../utils/Obj_heb.json';
import {debounce} from '../utils/supportFunctions';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import SearchedItem from './SearchedItem';
import SearchInput from './SearchInput';
import Loader from './Loader';

function SearchNavBar({className = '', setBgDiv = null}) {
  const [searchedText, setSearchedText] = useState('');
  const [values, setValues] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSearchPage, setIsLoadingSearchPage] = useState(false);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData')),
  );

  const isMobile = useSelector(state => state.mobile.isMobile);

  const navigate = useNavigate();
  const valuesRef = useRef();

  // the following code block is to make the data fetching working better and fire less frequently
  // and persist trow the function re-rendering from the user text
  const fetchingDataRef = useRef();

  fetchingDataRef.current = () => {
    fetchData({
      request: 'search_indexed_product',
      search_string: searchedText,
      token: userData?.token || '',
    })
      .then(response => {
        clearTimeout(timerId);
        setIsLoadingSearchPage(false);
        setValues(response.hits.hits.map(item => item._source));
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error:', error?.message);
        setIsLoading(false);
      });
  };

  const debouncedFetch = useCallback(
    debounce(() => fetchingDataRef.current(), 200),
    [],
  );
  let timerId;
  useEffect(() => {
    timerId = setTimeout(() => {
      if (searchedText) {
        setIsLoading(true);
        debouncedFetch();
      } else {
        setValues(null);
      }
    }, 500);

    return () => {
      clearTimeout(timerId); // Clear the timeout if component unmounts or effect re-runs
    };
  }, [searchedText]);

  useEffect(() => {
    if (setBgDiv) {
      if (values) {
        setBgDiv(<div className="bg-blur" />);
      } else {
        setBgDiv(null);
      }
    }
  }, [values, setBgDiv]);

  useEffect(() => {
    // Function to handle click outside the div
    const handleClickOutside = event => {
      if (valuesRef.current && !valuesRef.current.contains(event.target)) {
        // The click is outside the div, do something here
        setValues(null);
        setSearchedText('');
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSearch = () => {
    clearTimeout(timerId);
    if (searchedText && searchedText.length) {
      setIsLoadingSearchPage(true);
      navigate(`/search_results?query=${encodeURIComponent(searchedText)}`);
      fetchingDataRef.current();
    }
    setValues(null);
    setSearchedText('');
  };

  const handleOnChange = value => {
    if (!isLoadingSearchPage || isLoading) {
      setSearchedText(value.replace(/^\s+/, ''));
    }
  };

  const handleEnterPress = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
    if (e.key === 'Escape') {
      setValues(null);
      setSearchedText('');
    }
    // e.preventDefault();
  };

  return (
    <>
      <div className={classNames('search-nav-bar', className)} ref={valuesRef}>
        <SearchInput
          searchedText={searchedText}
          setSearchedText={handleOnChange}
          placeholderText={obj_heb.looking_for_something}
          onKeyDown={handleEnterPress}
          color={isMobile && 'var(--body-color)'}
        />
        {values && (
          <div className="search-nav-bar__searched-items card-shadow">
            {isLoading ? (
              <div className="search-nav-bar__searched-items--loader">
                <Loader />
              </div>
            ) : values.length > 0 ? (
              <>
                <div className="search-nav-bar__searched-items--list">
                  {values.map((item, index) => {
                    if (index === values.length - 1) {
                      return (
                        <Fragment key={JSON.stringify(item)}>
                          <SearchedItem item={item} setValues={setValues} />
                        </Fragment>
                      );
                    } else {
                      return (
                        <div
                          key={JSON.stringify(item)}
                          className="search-nav-bar__searched-items--list--item">
                          <SearchedItem
                            key={JSON.stringify(item)}
                            item={item}
                            setValues={setValues}
                          />
                          <div className="search-nav-bar__searched-items--separator" />
                        </div>
                      );
                    }
                  })}
                </div>

                <div
                  onClick={handleSearch}
                  className="search-nav-bar__searched-items--all-results">
                  {obj_heb.allResultsFor} " {searchedText} "
                </div>
              </>
            ) : (
              <div className="search-nav-bar__searched-items--not-found">
                {obj_heb.weDidntFind}
                <div>{obj_heb.somethingIsMissing}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default SearchNavBar;
