import React, {useState, useEffect} from 'react';
import {fetchData} from '../utils/fetchData';
import obj_heb from '../utils/Obj_heb.json';
import Title from './Title';
import {useSearchParams} from 'react-router-dom';
import FoundProduct from './FoundProduct';
import SEO from './SEO';
import Loader from './Loader';

function ResultsList() {
  const [products, setProducts] = useState([]);
  // const [start_with_product_id, setStart_with_product_id] = useState(0);
  // const [loadingMore, setLoadingMore] = useState(false);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData')),
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  // const handleScroll = () => {
  //   console.log(`handleScroll`);
  //   const isBottom =
  //     window.innerHeight + window.scrollY >= document.body.offsetHeight;
  //   if (isBottom) {
  //     console.log(`isBottom`);
  //     console.log(`products`, products);
  //     if (products) {
  //       // setStart_with_product_id(data.hits.hits[data.hits.hits.length-1]._id)

  //       findProducts(true);
  //     } else {
  //       setStart_with_product_id(0);
  //     }
  //   }
  // };
  // const findProducts = (scrolled = false) => {
  //   console.log(`findProducts is running`);
  //   if (loadingMore) return; // Return early if already loading more
  //   setLoadingMore(true);
  //   let dataObj = {
  //     request: 'search_indexed_product',
  //     search_string: searchParams.get('query'),
  //     token: userData?.token || '',
  //     start_with_product_id: start_with_product_id,
  //   };
  //   console.log(`dataObj`, dataObj);
  //   fetchData(dataObj)
  //     .then(response => {
  //       setIsLoading(false);
  //       console.log(`response`, response);
  //       const data = response;
  //       if (data) {
  //         // setProducts(data);
  //         setStart_with_product_id(
  //           data.hits.hits[data.hits.hits.length - 1]._id,
  //         );
  //         setLoadingMore(false); // Reset loadingMore to false
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Error:', error.message);
  //       setIsLoading(false);
  //       setLoadingMore(false); // Reset loadingMore to false in case of error
  //     });
  // };

  useEffect(() => {
    setIsLoading(true);
    let dataObj = {
      request: 'search_indexed_product',
      search_string: searchParams.get('query'),
      token: userData?.token || '',
    };

    fetchData(dataObj) // Don't assign the result to x, instead handle it using the 'then' method
      .then(response => {
        setIsLoading(false);
        const data = response;
        setProducts(data);
        // setStart_with_product_id(
        //   data.hits.hits[data.hits.hits.length - 1]._id,
        // );
      })
      .catch(error => {
        setIsLoading(true);
        console.error('Error:', error?.message);
      });
    // }
  }, [userData, searchParams]);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [start_with_product_id]);

  //Values for SEO
  const PageName = `${obj_heb.resultsForEntry} ${searchParams.get('query')}`;
  const Content = `${obj_heb.resultsForEntry}  ${searchParams.get('query')} ${
    obj_heb.SearchResultsDesc
  }`;
  const LINK = `/search_results?query=${encodeURIComponent(
    searchParams.get('query'),
  )}`;

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : null}
      <SEO pageName={PageName} content={Content} link={LINK} />
      <div className="results">
        <Title title={obj_heb.search_results} />
        {products.hits ? (
          <div className="results__subHeader">
            <div className="results__subHeader__count">
              {products.hits.total.value} {obj_heb.search_results} "
              {searchParams.get('query')}"
            </div>
          </div>
        ) : null}
        <div className="results__container">
          {products.hits &&
            products.hits.hits.map((product, index) => {
              return (
                <FoundProduct
                  product={product}
                  key={'search-result-item-' + index}
                />
              );
            })}
        </div>
      </div>
      {/* {loadingMore ? <Loader /> : null}{' '} */}
    </React.Fragment>
  );
}

export default ResultsList;
