import React from 'react';
import obj_heb from '../utils/Obj_heb.json';
import CreditCard from '../assets/icons/CreditCards/creditCard.svg';
import location from '../assets/icons/location.svg';
import leftArrowHead from '../assets/icons/leftArrowHead.svg';
import {useNavigate} from 'react-router-dom';

export default function PersonalSettingsTab() {
  const navigate = useNavigate();

  const clickHandelr = to => {
    navigate(to);
  };

  return (
    <div className="PersonalSettingsTab">
      <div
        onClick={() => clickHandelr('/credit-cards')}
        className="PersonalSettingsTab_item">
        <div className="PersonalSettingsTab_item--title">
          <img
            className="PersonalSettingsTab_item--title--icon-card"
            src={CreditCard}
            alt="CreditCard"
          />
          <span>{obj_heb.My_Payment_Methods}</span>
        </div>
        <img
          className="PersonalSettingsTab_item--title_arrowhead_img"
          src={leftArrowHead}
          alt="leftArrowHead"
        />
      </div>
      <div className="PersonalSettingsTab_hr">_</div>
      <div
        onClick={() => clickHandelr('/my-address')}
        className="PersonalSettingsTab_item">
        <div className="PersonalSettingsTab_item--title">
          <img
            className="PersonalSettingsTab_item--title--icon-loc"
            src={location}
            alt="location"
          />
          <span>{obj_heb.My_Addresses}</span>
        </div>
        <img
          className="PersonalSettingsTab_item--title_arrowhead_img"
          src={leftArrowHead}
          alt="leftArrowHead"
        />
      </div>
    </div>
  );
}
