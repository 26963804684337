import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Button from '../components/Button';
import obj_heb from '../utils/Obj_heb.json';
import {useNavigate} from 'react-router-dom';
import {numberWithCommas} from '../utils/supportFunctions';
import {fetchData} from '../utils/fetchData';

export default function CategoryProduct(props) {
  const navigate = useNavigate();
  const {product} = props;
  const [isShown, setIsShown] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [vat, setVat] = useState(0);

  const isMobile = useSelector(state => state.mobile.isMobile);
  const convertStringToNumber = input => {
    const number = parseFloat(input);
    const convertedNumber = number / 100 + 1;
    return convertedNumber;
  };
  const navigateToProduct = () => {
    navigate(
      `/product/${encodeURIComponent(
        product._source?.product_id
          ? product._source.product_id
          : product.product_id,
      )}`,
    );
  };
  useEffect(() => {
    setImageSrc(
      product._source?.image ? product._source?.image : product.image,
    );
  }, [product]);
  useEffect(() => {
    let dataObj = {
      request: 'get_vat_rate',
    };
    fetchData(dataObj).then(response => {
      if (response?.message === 'success')
        setVat(convertStringToNumber(response.vat_rate.value));
    });
  }, []);

  return (
    <div
      className="foundProduct"
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      onClick={navigateToProduct}>
      <div className="foundProduct__image">
        <img
          className="foundProduct__image--img"
          src={imageSrc}
          alt={
            product._source?.name ? product._source?.name.length : product.name
          }
        />
      </div>

      <div className="foundProduct__price-text">
        {!product.sale_price ? (
          <div className="foundProduct__text--inner"></div>
        ) : (
          ''
        )}
        <span className="categoryProduct__price_number">
          {product.sale_price > 0 || product._source?.sale_price > 0 ? (
            <div className="price_onSalenewPrice">
              <span className="price_onSalenewPrice_currency">₪</span>
              {product._source?.sale_price
                ? numberWithCommas(
                    (product._source?.sale_price * vat).toFixed(2),
                  )
                : numberWithCommas((product.sale_price * vat).toFixed(2))}
            </div>
          ) : null}
          {!product.sale_price > 0 && !product._source?.sale_price > 0 ? (
            <span className="price_regular-number">
              <span className="price_regular-number_currency">₪</span>
              {product._source?.price
                ? numberWithCommas((product._source?.price * vat).toFixed(2))
                : numberWithCommas((product.price * vat).toFixed(2))}
            </span>
          ) : (
            <span className="price_onSale">
              <span className="price_onSale_oldPrice">
                <span className="price_onSale_oldPrice_currency">₪</span>
                {product._source?.base_price
                  ? numberWithCommas(
                      (product._source?.base_price * vat).toFixed(2),
                    )
                  : numberWithCommas((product.base_price * vat).toFixed(2))}
                <div className="price_onSale_oldPricered-line"></div>
              </span>
            </span>
          )}
        </span>
      </div>

      <div className="foundProduct__text">
        {product._source?.name
          ? product._source.name.length > 19
            ? product._source.name.slice(0, 18) + '...'
            : product._source.name
          : product.name.length > 19
          ? product.name.slice(0, 18) + '...'
          : product.name}
      </div>
      {(isShown || isMobile) && (
        <Button
          text={obj_heb.add_to_cart}
          onClick={() => {}}
          backgroundColor="white"
          textColor="var(--dibble-yellow)"
          borderColor="var(--dibble-yellow)"
          className={isMobile && 'foundProduct__btn'}
        />
      )}
    </div>
  );
}
