import classNames from 'classnames';
import {Fragment} from 'react';

function ScrollView({items, className = ''}) {
  return (
    <div className={classNames('scroll-view', className)}>
      {items.map((item, index) => (
        <Fragment key={'scroll-view-item-' + index}>{item}</Fragment>
      ))}
    </div>
  );
}

export default ScrollView;
