import {useSelector, useDispatch} from 'react-redux';
import {setIsDownloadAppShown} from '../redux/store';
import classNames from 'classnames';

import Footer from './Footer';
import NavBar from './NavBar';
import MobileFooter from './MobileFooter';
import DownloadAppBanner from '../components/DownloadAppBanner';

function Layout({
  children,
  needAddress = true,
  cancelFooterOnMobile = false,
  cancelFooterScreeningOnMobile = false,
  navBarBack = false,
  hideDibbleIcon = false,
}) {
  const isMobile = useSelector(state => state.mobile.isMobile);
  const isDownloadAppShown = useSelector(
    state => state.mobile.isDownloadAppShown,
  );
  const dispatch = useDispatch();

  return (
    <div
      className={classNames(
        'layout full-page',
        isDownloadAppShown && 'layout--download-banner',
      )}>
      {isDownloadAppShown && (
        <DownloadAppBanner
          setIsShown={() => dispatch(setIsDownloadAppShown(false))}
        />
      )}
      <NavBar
        needAddress={needAddress}
        navBarBack={navBarBack}
        hideDibbleIcon={hideDibbleIcon}
      />
      <div className="content">{children}</div>
      {!isMobile ? (
        <Footer />
      ) : !cancelFooterOnMobile ? (
        <MobileFooter
          cancelFooterScreeningOnMobile={cancelFooterScreeningOnMobile}
        />
      ) : (
        <span></span>
      )}
    </div>
  );
}

export default Layout;
