import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import obj_heb from '../utils/Obj_heb.json';
import icons from '../assets/constants/icons';

import SummaryComponent from '../components/SummaryComponent';
import AddressInput from '../components/AddressInput';
import CreditCardInput from '../components/CreditCardInput';
import Title from '../components/Title';
import InstallmentInput from '../components/InstallmentInput';
import AddNotes from '../components/AddNotes';
import MaterialDate from '../components/MaterialDate';

function SummaryMobile({
  form,
  setForm,
  isProject,
  shoppingCartPrice,
  shoppingCartProducts,
  ready,
  createDataObj,
  setIsLoading,
  isNeedCrane,
  handleDeliveryType,
  bidPrice,
  bidDeliveryCost,
  orderId,
  bidId,
  materialOrderAddress,
  requestedDeliveryTime,
  payment_type,
  creditcard_id,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!form.deliveryType) {
      navigate(location.pathname + location.search);
    }
  }, [form.deliveryType]);

  const handleSlotClick = () => {
    if (form.deliveryType === 1) {
      navigate(location.pathname);
    } else {
      handleDeliveryType({index: 3});
    }
  };

  return (
    <>
      <Title title={obj_heb.orderSummary} />
      {/* address and supply*/}
      <div className="summary__form__item">
        <span
          className="summary__form__item--title"
          style={{marginTop: '1.6rem'}}>
          {obj_heb.addressAndSupply}
        </span>
        <div className="summary__mobile-address-supply">
          {/* slot */}
          {!bidId ? (
            <div
              className="summary__mobile-address-supply__address"
              onClick={() => handleSlotClick()}>
              <div className="summary__mobile-address-supply__address--title">
                <img
                  src={icons.dayCalender}
                  alt="location"
                  className="summary__mobile-address-supply__address__icon"
                />
                <span>
                  {form.deliveryType === 3 && form.slot
                    ? `${form.slot.split(' ')[0]}, בשעה ${
                        form.slot.split(' ')[3]
                      }`
                    : form.deliveryType === 1
                    ? obj_heb.fast_delivery
                    : obj_heb.pickUpDeliveryType}
                </span>
              </div>
              <img
                className={'summary__mobile-address-supply__address--arrow'}
                src={icons.arrow_head_left}
                alt=""
              />
            </div>
          ) : (
            <div>
              {' '}
              <MaterialDate
                boxShadow
                requestedDeliveryTime={requestedDeliveryTime}
              />
            </div>
          )}
          <span className="summary__mobile-address-supply--separator" />
          {/* address */}
          <AddressInput materialOrderAddress={materialOrderAddress} />
        </div>
      </div>
      {/* notes */}
      <div className="summary__form__item">
        <span
          className="summary__form__item--title"
          style={{marginTop: '1.6rem'}}>
          {obj_heb.business_notes}
        </span>
        <AddNotes
          showTitle={true}
          notes={form.notes}
          setNotes={value =>
            setForm(prev => {
              return {...prev, notes: value};
            })
          }
        />
      </div>
      {/* credit card */}
      <div className="summary__form__item">
        <span
          className="summary__form__item--title"
          style={{marginTop: '1.6rem'}}>
          {obj_heb.payment_type}
        </span>

        {/* cards container */}
        <CreditCardInput
          setCreditCard={value =>
            setForm(prev => {
              return {...prev, creditCard: value};
            })
          }
          setSecondCreditCard={value =>
            setForm(prev => {
              return {...prev, secondCreditCard: value};
            })
          }
          setInstallments={value =>
            setForm(prev => {
              return {...prev, installments: value};
            })
          }
          creditCard={form.creditCard}
          dibbleCoins={form.dibbleCoins}
          canPayWithDibbleCoins={form.canPayWithDibbleCoins}
          requestedDeliveryTime={requestedDeliveryTime}
        />
        {/* installments component */}
        {form.creditCard && form.creditCard.card_num && (
          <InstallmentInput
            setInstallments={value =>
              setForm(prev => {
                return {...prev, installments: value};
              })
            }
          />
        )}
        {/* second credit card */}
        {!form.canPayWithDibbleCoins &&
          !isProject &&
          form.deliveryCost &&
          form.deliveryCost != 0 &&
          (form.deliveryType == 1 || form.deliveryType == 3) &&
          !form.creditCard?.card_num && (
            <>
              <div className="summary__form__item--error-msg">
                {!form.secondCreditCard?.card_num
                  ? obj_heb.dibbleCoinsBalanceError
                  : obj_heb.the_rest_you_pay_with}
              </div>
              <CreditCardInput
                setCreditCard={value =>
                  setForm(prev => {
                    return {...prev, creditCard: value};
                  })
                }
                setSecondCreditCard={value =>
                  setForm(prev => {
                    return {...prev, secondCreditCard: value};
                  })
                }
                shoppingCartPrice={shoppingCartPrice()}
                deliveryPrice={form.deliveryCost}
                // sending the second
                creditCard={form.secondCreditCard}
                dibbleCoins={form.dibbleCoins}
                canPayWithDibbleCoins={form.canPayWithDibbleCoins}
                setInstallments={value =>
                  setForm(prev => {
                    return {...prev, installments: value};
                  })
                }
                second={true}
                requestedDeliveryTime={requestedDeliveryTime}
              />
            </>
          )}
      </div>

      <SummaryComponent
        action="summary"
        ready={ready}
        dataObj={createDataObj()}
        setIsLoading={setIsLoading}
        isNeedCrane={isNeedCrane}
        dibbleCoins={form.dibbleCoins}
        canPayWithDibbleCoins={form.canPayWithDibbleCoins}
        deliveryType={form.deliveryType}
        bidPrice={bidPrice}
        bidDeliveryCost={bidDeliveryCost}
        orderId={orderId}
        bidId={bidId}
        payment_type={payment_type}
        creditcard_id={creditcard_id}
        requestedDeliveryTime={requestedDeliveryTime}
      />
      <div style={{height: 'calc(5.6rem + 2.4rem)'}}></div>
    </>
  );
}

export default SummaryMobile;
