import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import obj_heb from '../../utils/Obj_heb.json';
import classNames from 'classnames';

import Button from '../Button';
import PriceForCardItem from '../PriceForCardItem';

export default function ProductMinCard({
  imgLink,
  product_id,
  txtBeforeThePrice,
  productOnSale,
  salePrice,
  price,
  title,
  num,
}) {
  const navigate = useNavigate();
  const [isShown, setIsShown] = useState(false);

  const isMobile = useSelector(state => state.mobile.isMobile);
  let className = num == 6 ? 'SmallProductCard' : 'ProductMinCard';
  return (
    <div
      className={className}
      onClick={() => navigate(`/product/${product_id}`)}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}>
      <img
        className="ProductMinCard_img"
        src={imgLink}
        alt="ProductMinCard_img"
      />
      <div className="ProductMinCard_container">
        <div className="ProductMinCard_container_second_part">
          <div className="ProductMinCard_container_second_part_price-box">
            <PriceForCardItem
              txtBeforeThePrice={txtBeforeThePrice}
              price={price}
              sale={productOnSale}
              newPrice={salePrice}
            />
          </div>

          <div className="ProductMinCard_container_second_part_title">
            {title}
          </div>
        </div>

        {(isShown || isMobile) && (
          <Button
            text={obj_heb.add_to_cart}
            onClick={() => navigate(`/product/${product_id}`)}
            backgroundColor="white"
            textColor="var(--dibble-yellow)"
            borderColor="var(--dibble-yellow)"
            className={isMobile && 'foundProduct__btn'}
          />
        )}
      </div>
    </div>
  );
}
