import React, {useEffect, useState} from 'react';
import arrow_head_down from '../assets/icons/arrow_head_down.svg';
import FilterBySelector from './FilterBySelector';
import classNames from 'classnames';
import obj_heb from '../utils/Obj_heb.json';

function FilterBy({
  options,
  slot,
  popUpIsOpen,
  handleSelect,
  orderType,
  firstTime,
  selected,
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!popUpIsOpen) {
      setIsOpen(false);
      if (!slot) {
      }
    }
  }, [popUpIsOpen]);

  return (
    <div className="filterBy">
      <div className="filterBy__item" onClick={() => setIsOpen(prev => !prev)}>
        <div
          className={classNames(
            'filterBy__item--title',
            'filterBy__item--selected',
          )}>
          <span>
            {(!orderType && firstTime) || (orderType === 1 && firstTime)
              ? obj_heb.filterBy
              : orderType === 3
              ? obj_heb.highToLow
              : orderType === 4
              ? obj_heb.lowToHigh
              : obj_heb.recommended}{' '}
          </span>
        </div>
        <img
          className={classNames('filterBy__item--arrow', isOpen && 'flip')}
          src={arrow_head_down}
          alt="location"
        />
      </div>
      {isOpen && (
        <FilterBySelector
          handleSelect={handleSelect}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          options={options}
          selected={selected}
        />
      )}
    </div>
  );
}

export default FilterBy;
