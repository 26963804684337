import React, {Fragment} from 'react';
import Modal from '../general/Modal';
import {icons} from '../../assets/constants';
import Button from '../Button';
import obj_heb from '../../utils/Obj_heb.json';

/**
 *
 * @param {number} index - the index of the iteration of the map
 * @param {*} multiOptions1ModalIsOpen - the state of the modal of option1
 * @param {*} multiOptions2ModalIsOpen - the state of the modal of option2
 * @param {*} setMultiOptions1ModalIsOpen - set the state of the modal of option1
 * @param {*} setMultiOptions2ModalIsOpen - set the state of the modal of option2
 * @param {*} closeCallback - function that will run after the modal is close
 * @param {*} dropDownOptions1 - the values of the modal options1
 * @param {*} dropDownOptions2 - the values of the modal options2
 * @param {*} productMainData - all of the product data
 * @param {string} title - the title of the modal
*  @param {string} defaultOption - the base value
 * @param {function} pickedOption = the function the run when choosing

 * @returns
 */
export default function ProductMultiOptionModal({
  index,
  multiOptions1ModalIsOpen,
  multiOptions2ModalIsOpen,
  setMultiOptions1ModalIsOpen,
  setMultiOptions2ModalIsOpen,
  closeCallback,
  dropDownOptions1,
  dropDownOptions2,
  productMainData,
  title,
  defaultOption,
  pickedOption,
}) {
  const modalOptions = index == 0 ? dropDownOptions1 : dropDownOptions2;
  return (
    <Modal
      isOpen={index == 0 ? multiOptions1ModalIsOpen : multiOptions2ModalIsOpen}
      setIsOpen={
        index == 0 ? setMultiOptions1ModalIsOpen : setMultiOptions2ModalIsOpen
      }
      // closeCallback={() => setDisplayAddToCart(true)}
      closeCallback={closeCallback}
      options={index == 0 ? dropDownOptions1 : dropDownOptions2}
      defaultOption={productMainData['option' + String(index + 1) + '_value']}>
      <>
        <div className="Product__content_first-box_product-Text-part_options-container_option-box--mobile__model__innerModal">
          <div className="Product__content_first-box_product-Text-part_options-container_option-box--mobile__model__innerModal__title">
            {title}
          </div>
          <div className="Product__content_first-box_product-Text-part_options-container_option-box--mobile__model__innerModal__body">
            {modalOptions?.map((option, index) => (
              <Fragment key={'dropdown-item-' + JSON.stringify(option)}>
                <div
                  className="Product__content_first-box_product-Text-part_options-container_option-box--mobile__model__innerModal__body--text"
                  key={index}
                  onClick={e => {
                    pickedOption(e.target.innerText);
                  }}
                  style={defaultOption === option ? {fontWeight: 700} : {}}>
                  {option}

                  <img
                    className="get-Product__content_first-box_product-Text-part_options-container_option-box--mobile__model__innerModal__checkIcon"
                    style={defaultOption !== option ? {opacity: '0'} : {}}
                    src={icons.yellow_check}
                    alt="yellow_check"
                    title="yellow_check"
                  />
                </div>
                {index < modalOptions?.length - 1 && <hr className="hr" />}
              </Fragment>
            ))}
            <div className="Product__content_first-box_product-Text-part_options-container_option-box--mobile__model__innerModal--btnDiv">
              <Button
                text={obj_heb.confirm}
                disable={false}
                onClick={closeCallback}
                textColor="white"
                backgroundColor={'#FFCA1A'}
                className="Product__content_first-box_product-Text-part_options-container_option-box--mobile__model__innerModal--btnDiv__btn"
              />
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
}
