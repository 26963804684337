import React, {useEffect, useMemo} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import OrderHistoryItem from './OrderHistoryItem';

export default function MyOrdersTab({
  orderData,
  currentPaginationPage,
  PageSize,
  onPageChange,
}) {
  const baseValue = orderData?.orders?.slice(0, 10);

  useEffect(() => {
    console.log(`page has changed`);
  }, [onPageChange]);

  return (
    <div className="MyOrdersTab">
      {typeof orderData?.orders?.length == 'number' &&
        orderData?.orders?.length === 0 && (
          <div className="MyOrdersTab_empty-list">
            <div className="MyOrdersTab_empty-list_text">
              <div className="MyOrdersTab_empty-list_text_title">
                {obj_heb.timeToMakeHistory}
              </div>
              <div className="MyOrdersTab_empty-list_text_sub-title">
                {obj_heb.hereWillShowenYourParches}
              </div>
            </div>
          </div>
        )}

      {orderData?.orders == undefined
        ? baseValue?.map(order => (
            <OrderHistoryItem key={order.order_id} item={order} />
          ))
        : orderData?.orders?.map(order => (
            <OrderHistoryItem key={order.order_id} item={order} />
          ))}
    </div>
  );
}
