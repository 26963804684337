import React from 'react';
import obj_heb from '../utils/Obj_heb.json';
import SEO from '../components/SEO';
import {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {fetchData} from '../utils/fetchData';

export default function EmaiVerification() {
  const {id} = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    let dataObj = {
      request: 'verify_email_code',
      verification_code: id,
    };
    fetchData(dataObj)
      .then(response => {
        if (response.message === 'success') {
          let loginObj = {
            request: 'get_user',
            token: response.user.token,
            user_id: response.user.id,
          };
          fetchData(loginObj)
            .then(response => {
              if (response.message === 'success') {
                localStorage.setItem('userData', JSON.stringify(response.user));
                navigate(`/`);
              }
            })
            .catch(error => {
              console.error('error', error);
              navigate(`/login`);
            });
        }
      })
      .catch(error => {
        console.error('error', error);
      });
  }, []);

  return (
    <React.Fragment>
      <SEO pageName={obj_heb.siteDesc} content={obj_heb.dibbleDesc} link="/" />
      <div className=" Home"></div>
    </React.Fragment>
  );
}
