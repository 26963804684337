import React, {useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import leftArrowHead from '../assets/icons/leftArrowHead.svg';
import SeeOffer from './SeeOffer';
export default function OrderHistoryMaterialItem({item}) {
  const [modalOfferOpen, setModalOfferOpen] = useState(false);
  const [offer, setOffer] = useState(null);
  const openOfferModal = offer => {
    setOffer(offer);
    setModalOfferOpen(true);
  };
  const getGenreText = m => {
    if (m == 2) {
      return obj_heb.iron;
    } else if (m == 3) {
      return obj_heb.quarry;
    } else if (m == 4) {
      return obj_heb.concrete;
    } else if (m == 5) {
      return obj_heb.rental;
    } else if (m == 6) {
      return obj_heb.fuel;
    } else if (m == 7) {
      return obj_heb.finishes;
    }
  };
  return (
    <>
      <div className="OrderHistoryMaterialItem__container">
        {item && item.bids > 0 ? (
          <div
            onClick={() => openOfferModal(item)}
            className={
              'OrderHistoryMaterialItem OrderHistoryMaterialItem_pointer'
            }>
            <div className="OrderHistoryMaterialItem_title">
              {obj_heb.offer +
                ' ' +
                getGenreText(item.material_type) +
                ' ' +
                obj_heb.orderWaitingForYou}
            </div>
            <div className="OrderHistoryMaterialItem_leftArrowBox">
              <img
                className="OrderHistoryMaterialItem_leftArrowBox_img"
                src={leftArrowHead}
                alt="leftArrowHead"
              />
            </div>
          </div>
        ) : (
          <div
            className={
              'OrderHistoryMaterialItem_waiting OrderHistoryMaterialItem_waiting_pointer'
            }>
            <div className="OrderHistoryMaterialItem_waiting_title--grey">
              {obj_heb.request +
                ' ' +
                getGenreText(item.material_type) +
                ' ' +
                obj_heb.sent}
            </div>
          </div>
        )}
        {modalOfferOpen && (
          <SeeOffer
            isOpen={modalOfferOpen}
            setIsOpen={setModalOfferOpen}
            offer={offer}
          />
        )}
      </div>
    </>
  );
}
