import React, {useState, useEffect, useRef} from 'react';
import {fetchData} from '../utils/fetchData';
import Title from './Title';
import {useSearchParams} from 'react-router-dom';
import FoundProduct from './FoundProduct';
import SEO from './SEO';
import Loader from '../components/Loader';
import FilterBy from '../components/FilterBy';
import obj_heb from '../utils/Obj_heb.json';

function CategoryProductsList() {
  const [productsList, setProductsList] = useState([]);
  const [products, setProducts] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [start_with_product_id, setStart_with_product_id] = useState(0);
  const [orderType, setOrderType] = useState(1);
  const endOfListRef = useRef();
  const [loadingMore, setLoadingMore] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData')),
  );

  const handleScroll = () => {
    const isBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight;
    if (isBottom) {
      if (productsList.length < products.total_roducts - 10) {
        findCategoryProducts(true, true);
      } else {
        setStart_with_product_id(0);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [start_with_product_id]);

  useEffect(() => {
    setStart_with_product_id(0);
    setProductsList([]);
  }, [searchParams]);

  useEffect(() => {
    let dataObj = {
      request: 'get_category',
      token: userData?.token || '',
      category_id: searchParams.get('id'),
    };
    fetchData(dataObj)
      .then(response => {
        const data = response;
        setCategoryDetails(data);
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  }, [searchParams, userData]);

  const findCategoryProducts = (filter = false, scrolled = false) => {
    if (loadingMore) return; // Return early if already loading more
    setLoadingMore(true);
    let dataObj = {
      request: 'get_category_products',
      token: userData?.token || '',
      category_id: Number(searchParams.get('id')),
      start_with_product_id: start_with_product_id,
      order_by: orderType,
    };
    fetchData(dataObj)
      .then(response => {
        setIsLoading(false);
        const data = response;
        if (data) {
          setProducts(data);
          if (!scrolled) {
            setProductsList(data.products);
          } else {
            const concatenatedProducts = [...productsList, ...data.products];
            setProductsList(concatenatedProducts);
          }
          setStart_with_product_id(response.next_product_id);

          setLoadingMore(false); // Reset loadingMore to false
        }
      })
      .catch(error => {
        console.error('Error:', error.message);
        setIsLoading(false);
        setLoadingMore(false); // Reset loadingMore to false in case of error
      });
  };
  const handleSelect = item => {
    setStart_with_product_id(0);
    setFirstTime(false);
    setOrderType(item.key);
  };

  useEffect(() => {
    if (categoryDetails) {
      if (orderType) {
        findCategoryProducts(true);
      }
    }
  }, [categoryDetails, userData, orderType]);
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : null}
      <SEO
        pageName={categoryDetails?.name || '_'}
        content={categoryDetails?.description}
        link="/category"
      />
      <div className="results">
        <Title title={categoryDetails?.name || ''} />
        {productsList && productsList.length ? (
          <div className="results__subHeader">
            <div className="results__subHeader__count">
              {products.total_roducts} {obj_heb.results}
            </div>

            <FilterBy
              options={[
                {key: 1, text: obj_heb.recommended},
                {key: 3, text: obj_heb.highToLow},
                {key: 4, text: obj_heb.lowToHigh},
              ]}
              handleSelect={handleSelect}
              selected={orderType}
              orderType={orderType}
              firstTime={firstTime}
              setFirstTime={setFirstTime}
            />
          </div>
        ) : null}

        <div className="results__container">
          {productsList &&
            productsList.map((product, index) => {
              return (
                <FoundProduct product={product} key={product.product_id} />
              );
            })}
          <div ref={endOfListRef} style={{height: '1px'}}></div>
        </div>
      </div>
      {loadingMore ? <Loader /> : null}{' '}
    </React.Fragment>
  );
}

export default CategoryProductsList;
