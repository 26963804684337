import React, {Fragment, useEffect, useState} from 'react';
import PopUp from '../components/general/PopUp';
import Button from './Button';
import Loader from '../components/Loader';
import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';
import SearchInput from './SearchInput';
import {useNavigate} from 'react-router-dom';
import SearchProjectUserItem from './SearchProjectUserItem';

function SearchProjectUserPopUp({
  isOpen,
  setIsOpen,
  projectId,
  isProjectManager = false,
  projectManagers = [],
  signaturePermitted = [],
  fetchAllData = {},
}) {
  const navigate = useNavigate();
  let userData = JSON.parse(localStorage.getItem('userData'));
  const [searchedText, setSearchedText] = useState('');
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUsersList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [amount, setAmount] = useState(null);
  const [itemChosen, setItemChosen] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      navigate('/login');
    }
    let dataObj = {
      request: 'get_contractor_users',
      token: userData?.token,
    };
    fetchData(dataObj)
      .then(response => {
        let users = response.users || [];

        if (isProjectManager) {
          users = users.filter(
            user =>
              !projectManagers.some(
                pm => pm.name === `${user.first_name} ${user.last_name}`,
              ),
          );
        } else {
          users = users.filter(
            user =>
              !signaturePermitted.some(
                sp => sp.name === `${user.first_name} ${user.last_name}`,
              ),
          );
        }

        setUsersList(users);
        setFilteredUsers(users);
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  }, []);

  const handleOnChange = value => {
    setSearchedText(value.replace(/^\s+/, ''));
    if (userList && userList.length > 0) {
      const filtered = userList.filter(user =>
        (user.first_name + ' ' + user.last_name)
          .toLowerCase()
          .includes(value.toLowerCase()),
      );
      setFilteredUsers(filtered);
    }
    setItemChosen(null); // Reset itemChosen when text changes
    setIsButtonDisabled(true); // Disable the button again
  };

  const handleEnterPress = e => {
    if (e.key === 'Enter') {
      console.log(`handleEnterPress`);
      // handleSearch();
    }
  };

  const handleItemClick = item => {
    const fullName = `${item.first_name} ${item.last_name}`;
    setSearchedText(fullName);
    setItemChosen(item);
    setIsButtonDisabled(false); // Enable the button when an item is chosen
  };

  const handleConfirm = async () => {
    const managers = isProjectManager
      ? [
          ...projectManagers.map(pm => ({contractor_user_id: pm.user_id})),
          {contractor_user_id: itemChosen?.contractor_user_id},
        ]
      : [
          ...signaturePermitted.map(pm => ({contractor_user_id: pm.user_id})),
          {contractor_user_id: itemChosen?.contractor_user_id},
        ];

    console.log(managers);

    setIsLoading(true);
    let dataObj = {
      request: isProjectManager
        ? 'set_project_managers'
        : 'set_project_sig_perms',
      token: userData?.token,
      project_id: projectId,
    };
    dataObj[isProjectManager ? 'project_managers' : 'signature_permitted'] =
      managers;
    fetchData(dataObj)
      .then(response => {
        if (response.message === 'success') {
          fetchAllData();
          setIsLoading(false);
          setIsOpen(false);
        }
      })
      .catch(error => {
        setIsOpen(false);
        setIsLoading(false);
        console.error('Error:', error.message);
      });
  };

  return (
    <>
      <PopUp isOpen={isOpen} setIsOpen={setIsOpen}>
        {isLoading ? (
          <div className="user-add__loading">
            <Loader />
            <span>{obj_heb.waitingForConfirmation}</span>
          </div>
        ) : (
          <div className="user-add">
            <span className="user-add__title">
              {isProjectManager
                ? obj_heb.addProjectManager
                : obj_heb.addSignManager}
            </span>

            {errors.length > 0 && (
              <div className="user-add__invalid-user">
                {errors.map((error, index) => (
                  <p key={index}>{error}</p>
                ))}
              </div>
            )}

            <div>
              <div>
                <div className="LobbyCreditsTab_part1_item-box"></div>
                <SearchInput
                  searchedText={searchedText}
                  setSearchedText={handleOnChange}
                  placeholderText={
                    isProjectManager
                      ? obj_heb.addProjectManagerPlaceHolder
                      : obj_heb.addSignPlaceHolder
                  }
                  onKeyDown={handleEnterPress}
                />
                {/* Hide the results list if an item is chosen */}
                {itemChosen === null && filteredUsers.length > 0 ? (
                  <div className="search-nav-bar__searched-items--list">
                    {filteredUsers.map((item, index) => (
                      <Fragment key={JSON.stringify(item.contractor_user_id)}>
                        <SearchProjectUserItem
                          item={item}
                          onItemClick={handleItemClick} // Pass the click handler to child
                        />
                        {index !== filteredUsers.length - 1 && (
                          <div className="search-nav-bar__searched-items--separator" />
                        )}
                      </Fragment>
                    ))}
                  </div>
                ) : (
                  itemChosen === null && (
                    <div className="search-nav-bar__searched-items--not-found">
                      {obj_heb.oops_project_managers}
                      <div>{obj_heb.oops_project_managers_info}</div>
                    </div>
                  )
                )}
              </div>
            </div>
            <Button
              text={
                isProjectManager
                  ? obj_heb.addProjectManager
                  : obj_heb.addSignManager
              }
              disable={isButtonDisabled} // Control the button state
              onClick={handleConfirm}
              textColor="white"
              backgroundColor={isButtonDisabled ? '#BDBCBC' : '#FFCA1A'}
              className="user-add__btn"
              id="user-add__btn"
            />
          </div>
        )}
      </PopUp>
    </>
  );
}

export default SearchProjectUserPopUp;
