import React from 'react';
import ResultsList from '../components/ResultsList';

export default function SearchResults() {
  return (
    <div>
      <ResultsList />
    </div>
  );
}
