import React, {useState} from 'react';
import classNames from 'classnames';

import PopUp from './general/PopUp';
import TextInput from './TextInput';
import Map from './Map';
import Button from './Button';
import GoogleSearch from './GoogleSearch';

import {useDispatch, useSelector} from 'react-redux';
import {
  setSelectedAddress,
  fetchAddressesAndSetInitialList,
} from '../redux/store';
import {fetchData} from '../utils/fetchData';
import obj_heb from '../utils/Obj_heb.json';

function AddAddress({
  isOpen,
  setIsOpen,
  setFinish,
  setFinishText,
  defultState = null,
  callback = null,
  apiName = null,
  fullAddressProp = null,
}) {
  const dispatch = useDispatch();
  const [address, setAddress] = useState({
    address: defultState?.address || '',
    lat: defultState?.lat || '',
    lon: defultState?.lon || '',
    title: defultState?.title || '',
    floor: defultState?.floor || '',
    appartment: defultState?.appartment || '',
    type: defultState?.type || '1',
    notes: defultState?.notes || '',
    color: defultState?.color || '',
  });

  const handleSubmit = () => {
    let data = localStorage.getItem('userData');
    data = JSON.parse(data);
    let dataObj;
    if (address.address) {
      if (apiName != null) {
        if (apiName === 'delete_address') {
          dataObj = {
            request: apiName,
            token: data.token,
            fullAddressProp: fullAddressProp?.id,
          };
        } else if (apiName === 'update_address') {
          dataObj = {
            request: apiName,
            token: data.token,
            address_id: fullAddressProp?.id,
            title: address.title,
            address: address.address,
            floor: address.floor,
            appartment: address.appartment,
            type: address.type,
            notes: address.notes,
            lat: address.lat,
            lon: address.lon,
            color: address.color,
          };
        }
      } else {
        dataObj = {
          request: 'add_address',
          token: data.token,
          ...address,
        };
      }

      fetchData(dataObj)
        .then(response => {
          dispatch(setSelectedAddress({...address, id: response.address_id}));
          dispatch(fetchAddressesAndSetInitialList());
          setIsOpen(false);
          setFinishText && setFinishText(obj_heb.addressSave);
          setFinish(true);
          setTimeout(() => {
            setFinish(false);
          }, 7800);
        })
        .catch(error => {
          console.error(apiName ? apiName : 'add_address', error);
        });
    }
  };

  const AddTitle = title => {
    setAddress(prev => ({...prev, title}));
  };

  const AddFloor = floor => {
    setAddress(prev => ({...prev, floor}));
  };

  const resetAddress = () => {
    setAddress({
      address: '',
      lat: '',
      lon: '',
      title: '',
      floor: '',
      appartment: '',
      type: '1',
      notes: '',
      color: '',
    });
  };

  return (
    <PopUp isOpen={isOpen} setIsOpen={setIsOpen} closeCallback={callback}>
      <div className="add-address">
        <span className="add-address__title">{obj_heb.addAddress}</span>
        <GoogleSearch
          address={address}
          setAddress={setAddress}
          resetAddress={resetAddress}
        />
        <div className="add-address__extra-details">
          <span className="add-address__extra-details--title">
            {obj_heb.otherDetailsOptional}
          </span>

          <TextInput
            description={obj_heb.nameOfTheAddress}
            placeholderText={obj_heb.nameOfTheAddressExample}
            onBlur={e => {
              AddTitle(e?.target?.value);
            }}
          />
          <TextInput
            description={obj_heb.floorApartmentEntry}
            placeholderText={obj_heb.floorApartmentEntryExample}
            onBlur={e => {
              AddFloor(e?.target?.value);
            }}
          />
        </div>
        <Map address={address} setAddress={setAddress} />
        <Button
          text={
            address.address
              ? obj_heb.saveAddress
              : obj_heb.selectAddressToDelivery
          }
          disable={address.address ? false : true}
          onClick={handleSubmit}
          textColor="white"
          backgroundColor={address.address ? '#FFCA1A' : '#BDBCBC'}
          className="add-address__btn"
        />
      </div>
    </PopUp>
  );
}

export default AddAddress;
