import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import PopUp from '../components/general/PopUp';
import TextInput from './TextInput';
import Button from './Button';
import Loader from '../components/Loader';
import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';
import {icons} from '../assets/constants';

function DepositWithrowPopUp({
  isOpen,
  setIsOpen,
  isPush = false,
  dibbleCoinsCredit,
  myProjectsBalance,
  projectId,
}) {
  let userData = JSON.parse(localStorage.getItem('userData'));

  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(null);
  const [pullValidation, setPullValidation] = useState(false);
  const [pushValidation, setPushValidation] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    let dataObj = {
      request: isPush ? 'dibblecoin_assign_budget' : 'dibblecoin_revoke_budget',
      token: userData?.token,
      amount,
      project_id: projectId,
    };
    fetchData(dataObj)
      .then(response => {
        if (response.message === 'success') {
          setIsLoading(false);
          setIsOpen(false);
        }
      })
      .catch(error => {
        setIsOpen(false);
        setIsLoading(false);
        console.error('Error:', error.message);
      });
  };

  const handleNumberInput = (e, field) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
      if (field === 'push') {
        if (value > myProjectsBalance.available_credit) {
          setPushValidation(true);
        } else {
          setPushValidation(false);
        }
      } else {
        if (value > dibbleCoinsCredit) {
          setPullValidation(true);
        } else {
          setPullValidation(false);
        }
      }
    }
  };

  return (
    <>
      <PopUp isOpen={isOpen} setIsOpen={setIsOpen}>
        {isLoading ? (
          <div className="user-add__loading">
            <Loader />
            <span>{obj_heb.waitingForConfirmation}</span>
          </div>
        ) : (
          <div className="user-add">
            <span className="user-add__title">
              {isPush ? obj_heb.push : obj_heb.pull}
            </span>

            {errors.length > 0 && (
              <div className="user-add__invalid-user">
                {errors.map((error, index) => (
                  <p key={index}>{error}</p>
                ))}
              </div>
            )}

            <div>
              {isPush && (
                <div>
                  <div
                    style={{marginBottom: '1.8rem'}}
                    className="LobbyCreditsTab_part1_item-box">
                    <div className="LobbyCreditsTab_part1_item-box_text-box">
                      <div className="LobbyCreditsTab_part1_item-box_text-box_title">
                        {obj_heb.dibbleCoinsLeft}
                      </div>
                    </div>
                    <div className="LobbyCreditsTab_part1_item-box_icon-box">
                      <div className="LobbyCreditsTab_part1_item-box_icon-box_txt">
                        {myProjectsBalance.available_credit}
                      </div>
                      <img
                        src={icons.x_icon}
                        className="LobbyCreditsTab_part1_item-box_icon-boxx-icone"
                        alt="x_icon"
                      />
                      <img
                        src={icons.dibbleCoinIcon}
                        className="LobbyCreditsTab_part1_item-box_icon-box_dibbl-icon"
                        alt="dibbleCoinIcon"
                      />
                    </div>
                  </div>
                  <TextInput
                    description={obj_heb.push_ammount}
                    placeholderText={obj_heb.pushPlaceHolder}
                    onChange={e => handleNumberInput(e, 'push')}
                    value={amount}
                    errorMsg="לא ניתן להפקיד יותר מהיתרה בחשבון"
                    IsError={pushValidation}
                    type="tel"
                  />
                </div>
              )}
              {!isPush && (
                <div>
                  <div
                    style={{marginBottom: '1.8rem'}}
                    className="LobbyCreditsTab_part1_item-box">
                    <div className="LobbyCreditsTab_part1_item-box_text-box">
                      <div className="LobbyCreditsTab_part1_item-box_text-box_title">
                        {obj_heb.dibbleCoinsLeftProject}
                      </div>
                    </div>
                    <div className="LobbyCreditsTab_part1_item-box_icon-box">
                      <div className="LobbyCreditsTab_part1_item-box_icon-box_txt">
                        {dibbleCoinsCredit.toFixed(2)}
                      </div>
                      <img
                        src={icons.x_icon}
                        className="LobbyCreditsTab_part1_item-box_icon-boxx-icone"
                        alt="x_icon"
                      />
                      <img
                        src={icons.dibbleCoinIcon}
                        className="LobbyCreditsTab_part1_item-box_icon-box_dibbl-icon"
                        alt="dibbleCoinIcon"
                      />
                    </div>
                  </div>
                  <TextInput
                    description={obj_heb.pull_ammount}
                    placeholderText={obj_heb.pullPlaceHolder}
                    onChange={e => handleNumberInput(e, 'pull')}
                    value={amount}
                    errorMsg="לא ניתן למשוך יותר מהיתרה בחשבון"
                    IsError={pullValidation}
                    type="tel"
                  />
                </div>
              )}
            </div>

            <Button
              text={
                pushValidation || pullValidation
                  ? `לא ניתן ${
                      isPush ? obj_heb.toPush : obj_heb.toPull
                    } יותר מהיתרה`
                  : isPush
                  ? obj_heb.approve
                  : obj_heb.approve
              }
              disable={pushValidation || pullValidation}
              onClick={handleConfirm}
              textColor="white"
              backgroundColor={
                pushValidation || pullValidation ? '#BDBCBC' : '#FFCA1A'
              }
              className="user-add__btn"
              id="user-add__btn"
            />
          </div>
        )}
      </PopUp>
    </>
  );
}

export default DepositWithrowPopUp;
