import React, {useState} from 'react';
import PopUp from './general/PopUp';
import Button from './Button';
import obj_heb from '../utils/Obj_heb.json';
import {icons} from '../assets/constants';
import DropDown from '../components/general/DropDown';
import {fetchData} from '../utils/fetchData';
import FullScreenModal from './general/FullScreenModal';
import {useSelector} from 'react-redux';
import Title from './Title';
import Modal from './general/Modal';
import classNames from 'classnames';
import GetDibbleCoinsModalChildren from './GetDibbleCoinsModalChildren';

const GetDibbleCoinModal = ({
  isOpen,
  setIsOpen,
  closeCallback,
  setIsFinish,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const creditValues = [
    '10,000 - 0 ש״ח',
    '20,000 - 10,000 ש״ח',
    '30,000 - 20,000 ש״ח',
    '40,000 - 30,000 ש״ח',
    '50,000 - 40,000 ש״ח',
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeBalanceReq, setActiveBalanceReq] = useState(null);
  const isMobile = useSelector(state => state.mobile.isMobile);

  const sendEmail = () => {
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);

    const message = `${obj_heb.creditLineEmailMessage1} <br>  ${
      obj_heb.creditLineEmailMessage2
    }${selectedOption} <br>  ${obj_heb.creditLineEmailMessage3}${
      userData.first_name
    }${' '}${userData.last_name} <br>  ${obj_heb.creditLineEmailMessage4} ${
      userData.phone
    } <br><br> <br>   ${obj_heb.creditLineEmailMessage5}`;

    const data = {
      request: 'send_mail',
      to_email: 'oded@dibble.co.il',
      from_email: 'office@dibble.co.il',
      from_name: userData.first_name + ' ' + userData.last_name,
      subject: obj_heb.creditLineEmailSubject,
      message: message,
    };
    fetchData(data)
      .then(response => {
        setIsOpen(perv => !perv);
        setIsFinish(true);
        setTimeout(() => {
          setIsFinish(false);
        }, 7800);
      })
      .catch(error => {
        console.error('send_mail', error);
      });
  };

  return isOpen ? (
    isMobile ? (
      <FullScreenModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        closeCallback={closeCallback}
        bottomElement={
          <div className="get-dibble-coins-modal__btnDiv">
            <Button
              text={selectedOption ? obj_heb.confirm : obj_heb.chooseCreditLine}
              disable={selectedOption ? false : true}
              onClick={() => sendEmail()}
              textColor="white"
              backgroundColor={selectedOption ? '#FFCA1A' : '#BDBCBC'}
              className="get-dibble-coins-modal__btnDiv__btn"
            />
            <div
              className="get-dibble-coins-modal__btnDiv__subBtn"
              onClick={closeCallback}>
              {obj_heb.notNow}
            </div>
          </div>
        }>
        <div className="get-dibble-coins-modal">
          <div className="">
            <img
              src={icons.dibble_person}
              alt="get dibble coins"
              className="get-dibble-coins-modal__img"
            />
          </div>
          <div className="get-dibble-coins-modal__bigger">
            <div className="get-dibble-coins-modal__title">
              <Title center={true} title={obj_heb.getDibbleCoinsModalTitle} />
            </div>
            <div className="get-dibble-coins-modal__content">
              {obj_heb.getDibbleCoinsModalContent}
              <br />
              {obj_heb.getDibbleCoinsModalContent2}
            </div>
          </div>

          <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
            <GetDibbleCoinsModalChildren
              creditValues={creditValues}
              activeBalanceReq={activeBalanceReq}
              setActiveBalanceReq={setActiveBalanceReq}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setIsModalOpen={setIsModalOpen}
            />
          </Modal>

          <div
            className={'get-dibble-coins-modal_dropDown'}
            style={{width: '100%'}}>
            <div
              className="dropdown-toggle"
              onClick={() => setIsModalOpen(true)}>
              <React.Fragment>
                <div className=""></div>
                <div className="get-dibble-coins-modal_dropDown__dropdownContainer">
                  <div className="dropdown__container__title">
                    {obj_heb.creditLine}
                  </div>
                  <div
                    className="dropdown__container__content"
                    style={selectedOption ? {color: 'var(--black)'} : {}}>
                    {selectedOption ? selectedOption : obj_heb.chooseValue}
                  </div>
                </div>
                <img src={icons.arrow_head_left} alt="arrow_head_down" />
              </React.Fragment>
            </div>
          </div>
        </div>
      </FullScreenModal>
    ) : (
      <PopUp
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        closeCallback={closeCallback}>
        <div className="get-dibble-coins-modal">
          <div>
            <img
              src={icons.dibble_person}
              alt="get dibble coins"
              className="get-dibble-coins-modal__img"
            />
          </div>
          <div>
            <div className="get-dibble-coins-modal__title">
              {obj_heb.getDibbleCoinsModalTitle}
            </div>
            <div className="get-dibble-coins-modal__content">
              {obj_heb.getDibbleCoinsModalContent}
              <br />
              {obj_heb.getDibbleCoinsModalContent2}
            </div>
          </div>

          <DropDown
            key={String(Math.random)}
            options={creditValues}
            title={obj_heb.creditLine}
            placeHolder={obj_heb.chooseValue}
            height={5.6}
            width={100}
            dropDownWithTitle
            onSelect={selected => {
              setSelectedOption(selected);
            }}
            arrowHeadToLeft={true}
            outSideClickClosing={true}
          />
        </div>
        <Button
          text={selectedOption ? obj_heb.confirm : obj_heb.chooseCreditLine}
          disable={selectedOption ? false : true}
          onClick={() => sendEmail()}
          textColor="white"
          backgroundColor={selectedOption ? '#FFCA1A' : '#BDBCBC'}
          className="get-dibble-coins-modal__btn"
        />
      </PopUp>
    )
  ) : (
    ''
  );
};

export default GetDibbleCoinModal;
