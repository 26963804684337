import classNames from 'classnames';
import React from 'react';

function MyFilterItem({item, onClick, setIsOpen, isOpen, isSelected}) {
  const action = item => {
    setIsOpen(!isOpen);
    onClick(item);
  };
  return (
    <div
      className="days-item"
      style={{background: 'var(--white)'}}
      onClick={() => action(item)}>
      <span className="is-hover" />
      <div
        className={classNames(
          'days-item__content',
          isSelected && 'days-item__content--selected',
        )}
        style={{justifyContent: 'flex-start'}}>
        {item.text}
      </div>
    </div>
  );
}

export default MyFilterItem;
