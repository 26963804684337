export const apiUrl = {
  development: 'http://dev.dibble.co.il:8089/backend/api/',
  test: 'https://test.dibble.co.il/backend/api/',
  qa: 'https://qa.dibble.co.il/backend/api/',
  production: 'https://api.dibble.co.il/backend/api/',
};
export const rc_success = '0';
export const rc_token_expire = '201';
export const rc_no_active_order = '514';
export const instagram = 'https://www.instagram.com/dibble_il/';
export const tiktok = 'https://www.tiktok.com/@dibble_il';
export const facebook = 'https://www.facebook.com/dibble.co.il';
export const googleLocationApiKey = 'AIzaSyDqFz3xZgAv-jjuKMW3bCHwPBKNubRjlxY';
export const payMe = {
  development: {
    sellerKey: 'MPL16583-08261DUO-VKCQHJF2-MH6G4XZR',
    endpoint: 'https://sandbox.payme.io/api/capture-buyer-token',
  },
  test: {
    sellerKey: 'MPL16583-08261DUO-VKCQHJF2-MH6G4XZR',
    endpoint: 'https://sandbox.payme.io/api/capture-buyer-token',
  },
  qa: {
    sellerKey: 'MPL16583-08261DUO-VKCQHJF2-MH6G4XZR',
    endpoint: 'https://sandbox.payme.io/api/capture-buyer-token',
  },
  production: {
    sellerKey: 'MPL16583-02794FVY-SI6PYBRS-VG1W4BUA',
    endpoint: 'https://live.payme.io/api/capture-buyer-token',
  },
};
export const payMeSuccess = 200;
export const INTERCOM_APP_ID = 'vv35glo7';
export const appstore =
  'https://apps.apple.com/us/app/dibble-%D7%97%D7%A0%D7%95%D7%AA-%D7%97%D7%95%D7%9E%D7%A8%D7%99-%D7%91%D7%A0%D7%99%D7%99%D7%9F/id1521796518';
export const googleplay =
  'https://play.google.com/store/apps/details?id=com.dibble.client&hl=en&gl=US';
export const rc_applicheck_underwriting_error = '553';
export const rc_approved_dibble_coins_underwriting = '600';
export const rc_address_error = '582';
export const rc_payme_api_error = '564';
export const rc_date_error = '660';
export const rc_credit_card_error = '542';
export const rc_no_address_error = '551';
export const rc_no_product_error = '512';
export const rc_no_project_balance = '642';

export const GooglePlay =
  'https://play.google.com/store/apps/details?id=com.dibble.client&hl=en&gl=US';
export const AppStore =
  'https://apps.apple.com/us/app/dibble-%D7%97%D7%A0%D7%95%D7%AA-%D7%97%D7%95%D7%9E%D7%A8%D7%99-%D7%91%D7%A0%D7%99%D7%99%D7%9F/id1521796518';
export const app_version_test = '0.7.18.14';
export const fireBaseConfig = {
  apiKey: 'AIzaSyB_89IobsZGc_wfVPwwiJEXXTcxtNJyB44',
  authDomain: 'dibble-app.firebaseapp.com',
  databaseURL: 'https://dibble-app.firebaseio.com',
  projectId: 'dibble-app',
  storageBucket: 'dibble-app.appspot.com',
  messagingSenderId: '252115356937',
  appId: '1:252115356937:web:64b4b075439499c10697d8',
  measurementId: 'G-6KJE7JSN07',
};
export const courierRealtimedbName = {
  development: 'courier-debug',
  test: 'courier-test',
  qa: 'courier-qa',
  production: 'courier',
};
