import {
  Route,
  Routes,
  useLocation,
  BrowserRouter,
  Navigate,
} from 'react-router-dom';
import {INTERCOM_APP_ID} from './utils/baseValues';
import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Layout from './layout/Layout';
import LayoutCart from './layout/LayoutCart';
import ReactPixel from 'react-facebook-pixel';

//components
import Login from './pages/Login';
import Home from './pages/Home';
import ProjectPage from './pages/ProjectPage';
import Cart from './pages/Cart';
import Product from './pages/Product';
import EmaiVerification from './pages/EmaiVerification';
import Terms from './pages/Terms';
import About from './pages/About';
import Articles from './pages/Articles';
import Privacy from './pages/Privacy';
import SearchResults from './pages/SearchResults';
import Category from './pages/Category';
import Categories from './pages/Categories';
import IntercomWidget from './components/Intercom';
import CreditCards from './pages/CreditCards';
import Summary from './pages/Summary';
import Success from './pages/Success';
import MaterialOrderOfferSent from './pages/MaterialOrderOfferSent';
import {ShoppingCart_setProducts} from './redux/slices/ShoppingCartSlice';
import {initialMobile} from './redux/store';
import Materials from './pages/Materials';
import PersonalArea from './pages/PersonalArea';
import Lobby from './pages/Lobby';
import MyAddress from './pages/MyAddress';
import OrderHistorySummary from './pages/OrderHistorySummary';
import AppModal from './components/AppModal';
import Departments from './pages/Departments';
import {updateAccessibilityState} from './redux/slices/accessibilitySlice';
import AllOrders from './pages/AllOrders';
import LikedProducts from './components/LikedProducts';
import JoinUsContractors from './pages/JoinUsContractors';
import JoinUsSupplier from './pages/JoinUsSupplier';
import AddProjectPage from './pages/AddProjectPage';
import LobbyRegister from './pages/LobbyRegister';

function ScrollToTop() {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function CheckIfMobile() {
  const {pathname} = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialMobile());
  }, [pathname]);

  return null;
}

function TrackPageView() {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.pageView(); // Track page view on route change
  }, [location]);

  return null;
}

function App() {
  const dispatch = useDispatch();
  const accessibilityState = useSelector(
    state => state.accessibility.accessibilityState,
  );
  const isMobile = useSelector(state => state.mobile.isMobile);

  //////////////////////////////////////////////////////// for mobile
  useEffect(() => {
    const checkScreenWidth = () => {
      dispatch(initialMobile());
    };

    // Initial check
    checkScreenWidth();

    // Add event listener for resize
    window.addEventListener('resize', checkScreenWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  //////////////////////////////////////////////////////// shopping cart
  useEffect(() => {
    const localShoppingCartStoredData = localStorage.getItem('shoppingCart');
    if (
      localShoppingCartStoredData == null ||
      localShoppingCartStoredData == undefined
    ) {
      localStorage.setItem('shoppingCart', []);
    }
  }, []);

  useEffect(() => {
    const localShoppingCartStoredData = localStorage.getItem('shoppingCart');
    if (localShoppingCartStoredData?.length != 0) {
      dispatch(
        ShoppingCart_setProducts(JSON.parse(localShoppingCartStoredData)),
      );
    }
  }, []);

  //////////////////////////////////////////////////////// accessibility
  useEffect(() => {
    const localAccessibilityState = localStorage.getItem('accessibility');
    if (
      localAccessibilityState === 'true' ||
      localAccessibilityState === true
    ) {
      dispatch(updateAccessibilityState(true));
    } else {
      dispatch(updateAccessibilityState(false));
    }
  }, []);

  return (
    <div
      className="app"
      data-theme={accessibilityState ? 'accessibility' : null}>
      <>
        <IntercomWidget appId={INTERCOM_APP_ID} />
        <BrowserRouter>
          <ScrollToTop />
          <CheckIfMobile />
          <TrackPageView />

          <Routes>
            <Route path="*" element={<Navigate to="/" replace />} />

            <Route path="/login" element={<Login />} />
            <Route path="/Join_us_con" element={<JoinUsContractors />} />
            <Route path="/Join_us_sup" element={<JoinUsSupplier />} />
            <Route path="/success" element={<Success />} />
            <Route
              path="/material-order-offer-sent"
              element={<MaterialOrderOfferSent />}
            />
            <Route
              path="/project-details/:project_id"
              element={
                <Layout cancelFooterScreeningOnMobile hideDibbleIcon>
                  <ProjectPage />
                </Layout>
              }
            />
            <Route
              path="/add-project/:project_id?"
              element={
                <Layout cancelFooterScreeningOnMobile hideDibbleIcon>
                  <AddProjectPage />
                </Layout>
              }
            />
            <Route
              path="/categories"
              element={
                <LayoutCart cancelFooterOnMobile navBarBack needAddress={false}>
                  <Categories />
                </LayoutCart>
              }
            />
            <Route
              path="/category"
              element={
                <LayoutCart cancelFooterOnMobile navBarBack needAddress={false}>
                  <Category />
                </LayoutCart>
              }
            />
            <Route
              path="/search_results"
              element={
                <LayoutCart cancelFooterOnMobile navBarBack needAddress={false}>
                  <SearchResults />
                </LayoutCart>
              }
            />
            <Route
              path="/cart"
              element={
                <Layout cancelFooterScreeningOnMobile hideDibbleIcon>
                  <Cart />c{' '}
                </Layout>
              }
            />
            <Route
              path="/summary"
              element={
                <Layout cancelFooterOnMobile navBarBack>
                  <Summary />
                </Layout>
              }
            />
            <Route
              path="/about"
              element={
                <Layout cancelFooterOnMobile>
                  <About />
                </Layout>
              }
            />
            <Route
              path="/terms"
              element={
                <Layout cancelFooterOnMobile>
                  <Terms />
                </Layout>
              }
            />
            <Route
              path="/articles"
              element={
                <Layout cancelFooterOnMobile>
                  <Articles />
                </Layout>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <Layout>
                  <Privacy />
                </Layout>
              }
            />
            <Route
              path="/credit-cards"
              element={
                <LayoutCart cancelFooterOnMobile navBarBack needAddress={false}>
                  <CreditCards />
                </LayoutCart>
              }
            />
            <Route
              path="/my-address"
              element={
                <LayoutCart cancelFooterOnMobile navBarBack needAddress={false}>
                  <MyAddress />
                </LayoutCart>
              }
            />
            <Route
              path="/personal-area"
              element={
                <LayoutCart needAddress={false} hideDibbleIcon={isMobile}>
                  <PersonalArea />
                </LayoutCart>
              }
            />
            <Route
              path="/lobby"
              element={
                <Layout needAddress={false} hideDibbleIcon={isMobile}>
                  <Lobby />
                </Layout>
              }
            />
            <Route
              path="/lobby-register"
              element={
                <Layout cancelFooterScreeningOnMobile hideDibbleIcon={isMobile}>
                  <LobbyRegister />
                </Layout>
              }
            />
            <Route
              path="/materials"
              element={
                <LayoutCart needAddress={false} hideDibbleIcon={isMobile}>
                  <Materials />
                </LayoutCart>
              }
            />
            <Route
              path="/OrderHistorySummary/:orderId"
              element={
                <LayoutCart cancelFooterOnMobile navBarBack needAddress={false}>
                  <OrderHistorySummary />
                </LayoutCart>
              }
            />
            <Route
              path="/product/:product_id"
              element={
                <LayoutCart
                  cancelFooterOnMobile
                  navBarBack
                  needAddress={false}
                  navBarCart>
                  <Product />
                </LayoutCart>
              }
            />
            <Route
              path="/app/verification/:id"
              element={
                <Layout>
                  <EmaiVerification />
                </Layout>
              }
            />
            <Route
              path="/departments"
              element={
                <LayoutCart needAddress={false}>
                  <Departments />
                </LayoutCart>
              }
            />
            <Route
              path="/AllOrders"
              element={
                <Layout needAddress={false} cancelFooterOnMobile navBarBack>
                  <AllOrders />
                </Layout>
              }
            />

            <Route
              path="/likedProducts"
              element={
                <LayoutCart>
                  <LikedProducts />
                </LayoutCart>
              }
            />

            <Route
              path="/"
              element={
                <LayoutCart>
                  <Home />
                </LayoutCart>
              }
            />
          </Routes>
        </BrowserRouter>
      </>
    </div>
  );
}

export default App;
