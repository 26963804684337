import Chart from 'chart.js/auto';
import Obj_heb from '../../utils/Obj_heb.json';
import React, {useEffect, useRef} from 'react';

export default function ProjectPie({prasentageUsed = 0}) {
  const chartRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    // Destroy existing chart instance before creating a new one
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = canvasRef.current.getContext('2d');

    let combine_lable_data = [
      {
        color: 'rgb(255,241,191)',
        data: prasentageUsed,
      },
      {
        color: 'rgb(189,188,188)',
        data: 100 - prasentageUsed,
      },
    ];

    const data = {
      labels: combine_lable_data.map((data, index) => `Label ${index + 1}`),
      datasets: [
        {
          label: '%',
          data: combine_lable_data.map(data => data.data),
          backgroundColor: combine_lable_data.map(data => data.color),
          hoverOffset: 4,
        },
      ],
    };

    const innerText = {
      id: 'projects_pie_chart',
      beforeDatasetsDraw: (chart, args, pluginOptions) => {
        const {ctx, data} = chart;
        ctx.save();
        const xcoor = chart.getDatasetMeta(0).data[0].x;
        const ycoor = chart.getDatasetMeta(0).data[0].y - 20;
        ctx.font = '16px AlmoniDlAAA';
        ctx.fillStyle = 'rgb(112,112,112)';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${Obj_heb.youUsed}`, xcoor, ycoor);
        ctx.font = '22px OscarFm';
        ctx.fillStyle = 'rgb(112,112,112)';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${prasentageUsed}%`, xcoor, ycoor + 22);
        ctx.font = '16px AlmoniDlAAA';
        ctx.fillStyle = 'rgb(112,112,112)';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${Obj_heb.from_budget}`, xcoor, ycoor + 42);
      },
    };

    chartRef.current = new Chart(ctx, {
      type: 'doughnut',
      data: data,
      plugins: [innerText],
      options: {
        responsive: true,
        cutout: 45,
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    });

    return () => {
      // Cleanup the chart instance on unmount
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [prasentageUsed]);

  return (
    <div style={{width: '140px', height: '140px'}}>
      <div className="doughnut_chart">
        <canvas ref={canvasRef} id="projects_pie_chart"></canvas>
      </div>
    </div>
  );
}
