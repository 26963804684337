import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';
import {numberWithCommas} from '../utils/supportFunctions';
import {icons} from '../assets/constants';
import {
  rc_address_error,
  rc_applicheck_underwriting_error,
  rc_approved_dibble_coins_underwriting,
  rc_date_error,
  rc_payme_api_error,
  rc_credit_card_error,
  rc_no_address_error,
  rc_no_project_balance,
} from '../utils/baseValues';
import {useIntercomContext} from '../utils/IntercomContext';
import {ShoppingCart_clearAllProducts} from '../redux/slices/ShoppingCartSlice';
import {updateActiveOrdersCount} from '../redux/slices/activeOrdersSlice';
import Button from './Button';
import PopUp from '../components/general/PopUp';
import PopUpConfirm from '../components/PopUpConfirm';
import ShoppingCartItem from './shoppingCart/ShoppingCartItem';
import PopUpValidations from './general/popUpsValidations';
import classNames from 'classnames';

function SummaryComponent({
  action,
  ready,
  isNeedCrane,
  deliveryType,
  setIsLoading,
  payment_type,
  bidPrice,
  bidDeliveryCost,
  orderId,
  bidId,
  creditcard_id,
  installments,
  canPayWithDibbleCoins,
  requestedDeliveryTime,
  slot,
  ...props
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {openIntercom} = useIntercomContext();
  const [vat, setVat] = useState(0);
  const [deliveryCost, setDeliveryCost] = useState(0);
  const [shoppingCartWeight, setShoppingCartWeight] = useState(0);

  const [isValidCart, setIsValidCart] = useState({
    isChanged: false,
    isChecked: false,
    isReady: false,
  });

  const [creditCardErrorIsOpen, setCreditCardErrorIsOpen] = useState(false);
  const [addressErrorIsOpen, setAddressErrorIsOpen] = useState(false);
  const [selectedDateErrorIsOpen, setSelectedDateErrorIsOpen] = useState(false);
  const [productsError, setProductsError] = useState(null);
  const [projectBalanceErrorIsOpen, setProjectBalanceErrorIsOpen] =
    useState(false);

  const userData = JSON.parse(localStorage.getItem('userData'));
  const convertStringToNumber = input => {
    const number = parseFloat(input);
    const convertedNumber = number / 100 + 1;
    return convertedNumber;
  };
  const shoppingCartProducts = useSelector(
    state => state.shopping_cart.products,
  );

  const selectedAddress = useSelector(
    state => state.addressSlice.selectedAddress,
  );

  const activeOrders = useSelector(
    state => state.activeOrders.activeOrdersCount,
  );

  const isMobile = useSelector(state => state.mobile.isMobile);

  useEffect(() => {
    let dataObj = {
      request: 'get_vat_rate',
    };
    if (action !== 'cart' && slot) {
      const date = slot.split(' ')[0].split('.').reverse().join('-');
      const output = `${date} 00:00:00`;
      dataObj.valid_from = output;
    }
    fetchData(dataObj).then(response => {
      if (response?.message === 'success')
        setVat(convertStringToNumber(response.vat_rate.value));
    });
  }, [action, slot]); // The effect runs when 'action' changes

  ////////////////////////////////////////////////////////  cart weight
  // get the cart weight
  useEffect(() => {
    const weight = shoppingCartProducts
      .map(item => item.weight * item.quantity)
      .reduce((partialSum, a) => partialSum + a, 0);

    setShoppingCartWeight(weight);
  }, [shoppingCartProducts]);

  //   create  the cart weight text to show
  const cartWeight = () => {
    let weightText = '';
    let weight = shoppingCartWeight;

    if (weight >= 1000000) {
      weight = weight / 1000000;
      if (Number(String(weight.toString().split('.').length) > 1)) {
        if (String(weight.toString().split('.')[1]).length > 2) {
          weight = Number((Number(weight) + 0.01).toFixed(2));
        }
      }
      weightText = `${weight} ${obj_heb.ton} `;
    } else if (weight >= 1000) {
      weight = weight / 1000;

      if (Number(String(weight.toString().split('.').length) > 1)) {
        if (String(weight.toString().split('.')[1]).length > 2) {
          weight = Number((Number(weight) + 0.01).toFixed(2));
        }
      }

      weightText = `${weight} ${obj_heb.kilo}`;
    } else if (weight < 1000) {
      if (Number(String(weight.toString().split('.').length) > 1)) {
        if (String(weight.toString().split('.')[1]).length > 2) {
          weight = Number(Number(weight) + (0.01).toFixed(2));
        }
      }
      weightText = `${weight} ${obj_heb.gram}`;
    }
    if (String(weight).length > 3) {
      weight = weight.toFixed(2);
    }

    return weightText;
  };

  ////////////////////////////////////////////////////////  cart price
  const shoppingCartPrice = () => {
    return Number(
      shoppingCartProducts
        .map(item => {
          if (item?.sale) {
            return item.sale_price * item.quantity;
          } else {
            return item.price * item.quantity;
          }
        })
        .reduce((partialSum, a) => partialSum + a, 0),
    );
  };

  ////////////////////////////////////////////////////////  delivery cost
  useEffect(() => {
    if (selectedAddress) {
      console.log(`_______________________________________`);
      if (userData && userData?.token) {
        let dataObj = {
          request: 'get_delivery_cost',
          token: userData.token,
          destination_lat: selectedAddress?.lat,
          destination_lon: selectedAddress?.lon,
          need_crane: isNeedCrane,
          products: shoppingCartProducts.map(item => ({
            ...item,
            amount: item.quantity,
          })),
          order_type: deliveryType,
        };
        fetchData(dataObj).then(response => {
          if (shoppingCartWeight >= 15000000) {
            setDeliveryCost(300); //frontend fix
          } else {
            setDeliveryCost(response?.delivery_cost);
          }
        });
      }
    } else {
      setDeliveryCost(0);
    }
  }, [selectedAddress, shoppingCartProducts, deliveryType]);

  ////////////////////////////////////////////////////////  place order
  const clearAllProducts = () => {
    dispatch(ShoppingCart_clearAllProducts());
  };

  const placeMaterialOrder = () => {
    let dataObj = {
      request: 'grant_material_order_bid',
      order_id: orderId,
      bid_id: bidId,
      payment_type: payment_type,
      creditcard_id: creditcard_id ? creditcard_id : -1,
      installments,
      token: userData.token,
    };
    setIsLoading(true);
    fetchData(dataObj)
      .then(response => {
        if (response?.message === 'success') {
          console.log(JSON.stringify(response));
          navigate('/success');
        } else {
          setCreditCardErrorIsOpen(true);
          console.log('Error:', response);
        }
      })
      .catch(error => {
        setCreditCardErrorIsOpen(true);
        console.log('Error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const placeOrder = () => {
    // setShowMultiPay(false);
    if (!isValidCart.isChecked) {
      setIsValidCart(prev => {
        return {
          ...prev,
          isReady: false,
          isChecked: true,
        };
      });
      return;
    }
    if (isValidCart.isReady) {
      setIsLoading(true);
      fetchData(props.dataObj)
        .then(response => {
          if (response?.message === 'success') {
            setIsLoading(false);
            clearAllProducts();
            dispatch(updateActiveOrdersCount(activeOrders + 1));
            navigate(`/success`);
          } else {
            if (
              response.rc === Number(rc_payme_api_error) ||
              response.rc === Number(rc_credit_card_error)
            ) {
              console.log(`error 2 :rc_payme_api_error`);
              setIsLoading(false);
              setCreditCardErrorIsOpen(true);
            } else if (response.rc === Number(rc_date_error)) {
              setIsLoading(false);
              setSelectedDateErrorIsOpen(true);
            } else if (response.rc === Number(rc_no_project_balance)) {
              setIsLoading(false);
              setProjectBalanceErrorIsOpen(true);
            } else if (
              response.rc === Number(rc_address_error) ||
              response.rc === Number(rc_no_address_error)
            ) {
              setIsLoading(false);
              setAddressErrorIsOpen(true);
            } else if (
              response.rc === Number(rc_applicheck_underwriting_error) ||
              response.rc === Number(rc_approved_dibble_coins_underwriting)
            ) {
              setIsLoading(false);
              setCreditCardErrorIsOpen(true);
            }
          }
        })
        .catch(error => {
          console.error('place_order_with_products', error);
        });
    } else {
      setIsValidCart(prev => {
        return {...prev, isChanged: false};
      });
    }
  };

  useEffect(() => {
    if (
      isValidCart.isReady &&
      isValidCart.isChecked &&
      !isValidCart.isChanged
    ) {
      placeOrder();
    }
  }, [isValidCart.isReady, isValidCart.isChecked]);

  //////////////////////////////////////////////////////// intercom opening

  const handleOpenIntercom = () => {
    setCreditCardErrorIsOpen(false);
    setProductsError(null);

    openIntercom(); // This will open the Intercom chat
  };

  //////////////////////////////////////////////////////// cart page
  // continue to summary
  const checkCanContinue = async () => {
    userData ? navigate(`/summary`) : navigate('/login');
  };

  //////////////////////////////////////////////////////// for mobile
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    function handleScroll() {
      // Calculate how far the user has scrolled from the top of the page
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      // Calculate the height of the visible window
      const windowHeight = window.innerHeight;
      // Calculate the total height of the document, including off-screen content
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
      );

      // Check if the user has scrolled to the bottom
      const isBottom = scrollTop + windowHeight >= documentHeight - 100;
      setIsAtBottom(isBottom);
    }

    if (isAtBottom !== true) {
      window.addEventListener('scroll', handleScroll);
      // for case that there is no scroll
      const windowHeight = window.innerHeight;
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
      );
      if (windowHeight >= documentHeight - 100) setIsAtBottom(true);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={classNames(
          !isMobile || action === 'summary'
            ? 'summary-component__container'
            : 'summary-component--mobile',
        )}>
        {isMobile && action === 'cart' && (
          <div className="screening-bottom-to-top summary-component--mobile-screening"></div>
        )}
        {isMobile && action === 'summary' && (
          <div className="summary-component__item">
            <span
              style={{marginTop: bidId ? '1.8rem' : '0rem'}}
              className={classNames(
                'summary-component__item--title summary-component--space',
                isMobile && 'summary-component__item--title--gray',
              )}>
              {obj_heb.summery}
            </span>
            {!bidPrice && (
              <span className="summary-component__item--items">
                {shoppingCartProducts.length + ' ' + obj_heb.items}
              </span>
            )}
          </div>
        )}

        <div
          className={classNames(
            'summary-component',
            action === 'summary' && !isMobile && 'summary-component--summary',
            isMobile && action === 'cart' && 'summary-component--cart',
            isMobile &&
              action === 'cart' &&
              !isAtBottom &&
              'summary-component--on-scroll',
          )}>
          {!isMobile && (
            <div className="summary-component__item">
              <span
                className={classNames(
                  'summary-component__item--title summary-component--space',
                  isMobile && 'summary-component__item--title--gray',
                )}>
                {obj_heb.summery}
              </span>
              {!bidPrice && (
                <span className="summary-component__item--items">
                  {shoppingCartProducts.length + ' ' + obj_heb.items}
                </span>
              )}
            </div>
          )}

          {(!isMobile || action === 'summary') && (
            <>
              <div className="summary-component__item">
                <span className="summary-component__item--small-title">
                  {obj_heb.orderAmount}:
                </span>
                {!bidPrice ? (
                  <span className="summary-component__item--small-title">
                    <span className="summary-component__item--currency">₪</span>
                    {numberWithCommas((shoppingCartPrice() * vat).toFixed(2))}
                  </span>
                ) : (
                  <span className="summary-component__item--small-title">
                    <span className="summary-component__item--currency">₪</span>
                    {(bidPrice * vat).toFixed(2)}
                  </span>
                )}
              </div>

              <span
                className="summary-component__item--separator"
                style={{height: '0.1rem'}}
              />

              <div className="summary-component__item">
                <span className="summary-component__item--small-title">
                  {obj_heb.postage}
                </span>
                <span className="summary-component__item--small-title">
                  {bidDeliveryCost ? (
                    <span>
                      <span className="summary-component__item--currency">
                        ₪
                      </span>
                      {(bidDeliveryCost * vat).toFixed(2)}
                    </span>
                  ) : selectedAddress && deliveryType ? (
                    <span>
                      <span className="summary-component__item--currency">
                        ₪
                      </span>
                      {numberWithCommas((deliveryCost * vat).toFixed(2))}
                    </span>
                  ) : !selectedAddress ? (
                    obj_heb.calculateTheShipping
                  ) : deliveryType !== 3 || deliveryType !== 1 ? (
                    obj_heb.addDeliveryType
                  ) : null}
                </span>
              </div>

              <span
                className="summary-component__item--separator summary-component--space"
                style={{height: '0.1rem'}}
              />
            </>
          )}
          {isMobile && action === 'cart' ? (
            isAtBottom && (
              <div className="summary-component__mobile-content">
                <div className="summary-component__item--amount">
                  <span className="summary-component__item--title">
                    {obj_heb.total}
                  </span>
                  <span className="summary-component__item--title">
                    <span className="summary-component__item--currency">₪</span>
                    {bidDeliveryCost && bidPrice
                      ? bidDeliveryCost + bidPrice
                      : selectedAddress && deliveryCost
                      ? ((shoppingCartPrice() + deliveryCost) * vat).toFixed(2)
                      : (shoppingCartPrice() * vat).toFixed(2)}
                  </span>
                </div>
                <div className="summary-component__mobile-content--details">
                  <span className="summary-component__item--items">
                    {shoppingCartProducts.length + ' ' + obj_heb.items}
                  </span>

                  <div className="summary-component__item__weight summary-component--space">
                    <span> {obj_heb.weight}: </span>
                    {cartWeight()}
                  </div>
                </div>
              </div>
            )
          ) : (
            <>
              <div className="summary-component__item summary-component__item--amount">
                <span className="summary-component__item--title">
                  {obj_heb.total}
                </span>
                <span className="summary-component__item--title--black">
                  <span className="summary-component__item--title--currency">
                    ₪
                  </span>
                  {bidDeliveryCost && bidPrice
                    ? (
                        Number(bidDeliveryCost) * vat +
                        Number(bidPrice) * vat
                      ).toFixed(2)
                    : deliveryCost
                    ? numberWithCommas(
                        ((shoppingCartPrice() + deliveryCost) * vat).toFixed(2),
                      )
                    : numberWithCommas((shoppingCartPrice() * vat).toFixed(2))}
                </span>
              </div>
              {!bidDeliveryCost && (
                <div
                  className={classNames(
                    'summary-component__item__weight',
                    !isMobile && 'summary-component--space',
                  )}>
                  <span> {obj_heb.weight}: </span>
                  {cartWeight()}
                </div>
              )}
            </>
          )}
          {!(isMobile && action === 'summary') && (
            <Button
              text={
                //in cart page
                shoppingCartProducts.length >= 1 && action === 'cart'
                  ? // on mobile - need to scroll
                    isMobile && !isAtBottom
                    ? obj_heb.scrollBottomToContinue
                    : // it there is user data
                    userData
                    ? // when email isnt verified
                      userData?.is_email_verified != 1
                      ? isMobile
                        ? obj_heb.youNeedToVerifyYourEmail
                        : obj_heb.youNeedToVerifyYourEmailToOrder
                      : // email is verified - choose delivery
                        obj_heb.choseDelivery
                    : // it there isnt user data
                      obj_heb.choseDelivery
                  : //in summary page when not ready
                  action === 'summary' && !ready
                  ? !shoppingCartProducts.length && !bidPrice
                    ? // if there are no items in bag
                      obj_heb.no_shopping_cart_item_found
                    : // if the form does'nt filled
                    payment_type == 3 && ready && !bidPrice
                    ? obj_heb.you_need_some_more_coins
                    : (bidPrice &&
                        canPayWithDibbleCoins &&
                        payment_type == 3) ||
                      (bidPrice && payment_type == 1)
                    ? obj_heb.pay
                    : bidPrice && !canPayWithDibbleCoins && payment_type == 3
                    ? obj_heb.you_need_some_more_coins
                    : obj_heb.need_to_fill_up_form
                  : action === 'summary' && ready
                  ? // in summary page when  ready
                    obj_heb.pay
                  : ''
              }
              backgroundColor={
                userData
                  ? // when email isnt verified
                    userData?.is_email_verified != 1
                    ? 'var(--unclickable-gray)'
                    : (bidPrice &&
                        canPayWithDibbleCoins &&
                        payment_type == 3) ||
                      (bidPrice && payment_type == 1)
                    ? 'var(--dibble-yellow)'
                    : //in cart page or on finish summary
                    action === 'cart' &&
                      shoppingCartProducts.length >= 1 &&
                      !bidPrice
                    ? isMobile && !isAtBottom
                      ? 'var(--unclickable-gray)'
                      : 'var(--dibble-yellow)'
                    : action === 'summary'
                    ? ready && !bidPrice
                      ? 'var(--dibble-yellow)'
                      : 'var(--unclickable-gray)'
                    : //in all other cases
                      'var(--unclickable-gray)'
                  : isMobile && !isAtBottom
                  ? 'var(--unclickable-gray)'
                  : 'var(--dibble-yellow)'
              }
              disable={
                (bidPrice && canPayWithDibbleCoins && payment_type == 3) ||
                (bidPrice && payment_type == 1)
                  ? false
                  : action === 'cart'
                  ? !userData
                    ? false
                    : userData?.is_email_verified != 1
                    ? true
                    : false
                  : !ready
                  ? true
                  : null
              }
              onClick={
                bidPrice
                  ? () => placeMaterialOrder()
                  : action === 'cart'
                  ? () => checkCanContinue()
                  : ready
                  ? () => placeOrder()
                  : () => {}
              }
              textColor="var(--white)"
              className={isMobile && !isAtBottom && 'summary-component__btn'}
            />
          )}
        </div>

        {!(isMobile && action === 'cart') && (
          <div className="summary-component__delivery-note">
            {obj_heb.deliveryCostIsCalculatedByWeight}
          </div>
        )}
      </div>
      {/* button for mobile summary */}
      {isMobile && action === 'summary' && (
        <div className="summary-component__btn--summary">
          {isMobile && action === 'summary' && (
            <div className="screening-bottom-to-top summary-component--mobile-screening"></div>
          )}

          <Button
            text={
              //in cart page
              !isAtBottom
                ? obj_heb.scrollBottomToContinue
                : // when email isnt verified

                !ready
                ? !shoppingCartProducts.length && !bidPrice
                  ? // if there are no items in bag
                    obj_heb.no_shopping_cart_item_found
                  : // if the form does'nt filled
                  payment_type == 3 && ready && !bidPrice
                  ? obj_heb.you_need_some_more_coins
                  : (bidPrice && canPayWithDibbleCoins && payment_type == 3) ||
                    (bidPrice && payment_type == 1)
                  ? obj_heb.pay
                  : bidPrice && !canPayWithDibbleCoins && payment_type == 3
                  ? obj_heb.you_need_some_more_coins
                  : obj_heb.need_to_fill_up_form
                : action === 'summary' && ready
                ? // in summary page when  ready
                  obj_heb.pay
                : ''
            }
            backgroundColor={
              // First condition: Checking if shoppingCartProducts has at least one item and isAtBottom is false
              shoppingCartProducts.length >= 1 && !isAtBottom
                ? 'var(--unclickable-gray)'
                : // Second condition: Checking if ready is true and bidPrice is false
                ready && !bidPrice
                ? 'var(--dibble-yellow)'
                : // Third condition: Checking combinations of bidPrice, canPayWithDibbleCoins, and payment_type
                (bidPrice &&
                    canPayWithDibbleCoins &&
                    payment_type === 3 &&
                    isAtBottom) ||
                  (bidPrice && payment_type === 1 && isAtBottom)
                ? 'var(--dibble-yellow)'
                : // Default case if none of the above conditions are met
                  'var(--unclickable-gray)'
            }
            disable={
              !isAtBottom
                ? true
                : (bidPrice && canPayWithDibbleCoins && payment_type == 3) ||
                  (bidPrice && payment_type == 1)
                ? false
                : !ready
                ? true
                : null
            }
            onClick={
              bidPrice
                ? () => placeMaterialOrder()
                : ready
                ? () => placeOrder()
                : () => {}
            }
            textColor="var(--white)"
            className={classNames(
              isMobile && !isAtBottom && 'summary-component__btn',
            )}
          />
        </div>
      )}

      {/* pop up credit card is not good */}
      <PopUp
        isOpen={creditCardErrorIsOpen}
        setIsOpen={setCreditCardErrorIsOpen}>
        <PopUpConfirm
          img={icons.no_credit_icon}
          title={obj_heb.just_a_moment}
          subTitle={
            <div className="summary-component__credit-error">
              <span>{obj_heb.creditErrorLine1}</span>
              <span>{obj_heb.creditErrorLine2}</span>
              <div
                onClick={handleOpenIntercom}
                className="summary-component__credit-error--contact-us">
                <span>{obj_heb.chatWithUsError}</span>
                <img src={icons.chat_icon} alt="" />
              </div>
            </div>
          }
        />
      </PopUp>

      {/* pop up address is not good */}
      <PopUp isOpen={addressErrorIsOpen} setIsOpen={setAddressErrorIsOpen}>
        <PopUpConfirm
          img={icons.location_error_icon}
          title={obj_heb.AreaWithoutService}
          subTitle={
            <>
              <span>{obj_heb.AreaWithoutServiceLine}</span>
              <div className="summary-component__address-error">
                {obj_heb.AreaWithoutServiceMeantime}
              </div>
            </>
          }
        />
      </PopUp>

      {/* pop up selected date is not good */}
      <PopUp
        isOpen={selectedDateErrorIsOpen}
        setIsOpen={setSelectedDateErrorIsOpen}>
        <PopUpConfirm
          img={icons.no_credit_icon}
          title={obj_heb.selectedTimeTitle}
          subTitle={obj_heb.selectedTimeLine1}
        />
      </PopUp>

      {/* pop up out of project budget available */}
      <PopUp
        isOpen={projectBalanceErrorIsOpen}
        setIsOpen={setProjectBalanceErrorIsOpen}>
        <PopUpConfirm
          img={icons.no_credit_icon}
          title={obj_heb.just_a_moment}
          subTitle={obj_heb.just_a_dibblecoins1}
        />
      </PopUp>

      {/* {!isValidCart && ( */}
      <PopUpValidations
        shoppingCartData={shoppingCartProducts}
        isChecked={isValidCart.isChecked}
        setIsChanged={value =>
          setIsValidCart(prev => {
            return {...prev, isChanged: value};
          })
        }
        setIsReady={value =>
          setIsValidCart(prev => {
            return {...prev, isReady: value};
          })
        }
      />
    </>
  );
}

export default SummaryComponent;
