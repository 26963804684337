import React, {useEffect, useState} from 'react';
import DayInput from './DayInput';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {fetchData} from '../utils/fetchData';
import HourInput from './HourInput';
import obj_heb from '../utils/Obj_heb.json';
import Button from './Button';
import {useSelector} from 'react-redux';
import Title from './Title';
import {days} from '../utils/supportFunctions';
import AddressInput from './AddressInput';
import locationIcon from '../assets/icons/location.svg';
import calenderIcon from '../assets/icons/icon_calender.png';
import AddNotes from './AddNotes';
import {Document, Page, pdfjs} from 'react-pdf';

export default function ChooseDateComponent({
  setSlot,
  listOption,
  files,
  fileId,
  openMaterial,
  extra_info,
  materialObjectElements,
  qtyPlus,
  setPatentStep,
  parentStep,
}) {
  const isMobile = useSelector(state => state.mobile.isMobile);
  const [notes, setNotes] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [settedDays, setDays] = useState([]);
  const [hours, setHours] = useState([]);
  const [selectedTime, setSelectedTime] = useState({
    day: '',
    hour: '',
  });

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 20,
  };
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 150,
    height: 150,
    padding: 4,
    boxSizing: 'border-box',
  };
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  };
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
  };
  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        {file.type.startsWith('image/') ? (
          <div>
            <img src={file.preview} style={img} alt="Uploaded" />
          </div>
        ) : (
          <div>
            <Document file={file.preview}>
              <Page pageNumber={1} width={150} />
            </Document>
          </div>
        )}
      </div>
      <div></div>
    </div>
  ));

  const selectedAddress = useSelector(
    state => state.addressSlice.selectedAddress,
  );
  let weekDays = days();

  const navigate = useNavigate();

  useEffect(() => {
    getDates();
  }, [selectedTime.day]);

  const convertToUTC = selectedTime => {
    const {FORMAT_DATE} = selectedTime.day;
    const hourRange = selectedTime.hour;
    const startHour = hourRange.split(' - ')[1].trim();
    const dateTimeString = `${FORMAT_DATE} ${startHour}`;
    const localMoment = moment(dateTimeString, 'YYYY-MM-DD HH:mm');
    const localOffsetMinutes =
      new Date(selectedTime.day.FORMAT_DATE).getTimezoneOffset() / 60;
    const utcMoment = localMoment
      .subtract(localOffsetMinutes, 'minutes')
      .utc()
      .startOf('hour');
    return utcMoment.format('YYYY-MM-DD HH:mm:ss');
  };

  const handleSubmit = () => {
    if (parentStep == 3) {
      setPatentStep(parentStep + 1);
    } else {
      if (selectedAddress) {
        setAddressError(false);
        const date = moment(selectedTime.day.FORMAT_DATE, 'YYYY-MM-DD').format(
          'DD.MM.YYYY',
        );
        setSlot(date + ' ' + selectedTime.hour);
        setIsLoading(true);
        const userData = JSON.parse(localStorage.getItem('userData'));
        let dataObj = {
          request:
            openMaterial == 2
              ? 'place_order_with_iron'
              : 'place_material_order',
          token: userData?.token,
          destination_address: selectedAddress?.address,
          destination_lat: selectedAddress?.lat,
          destination_lon: selectedAddress?.lon,
          notes: notes ? notes : '',
          purpose: '',
          requested_delivery_date: convertToUTC(selectedTime),
          project_id: selectedAddress?.project_id || selectedAddress?.id,
        };
        if (openMaterial == 2) {
          dataObj.questions = Object.values(listOption);
          dataObj.documents = fileId;
        } else {
          dataObj.extra_info = extra_info;
          if (openMaterial == 4) {
            dataObj.extra_info.qty_plus = qtyPlus ? true : false;
          }
          dataObj.material_type = openMaterial;
        }

        try {
          fetchData(dataObj).then(response => {
            if (response?.message === 'success') {
              setIsLoading(false);
              navigate(`/material-order-offer-sent`);
            }
            console.log(JSON.stringify(response));
          });
        } catch (error) {
          console.error('Error:', error?.message);
          return null;
        }
      } else {
        console.error('Error no address');
        setAddressError(true);
      }
    }
  };

  const getDates = () => {
    setIsLoading(true);
    const userData = JSON.parse(localStorage.getItem('userData'));

    let dataObj = {
      request: 'get_delivery_slots',
      token: userData?.token,
      need_crane: true,
    };
    const currentHour = moment().hour();

    try {
      fetchData(dataObj).then(response => {
        const dateOptions = [];

        if (response?.slots) {
          for (const key in response.slots) {
            if (response.slots.hasOwnProperty(key)) {
              const dateMoment = moment(key, 'YYYY-MM-DD');
              const dayNumber = dateMoment.day();
              const parsedDate = dateMoment.format('DD.MM.YY');
              const parsedDate2 = dateMoment.format('YYYY-MM-DD');

              const date = {
                day: dayNumber,
                date: parsedDate,
                FORMAT_DATE: parsedDate2,
              };
              dateOptions.push(date);
            }
          }
          setDays(dateOptions);
        } else {
          navigate('/login');
        }

        if (selectedTime.day) {
          let formatTime = response?.slots[selectedTime.day?.FORMAT_DATE];
          let slot_len = response?.delivery_slot_len_hours;

          if (formatTime) {
            const formatMoment = moment(
              selectedTime.day?.FORMAT_DATE,
              'YYYY-MM-DD',
            );
            const diff =
              new Date(selectedTime.day.FORMAT_DATE).getTimezoneOffset() / 60;
            const transformedArray = formatTime?.map((original, index) => {
              const startTime = `${original - diff}:00`;
              const endTime = `${original - diff + slot_len}:00`;
              const timeFrame = `${endTime} - ${startTime}`;
              return {original, time_frame: timeFrame};
            });

            setHours(transformedArray);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.error('Error:', error?.message);
      return null;
    }
  };
  const setDay = day => {
    setSelectedTime(prev => {
      return {...prev, day: day};
    });
  };
  const setHour = hour => {
    setSelectedTime(prev => {
      return {...prev, hour: hour};
    });
  };

  return (
    <div className="ChooseDateComponent">
      {parentStep == 3 ? (
        <div>
          {!isMobile && (
            <span
              className="SelectMaterialTab_content--title--small"
              style={{marginTop: '5rem'}}>
              {obj_heb.chooseDeliveryDate}
            </span>
          )}
          <div className="ChooseDateComponent--error--box">
            <div>{obj_heb.chooseDeliveryDateNote_1}</div>
            <div>{obj_heb.chooseDeliveryDateNote_2}</div>
          </div>
          {isMobile && (
            <div style={{marginTop: isMobile ? '1.8rem' : '0'}}>
              <Title center={true} title={obj_heb.chooseDeliveryDate} />
            </div>
          )}
          <div className="ChooseDateComponent--content">
            <div
              className="ChooseDateComponent--content--dates"
              style={{marginTop: '5rem'}}>
              <DayInput
                setDay={setDay}
                setHour={setHour}
                day={selectedTime.day}
                daysOptions={settedDays}
                popUpIsOpen={true}
              />
              <div style={{marginTop: '2rem'}}></div>
              <HourInput
                hours={hours}
                setHour={setHour}
                hour={selectedTime.hour}
                popUpIsOpen={true}
              />
            </div>
          </div>

          {addressError && <div>{obj_heb.noAddressError}</div>}

          <div className="SelectMaterialTab_content__parent">
            <Button
              disable={
                !selectedAddress || !selectedTime.day || !selectedTime.hour
              }
              text={
                !selectedAddress
                  ? obj_heb.noAddressError
                  : !selectedTime.day
                  ? obj_heb.noDeliveryTimeDateError
                  : !selectedTime.hour
                  ? obj_heb.noDeliveryTimeHrError
                  : obj_heb.continue
              }
              textColor="white"
              backgroundColor={
                !selectedAddress || !selectedTime.hour || !selectedTime.day
                  ? 'var(--unclickable-gray)'
                  : 'var(--dibble-yellow)'
              }
              className={'SelectMaterialTab_content__parent--button'}
              onClick={handleSubmit}
            />
          </div>
        </div>
      ) : parentStep == 4 ? (
        <div>
          <div
            style={{textAlign: isMobile ? 'right' : 'center'}}
            className="ChooseDateComponent--error--box">
            <div>
              אנחנו נעביר לכם הצעות מחיר בדיוק בהתאם לכמות ולקריטריונים שהזנתם.
            </div>
            <div>
              לפני שליחת הבקשה להצעות מחיר מומלץ לעבור על הפרטים כאן למטה ולוודא
              שהכל כמו שצריך.
            </div>
          </div>
          {openMaterial == 2 && (
            <div style={{marginRight: '3rem'}}>
              <Title
                cancelTitleLines={true}
                center={false}
                title={obj_heb.filesUploaded}
              />
              <aside style={thumbsContainer}>{thumbs}</aside>
            </div>
          )}
          <div style={{marginTop: '3rem'}}>
            <div style={{marginRight: '3rem'}}>
              <Title
                cancelTitleLines={true}
                center={false}
                title={'כתובת ואספקה:'}
              />
            </div>
            <div
              onClick={() => setPatentStep(3)}
              className="summary__form__item">
              <div className="address-input">
                <div className="address-input__item">
                  <div className="address-input__item--title">
                    <img
                      src={calenderIcon}
                      alt="location"
                      className="address-input__icon"
                    />
                    {weekDays[selectedTime.day?.day] +
                      ' , ' +
                      selectedTime.day?.date +
                      ' , ' +
                      selectedTime.hour}
                    <span
                      className={
                        !selectedAddress
                          ? 'address-input__item--title--placeholder'
                          : ''
                      }></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="summary__form__item">
            <AddressInput boxShadow />
          </div>
          <div className="summary__form__item">
            <div className="address-input">
              <div className="address-input__item">
                <AddNotes
                  boxShadow={false}
                  showTitle={false}
                  notes={notes}
                  setNotes={value => setNotes(value)}
                />
              </div>
            </div>
          </div>
          <div style={{marginTop: '3rem'}}>
            <div style={{marginRight: '3rem'}}>
              <Title
                cancelTitleLines={true}
                center={false}
                title={'ערכי ההזמנה:'}
              />
            </div>
            {openMaterial !== 2
              ? materialObjectElements?.map(element => (
                  <div className="summary__form__item" key={element.key}>
                    <div className="address-input">
                      <div className="address-input__item">
                        <div className="address-input__item--title">
                          {element.ui_name}:
                          <span style={{fontWeight: 'bold'}}>
                            {element.key !== 'qty'
                              ? element.options[extra_info[element.key] - 1]
                                  ?.name
                              : extra_info[element.key]}
                          </span>
                        </div>
                      </div>
                      {element.sub_elements?.map(subElement => (
                        <div
                          key={subElement.key}
                          className="address-input__sub">
                          <span style={{marginLeft: '1rem'}}>
                            {subElement.ui_name}:
                          </span>
                          <span style={{fontWeight: 'bold'}}>
                            {qtyPlus ? 'כן' : 'לא'}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              : Object.keys(listOption).map(key => (
                  <div className="summary__form__item" key={key}>
                    <div className="address-input">
                      <div className="address-input__item">
                        <div className="address-input__item--title">
                          {listOption[key].question}:
                          <span style={{fontWeight: 'bold'}}>
                            {listOption[key].answer}
                            {listOption[key].extra
                              ? ' , ' + listOption[key].extra
                              : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            <div className="SelectMaterialTab_content__parent">
              <Button
                disable={!selectedAddress}
                text={
                  !selectedAddress
                    ? obj_heb.noAddressError
                    : obj_heb.sendMeMaterialOffer
                }
                textColor="white"
                backgroundColor={
                  !selectedAddress || !selectedTime.hour || !selectedTime.day
                    ? 'var(--unclickable-gray)'
                    : 'var(--dibble-yellow)'
                }
                className={'SelectMaterialTab_content__parent--button'}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
