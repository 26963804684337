import classNames from 'classnames';
import React, {useState, useEffect} from 'react';
import {icons} from '../assets/constants';

function TextInput({
  value,
  onChange,
  onClear,
  onBlur,
  onFocus = () => {},
  description,
  placeholderText,
  className,
  errorMsg = '',
  IsError = false,
  disable = false,
  isLeft = false,
  max = null,
  min = null,
  name = '',
  icon = false,
  clearColor = 'white',
  autoFocus = false,
  type = 'text',
  inputColorIsBlack = false,
  directError = false,
  ltr = false,
}) {
  const [isBlurred, setIsBlurred] = useState(directError);

  const handleTextClear = e => {
    e.stopPropagation();
    onClear();
  };

  useEffect(() => {
    setIsBlurred(directError);
  }, [directError]);
  return (
    <div className={classNames('text-input__container', className)}>
      <div
        className={classNames(
          'text-input',
          isBlurred && IsError && value && 'text-input__error',
          disable && 'text-input__disable',
          ltr ? 'text-input__ltr' : 'text-input__rtl',
        )}>
        <span className="text-input__description">{description}</span>
        <span className="text-input__input__container">
          {!isLeft && icon && (
            <img src={icon} alt="icon" className="text-input__icon" />
          )}
          <input
            style={inputColorIsBlack ? {color: 'black'} : {}}
            value={value}
            placeholder={placeholderText}
            onChange={onChange}
            onBlur={e => {
              setIsBlurred(true);
              if (onBlur) {
                onBlur(e);
              }
            }}
            onFocus={onFocus}
            className={classNames(
              'text-input__input',
              disable && 'text-input__disable',
              isLeft && 'text-input__input--is-left',
            )}
            maxLength={max ? max : 1000}
            min={min}
            name={name}
            autoFocus={autoFocus}
            type={type}
          />
          {isLeft && icon && (
            <img src={icon} alt="icon" className="text-input__icon" />
          )}
        </span>
        {onClear && value && !disable && (
          <img
            src={
              clearColor === 'white'
                ? icons.inputClearWhite
                : icons.inputClearGray
            }
            alt="x"
            className={classNames(
              'text-input__delete',
              isLeft && 'text-input__delete--is-left',
            )}
            onClick={handleTextClear}
          />
        )}
      </div>
      {isBlurred && IsError && value && (
        <div className="text-input__error-msg">{errorMsg}</div>
      )}
    </div>
  );
}

export default TextInput;
