// Projects.js
import React, {useState, useEffect, useRef} from 'react';
import {fetchData} from '../utils/fetchData';
import {useSearchParams} from 'react-router-dom';
import Project from './Project'; // Component to display individual project details
import Loader from '../components/Loader';
import obj_heb from '../utils/Obj_heb.json';
import AddProjectCard from './AddProjectCard';

function Projects() {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userData] = useState(JSON.parse(localStorage.getItem('userData')));
  const [searchParams] = useSearchParams();
  const endOfListRef = useRef();

  useEffect(() => {
    let dataObj = {
      request: 'get_projects',
      token: userData?.token,
      page: 0,
      page_size: 30,
    };

    fetchData(dataObj)
      .then(response => {
        if (response.message === 'success') {
          setProjects(response);
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error:', error.message);
        setIsLoading(false);
      });
  }, [userData]);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : (
        <div className="projects-container">
          <AddProjectCard />
          {projects.projects &&
            projects.projects.length > 0 &&
            projects.projects.map((project, index) => (
              <div key={index} className="project">
                <Project project={project} />
              </div>
            ))}
        </div>
      )}
    </React.Fragment>
  );
}

export default Projects;
