import React from 'react';
import {useSelector} from 'react-redux';

function ProgressBar(props) {
  const isMobile = useSelector(state => state.mobile.isMobile);

  let x = () => {
    if (props.openMaterial && props.openMaterial == 2) {
      return 1;
    } else if (props.openMaterial && props.openMaterial != 2) {
      if (props.step == 3 || props.step == 4) {
        return -1;
      } else {
        return 1;
      }
    }
    return 0; // Ensure x always returns a value
  };

  // Calculate the width of the progress bar based on the step prop
  const width = !props.openMaterial
    ? `${props.step * 20}%`
    : props.openMaterial == 2
    ? `${(props.step + x()) * 20}%`
    : `${(props.step + x()) * 33.333}%`; // Fixing the ternary operator usage

  return (
    <div
      className={
        isMobile
          ? 'progress-bar--mobile card-shadow'
          : 'progress-bar card-shadow'
      }>
      <div className="progress" style={{width}}></div>
    </div>
  );
}

export default ProgressBar;
