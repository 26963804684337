import React, {useState, useEffect, useRef} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import arrow_head_down from '../assets/icons/arrow_head_down.svg';
import classNames from 'classnames';
import CreditCardFavorite from './CreditCardFavorite';
import CreditCardDelete from './CreditCardDelete';
import PopUp from './general/PopUp';
import clipBoardIcon from '../assets/icons/clipBoardIcon.svg';
import house from '../assets/icons/house.svg';
import AddressDelete from './AddressDelete';
import AddressEdit from './AddressEdit';
import {useSelector} from 'react-redux';
import Modal from './general/Modal';
import AddressItemModalChildren from './AddressItemModalChildren';

export default function AddressItem({
  address,
  list,
  listAction,
  canPayWithDibbleCoins,
  dibbleCoins,
  cardSelected,
  setFinish,
  setFinishText,
}) {
  const [AddressEditIsOpen, setAddressEditIsOpen] = useState(false);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [popUpContent, setPopUpContent] = useState(<></>);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMobile = useSelector(state => state.mobile.isMobile);

  const addressRef = useRef();

  const Func = address => {
    setAddressEditIsOpen(prev => !prev);
    setIsModalOpen(prev => !prev);
    if (list === 'summary') {
      listAction(address);
    }
  };

  useEffect(() => {
    // Function to handle click outside the div
    const handleClickOutside = event => {
      if (
        addressRef.current &&
        !addressRef.current.contains(event.target) &&
        event.srcElement.title !== 'googleSelectAble'
      ) {
        // The click is outside the div, do something here
        setAddressEditIsOpen(prev => false);
        setIsModalOpen(prev => false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="address-Item" ref={addressRef}>
        <div
          className={classNames('address-Item_item ')}
          onClick={() => Func(address)}>
          <div className="address-Item_item_details">
            {address?.address_type === 'project' ? (
              <img
                className="address-Item_item-img-project"
                src={clipBoardIcon}
                alt="clipBoardIcon"
              />
            ) : (
              <img
                className="address-Item_item-img-house"
                src={house}
                alt="house"
              />
            )}
            <div className="address-Item_item_details-info">
              <span className="address-Item_item_details-info_title">
                {address.title?.length >= 138
                  ? address.title?.slice(0, 140) + '..'
                  : address.title}
              </span>
              <span className="address-Item_item_details-info_sub-title">
                {address.address?.length >= 138
                  ? address.address?.slice(0, 140) + '..'
                  : address.address}
              </span>
            </div>
          </div>
          <img
            src={arrow_head_down}
            alt="arrow"
            className={classNames(
              'address-Item_item__arrow',
              AddressEditIsOpen && 'flip',
            )}
          />
        </div>
        {!isMobile ? (
          AddressEditIsOpen &&
          list != 'summary' && (
            <div className="address-Item__edit">
              <AddressEdit
                address={address}
                popUpIsOpen={popUpIsOpen}
                setAddressEditIsOpen={setAddressEditIsOpen}
                setPopUpIsOpen={setPopUpIsOpen}
                setPopUpContent={setPopUpContent}
                setFinish={setFinish}
                setFinishText={setFinishText}
              />
              <AddressDelete
                address={address}
                setAddressEditIsOpen={setAddressEditIsOpen}
                setPopUpIsOpen={setPopUpIsOpen}
                setPopUpContent={setPopUpContent}
                setFinish={setFinish}
                setFinishText={setFinishText}
              />
            </div>
          )
        ) : (
          <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
            <AddressItemModalChildren
              address={address}
              popUpIsOpen={popUpIsOpen}
              setPopUpIsOpen={setPopUpIsOpen}
              setPopUpContent={setPopUpContent}
              setAddressEditIsOpen={setAddressEditIsOpen}
              setFinish={setFinish}
              setFinishText={setFinishText}
            />
          </Modal>
        )}
      </div>
      {popUpIsOpen && (
        <PopUp isOpen={popUpIsOpen} setIsOpen={setPopUpIsOpen}>
          {popUpContent}
        </PopUp>
      )}
    </>
  );
}
