import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import PopUp from './general/PopUp';
import DayInput from './DayInput';
import HourInput from './HourInput';
import Button from './Button';
import Loader from './Loader';

import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';
import moment from 'moment';

function PopUpSlots({isOpen, setIsOpen, slot, setSlot, isNeedCrane}) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [days, setDays] = useState([]);
  const [hours, setHours] = useState([]);
  const [selectedTime, setSelectedTime] = useState({
    day: '',
    hour: '',
  });

  const setDay = day => {
    setSelectedTime(prev => {
      {
        return {
          ...prev,
          day: day,
        };
      }
    });
  };

  const setHour = hour => {
    setSelectedTime(prev => {
      {
        return {
          ...prev,
          hour: hour,
        };
      }
    });
  };

  useEffect(() => {
    getDates();
  }, [selectedTime.day]);

  const handleSubmit = () => {
    const date = moment(selectedTime.day.FORMAT_DATE, 'YYYY-MM-DD').format(
      'DD.MM.YYYY',
    );
    setSlot(date + ' ' + selectedTime.hour);
    setIsOpen(false);
  };

  //   #################################################### get the days and hours
  const getDates = () => {
    setIsLoading(true);
    const userData = JSON.parse(localStorage.getItem('userData'));

    let dataObj = {
      request: 'get_delivery_slots',
      token: userData?.token,
      need_crane: isNeedCrane,
    };
    const currentHour = moment().hour();

    try {
      fetchData(dataObj).then(response => {
        const dateOptions = [];

        if (response?.slots) {
          for (const key in response.slots) {
            if (response.slots.hasOwnProperty(key)) {
              const dateMoment = moment(key, 'YYYY-MM-DD');
              const dayNumber = dateMoment.day();
              const parsedDate = dateMoment.format('DD.MM.YY');
              const parsedDate2 = dateMoment.format('YYYY-MM-DD');

              const date = {
                day: dayNumber,
                date: parsedDate,
                FORMAT_DATE: parsedDate2,
              };
              dateOptions.push(date);
            }
          }
          setDays(dateOptions);
        } else {
          navigate('/login');
        }

        if (selectedTime.day) {
          let formatTime = response?.slots[selectedTime.day?.FORMAT_DATE];
          let slot_len = response?.delivery_slot_len_hours;

          if (formatTime) {
            const formatMoment = moment(
              selectedTime.day?.FORMAT_DATE,
              'YYYY-MM-DD',
            );
            const diff =
              new Date(selectedTime.day.FORMAT_DATE).getTimezoneOffset() / 60;
            const transformedArray = formatTime?.map((original, index) => {
              const startTime = `${original - diff}:00`;
              const endTime = `${original - diff + slot_len}:00`;
              const timeFrame = `${endTime} - ${startTime}`;
              return {original, time_frame: timeFrame};
            });

            setHours(transformedArray);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.error('Error:', error?.message);
      return null;
    }
  };
  return (
    <PopUp isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="pop-up-slots">
        {isLoading && (
          <div className="pop-up-slots__loader-container">
            <Loader />
          </div>
        )}
        <div className="pop-up-slots__title">
          {obj_heb.preferredDeliveryDate}
        </div>
        <div className="pop-up-slots__item">
          <span className="pop-up-slots__title">{obj_heb.day}</span>
          <DayInput
            setDay={setDay}
            slot={slot}
            setHour={setHour}
            day={selectedTime.day}
            daysOptions={days}
            popUpIsOpen={isOpen}
          />
        </div>
        <div className="pop-up-slots__item pop-up-slots--bottom-space">
          <span className="pop-up-slots__title">{obj_heb.hoursRange}</span>
          <HourInput
            hours={hours}
            setHour={setHour}
            hour={selectedTime.hour}
            popUpIsOpen={isOpen}
          />
        </div>

        <Button
          text={
            !selectedTime.day
              ? obj_heb.chooseADay
              : !selectedTime.hour
              ? obj_heb.rangeOfHours
              : obj_heb.next
          }
          disable={!selectedTime.day || !selectedTime.hour}
          onClick={handleSubmit}
          backgroundColor={
            !selectedTime.day || !selectedTime.hour
              ? 'var(--unclickable-gray)'
              : 'var(--dibble-yellow)'
          }
          textColor="var(--white)"
          className="full-page"
        />
      </div>
    </PopUp>
  );
}

export default PopUpSlots;
