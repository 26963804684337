import React from 'react';

import obj_heb from '../utils/Obj_heb.json';

import Button from './Button';

/**
 * PopUpConfirm Component.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.img - The path of the image.
 * @param {string} props.title - The title of the popup.
 * @param {string} props.subTitle - The sub-title of the popup (the message).
 * @param {Function} [props.onConfirm] - Callback for confirm action. If not provided, the cancel button will take 100% width.
 * @param {Function} [props.onCancel] - Callback for cancel action. If not provided, the confirm button will take 100% width.
 */

function PopUpConfirm({img, title, subTitle, onConfirm, onCancel}) {
  return (
    <div className="pop-up-confirm">
      {img && <img alt={title} src={img} className="pop-up-confirm__img" />}
      <span className="pop-up-confirm__text">
        <span className="pop-up-confirm__text--title">{title}</span>
        <div className="full-page">{subTitle}</div>
      </span>
      {(onConfirm || onCancel) && (
        <div className="pop-up-confirm__btns">
          {onConfirm && (
            <Button
              onClick={onConfirm}
              textColor={'var(--white)'}
              backgroundColor="var(--black)"
              text={obj_heb.confirm}
              className="pop-up-confirm__btns--btn"
            />
          )}
          {onCancel && (
            <Button
              onClick={onCancel}
              backgroundColor={'var(--white)'}
              textColor="var(--black)"
              borderColor="var(--black)"
              text={obj_heb.cancel}
              className="pop-up-confirm__btns--btn"
            />
          )}
        </div>
      )}
    </div>
  );
}

export default PopUpConfirm;
