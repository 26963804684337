export function days() {
  return [`ראשון`, `שני`, `שלישי`, `רביעי`, `חמישי`, `שישי`, `שבת`];
}
export function numberWithCommas(x) {
  if (x > 0) {
    return parseFloat(x)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return 0;
  }
}

export function changeUrl(pageName) {
  const newUrl = window.location.origin + pageName;
  window.location.href = newUrl;
}

/**
 * to get  all of the options of the product
 * @param {*} product
 * @returns
 */
export function getOptionsArray(product) {
  let options = [];
  let i = 0;

  while (product['options' + String(i + 1)]) {
    options.push({
      name: product[`options${String(i + 1)}_name`],
      value: product[`options${String(i + 1)}_type`],
      data: product[`options${String(i + 1)}`],
    });
    i += 1;
  }
  return options;
}

export function updateLocalStorage(key, newData) {
  if (typeof key !== 'string' || key.trim() === '') {
    console.error('Invalid key provided.');
    return;
  }

  // Check if the key exists in local storage
  if (localStorage.getItem(key)) {
    // If key exists, remove it
    localStorage.removeItem(key);
  }

  // Store new data under the specified key
  localStorage.setItem(key, JSON.stringify(newData));
}

export function convertTimeFormat(inputTime) {
  const date = new Date(inputTime);

  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are 0-based
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedDate = `${day}/${month}/${year}`;
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;

  return `${formattedDate} ${formattedTime}`;
}

/**
 * Debounces a function for a given amount of time.
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The time, in milliseconds, to debounce the function.
 * @return {Function} - The debounced function.
 */
export function debounce(func, wait) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}
/**
 *  function  that cancel the scrolling in the page if the value is true
 * @param {boolean} turnOn
 */
export const cancel_scrolling = turnOn => {
  const htmlBody = document.getElementsByTagName('body')[0];
  if (turnOn) {
    htmlBody.style.overflow = 'hidden';
  } else {
    htmlBody.style.overflow = 'auto';
  }
};
