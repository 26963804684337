import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import Loader from './Loader';
import classNames from 'classnames';

function CategoryBox({category, goToCategory, className, width}) {
  const [isLoading, setIsLoading] = useState(true);

  const isMobile = useSelector(state => state.mobile.isMobile);

  return (
    <div
      className={classNames('category-box', className)}
      style={{width: width && width}}
      onClick={() => goToCategory(category.category_id, category.name)}>
      {isLoading && (
        <div className="category-box__image">
          <Loader />
        </div>
      )}
      <img
        src={category.image}
        alt={category.name}
        className="category-box__image"
        onLoad={() => setIsLoading(false)}
        style={{display: isLoading ? 'none' : 'block'}}
        loading="eager"
      />
      <div
        className={classNames(
          'category-box__name',
          isMobile &&
            category.name.length > 6 &&
            category.name.match(/\s/) &&
            'category-box__name--small',
        )}>
        {category.name}
      </div>
    </div>
  );
}

export default CategoryBox;
