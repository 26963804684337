import React from 'react';
import {numberWithCommas} from '../../utils/supportFunctions';
import classNames from 'classnames';

/**
 * ShoppingCartPrice Component.
 *
 * @param {number} price - the original product price
 * @param {boolean} sale - true if the product on sale
 * @param {number} salePrice - the new price- will show only of the product on sale
 * @param {boolean} widget - if the widget is true it will change the style to the widget cart
 *@param {boolean} priceDefaultBlackColor - if true the default price number is black if false its default gray
 */

export default function ShoppingCartPrice({
  priceDefaultBlackColor = false,
  price,
  sale = false,
  salePrice,
  widget,
  mobileCart,
}) {
  return (
    <div
      className={
        widget ? 'shopping-cart-price__widget' : 'shopping-cart-price'
      }>
      {!sale ? (
        <div
          className="shopping-cart-price__regular-number"
          style={priceDefaultBlackColor ? {color: 'black'} : {}}>
          <span
            className="shopping-cart-price__regular-number__currency"
            style={priceDefaultBlackColor ? {color: 'black'} : {}}>
            ₪
          </span>
          {numberWithCommas(price.toFixed(2))}
        </div>
      ) : (
        salePrice && (
          <div
            className={classNames(
              widget
                ? 'shopping-cart-price__widget'
                : 'shopping-cart-price__onSale',
              mobileCart && 'margin-0',
            )}>
            <div className="shopping-cart-price__oldPrice">
              <span className="shopping-cart-price__regular-number__currency__smaller">
                ₪
              </span>
              {numberWithCommas(price.toFixed(2))}
              <span className="shopping-cart-price__regular-number__currency"></span>
              <div className="shopping-cart-price__red-line"></div>
            </div>
            <div className="shopping-cart-price__onSalePrice">
              <span className="shopping-cart-price__onSalePrice__currency">
                ₪
              </span>
              {numberWithCommas(salePrice.toFixed(2))}
            </div>
          </div>
        )
      )}
    </div>
  );
}
