import React from 'react';
import SEO from '../components/SEO';
import obj_heb from '../utils/Obj_heb.json';

export default function Terms() {
  return (
    <React.Fragment>
      <SEO pageName={obj_heb.TOU} content={obj_heb.TOUDesc} link="/terms" />
      <div className="Terms">
        <div>
          {/* p1 */}
          <div className="strong-margin-top">
            <div>
              <strong>
                <u>חנות אונליין לכלי עבודה וחומרי בניין</u>
              </strong>
            </div>
            <p>
              כל מה שצריך לפרויקטים בתחום הנדל״ן, השיפוצים ועיצוב הבית והכל
              באפליקציה אחת - זמין מכל מקום, בקצב מהיר, בלחיצה אחת..{' '}
              <strong>פשוט, נוח ובטוח </strong>
              <strong>
                המיזם החדשני שמביא לראשונה פורטפוליו מוצרים עצום בזמינות מלאה
                ומשלוח עד שעתיים לכל מקום בארץ!
              </strong>
            </p>
          </div>

          <div className="strong-margin-top">
            <div>
              <strong>
                <u>פשוט נכנסים ל dibble</u>
              </strong>
            </div>
            <p>
              מוסיפים לסל הקניות מוצרים מתוך מגוון עצום, משלמים באפליקציה
              ובוחרים את זמן המשלוח (אפשר גם תוך שעתיים)
            </p>
          </div>

          <div className="strong-margin-top">
            <div>
              <strong>
                <u>החנויות הקרובות אליכם</u>
              </strong>
            </div>
            <p>
              יוכלו להציע את המחיר הטוב ביותר עבור סל הקניות שלכם - והכל בתוך
              כמה דקות בלבד. לאחר אישור ההזמנה בחנות יאספו את המוצרים מהמדף
              ויקראו לשליח שלנו
            </p>
          </div>

          <div className="strong-margin-top">
            <div>
              <strong>
                <u>השליחים של dibble</u>
              </strong>
            </div>
            <p>
              כבר בדרך אליכם (למשרד, לאתר או לבית) עם כל החבילה שרכשתם. עם אספקת
              החבילה תחתמו שקיבלתם והכל תקין. פשוט, לא?{' '}
            </p>
          </div>

          <div className="strong-margin-top">
            <div>
              <strong>
                <u>פיננסי</u>
              </strong>
            </div>
            <p>
              תנאי מימון - אשראי, שוטף, החשבוניות במקום אחד , מגוון ערוצי תשלום
            </p>
          </div>

          <div className="strong-margin-top">
            <div>
              <strong>
                <u>מוצר</u>
              </strong>
            </div>
            <p>
              נוחות וקלות - מגוון מוצרים עשיר וזמין ריכוז חשבוניות לפי פרויקטים
              אופטימיזציה ופרסונליזציה חיסכון זמן וכסף{' '}
            </p>
          </div>

          <div className="strong-margin-top">
            <div>
              <strong>
                <u>שירות וחווית לקוח</u>
              </strong>
            </div>
            <p>
              משלוח מהיר - אספקה עד שעתיים משלוח מתוזמן צ׳אט ומוקד טלפוניים
              זמינים{' '}
            </p>
          </div>
          <div className="strong-margin-top">
            <div>
              <strong>
                <u>מערכת לניהול פרויקטים</u>
              </strong>
            </div>
            <p>
              מסלול ייעודי ללקוחות עסקיים - מנוי חודשי, דשבורד מנהלים מתעדכן,
              ניהול אשראי וחלוקת תקציב , ניהול הזמנות, שירות לקוחות ייעודי,
              היסטוריית פרויקטים ונתונים אישיים{' '}
            </p>
          </div>
          <div className="strong-margin-top">
            <div>
              <strong>
                <u>דשבורד מנהלים</u>
              </strong>
            </div>
            <p>
              מסך ראשון מסוגו לשליטה מלאה בהוצאות הפרויקטים בקרה ושליטה על
              נתונים מתעדכנים - הוצאות, הכנסות ויעדי משלוח בניית מסך פרסונלי
              מותאם לצרכי החברה
            </p>
          </div>
          <div className="strong-margin-top">
            <div>
              <strong>
                <u>דרכי תקשורת</u>
              </strong>
            </div>
            <p>
              <div>
                {' '}
                <span className="contact">טלפון:</span>
                050-9040109
              </div>
              <div>
                {' '}
                <span className="contact">כתובת:</span>
                שביל המרץ ,4 תל אביב
              </div>
            </p>
          </div>

          <br />
        </div>
      </div>
    </React.Fragment>
  );
}
