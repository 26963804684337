import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';

import NavBarDropDownItem from './NavBarDropDownItem';
import right_arrow from '../assets/icons/right_arrow.svg';
import classNames from 'classnames';

/**
 * NavBarDropDown Component.
 *
 * @param {Object} items - list of elements that will be shown in the dropdown.
 * @param {string} right - set a yellow line at right.
 * @param {string} left - set a yellow line at left.
 */
function NavBarDropDown({items, right, left}) {
  const navigate = useNavigate();

  const [activeItem, setActiveItem] = useState(undefined);
  const [itemChildren, setItemChildren] = useState([]);
  const data = JSON.parse(localStorage.getItem('userData'));

  const activeItemHandler = item => {
    if (item?.children) {
      const childrenList = Object.values(item?.children).map(child => ({
        name: child.name,
        category_id: child.category_id,
      }));
      setActiveItem(item);
      setItemChildren(childrenList);
    }
  };

  const handleBackClick = () => {
    setActiveItem(undefined);
  };

  return (
    <div
      className="nav-bar-drop-down"
      style={{left: left ? '0' : 'auto', right: right ? '0' : 'auto'}}>
      {!activeItem ? (
        <div
          className={classNames(
            'nav-bar-drop-down__box',
            right && 'nav-bar-drop-down__box--right',
            left && 'nav-bar-drop-down__box--left',
          )}>
          {items.map(item => (
            <div
              key={'nav-bar-drop-down__box-' + item.name}
              onClick={() => {
                activeItemHandler(item);
                item?.onClick && item.onClick();
              }}>
              {item.num_of_products !== 0 &&
                (item?.location ? (
                  <Link
                    to={`/${item?.location}`}
                    className={
                      item &&
                      item?.available !== undefined &&
                      item.value !== null
                        ? item?.available
                          ? ''
                          : 'drop-down-item--disable'
                        : ''
                    }>
                    <NavBarDropDownItem
                      text={item.name}
                      available={
                        item &&
                        item?.available !== undefined &&
                        item.value !== null
                          ? item?.available
                          : true
                      }
                    />
                  </Link>
                ) : (
                  <NavBarDropDownItem text={item.name} />
                ))}
            </div>
          ))}
        </div>
      ) : (
        <div className="nav-bar-drop-down__box">
          <div className="full-page">
            <div className="nav-bar-drop-down__box__children">
              <img
                src={right_arrow}
                alt="back"
                onClick={() => handleBackClick()}
                className="nav-bar-drop-down__box__children--icon"
              />
              <div>
                <div className="nav-bar-drop-down__box__children--title">
                  {activeItem?.name}
                </div>
                <div className="nav-bar-drop-down__box__children--sub-options">
                  {itemChildren.map(item => (
                    <div
                      key={'nav-bar-drop-down-box-' + item.name}
                      onClick={() =>
                        navigate(`/category?id=${item.category_id}`)
                      }>
                      <div>
                        <NavBarDropDownItem text={item.name} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NavBarDropDown;
