import Chart from 'chart.js/auto';
import Obj_heb from '../../utils/Obj_heb.json';
import React, {useEffect} from 'react';

export default function PieChart({prasentageUsed = 0}) {
  useEffect(() => {
    //  settings for the chart
    const ctx = document.getElementById('profolio_page_doughnut_chart');

    let combine_lable_data = [
      {
        color: 'rgb( 255,241,191)',
        data: prasentageUsed,
      },
      {
        color: 'rgb( 189,188,188)',
        data: 25,
      },
    ];

    const data = {
      labels: combine_lable_data.map(data => data.label),
      datasets: [
        {
          label: '%',
          data: combine_lable_data.map(data => data.data),
          backgroundColor: combine_lable_data.map(data => data.color),
          hoverOffset: 4,
          // spacing: 8,
          //   borderRadius: 10,
        },
      ],
    };

    const textForInnerCircul = {
      header: Obj_heb.frame,
      value: '',
      titleColor: 'gray',
    };

    const innerText = {
      id: 'profolio_page_doughnut_chart',
      beforeDatasetsDraw: (chart, args, pluginOptions) => {
        const {ctx, data} = chart;
        ctx.save();
        const xcoor = chart.getDatasetMeta(0).data[0].x;
        const ycoor = chart.getDatasetMeta(0).data[0].y - 20;
        ctx.font = ' 16px AlmoniDlAAA';
        ctx.fillStyle = 'rgb(112,112,112)';
        ctx.textAlign = 'center';
        ctx.textbaseLine = 'middle';
        ctx.fillText(`${Obj_heb.youUsed}`, xcoor, ycoor);
        ctx.font = ' 26px OscarFm';
        ctx.fillStyle = 'rgb(112,112,112)';
        ctx.textAlign = 'center';
        ctx.textbaseLine = 'middle';
        ctx.fillText(`${prasentageUsed}%`, xcoor, ycoor + 27);
        ctx.font = ' 16px AlmoniDlAAA';
        ctx.fillStyle = 'rgb(112,112,112)';
        ctx.textAlign = 'center';
        ctx.textbaseLine = 'middle';
        ctx.fillText(`${Obj_heb.frame}`, xcoor, ycoor + 45);
      },
    };

    const getData = tooltipItems => {
      tooltipItems?.forEach(tooltipItem => {
        textForInnerCircul.header = tooltipItem.label;
        textForInnerCircul.value = `${tooltipItem.formattedValue}%`;
        textForInnerCircul.titleColor = `${
          tooltipItem.dataset.backgroundColor[tooltipItem.dataIndex]
        }`;
      });
      return '';
    };

    const config = new Chart(ctx, {
      type: 'doughnut',
      data: data,
      plugins: [innerText],
      options: {
        responsive: true,
        cutout: 45,
        plugins: {
          title: {
            display: false,
            text: 'compeny shareholders percentage',
            font: {
              size: 20,
            },
            color: 'rgba(255,255,255,1)',
          },
          legend: {display: false},
          tooltip: {
            enabled: false,
          },
        },
      },
    });
  }, []);

  return (
    <div style={{width: '140px', height: '140px'}}>
      <div className="doughnut_chart">
        <canvas id="profolio_page_doughnut_chart"></canvas>
      </div>
    </div>
  );
}
