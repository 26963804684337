import React from 'react';
import PopUpConfirm from './PopUpConfirm';
import {useDispatch} from 'react-redux';
import {fetchData} from '../utils/fetchData';

import {fetchCreditCardsAndSetInitialList} from '../redux/store';
import obj_heb from '../utils/Obj_heb.json';
import classNames from 'classnames';
import CardDelete from '../assets/icons/cardDelete.svg';

function CreditCardDelete({
  card,
  setCardOptionsIsOpen,
  setPopUpIsOpen,
  setPopUpContent,
  setFinish,
  setFinishText,
}) {
  const dispatch = useDispatch();

  //   define favorite
  const handleClickDelete = () => {
    setPopUpIsOpen(true);
    setPopUpContent(popUpContent());
    setCardOptionsIsOpen(false);
  };

  const popUpContent = () => {
    return (
      <PopUpConfirm
        img={CardDelete}
        title={obj_heb.sureToDelete}
        subTitle={obj_heb.ConfirmWillDeleteYourCard}
        onConfirm={DeleteCard}
        onCancel={() => {
          setPopUpIsOpen(false);
          setPopUpContent(<></>);
        }}
      />
    );
  };

  const DeleteCard = () => {
    let data = localStorage.getItem('userData');
    data = JSON.parse(data);
    setFinishText(obj_heb.cardDetailsDeletedSuccessfully);

    if (!data?.token) return;

    let dataObj = {
      request: 'delete_creditcard',
      token: data.token,
      creditcard_id: card.creditcard_id,
    };

    fetchData(dataObj) // Don't assign the result to x, instead handle it using the 'then' method
      .then(response => {
        dispatch(fetchCreditCardsAndSetInitialList());

        setPopUpIsOpen(false);
        setPopUpContent(<></>);
        setFinish(true);
        setTimeout(() => {
          setFinish(false);
        }, 7800);
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  };

  return (
    <div
      onClick={handleClickDelete}
      className={classNames('credit-card__edit--field')}>
      <div
        className={classNames(
          'credit-card__edit--field--text',
          'credit-card__edit--field--text--red',
        )}>
        {obj_heb.deleteCard}
        <span className="is-hover" />
      </div>
    </div>
  );
}

export default CreditCardDelete;
