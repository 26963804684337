import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import obj_heb from '../utils/Obj_heb.json';
import ShoppingCartItemAmount from './shoppingCart/ShoppingCartItemAmount';
function InstallmentInput(props) {
  const [amount, setAmount] = useState(1);

  const isMobile = useSelector(state => state.mobile.isMobile);

  // open the correct modal
  const incrementFunction = () => {
    if (amount < 12) {
      setAmount(amount + 1);
      props.setInstallments(Number(amount + 1));
    }
  };
  const decrementFunction = () => {
    if (amount === 1) {
      return;
    } else {
      setAmount(amount - 1);
      props.setInstallments(Number(amount - 1));
    }
  };

  return (
    <div className="installments">
      <span className="installments__text">{obj_heb.installments}</span>
      <div className="installments__counter">
        <ShoppingCartItemAmount
          Amount={amount}
          isActive={true}
          decrementFunction={decrementFunction}
          incrementFunction={incrementFunction}
          setWidth={15.8}
          setHight={isMobile ? 5.6 : 3.6}
          btnSize={isMobile ? 4 : 2.8}
          fontSize={2.2}
          boxShadow={isMobile}
        />
      </div>
    </div>
  );
}

export default InstallmentInput;
