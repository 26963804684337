import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import PopUp from '../components/general/PopUp';
import TextInput from './TextInput';
import Button from './Button';
import Loader from '../components/Loader';
import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';
import {icons} from '../assets/constants';

function CancelProjectOrderModal({isOpen, setIsOpen, orderId}) {
  let userData = JSON.parse(localStorage.getItem('userData'));

  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(null);
  const [pullValidation, setPullValidation] = useState(false);
  const [pushValidation, setPushValidation] = useState(false);
  const cancel = () => {
    let dataObj2 = {
      request: 'reject_pending_order',
      order_id: orderId,
    };
    fetchData(dataObj2)
      .then(response => {
        console.log(`response`, response);
        setIsOpen(false);
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  };
  return (
    <>
      <PopUp isOpen={isOpen} setIsOpen={setIsOpen}>
        {isLoading ? (
          <div className="user-add__loading">
            <Loader />
            <span>{obj_heb.waitingForConfirmation}</span>
          </div>
        ) : (
          <div className="user-add">
            <span className="user-add__title">ביטול רכישה </span>
            <span className="user-add__text">
              האם אתה בטוח שברצונך לבטל את הזמנה {orderId}?{' '}
            </span>

            {errors.length > 0 && (
              <div className="user-add__invalid-user">
                {errors.map((error, index) => (
                  <p key={index}>{error}</p>
                ))}
              </div>
            )}

            <Button
              text={obj_heb.approve}
              disable={pushValidation || pullValidation}
              onClick={cancel}
              textColor="white"
              backgroundColor={
                pushValidation || pullValidation ? '#BDBCBC' : '#FFCA1A'
              }
              className="user-add__btn"
              id="user-add__btn"
            />
          </div>
        )}
      </PopUp>
    </>
  );
}

export default CancelProjectOrderModal;
