import {createSlice} from '@reduxjs/toolkit';
import {fetchData} from '../../utils/fetchData';

const activeOrdersSlice = createSlice({
  name: 'activeOrders',
  initialState: {
    activeOrdersCount: 0,
    activeOrders: [],
  },
  reducers: {
    updateActiveOrdersCount: (state, action) => {
      state.activeOrdersCount = action.payload;
    },
    clearActiveOrdersCount: state => {
      state.activeOrdersCount = 0;
    },
    updateActiveOrders: (state, action) => {
      state.activeOrders = action.payload;
    },
  },
});

export const fetchActiveOrdersAndSetInitialList = () => async dispatch => {
  let data = localStorage.getItem('userData');
  data = JSON.parse(data);
  if (data?.token) {
    let dataObj = {
      request: 'get_my_active_orders',
      token: data.token,
    };
    try {
      const response = await fetchData(dataObj);
      const activeOrders = response.orders.filter(order => order.status < 7);
      dispatch(updateActiveOrdersCount(activeOrders.length));
      dispatch(updateActiveOrders(activeOrders));
    } catch (error) {
      console.error('initializeActiveOrder', error);
    }
  } else {
    console.log(`no data token`);
  }
};

export const {
  updateActiveOrdersCount,
  updateActiveOrders,
  clearActiveOrdersCount,
} = activeOrdersSlice.actions;
export default activeOrdersSlice.reducer;
