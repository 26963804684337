// react addons
import React, {useEffect, useState} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

//components
import MoreProductsCarousel from '../components/MoreProductsCarusel';
import SEO from '../components/SEO';
import MoreInfo from '../components/product/MoreInfo';
import Loader from '../components/Loader';
import ProductContent from '../components/product/ProductContent';
import Toast from '../components/Toast';

// functions
import {getOptionsArray} from '../utils/supportFunctions';
import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';

// redux
import {
  ShoppingCart_addProduct,
  ShoppingCart_removeProduct,
} from '../redux/slices/ShoppingCartSlice';
import {fetchLikedProductsAndSetInitialList} from '../redux/store';
/**
 * isLoading - used to know when the loader run
 * isFinish - used for activate and run the footer
 * productMainData - for storing all the data from the fetch and storing manipulated data
 * available_options1 - the value is the optional VALID options1
 * available_options2 - the value is the optional VALID options2
 */
export default function Product() {
  const [productAmount, setProductAmount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinish, setIsFinish] = useState(() => false);
  const [productMainData, setProductMainData] = useState({
    product_id: '',
    title: '',
    catalog_number: 0,
    price: 0,
    image: '',
    black_Product: false,
    sale: false,
    sale_price: 0,
    weight: 0,
    quantity: 1,
    options1: '',
    options1_name: '',
    option1_index: '',
    option1_value: '',
    options2: '',
    options2_name: '',
    option2_index: '',
    option2_value: '',
    other_images: [],
    is_liked: false,
  });
  const [available_options1, setAvailable_options1] = useState([]);
  const [available_options2, setAvailable_options2] = useState([]);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData')),
  );

  // grab the params and the qury selector
  let params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access specific query parameters
  const option1_index_query = queryParams.get('option1_index');
  const option2_index_query = queryParams.get('option2_index');

  //redux
  const Dispatch = useDispatch();

  const likedProducts = useSelector(
    state => state.likedProducts.likedProductList,
  );

  const isLiked = product_id => {
    return likedProducts?.products?.filter(
      item => item?.product_id === product_id,
    )[0];
  };

  // the main function that getting  and parsing the received data
  useEffect(() => {
    setProductAmount(1);
    setIsLoading(true);
    let dataObj = {
      request: 'get_product',
      token: userData?.token || '',
      product_id: Number(params.product_id),
    };
    fetchData(dataObj)
      .then(response => {
        let data = response;
        setIsLoading(false);
        data.options_prices = data?.options_prices.filter(
          item => item.price > 0,
        );
        data.options_prices?.sort((a, b) => a.price - b.price);
        const optionsArray = getOptionsArray(data);
        setAvailable_options1(optionsArray[0].data);
        setAvailable_options2(optionsArray[1].data);

        let query_data_for_state;
        if (
          option1_index_query !== null &&
          option1_index_query !== '' &&
          option2_index_query !== null &&
          option2_index_query !== ''
        ) {
          query_data_for_state = {
            option1_index: option1_index_query,
            option1_value: data?.options_prices?.filter(
              item => item.option1_index === option1_index_query,
            )[0].option1,
            option2_index: option2_index_query,
            option2_value: data?.options_prices?.filter(
              item => item.option2_index === option1_index_query,
            )[0].option2,
          };
        } else if (
          (option1_index_query != null &&
            option1_index_query !== '' &&
            option2_index_query == null) ||
          option2_index_query === ''
        ) {
          query_data_for_state = {
            option1_index: option1_index_query,
            option1_value: data?.options_prices?.filter(
              item => item.option1_index === option1_index_query,
            )[0].option1,
          };
        }

        setProductMainData(prev => ({
          ...prev,
          ...data,
          sale_price: data.sale_price,
          black_Product: data.can_return,

          option1_value:
            query_data_for_state?.option1_value ||
            (data?.options_prices && data?.options_prices[0]?.option1),
          option1_index:
            query_data_for_state?.option1_index ||
            (data?.options_prices && data?.options_prices[0]?.option1_index),
          option2_value:
            query_data_for_state?.option2_value ||
            (data?.options_prices && data?.options_prices[0]?.option2),
          option2_index:
            query_data_for_state?.option2_index ||
            (data?.options_prices && data?.options_prices[0]?.option2_index),
          is_like: isLiked(data.product_id),
        }));
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });

    return setIsFinish(false);
  }, [userData, params]);

  const like_disLiked_product = product_id => {
    let data = localStorage.getItem('userData');
    data = JSON.parse(data);

    let dataObj = {
      request: 'set_user_product_like',
      token: data?.token,
      product_id: Number(product_id),
      is_like: !productMainData.is_liked,
    };
    fetchData(dataObj)
      .then(response => {
        fetchLikedProductsAndSetInitialList();
        setProductMainData(prev => ({...prev, is_liked: !prev.is_liked}));
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  };

  /**
   *this function is to remove not optional pairs of more then one multi potion
   * @param {string} chosenOption - the option value that chosen
   */

  const pickedOption = chosenOption => {
    // check if the user selection is option 1 , it is if it is retune
    const userSelectInPart1 = productMainData.options_prices.find(
      i => i['option1'] === chosenOption,
    );
    if (userSelectInPart1) {
      let arrayOfValidOption2Options = productMainData.options_prices
        .filter(item => item['option1'] === chosenOption)
        .map(i => i.option2);
      setAvailable_options2(arrayOfValidOption2Options);

      // check if the saved option2 is valid option with the users choise
      if (
        !productMainData.options_prices.find(
          i =>
            i.option1 === chosenOption &&
            i.option2 === productMainData.option2_value,
        )
      ) {
        // if it isn't valid choice , find the first valid choice with the user pick, and set it as the second option
        const available_second_option = productMainData.options_prices.filter(
          item => item.option1 === chosenOption,
        )[0];

        pickedOption(available_second_option.option2, 0);

        setProductMainData(prev => ({
          ...prev,
          option1_value: available_second_option.option1,
          option1_index: available_second_option.option1_index,
          option2_value: available_second_option.option2,
          option2_index: available_second_option.option2_index,
          price: available_second_option.price,
        }));
      } else {
        //  its valid choice , set the state of the option to the user choice

        let activeSelection = productMainData.options_prices.find(
          i =>
            i.option1 === chosenOption &&
            i.option2 === productMainData.option2_value,
        );

        setProductMainData(prev => ({
          ...prev,
          option1_value: activeSelection.option1,
          option1_index: activeSelection.option1_index,
          price: activeSelection.price,
        }));
      }
    } else {
      // user change value in the second option
      let arrayOfValidOption1Options = productMainData.options_prices
        .filter(item => item['option2'] === chosenOption)
        .map(i => i.option1);
      setAvailable_options1(arrayOfValidOption1Options);

      // check if the saved option1 is valid option with the users choise
      if (
        !productMainData.options_prices.find(
          i =>
            i.option2 === chosenOption &&
            i.option1 === productMainData.option1_value,
        )
      ) {
        // if it isn't valid choice , find the first valid choice with the user pick, and set it as the first option

        const available_second_option = productMainData.options_prices.filter(
          item => item.option2 === chosenOption,
        )[0];

        setProductMainData(prev => ({
          ...prev,
          option1_value: available_second_option.option1,
          option1_index: available_second_option.option1_index,
          option2_value: available_second_option.option2,
          option2_index: available_second_option.option2_index,
          price: available_second_option.price,
        }));
      } else {
        // user change value in the second option

        let activeSelection = productMainData.options_prices.find(
          i =>
            i.option2 === chosenOption &&
            i.option1 === productMainData.option1_value,
        );

        setProductMainData(prev => ({
          ...prev,
          option2_value: activeSelection?.option2,
          option2_index: activeSelection?.option2_index,
          price: activeSelection?.price,
        }));
      }
    }
  };
  const reduxAddToCartProductItem = {
    product: {
      product_id: productMainData?.product_id,
      name: productMainData?.name,
      image: productMainData?.image,
      catalog_number: productMainData?.catalog_number,
      weight: productMainData?.weight,
      price: productMainData?.price,
      sale:
        productMainData?.sale_price !== 0 &&
        productMainData?.sale_price < productMainData?.price,
      sale_price: productMainData?.sale_price,
      black_Product: productMainData?.black_Product,
      quantity: productAmount,
      option1_value: productMainData?.option1_value,
      option1_index: productMainData?.option1_index,
      option2_value: productMainData?.option2_value,
      option2_index: productMainData?.option2_index,
      options1_name: productMainData?.options1_name,
      options2_name: productMainData?.options2_name,
      need_crane: productMainData?.need_crane,
    },
    oparation: 'increase',
  };
  const addToCart = () => {
    Dispatch(ShoppingCart_addProduct(reduxAddToCartProductItem));
    setIsFinish(true);
    setTimeout(() => {
      setIsFinish(false);
    }, 7800);
  };
  /**
   * to use when the product  is already in the shopping cart so it will remove the item and add the new product
   * @param {string} key the key of the product from redux store
   */
  const updateShoppingCart = key => {
    Dispatch(ShoppingCart_removeProduct({key: key}));
    Dispatch(ShoppingCart_addProduct(reduxAddToCartProductItem));
  };

  const SEOLoader = () => {
    const ProductLink = `/product/${productMainData.product_id}`;
    return productMainData?.name ? (
      <SEO
        pageName={productMainData?.name}
        content={obj_heb.cartDesc}
        link={ProductLink}
      />
    ) : null;
  };

  return (
    <React.Fragment>
      <SEOLoader />
      <div className="Product " key={productMainData.product_id}>
        {isLoading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          <div className="Product__content">
            <ProductContent
              key={`${productMainData?.name}_${productMainData?.product_id}`}
              productMainData={productMainData}
              pickedOption={pickedOption}
              productAmount={productAmount}
              addToCart={addToCart}
              setProductAmount={setProductAmount}
              isLoading={isLoading}
              isLiked={productMainData.is_liked}
              userData={userData}
              liked_disLikedFunction={() =>
                like_disLiked_product(productMainData.product_id)
              }
              dropDownOptions1={available_options1}
              dropDownOptions2={available_options2}
              updateShoppingCart={updateShoppingCart}
            />

            <MoreInfo productMainData={productMainData} />

            <div className="">
              <MoreProductsCarousel
                header={obj_heb.otherAlsoChecked}
                productList={
                  productMainData?.other_searched?.map(item => {
                    return {
                      imgLink: item.image,
                      product_id: item.product_id,
                      txtBeforeThePrice: obj_heb.priceStartAt,
                      productOnSale: false,
                      salePrice: 0,
                      price: item.price,
                      title: item.name,
                    };
                  }) || []
                }
              />
            </div>
          </div>
        )}
        {isFinish && <Toast text={obj_heb.productAddedToCart} />}
      </div>
    </React.Fragment>
  );
}
