import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import TextInput from '../components/TextInput.jsx';
import Button from '../components/Button.jsx';
import Title from '../components/Title';
import Dropdown from '../components/general/DropDown.jsx';
import {useNavigate, useParams} from 'react-router-dom';
import img from '../assets/images/shutterstock_567718675.png';
import img2 from '../assets/images/img_bg2.png';
import img3 from '../assets/images/img_bg3.png';
import img4 from '../assets/images/img_bg4.png';
import img5 from '../assets/images/img_bg5.png';
import img7 from '../assets/images/img_bg7.png';
import {fetchData} from '../utils/fetchData.js';
import obj_heb from '../utils/Obj_heb.json';
import Toggle from '../components/general/Toggle.jsx';
import icons from '../assets/constants/icons.js';
import moment from 'moment';
import SearchProjectUserPopUp from '../components/SearchProjectUserPopUp.jsx';
import GoogleSearch from '../components/GoogleSearch.jsx';

const AddProjectPage = ({props}) => {
  const params = useParams();
  const {project_id} = useParams();
  const pageTitle = project_id ? obj_heb.editProject : obj_heb.createNewProject;
  const isMobile = useSelector(state => state.mobile.isMobile);
  const [isOrderNeedPermission, setIsOrderNeedPermission] = useState(false);
  const [sendUsedBudgetNotifications, setSendUsedBudgetNotifications] =
    useState(false);
  const [sendNotificationsForSales, setSendNotificationsForSales] =
    useState(false);
  const [isOnBudget, setIsOnBudget] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [contactName, setContactName] = useState('');
  const [email, setEmail] = useState('');
  const [budget, setBudget] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [lat, setLat] = useState('');
  const [lon, setLon] = useState('');
  const [managerOrderLimit, setManagerOrderLimit] = useState('0');
  const [projectDetails, setProjectDetails] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [plusBtnSrc, setPlusBtnSrc] = useState(icons.gray_plus_btn); // State for image src
  const [isProjectManager, setIsProjectManager] = useState(false);
  const [isAddWorkerModalOpen, setIsAddWorkerModalOpen] = useState(false);
  const [projectManagerUseBalanceOnly, setProjectManagerUseBalanceOnly] =
    useState(false);
  useState(false);
  const handleManagerOrderLimitChange = e =>
    setManagerOrderLimit(e.target.value);
  const handleCompanyNameChange = e => setCompanyName(e.target.value);
  const handleMailChange = e => setEmail(e.target.value);
  const handleBudgetChange = e => setBudget(e.target.value);
  const handleStartDate = e => setStartDate(e.target.value);
  const handleEndDate = e => setEndDate(e.target.value);
  const isCompanyNameValid = () => companyName.trim() !== '';
  const isContactNameValid = () => contactName.trim() !== '';
  const isEmailValid = () => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isPhoneValid = () => /^\+?\d{7,15}$/.test(phone);
  const resetInput = () => setCompanyName('');
  const resetInputAddress = () => setAddress('');
  const resetInputMail = () => setEmail('');
  const resetInputBudget = () => setBudget('');
  const resetInputStartDate = () => setStartDate('');
  const resetInputEndDate = () => setEndDate('');
  const resetInputLimit = () => setManagerOrderLimit('');
  const navigate = useNavigate();
  const images = [img, img2, img3, img4, img5, img7];
  let userData = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    setSelectedImage(getRandomImage());
  }, []);

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };

  const handleAddWorker = action => {
    if (action === 'manager') {
      setIsProjectManager(true);
      setIsAddWorkerModalOpen(true);
    } else {
      setIsProjectManager(false);
      setIsAddWorkerModalOpen(true);
    }
  };
  const formValid = useMemo(() => {
    return isCompanyNameValid();
  }, [companyName, contactName, email, phone]);

  const removeWorker = (type, id) => {
    let managers = [];
    if (type === 'manager') {
      managers =
        projectDetails?.project_managers?.filter(pm => pm.user_id !== id) || [];
    } else {
      managers =
        projectDetails?.signature_permitted?.filter(sp => sp.user_id !== id) ||
        [];
    }
    managers = managers.map(manager => ({
      ...manager,
      contractor_user_id: manager.user_id,
    }));
    setIsLoading(true);
    let dataObj = {
      request:
        type == 'manager' ? 'set_project_managers' : 'set_project_sig_perms',
      token: userData?.token,
      project_id: projectDetails?.project_info?.project_id,
    };
    dataObj[type == 'manager' ? 'project_managers' : 'signature_permitted'] =
      managers;

    fetchData(dataObj)
      .then(response => {
        if (response.message === 'success') {
          setIsLoading(false);
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error:', error.message);
      });
  };
  const handleProjectAdd = async () => {
    let dataObj = {
      request: project_id ? 'update_project' : 'add_project',
      name: companyName,
      project_id: project_id,
      address: project_id ? address : address.address,
      lat: lat,
      lon: lon,
      manager_order_limit: managerOrderLimit,
      invoice_emails: [{email: email}],
      validatedAddress: project_id ? address : address.address,
      estimated_budget: budget,
      is_on_budget: isOnBudget ? 1 : 0,
      start_date: startDate,
      end_date: endDate,
      is_orders_need_permission: isOrderNeedPermission ? 1 : 0,
      send_used_budget_notification: sendUsedBudgetNotifications ? 1 : 0,
      send_notification_for_sales: sendNotificationsForSales ? 1 : 0,
      project_managers: projectDetails?.project_managers?.map(item => ({
        contractor_user_id: item.user_id,
      })),
      signature_permitted: projectDetails?.signature_permitted?.map(item => ({
        contractor_user_id: item.user_id,
      })),
    };
    fetchData(dataObj)
      .then(response => {
        if (response?.message === 'success') {
          console.log(`send_mail API return success`);
          if (project_id) {
            navigate(`/project-details/${project_id}`);
          } else {
            navigate(`/project-details/${response.project_id}`);
          }
        }
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  };

  const fetchAllData = () => {
    setIsLoading(true);
    let dataObj = {
      request: 'get_my_project_details',
      token: userData?.token || '',
      project_id: Number(params.project_id),
    };
    fetchData(dataObj)
      .then(response => {
        let data = response;
        setProjectDetails(data);
        setIsOrderNeedPermission(data?.project_info?.is_orders_need_permission);
        setSendUsedBudgetNotifications(
          data?.project_info?.send_used_budget_notification,
        );
        setSendNotificationsForSales(
          data?.project_info?.send_notification_for_sales,
        );
        setIsOnBudget(data?.project_info?.is_on_budget);
        setCompanyName(data?.project_info?.name);
        setBudget(data?.project_info?.estimated_budget);
        setEmail(data?.project_info?.invoice_emails[0]);
        setAddress(data?.project_info?.address);
        setStartDate(
          moment(data?.project_info?.start_date).format('YYYY-MM-DD'),
        );
        setEndDate(moment(data?.project_info?.end_date).format('YYYY-MM-DD'));
        setLat(data?.project_info?.address_lat);
        setLon(data?.project_info?.address_lon);
        setManagerOrderLimit(data?.project_info?.manager_order_limit);
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  };
  useEffect(() => {
    if (project_id) {
      fetchAllData();
    }
  }, [project_id]);

  useEffect(() => {
    if (!project_id) {
      setLat(address.lat);
      setLon(address.lon);
    }
  }, [address]);

  return (
    <React.Fragment>
      <div className="addProject">
        <img
          className="addProject__img"
          src={selectedImage}
          alt="background-image"
        />
        <div className="addProject__pop-up">
          <div
            className="addProject__pop-up__text-wrapper"
            style={{fontWeight: 800}}>
            <Title
              title={pageTitle}
              style="addProject__container--title"
              center={true}
            />
            <div
              className="addProject__container--text--bold"
              style={{
                textAlign: 'center',
                color: !isMobile ? '#707070' : '',
              }}></div>
          </div>
          <div className="addProject--gap-24">
            <div className="addProject--flex-row">
              <TextInput
                value={companyName}
                onChange={handleCompanyNameChange}
                onClear={resetInput}
                description={obj_heb.projectName}
                placeholderText={obj_heb.projectName}
                errorMsg={obj_heb.projectNameError}
                IsError={!isCompanyNameValid()}
                directError={companyName}
              />

              <GoogleSearch
                address={address}
                setAddress={setAddress}
                resetAddress={resetInputAddress}
              />
            </div>
            <div className="addProject--flex-row">
              <TextInput
                value={email}
                onChange={handleMailChange}
                onClear={resetInputMail}
                description={obj_heb.projectMail}
                placeholderText={obj_heb.projectMail}
                errorMsg={obj_heb.projectMailError}
                IsError={!isCompanyNameValid()}
                directError={companyName}
                type="email"
              />
              <TextInput
                value={budget}
                onChange={handleBudgetChange}
                onClear={resetInputBudget}
                description={obj_heb.projectBudget}
                placeholderText={obj_heb.projectBudget}
                errorMsg={obj_heb.projectBudgetError}
                IsError={!isCompanyNameValid()}
                directError={companyName}
                type="number"
              />
            </div>
            <div className="addProject--flex-row">
              <TextInput
                value={startDate}
                onChange={handleStartDate}
                onClear={resetInputStartDate}
                description={obj_heb.projectStartDate}
                placeholderText={obj_heb.projectStartDate}
                errorMsg={obj_heb.projectMailError}
                IsError={!isCompanyNameValid()}
                directError={companyName}
                type="date"
              />

              <TextInput
                value={endDate}
                onChange={handleEndDate}
                onClear={resetInputEndDate}
                description={obj_heb.projectEndDate}
                placeholderText={obj_heb.projectEndDate}
                errorMsg={obj_heb.projectMailError}
                IsError={!isCompanyNameValid()}
                directError={companyName}
                type="date"
              />
            </div>

            <div className="w-50 text-center mb-2">
              <Title
                title={obj_heb.personalProjectSettings}
                style="addProject__container--title"
                center={true}
              />
              <div className="addProject--personalSettings">
                <div className="addProject--personalSettings--part_1">
                  <div className="LobbyCreditsTab_part1_hr">
                    _horizintalLine
                  </div>

                  <div className="LobbyCreditsTab_part1_item-box2">
                    <div className="LobbyCreditsTab_part1_item-box2_text-box">
                      <div className="LobbyCreditsTab_part1_item-box2_text-box_title">
                        {obj_heb.project_managers}
                      </div>
                      <div className="LobbyCreditsTab_part1_item-box2_text-box_sub-title">
                        {projectDetails?.project_managers?.map(
                          (manager, index) => (
                            <div key={index}>
                              {manager.name}
                              <img
                                onClick={() =>
                                  removeWorker('manager', manager.user_id)
                                }
                                style={{
                                  height: '1.5rem',
                                  width: '1.5rem',
                                  marginLeft: '2rem',
                                  marginRight: '0.5rem',
                                }}
                                src={icons.yellow_minus_btn}></img>

                              {index <
                                projectDetails?.project_managers?.length - 1 &&
                                ' , '}
                            </div>
                          ),
                        )}
                        {!projectDetails?.project_managers?.length && 'ללא'}
                        <img
                          onClick={() => handleAddWorker('manager')}
                          style={{
                            width: '1.5rem',
                            marginRight: '3px',
                            cursor: 'pointer',
                          }}
                          src={plusBtnSrc}
                          alt="plus"
                          onMouseEnter={() =>
                            setPlusBtnSrc(icons.yellow_plus_btn)
                          }
                          onMouseLeave={() =>
                            setPlusBtnSrc(icons.gray_plus_btn)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="LobbyCreditsTab_part1_hr">
                    _horizintalLine
                  </div>
                  <div className="LobbyCreditsTab_part1_item-box2">
                    <div className="LobbyCreditsTab_part1_item-box2_text-box">
                      <div className="LobbyCreditsTab_part1_item-box2_text-box_title">
                        {obj_heb.signature_permitted}
                      </div>
                      <div className="LobbyCreditsTab_part1_item-box2_text-box_title">
                        {projectDetails?.signature_permitted?.map(
                          (signature, index) => (
                            <div key={index}>
                              {signature?.name}
                              <img
                                onClick={() =>
                                  removeWorker('sign', signature.user_id)
                                }
                                style={{
                                  height: '1.5rem',
                                  width: '1.5rem',
                                  marginLeft: '2rem',
                                  marginRight: '0.5rem',
                                }}
                                src={icons.yellow_minus_btn}></img>
                              {index <
                                projectDetails?.signature_permitted?.length -
                                  1 && ' , '}
                            </div>
                          ),
                        )}
                        {!projectDetails?.signature_permitted?.length && 'ללא'}
                        <img
                          onClick={() => handleAddWorker('sign')}
                          style={{
                            width: '1.5rem',
                            marginRight: '3px',
                            cursor: 'pointer',
                          }}
                          src={plusBtnSrc}
                          alt="plus"
                          onMouseEnter={() =>
                            setPlusBtnSrc(icons.yellow_plus_btn)
                          }
                          onMouseLeave={() =>
                            setPlusBtnSrc(icons.gray_plus_btn)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="addProject--personalSettings--part_2">
                  <div
                    className="addProject--settings"
                    style={{marginTop: '3.2rem'}}>
                    <span className="addProject--settings--span">
                      {obj_heb.projectNeedPermission}
                    </span>
                    <Toggle
                      toggle={isOrderNeedPermission}
                      handleToggleChange={() =>
                        setIsOrderNeedPermission(!isOrderNeedPermission)
                      }
                    />
                  </div>
                  {isOrderNeedPermission && (
                    <TextInput
                      value={managerOrderLimit}
                      onChange={handleManagerOrderLimitChange}
                      onClear={resetInputLimit}
                      description={obj_heb.till}
                      placeholderText={obj_heb.putammount}
                      errorMsg={obj_heb.projectNameError}
                      IsError={!isCompanyNameValid()}
                      directError={companyName}
                    />
                  )}
                  <div className="addProject--flex-row"></div>

                  <div className="addProject--settings">
                    <span className="addProject--settings--span">
                      {obj_heb.inform10Precent}
                    </span>
                    <Toggle
                      toggle={sendUsedBudgetNotifications}
                      handleToggleChange={() =>
                        setSendUsedBudgetNotifications(
                          !sendUsedBudgetNotifications,
                        )
                      }
                    />
                  </div>

                  <div className="addProject--settings">
                    <span className="addProject--settings--span">
                      {obj_heb.getMeNotifications}
                    </span>
                    <Toggle
                      toggle={sendNotificationsForSales}
                      handleToggleChange={() =>
                        setSendNotificationsForSales(!sendNotificationsForSales)
                      }
                    />
                  </div>

                  <div className="addProject--settings">
                    <span className="addProject--settings--span">
                      {obj_heb.onlyFromFrojectBalance}
                    </span>
                    <Toggle
                      toggle={isOnBudget}
                      handleToggleChange={() => setIsOnBudget(!isOnBudget)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Button
            text={
              formValid && project_id
                ? obj_heb.editProjectChanges
                : formValid && !project_id
                ? obj_heb.createNewProject
                : obj_heb.createNewProjectError
            }
            disable={!formValid}
            onClick={() => handleProjectAdd()}
            textColor="white"
            backgroundColor={formValid ? '#FFCA1A' : '#BDBCBC'}
            className="addProject--margin-horizontal-16"
          />
        </div>
      </div>
      {isAddWorkerModalOpen && (
        <SearchProjectUserPopUp
          isOpen={isAddWorkerModalOpen}
          setIsOpen={setIsAddWorkerModalOpen}
          isProjectManager={isProjectManager}
          projectId={Number(params.project_id)}
          projectManagers={projectDetails?.project_managers}
          signaturePermitted={projectDetails?.signature_permitted}
          fetchAllData={fetchAllData}
        />
      )}
    </React.Fragment>
  );
};

export default AddProjectPage;
