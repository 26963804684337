import React, {useEffect, useMemo, useState} from 'react';
import {numberWithCommas} from '../utils/supportFunctions';
import obj_heb from '../utils/Obj_heb.json';
import dibbleCoinIcon from '../assets/icons/dibbleCoinIcon.svg';
import present_icon from '../assets/icons/present_icon.svg';
import top_arrow from '../assets/icons/top_arrow.svg';
import chart from '../assets/icons/chart.svg';
import x_icon from '../assets/icons/x_icon.svg';
import OrderHistoryItem from './OrderHistoryItem';
import Pagination from './Pagination';
import OrderHistoryMaterialItem from './orderHistoryMaterialItem';

/**
 *
 * @param {*} props.orderData the data from the serve for each order,
 * @param {number} props.currentPaginationPage ,
 * @param {number} props.PageSize the amount of item per page
 * @param {setState} props.onPageChange a function of setState for changing the currentPaginationPage,
 * @returns
 */
export default function MyMaterialsOrdersTab({
  orderData,
  currentPaginationPage,
  PageSize,
  onPageChange,
}) {
  const dataToDisplay = useMemo(() => {
    const firstPageIndex = (currentPaginationPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return orderData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPaginationPage]);

  const baseValue = orderData?.slice(0, 10);

  useEffect(() => {
    onPageChange(1);
  }, []);
  return (
    <>
      <div className="MyOrdersTab">
        {/* {
          !orderData?.length  && (
            <div className="MyOrdersTab_empty-list">
              <div className="MyOrdersTab_empty-list_text">
                <div className="MyOrdersTab_empty-list_text_title">
                  {obj_heb.timeToMakeHistory}
                </div>
                <div className="MyOrdersTab_empty-list_text_sub-title">
                  {obj_heb.hereWillShowenYourParches}
                </div>
              </div>
            </div>
          )} */}

        {dataToDisplay == undefined
          ? baseValue?.map(order => <OrderHistoryMaterialItem item={order} />)
          : dataToDisplay?.map(order => (
              <OrderHistoryMaterialItem item={order} />
            ))}
      </div>
    </>
  );
}
