import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import obj_heb from '../utils/Obj_heb.json';
import arrow_head_down from '../assets/icons/arrow_head_down.svg';
import classNames from 'classnames';

import CreditCardFavorite from './CreditCardFavorite';
import CreditCardDelete from './CreditCardDelete';
import CreditCardAddition from './CreditCardAddition';
import PopUp from '../components/general/PopUp';
import creditCard from '../assets/icons/CreditCards/creditCard.svg';
import CreditCardEdition from './CreditCardEdition';
import Modal from './general/Modal';
import CreditCardModalChildren from './CreditCardModalChildren';

function CreditCard({
  card,
  list,
  onSelect,
  cardSelected,
  setFinish,
  setFinishText,
  className,
}) {
  const [cardOptionsIsOpen, setCardOptionsIsOpen] = useState(false);
  const [editCardIsOpen, setEditCardIsOpen] = useState(false);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [popUpContent, setPopUpContent] = useState(<></>);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const isMobile = useSelector(state => state.mobile.isMobile);
  const cardRef = useRef();

  const image = card?.brand
    ? require(`../assets/icons/CreditCards/${card?.brand}.svg`)
    : creditCard;

  const handleCardClick = () => {
    setCardOptionsIsOpen(prev => !prev);
    setIsModalOpen(prev => !prev);
    if (list === 'summary') {
      onSelect(card);
    }
  };
  const isSelected = () => {
    if (cardSelected) {
      if (cardSelected.creditcard_id === card.creditcard_id) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    // Function to handle click outside the div
    const handleClickOutside = event => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        // The click is outside the div, do something here
        setCardOptionsIsOpen(prev => false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="credit-card" ref={cardRef}>
        <div
          className={classNames(
            'credit-card__card ',
            list !== 'summary' &&
              list === 'summary' &&
              isSelected(card) &&
              'credit-card__card--selected',
            list !== 'summary' &&
              card.is_default &&
              'credit-card__card--favorite',
            className && className,
          )}
          onClick={() => handleCardClick()}>
          <div className="credit-card__card__details">
            <img className="credit-card__card--img" src={image} alt="card" />
            <div className="credit-card__card__details--info">
              <span className="credit-card__card__details--info--type">
                {card?.name || obj_heb.creditCard}
              </span>
              <span
                className={classNames(
                  (list === 'summary' ? isSelected(card) : card.is_default) &&
                    'credit-card__card__details--favorite',
                )}>
                {obj_heb.endsWithDigits} ****{card.card_num}
              </span>
            </div>
          </div>
          {list !== 'summary' && (
            <img
              src={arrow_head_down}
              alt=""
              className={classNames(
                'credit-card__card__arrow',
                cardOptionsIsOpen && 'flip',
              )}
            />
          )}
          {list === 'summary' && <span className="is-hover" />}
        </div>
        {!isMobile ? (
          cardOptionsIsOpen &&
          list != 'summary' && (
            <div className="credit-card__edit">
              <CreditCardFavorite
                card={card}
                setCardOptionsIsOpen={setCardOptionsIsOpen}
                setPopUpIsOpen={setPopUpIsOpen}
                setPopUpContent={setPopUpContent}
              />
              <CreditCardEdition
                setCardOptionsIsOpen={setCardOptionsIsOpen}
                setPopUpIsOpen={setEditCardIsOpen}
                setFinish={setFinish}
                setFinishText={setFinishText}
              />
              <CreditCardDelete
                card={card}
                setCardOptionsIsOpen={setCardOptionsIsOpen}
                setPopUpIsOpen={setPopUpIsOpen}
                setPopUpContent={setPopUpContent}
                setFinish={setFinish}
                setFinishText={setFinishText}
              />
            </div>
          )
        ) : (
          <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
            <CreditCardModalChildren
              card={card}
              setCardOptionsIsOpen={setCardOptionsIsOpen}
              setPopUpIsOpen={setPopUpIsOpen}
              setPopUpContent={setPopUpContent}
              setEditCardIsOpen={setEditCardIsOpen}
              setFinish={setFinish}
              setFinishText={setFinishText}
            />
          </Modal>
        )}
      </div>
      {popUpIsOpen && (
        <PopUp isOpen={popUpIsOpen} setIsOpen={setPopUpIsOpen}>
          {popUpContent}
        </PopUp>
      )}
      {editCardIsOpen && (
        <CreditCardAddition
          isOpen={editCardIsOpen}
          setIsOpen={setEditCardIsOpen}
          isEdit={true}
          card={card}
          setFinish={setFinish}
          setFinishText={setFinishText}
        />
      )}
    </>
  );
}

export default CreditCard;
