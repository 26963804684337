import React, {useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import AddAndEditUserPopUp from './AddAndEditUserPopUp'; // Import the modal component
import {useSelector} from 'react-redux';

export default function UserListItem({item}) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State for modal visibility
  const isMobile = useSelector(state => state.mobile.isMobile);

  const handleEditClick = () => {
    setIsEditModalOpen(true); // Open the modal in edit mode
  };

  return (
    <>
      <div className="OrderHistoryItem__container">
        {isMobile ? (
          <div
            className={'OrderHistoryItem OrderHistoryItem_pointer'}
            onClick={handleEditClick} // Open modal or redirect based on status
            style={item?.status > 6 ? {cursor: 'pointer'} : {}}>
            <div className="OrderHistoryItem_title">
              {item.first_name + ' ' + item.last_name}
              <div className="OrderHistoryItem_centerGroup_orderDateTime">
                <div className="OrderHistoryItem_centerGroup_orderDateTime_txt">
                  {item?.purpose}
                </div>
                <div className="OrderHistoryItem_centerGroup_orderDateTime_txt">
                  {item.phone_number}
                </div>
              </div>
            </div>

            <div className="OrderHistoryItem_centerGroup">
              <div className="OrderHistoryItem_centerGroup_price">
                {item.roles ? item.roles : '-'}
              </div>
            </div>
            {item.projects.length ? item.projects : '-'}
          </div>
        ) : (
          <div
            className={'OrderHistoryItem OrderHistoryItem_pointer'}
            onClick={handleEditClick} // Open modal or redirect based on status
            style={item?.status > 6 ? {cursor: 'pointer'} : {}}>
            <div className="OrderHistoryItem_title">
              {item.first_name + ' ' + item.last_name}
            </div>
            <div className="OrderHistoryItem_centerGroup">
              <div className="OrderHistoryItem_centerGroup_orderDateTime">
                <div className="OrderHistoryItem_centerGroup_orderDateTime_txt">
                  {item?.purpose}
                </div>
                <div className="OrderHistoryItem_centerGroup_orderDateTime_txt">
                  {item.phone_number}
                </div>
              </div>
              <div className="OrderHistoryItem_centerGroup_price">
                {item.roles ? item.roles : '-'}
              </div>
            </div>
            <div className="OrderHistoryItem_centerGroup_price">
              {item.projects.length ? item.projects : '-'}
            </div>
          </div>
        )}
        <div className="OrderHistoryItem_hr">-</div>
      </div>

      {isEditModalOpen && (
        <AddAndEditUserPopUp
          isOpen={isEditModalOpen}
          setIsOpen={setIsEditModalOpen}
          isEdit={true}
          user={item}
        />
      )}
    </>
  );
}
