import React from 'react';
import {useSelector} from 'react-redux';

import CookieConsent from 'react-cookie-consent';
import {icons} from '../assets/constants';
import obj_heb from '../utils/Obj_heb.json';

const CookiesComp = () => {
  const isMobile = useSelector(state => state.mobile.isMobile);

  return (
    <CookieConsent
      enableDeclineButton
      location="bottom"
      buttonText={obj_heb.cookieApproval}
      declineButtonText={obj_heb.cookieDecline}
      style={{
        background: '#fff',
        borderRadius: '2rem 2rem 0 0',
        boxShadow: '4px 4px 10px 2px rgba(0, 0, 0, 0.25)',
        height: isMobile ? 'max-content' : '5.6rem',
        justifyContent: 'center',
        paddingBottom: isMobile ? '0' : '7rem',
        alignItems: isMobile ? 'center' : 'flex-start',
      }}
      buttonWrapperClasses="Cookie_justifyCenter"
      buttonStyle={{
        color: '#fff',
        fontSize: '2.2rem',
        background: '#000',
        borderRadius: '0.2rem',
        height: '3.6rem',
        width: isMobile ? 'auto' : '23rem',
        fontFamily: 'OscarFm',
        flex: isMobile ? '1' : 'auto',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
        margin: '1.6rem',
        marginRight: '0.8rem',
      }}
      declineButtonStyle={{
        color: '#000',
        fontSize: '2.2rem',
        background: '#fff',
        borderWidth: '0.2rem',
        borderStyle: 'solid',
        borderColor: '#000',
        borderRadius: '0.2rem',
        height: '3.6rem',
        width: isMobile ? 'auto' : '23rem',
        flex: isMobile ? '1' : 'auto',
        fontFamily: 'OscarFm',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
        margin: '1.6rem',
        marginLeft: '0.8rem',
      }}
      expires={150}>
      <div className="Cookie_justifyCenter">
        <img src={icons.cookies_icon} className="Cookie_icon"></img>
        <div className={'Cookie_justifyRow'}>
          {obj_heb.cookieTitle}
          <div>{obj_heb.cookieSub}</div>
        </div>
      </div>
    </CookieConsent>
  );
};

export default CookiesComp;
