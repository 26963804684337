import {useDispatch} from 'react-redux';
import {fetchData} from '../utils/fetchData';
import {
  ShoppingCart_updateProductPrice,
  ShoppingCart_updateProductSalePrice,
  ShoppingCart_removeProduct,
  ShoppingCart_updateProductCrane,
  ShoppingCart_updateProductBlackMaterial,
  ShoppingCart_updateProductWeight,
} from '../redux/slices/ShoppingCartSlice';

export const useValidation = () => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('userData'));

  const validateProductsFromServer = async shoppingCartData => {
    let dataObj = {
      request: 'get_products',
      products: shoppingCartData,
      token: userData?.token || '',
      project_id: '',
    };

    try {
      const response = await fetchData(dataObj);

      // Don't Allow the user continue before checking the all products
      let hasPriceChanged = false;
      let hasProductRemoved = false;
      let hasSalePriceChanged = false;
      let canReturnWasChanged = false;
      let weightWasChanged = false;
      let needCraneWasChanged = false;

      // Those validations will return in the hook call
      let validationOutcome = {
        productRemoved: false,
        priceWasChanged: false,
        salePriceWasChanged: false,
        allGood: true,
        theUpdatedProducts: [],
        removedProducts: [],
        canReturnWasChanged: false,
        weightWasChanged: false,
        needCraneWasChanged: false,
      };

      const updatedProduct = shoppingCartData
        .map(product => {
          const serverProduct = response.products.find(
            x => x.product_id === product.product_id,
          );

          // Placing the products in a new object
          let updatedProduct = {...product};
          updatedProduct.is_available = true;
          updatedProduct.weight = serverProduct.weight;

          ////////////////////// MULTI OPTIONS
          //Checking if the are any price changes in product options
          if (product.option1_value) {
            const newPrice = getOptionPrice(serverProduct, [
              product.option1_value,
              product.option2_value || '',
            ]);

            if (newPrice !== product.price) {
              hasPriceChanged = true;
              updatedProduct.price = newPrice;
              dispatch(
                ShoppingCart_updateProductPrice({
                  key: product.key,
                  newPrice: newPrice,
                }),
              );
            }
          }

          // Checking if there are any price changes
          else {
            let newPrice = serverProduct.price;
            let newSalePrice = serverProduct.sale_price;

            // Checking if the SALE price is the same with local and server
            if (serverProduct.sale_price !== product.sale_price) {
              // Placing the new SALE price as sale price
              hasPriceChanged = true;
              updatedProduct.sale_price = newSalePrice;
            }

            // Checking if the price is the same with local and server
            if (serverProduct.price !== product.price) {
              hasPriceChanged = true;
              updatedProduct.price = newPrice;
            }

            // Changing the price if there are any price changes
            if (hasPriceChanged) {
              // Checking if there is SALE price changes, if yes it will update the redux and will change the state of sale
              if (serverProduct?.sale_price !== product?.sale_price) {
                dispatch(
                  ShoppingCart_updateProductSalePrice({
                    productId: product.product_id,
                    // Placing the new sale price
                    newSalePrice: serverProduct.sale_price,
                    // Changing the state of sale
                    sale:
                      serverProduct.sale_price > 0 &&
                      serverProduct.price > serverProduct.sale_price
                        ? true
                        : false,
                  }),
                );
              } else {
                // Checking if there are price changes, if yes it will update the redux
                dispatch(
                  ShoppingCart_updateProductPrice({
                    productId: product.product_id,
                    newPrice: serverProduct.price,
                  }),
                );
              }
            }
          }

          //////////////// Checking if the product is needs crane
          if (product.need_crane !== serverProduct.need_crane) {
            needCraneWasChanged = true;
            dispatch(
              ShoppingCart_updateProductCrane({
                key: product.key,
                need_crane: serverProduct.need_crane,
              }),
            );
          }

          //////////////// Checking if the product is black material or not
          if (product.black_Product !== serverProduct.can_return) {
            canReturnWasChanged = true;
            dispatch(
              ShoppingCart_updateProductBlackMaterial({
                key: product.key,
                black_Product: serverProduct.can_return,
              }),
            );
          }

          //////////////// Checking if the product is black material or not
          if (product.weight !== serverProduct.weight) {
            canReturnWasChanged = true;
            dispatch(
              ShoppingCart_updateProductWeight({
                key: product.key,
                weight: serverProduct.weight,
              }),
            );
          }

          //////////////// Removing the product if is not available
          if (!serverProduct || !serverProduct.is_available) {
            dispatch(ShoppingCart_removeProduct({key: product.key}));
            hasProductRemoved = true;
            validationOutcome.removedProducts.push(product);
            return null;
          }

          validationOutcome.theUpdatedProducts.push(updatedProduct);
          return updatedProduct.is_available ? updatedProduct : null;
        })
        .filter(Boolean);

      if (hasProductRemoved) {
        validationOutcome.productRemoved = true;
        validationOutcome.allGood = false;
      }

      if (hasPriceChanged) {
        validationOutcome.priceWasChanged = true;
        validationOutcome.allGood = false;
      }

      if (hasSalePriceChanged) {
        validationOutcome.salePriceWasChanged = true;
        validationOutcome.allGood = false;
      }

      if (canReturnWasChanged) {
        validationOutcome.canReturnWasChanged = true;
        validationOutcome.allGood = false;
      }

      if (weightWasChanged) {
        validationOutcome.weightWasChanged = true;
        validationOutcome.allGood = false;
      }

      if (needCraneWasChanged) {
        validationOutcome.needCraneWasChanged = true;
        validationOutcome.allGood = false;
      }

      return {validationOutcome};
    } catch (error) {
      console.error('Error:', error?.message);
      return [];
    }
  };

  return {validateProductsFromServer};
};

const getOptionPrice = (productInfo, options) => {
  let price;
  if (productInfo.is_available === true) {
    if (options.length > 1) {
      price = productInfo.options_prices.filter(
        i => i.option1 == options[0] && i.option2 == options[1],
      )[0].price;
    } else {
      price = productInfo.options_prices.filter(i => i.option1 == options[0])[0]
        .price;
    }
    return price;
  } else {
    return false;
  }
};
