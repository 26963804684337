import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {fetchData} from '../utils/fetchData';
import Title from '../components/Title';
import ProjectMainTabsComponent from '../components/ProjectMainTabsComponent';
import LobbyTabsCointainer from '../components/LobbyTabsCointainer';

export default function ProjectPage() {
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [projectDetails, setProjectDetails] = useState(null);
  const [projectProducts, setProjectProducts] = useState(null);
  const [myProjectsBalance, setMyProjectsBalance] = useState(null);
  const [dibbleCoinsCredit, setDibbleCoinCredit] = useState(null);
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData')),
  );
  const [isPopUpOpen, setIsPopUpOpen] = useState(false); // Add state for managing the popup visibility

  let params = useParams();

  const fetchAllData = () => {
    setIsLoading(true);
    let dataObj = {
      request: 'get_my_project_details',
      token: userData?.token || '',
      project_id: Number(params.project_id),
    };
    fetchData(dataObj)
      .then(response => {
        let data = response;
        setProjectDetails(data);
        setChartData(response['graphs'].length ? response['graphs'][0] : []);
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });

    let dataObj4 = {
      request: 'dibblecoin_get_my_projects_balance',
      project_id: Number(params.project_id),
      token: userData?.token || '',
    };
    fetchData(dataObj4)
      .then(response => {
        let data = response;
        setMyProjectsBalance(data);
        setDibbleCoinCredit(
          data.projects.find(p => p.project_id == Number(params.project_id))
            ?.dcoin_budget,
        );
      })
      .catch(error => {
        console.error('Error:', error?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAllData();
  }, [userData, isDepositModalOpen]);

  return (
    <React.Fragment>
      <div>
        <Title title={projectDetails?.project_info?.name} />

        <ProjectMainTabsComponent
          projectDetails={projectDetails}
          myProjectsBalance={myProjectsBalance}
          chartData={chartData}
          fetchAllData={fetchAllData}
        />
        <LobbyTabsCointainer />
      </div>
    </React.Fragment>
  );
}
