import React from 'react';
import MyHrItem from './MyHrItem';
import Obj_heb from '../utils/Obj_heb.json';

const HourSelector = ({hours, handleSelect}) => {
  return (
    <div className="days-selector">
      {hours && hours.length !== 0 ? (
        <>
          <div className="days-selector__list">
            {hours.map((item, index) => {
              return (
                <div
                  className="days-selector__list--item"
                  key={JSON.stringify(item.original)}>
                  <MyHrItem item={item} onClick={() => handleSelect(item)} />
                  {index !== hours.length - 1 && (
                    <span className="days-selector__list--separator" />
                  )}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className="days-selector__no-days">
          {Obj_heb.need_to_pick_date}
        </div>
      )}
    </div>
  );
};

export default HourSelector;
