import React from 'react';
import {days} from '../utils/supportFunctions';
import obj_heb from '../utils/Obj_heb.json';

function MyDayItem({item, onClick}) {
  let Days = days();
  return (
    <div className="days-item" onClick={() => onClick(item)}>
      <span className="is-hover" />
      <div className="days-item__content">
        {`${obj_heb.onDay} ${Days[item.day]} `}
        {item.date}
      </div>
    </div>
  );
}

export default MyDayItem;
