import React, {useEffect} from 'react';
import close from '../../assets/icons/close_icon.svg';
import classNames from 'classnames';
import {cancel_scrolling} from '../../utils/supportFunctions';
/**
 *
 * @param {*} children  the element for the main container
 * @param {*} bottomElement - optional for adding element under the main modal - like buttons
 * @returns
 */
export default function FullScreenModal({
  isOpen = false,
  setIsOpen,
  children,
  bottomElement = null,
  closeCallback = null,
}) {
  useEffect(() => {
    cancel_scrolling(isOpen);
    return () => {
      cancel_scrolling(false);
    };
  }, [isOpen]);
  return (
    <div className={classNames('FullScreenModal', !isOpen ? 'hide' : '')}>
      <div className={'FullScreenModal__content'}>
        {
          <img
            src={close}
            alt="x"
            className={'FullScreenModal__content--close'}
            onClick={() => {
              setIsOpen(false);
              if (closeCallback !== null) {
                closeCallback();
              }
            }}
          />
        }
        {children}
      </div>
      {bottomElement && (
        <div className="FullScreenModal__additional-content">
          {bottomElement}
        </div>
      )}
    </div>
  );
}
