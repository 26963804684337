import React from 'react';
import CategorysList from '../components/CategorysList';

export default function Categorys() {
  return (
    <div>
      <CategorysList />
    </div>
  );
}
