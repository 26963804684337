import React, {useEffect, useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import SEO from '../components/SEO';
import SelectMaterial from '../components/SelectMaterial';
import MoreProductsCarusel from '../components/MoreProductsCarusel';
import Title from '../components/Title';
import {useNavigate} from 'react-router-dom';
import Pagination from '../components/Pagination';
import RecommendedForYou from '../components/RecommendedForYou';
import classNames from 'classnames';
import Toast from '../components/Toast';
import 'firebase/firestore';
import {initializeApp} from 'firebase/app';
import {fireBaseConfig, INTERCOM_APP_ID} from '../utils/baseValues';
import {getDatabase, ref, onValue} from 'firebase/database';
import {clearActiveOrdersCount} from '../redux/slices/activeOrdersSlice';
import TimeBasedGreeting from '../components/cart/TimeBasedGreeting';
import {icons} from '../assets/constants';
import {clearAddressData, clearCreditCardsData} from '../redux/store';
import MyMaterialsOrdersTab from '../components/MyMaterialsOrdersTab';
import {useIntercomContext} from '../utils/IntercomContext';
import PopUp from '../components/general/PopUp';
import PopUpConfirm from '../components/PopUpConfirm';
export default function PersonalArea() {
  const material = new URLSearchParams(useLocation().search).get('material');

  let app = initializeApp(fireBaseConfig);
  const db = getDatabase(app);
  const dataRef = ref(db, 'courier');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [TabOptions, setTabOptions] = useState([
    {
      name: obj_heb.order_materials,
      key: 0,
      tab_name: 'order_materials',
    },

    {name: obj_heb.materials_history, key: 2, tab_name: 'my_orders'},
  ]);
  const {openIntercom} = useIntercomContext();
  const [loginError, setLoginError] = useState(false);
  const [openMaterial, setOpenMaterial] = useState(0);
  const [openTabIndex, setOpenTabIndex] = useState(0);
  const [availableDibbleCoin, setAvailableDibbleCoin] = useState(0);
  const [materialsData, setMaterialsData] = useState([]);
  const [likedPRoduct, setlikedPRoduct] = useState([]);
  const [userOrderHistory, setUserOrderHistory] = useState([]);
  const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  let PageSize = 10;
  const isMobile = useSelector(state => state.mobile.isMobile);
  let userData = JSON.parse(localStorage.getItem('userData'));

  // grab the query selector
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access specific query parameters
  const tabName = queryParams.get('tab');
  const activeOrders = useSelector(state => state.activeOrders.activeOrders);
  useEffect(() => {
    // get avible dibble coin
    let userData = JSON.parse(localStorage.getItem('userData'));

    if (userData) {
      let MaterialsDatataObj = {
        request: 'get_material_extra_info',
        token: userData?.token,
      };
      fetchData(MaterialsDatataObj)
        .then(response => {
          const data = response;
          if (data) {
            setMaterialsData(data);
          }
        })
        .catch(error => {
          console.error('Error:', error.message);
        });
      let dataObj = {
        request: 'dibblecoin_get_available_credit',
        token: userData?.token,
      };
      fetchData(dataObj)
        .then(response => {
          const data = response;
          if (data.available_credit) {
            setAvailableDibbleCoin(data.available_credit);
          }
        })
        .catch(error => {
          console.error('Error:', error.message);
        });

      // getting user liked items
      let likedProductDataObj = {
        request: 'get_user_liked_products',
        token: userData?.token,
      };
      fetchData(likedProductDataObj)
        .then(response => {
          const data = response;
          if (data.products) {
            setlikedPRoduct(data.products);
          }
        })
        .catch(error => {
          console.error('Error:', error.message);
        });

      let orderHistoryDataObj = {
        request: 'get_my_waiting_material_orders',
        token: userData?.token,
      };
      fetchData(orderHistoryDataObj)
        .then(response => {
          const data = response;
          if (data) {
            setUserOrderHistory(data.orders);
          }
        })
        .catch(error => {
          console.error('Error:', error.message);
        });
    }
  }, []);
  const handleOpenIntercom = () => {
    setLoginError(false);

    openIntercom(); // This will open the Intercom chat
  };
  useEffect(() => {
    if (tabName === 'personal_preference') {
      setOpenTabIndex(1);
    } else if (tabName === 'my_orders') {
      setOpenTabIndex(2);
    } else setOpenTabIndex(0);
  }, [tabName]);
  useEffect(() => {
    if (material === 'iron') {
      setOpenMaterial(2);
    } else if (material === 'quarry') {
      setOpenMaterial(3);
    } else if (material === 'concrete') {
      setOpenMaterial(4);
    } else if (material === 'rental') {
      setOpenMaterial(5);
    } else if (material === 'fuel') {
      setOpenMaterial(6);
    } else if (material === 'finishes') {
      setOpenMaterial(7);
    } else setOpenMaterial(0);
  }, [material]);

  const reset = () => {
    let searchParams = new URLSearchParams(location.search);
    searchParams.delete('material');
    searchParams.delete('tab');
    searchParams.delete('order_genre');
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
    setOpenMaterial(0);
  };
  const pickTabHandler = item => {
    if (item.key == 2 && !userData) {
      setLoginError(true);
      navigate('/materials');
      return;
    }
    setOpenTabIndex(item.key);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tab', item.tab_name);
    searchParams.delete('order_genre');
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const logout = () => {
    if (!userData?.token) return;
    let dataObj = {
      request: 'logout',
      token: userData.token,
    };
    fetchData(dataObj).then(response => {
      localStorage.setItem('userData', null);
      dispatch(clearAddressData());
      dispatch(clearCreditCardsData());
      dispatch(clearActiveOrdersCount());
      window.Intercom('shutdown');
      window.Intercom('reset');
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
      });
      window.Intercom('update', {name: '', email: '', phone: '', userId: ''});
      window.location.reload();
      navigate('/');
    });
  };

  const SEOLoader = () => {
    const PersonalAreaLink = `/personal-area}`;
    return (
      <SEO
        pageName={obj_heb.personal_area}
        content={obj_heb.personal_area}
        link={PersonalAreaLink}
      />
    );
  };

  const titleText = () => {
    const useNameText = userData
      ? userData.first_name + ' ' + userData.last_name
      : '';
    return useNameText;
  };

  return (
    <>
      <SEOLoader />

      <div className="Materials ">
        <PopUp isOpen={loginError} setIsOpen={setLoginError}>
          <PopUpConfirm
            img={icons.no_credit_icon}
            title={obj_heb.just_a_moment}
            subTitle={
              <div className="summary-component__credit-error">
                <span>{obj_heb.needToLoginHistory}</span>
                <div
                  onClick={handleOpenIntercom}
                  className="summary-component__credit-error--contact-us">
                  <span>{obj_heb.chatWithUsError}</span>
                  <img src={icons.chat_icon} alt="" />
                </div>
              </div>
            }
          />
        </PopUp>
        <div className="Materials_content">
          {isMobile ? (
            <>
              <div
                className="Materials_content__title--mobile_part1"
                style={{
                  textAlign: 'center',
                }}>
                {TimeBasedGreeting()}
              </div>
              <Title
                center={true}
                CustomTitle={
                  <div className="Materials_content__title--mobile">
                    <div className="Materials_content__title--mobile_part2">
                      {titleText()}
                    </div>
                  </div>
                }
                title={titleText()}
              />
            </>
          ) : (
            <Title title={obj_heb.materials} />
          )}
          <div className="Materials_content_section1">
            <div
              onClick={() => {
                reset();
              }}
              className="Materials_content_section1_tab-headers">
              {TabOptions.map((item, index) => (
                <div
                  key={index}
                  className={classNames(
                    'Materials_content_section1_tab-headers_tab_head',
                    openTabIndex == item.key &&
                      'Materials_content_section1_tab-headers_tab_head--selected',
                  )}
                  onClick={() => {
                    pickTabHandler(item);
                  }}>
                  <div
                    className="Materials_content_section1_tab-headers_tab_head_title"
                    style={openTabIndex == item.key ? {fontWeight: '700'} : {}}>
                    {item.name}
                  </div>
                </div>
              ))}
            </div>

            <div className="Materials_content_section1_tab-body">
              {openTabIndex == 0 ? (
                <div className="Materials_content_section1_tab_margin">
                  <SelectMaterial
                    materialsData={materialsData}
                    material={material}
                    openMaterial={openMaterial}
                  />
                </div>
              ) : (
                <>
                  <div className="Materials_content_section1_colum-div">
                    <MyMaterialsOrdersTab
                      orderData={userOrderHistory}
                      currentPaginationPage={currentPaginationPage}
                      PageSize={100}
                      onPageChange={page => setCurrentPaginationPage(page)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          {openTabIndex == 1 && isMobile && (
            <div
              onClick={() => {
                logout();
              }}
              className="PersonalSettingsTab__logOut">
              <div className="PersonalSettingsTab__logOut__titleBox">
                <span className="PersonalSettingsTab__logOut__titleBox__textRed">
                  {obj_heb.exit}
                </span>
                <span>{obj_heb.exitText}</span>
              </div>
              <img
                className="PersonalSettingsTab_item--title_arrowhead_img"
                src={icons.red_left_arrow_head}
                alt="leftArrowHead"
              />
            </div>
          )}
          {openTabIndex == 2 &&
            (!isMobile ? (
              <Pagination
                className="pagination-bar"
                currentPage={currentPaginationPage}
                totalCount={userOrderHistory?.orders?.length || 0}
                pageSize={PageSize}
                onPageChange={page => setCurrentPaginationPage(page)}
              />
            ) : (
              <div className="Materials_content--allOrders">
                <div
                  className="Materials_content--allOrders_text"
                  onClick={() => {
                    navigate('/AllOrders');
                  }}>
                  {obj_heb.toAllOrders}
                </div>
              </div>
            ))}
          <div className="Materials_content_liked_products">
            {likedPRoduct?.length ? (
              <MoreProductsCarusel
                YPositionNavButtons={-25}
                header={obj_heb.product_i_liked}
                headerNavigation={'/likedProducts'}
                productList={
                  likedPRoduct?.map(item => {
                    return {
                      imgLink: item.image,
                      product_id: item.product_id,
                      txtBeforeThePrice: obj_heb.priceStartAt,
                      productOnSale: false,
                      salePrice: 0,
                      price: item.price,
                      title: item.name,
                    };
                  }) || []
                }
              />
            ) : (
              <div> </div>
            )}
          </div>
        </div>

        {isFinish && <Toast text={obj_heb.creditLineSuccessMessage} />}
      </div>
    </>
  );
}
