import React, {useEffect, useState} from 'react';
import {useValidation} from '../../hooks/useValidation';
import {Navigate} from 'react-router-dom';
import {useIntercomContext} from '../../utils/IntercomContext';
import {icons} from '../../assets/constants';
import obj_heb from '../../utils/Obj_heb.json';
import PopUpConfirm from '../../components/PopUpConfirm';
import PopUp from './PopUp';
import ShoppingCartItem from '../shoppingCart/ShoppingCartItem';
import Button from '../Button';

export const PopUpValidations = ({
  shoppingCartData,
  isChecked,
  setIsChanged,
  setIsReady,
}) => {
  const {validateProductsFromServer} = useValidation();
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpContent, setPopUpContent] = useState(null);

  const {openIntercom} = useIntercomContext();

  useEffect(() => {
    if (shoppingCartData.length) {
      setIsChanged(false);
      verifyProductChanges();
    }
  }, [isChecked]);

  const handleProductInvalid = () => {
    setShowPopUp(false);
    setIsReady(true);
    if (shoppingCartData.length === 0) Navigate('/cart');
  };

  const handleOpenIntercom = () => {
    setShowPopUp(false);
    setIsReady(true);
    openIntercom();
  };

  const ProductList = ({products}) =>
    products.map((product, index) => (
      <ShoppingCartItem
        key={index}
        item={product}
        orderHistory
        className="summary-component__missing-product--item"
        backgroundColor="var(--body-color)"
      />
    ));

  const ActionSection = () => (
    <div className="summary-component__missing-product">
      <Button
        backgroundColor="var(--black)"
        textColor="var(--white)"
        text={obj_heb.confirm}
        onClick={handleProductInvalid}
      />
      <img
        src={icons.chat_icon}
        alt="chat_icon"
        className="pointer"
        onClick={handleOpenIntercom}
      />
    </div>
  );

  const verifyProductChanges = async () => {
    const validationResult = await validateProductsFromServer(shoppingCartData);
    const {
      allGood,
      productRemoved,
      removedProducts,
      priceWasChanged,
      salePriceWasChanged,
      theUpdatedProducts,
      canReturnWasChanged,
      weightWasChanged,
      needCraneWasChanged,
    } = validationResult.validationOutcome;

    if (allGood) {
      setIsReady(true);
      return true;
    }

    if (
      productRemoved ||
      priceWasChanged ||
      salePriceWasChanged ||
      weightWasChanged ||
      needCraneWasChanged
    ) {
      let title;
      if (weightWasChanged || needCraneWasChanged) {
        title = obj_heb.productPropertiesChangeTitle;
      } else {
        title = productRemoved ? obj_heb.oops : obj_heb.priceWasChangedTitle;
      }

      let subTitleText;
      if (weightWasChanged || needCraneWasChanged) {
        subTitleText = obj_heb.productPropertiesChangedSubTitle;
      } else {
        subTitleText = productRemoved
          ? obj_heb.productDeletedFromWebsite
          : obj_heb.priceWasChanged;
      }
      const products =
        productRemoved || needCraneWasChanged || weightWasChanged
          ? removedProducts
          : theUpdatedProducts;

      const img = productRemoved
        ? icons.no_credit_icon
        : icons.price_was_changed;

      setPopUpContent(() => (
        <PopUpConfirm
          img={img}
          title={title}
          subTitle={
            <div>
              <span>{subTitleText}</span>
              <div style={{overflowY: 'scroll', maxHeight: '30rem'}}>
                <ProductList products={products} />
              </div>
              <ActionSection />
            </div>
          }
        />
      ));
      setShowPopUp(true);
      setIsChanged(true);
      setIsReady(false);
    }
  };

  return (
    <React.Fragment>
      {showPopUp && (
        <PopUp
          isOpen={showPopUp}
          setIsOpen={setShowPopUp}
          closeCallback={handleProductInvalid}>
          {popUpContent}
        </PopUp>
      )}
    </React.Fragment>
  );
};

export default PopUpValidations;
