import React from 'react';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import obj_heb from '../utils/Obj_heb.json';

/**
 * NavBarDropDown Component.
 *
 * @param {string} text - the text in the NavBarDropDownItem
 * @param {string} className - class name to add (optional)
 * @param {string} available - default true, when false - gray and not clickable
 */

function NavBarDropDownItem({text, className, available = true}) {
  const activeOrdersCount = useSelector(
    state => state.activeOrders.activeOrdersCount,
  );
  return (
    <div
      className={classNames(
        'drop-down-item',
        className,
        !available && 'drop-down-item--disable',
      )}>
      <span className="is-hover" />
      {text}
      {text === obj_heb.my_orders && activeOrdersCount > 0 && (
        <div className="dropdown-menu_div_li__badge">
          <div className="dropdown-menu_div_li__badge__text">
            {activeOrdersCount}
          </div>
        </div>
      )}
    </div>
  );
}

export default NavBarDropDownItem;
