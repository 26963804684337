import React, {useEffect, useState} from 'react';
import {IntercomProvider, useIntercom} from 'react-use-intercom';
import {useIntercomContext} from '../utils/IntercomContext';
import {useSelector} from 'react-redux';

const IntercomWidget = ({appId}) => {
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData')),
  );

  return (
    <IntercomProvider appId={appId} autoBoot={true}>
      <IntercomComponent userData={userData} />
    </IntercomProvider>
  );
};

const IntercomComponent = ({userData, closeIntercom}) => {
  const intercom = useIntercom();
  const {isIntercomOpen, openIntercom} = useIntercomContext();
  const isMobile = useSelector(state => state.mobile.isMobile);

  useEffect(() => {
    if (userData) {
      intercom.update({
        name: userData.first_name + ' ' + userData.last_name,
        email: userData.email,
        phone: userData.phone,
        userId: userData.phone,
      });
    } else {
      intercom.update({
        name: null,
        email: null,
        phone: null,
        userId: null,
      });
    }

    if (isIntercomOpen) {
      intercom.show();
      openIntercom(); // This will open the Intercom chat
    }
    if (isMobile) {
      window.Intercom('update', {hide_default_launcher: true});
    } else {
      window.Intercom('update', {hide_default_launcher: false});
    }
  }, [intercom, userData, isIntercomOpen]);

  return <></>;
};

export default IntercomWidget;
