import React from 'react';
import {icons} from '../assets/constants';
import obj_heb from '../utils/Obj_heb.json';

function AddNotes({notes, setNotes, showTitle, boxShadow = true}) {
  const handleNotesChange = event => {
    const inputText = event.target.value;
    const lines = inputText.split('\n');

    let linesAmount = lines.length;

    lines.map(item => {
      if (item.length > 100) linesAmount++;
    });

    if (linesAmount < 10 && event.target.value.length < 900) {
      setNotes(event.target.value.replace(/^\s+/g, ''));
      adjustTextAreaHeight(event);
    }
  };

  const adjustTextAreaHeight = event => {
    const element = event.target;

    if (event.target.value.replace(/^\s+/g, '')) {
      element.style.height = '1.8rem';
      element.style.height = element.scrollHeight + 'px';
    } else {
      element.style.height = '1.8rem';
    }
  };

  const countNewLines = str => {
    return (str.match(/\n/g) || []).length;
  };

  return (
    <div className={`add-notes ${boxShadow ? '' : 'no-shadow'}`}>
      <img src={icons.notes_icon} alt="" className="add-notes__img" />
      <div className="add-notes__text">
        {showTitle && <span className="add-notes__text--title">הערות</span>}
        <textarea
          value={notes}
          onChange={handleNotesChange}
          placeholder={
            showTitle
              ? obj_heb.notesPlaceHolder
              : obj_heb.notesPlaceHolderMaterial
          }
          style={{cursor: 'text', marginTop: showTitle ? '0' : '10px'}}
          className="add-notes__text--input"
        />
      </div>
    </div>
  );
}

export default AddNotes;
