import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {fetchData} from '../utils/fetchData';

import {fetchCreditCardsAndSetInitialList} from '../redux/store';

import obj_heb from '../utils/Obj_heb.json';
import classNames from 'classnames';
import CardFavorite from '../assets/icons/CardFavorite.svg';
import PopUpConfirm from './PopUpConfirm';

function CreditCardFavorite({
  card,
  setCardOptionsIsOpen,
  setPopUpIsOpen,
  setPopUpContent,
}) {
  const dispatch = useDispatch();

  //   define favorite
  const handleClickFavorite = () => {
    if (!card.is_default) {
      setPopUpIsOpen(true);
      setPopUpContent(popUpContent());
      setCardOptionsIsOpen(false);
    }
  };

  const popUpContent = () => {
    return (
      <PopUpConfirm
        img={CardFavorite}
        title={obj_heb.defineThisCardAsDefault}
        subTitle={obj_heb.thisCardWillBeDefault}
        onConfirm={makeFavorite}
        onCancel={() => {
          setPopUpIsOpen(false);
          setPopUpContent(<></>);
        }}
      />
    );
  };

  const makeFavorite = () => {
    let data = localStorage.getItem('userData');
    data = JSON.parse(data);

    if (!data?.token) return;

    let dataObj = {
      request: 'set_default_creditcard',
      token: data.token,
      creditcard_id: card.creditcard_id,
    };

    fetchData(dataObj) // Don't assign the result to x, instead handle it using the 'then' method
      .then(response => {
        dispatch(fetchCreditCardsAndSetInitialList());

        setPopUpIsOpen(false);
        setPopUpContent(<></>);
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  };

  return (
    <div
      onClick={handleClickFavorite}
      className={classNames(
        'credit-card__edit--field',
        card.is_default && 'credit-card__edit--field--unclickable',
      )}>
      <div
        className={classNames(
          'credit-card__edit--field--text',
          card.is_default && 'credit-card__edit--field--unclickable',
        )}>
        {obj_heb.defineCardAsPreferred}
        <span className="is-hover" />
      </div>
      <span className="credit-card__edit--field--separator" />
    </div>
  );
}

export default CreditCardFavorite;
