import React from 'react';
import SEO from '../components/SEO';
import obj_heb from '../utils/Obj_heb.json';

export default function Privacy() {
  return (
    <React.Fragment>
      <SEO
        pageName={obj_heb.PP}
        content={obj_heb.PPDesc}
        link="/privacy-policy"
      />
      <div className="Privacy">
        <div>
          {/* p1 */}
          <p>
            <strong>
              <u>מדיניות פרטיות</u>
            </strong>
          </p>
          <br />
          <p>
            דיבל אפ בע"מ (<strong>"דיבל"</strong>, <strong>"אנחנו"</strong> או{' '}
            <strong>"אנו"</strong>) מחויבים להגן על פרטיות לקוחותיה (
            <strong>"משתמש/ים"</strong> או <strong>"אתה"</strong>
            ). מדיניות פרטיות זו ( <strong>"מדיניות הפרטיות"</strong> ) נוצרה
            כדי ליידע אותך לגבי הדרך שבה אנו מנהלים, אוספים, מאחסנים ועושים
            שימוש במידע שאתה מספק לנו באמצעות הפלטפורמה של דיבל (מוגדר להלן).
            בנוסף, מדיניות הפרטיות שלהלן מתארת את האמצעים בהם אנו נוקטים על מנת
            להגן על בטיחותו של המידע הנאסף על ידנו וכיצד ניתן ליצור איתנו קשר
            בנוגע לנוהלי הפרטיות שלנו. תקנון השימוש המפורט להלן יחד עם "מדיניות
            <br />
          </p>

          <p>
            אנא שים לב שהיקף מדיניות פרטיות זו מוגבל רק למידע שנאסף על-ידינו
            באמצעות השימוש שאתה עושה בפלטפורמה (בין שהשימוש כאמור הוא באמצעות כל
            מחשב בו נעשה שימוש כדי לקבל גישה לשירותי הפלטפורמה, לרבות ללא הגבלה,
            מחשב נייח, מחשב נייד, טלפון נייד או כל מכשיר אלקטרוני אחר). באמצעות
            שימוש בפלטפורמה שלנו, אתה מסכים למדיניות הפרטיות שלנו.
          </p>
          <p>
            דיבל מספקת פלטפורמה שמספקת ציוד לענף הבניה באמצעות חנות דיגיטלית
            לקהילה המקצועית והפרטית בישראל (<strong>"פלטפורמה"</strong>
            ). הפלטפורמה מאפשרת למשתמשים להזמין ציוד וחומרי בניין ולקבלם במשלוח
            מהיר ומתוזמן ליעד המתבקש. כמו כן, הפלטפורמה מספקת גם שירותי ניהול
            מידע למנהלים כאשר מאפשרת למשתמשים לתעד ולנתח נתונים וכן לנהל
            פרויקטים שונים תוך מעקב תקציבי שוטף (
            <strong>"שירותי הפלטפורמה"</strong>
            ).
          </p>

          <p>
            <br />
            <u>
              בעת השימוש בפלטפורמה שלנו, אנו עשויים לאסוף ממך שני סוגי מידע
              עליך:
            </u>
          </p>
          <p>
            <strong>"מידע אישי"</strong>– משמעו מידע עליך שניתן להשתמש בו על מנת
            ליצור עמך קשר או לזהות אותך. חלק מהמידע מזהה אותך באופן אישי, כגון
            שמך, כתובת ההזמנה, דואר האלקטרוני שלך, מספר הטלפון שלך, ח.פ וכיו"ב.
            זהו המידע שאתה מוסר ביודעין, בהסכמתך, לטובת קבלת מידע ושירותים
            שמוצגים בפלטפורמה שלנו.
          </p>
          <p>
            <strong>"מידע לא אישי"</strong>– משמעו מידע שכשלעצמו, לא מאפשר לעשות
            שימוש לשם זיהוי או יצירת קשר איתך. זהו מידע סטטיסטי ומצטבר. לדוגמה,
            העמודים שבהם צפית, ההצעות ושירותי הפלטפורמה שעניינו אותך ועוד. אנו
            רשאים לאסוף מידע לא אישי ולהשתמש בו לכל מטרה באמצעות כל אחת מהשיטות
            לעיל וכן באופן אוטומטי או באופן אחר באמצעות השימוש שתעשה בשירותי
            הפלטפורמה.
          </p>
          <p>
            אינך מחויב לפי חוק למסור לנו את פרטי המידע האישי המתוארים להלן, אך
            לצורך השימוש בשירותי הפלטפורמה באופן המיטבי, הינך נדרש לשתף עם דיבל
            את פרטי המידע האישי המפורטים. במידה ותסרב למסור חלק מהמידע האישי
            שלך, ייתכן ולא תוכל לעשות שימוש בשירותי הפלטפורמה או שתוכל לעשות
            שימוש חלקי בלבד.
          </p>

          <p>
            <br />
            <strong>1. כיצד אנו אוספים את המידע שלך?</strong>
          </p>
          <br />
          <p>אנו אוספים מידע אישי ומידע לא אישי ממך, בין היתר, למטרות הבאות:</p>
          <p>
            <li>
              <u>איזור אישי:</u> מתן שירותי הפלטפורמה ללקוחות כרוך ביצירת פרופיל
              לקוח. במסגרת יצירת פרופיל ללקוחות אשר מעוניינים להזמין מוצרים
              באמצעות הפלטפורמה, יתכן ונאסוף את המידע הבא :שם מלא, כתובת דואר
              אלקטרוני, כתובת הזמנה, פרטי תשלום, תעודת זהות, ח.פ חברה, מיקום,
              ומידע האודות הפרויקט הרלוונטית לך, צוות המומחים שלנו יאסוף את
              המידע שמוזן במסמכים שהעלית לפלטפורמה, ובהתאם ישווה ויבדוק הצעות
              מחיר שמותאמות לך.
            </li>
            <li>
              <u>איתור נהגים:</u> דיבל עובדת בשיתוף פעולה עם נהגים שונים. במידה
              והינך מעוניין להצטרף לקהילת הנהגים המקצועית של דיבל ולפנות אלינו
              ליצירת קשר באמצעות הפלטפורמה, אנו נאסוף את שמך המלא, כתובת דואר
              אלקטרוני, טלפון וסוג הרכב שבו אתה נוהג.
            </li>
            <li>
              <u>לובי מנהלים:</u> הפלטפורמה מספקת גם שירותי ניהול מידע למנהלים.
              במידה והינך מעוניין להשתמש בפלטפורמה לטובת שליטה מלאה על תקציבי
              פרויקטים, ובקרה על נתוני הוצאות, הכנסות ויעדי משלוח, אנו נאסוף
              פרטים אודות הפרויקטים שאתה מעלה לטובת מתן שירותי הפלטפורמה.
            </li>
            <li>
              <u>הצטרפות לניוזלטר:</u> במידה ותהיה מעוניין לקבל עדכונים הנוגעים
              לפלטפורמה של דיבל, מבצעים והטבות, אנו נאסוף את שמך, מספר הטלפון
              שלך וכתובת הדואר האלקטרוני שלך.
            </li>
            <li>
              <u>צור קשר:</u> במידה ואתה מעוניין ליצור עמנו קשר, באפשרותך לעשות
              זאת בפלטפורמה באמצעות טופס פניה בפלטפורמה או בצ'אט הזמין
              בפלטפורמה. לשם כך, נאסוף את שמך המלא, כתובת דואר אלקטרוני, טלפון
              ונושא ותוכן הפניה שלך.
            </li>
          </p>
          <p>
            <p>
              <br />
              <strong>2. כיצד אנו משתמשים בפרטים שלך?</strong>
            </p>
            <br />
            <p>אנו משתמשים בפרטים שנאספו ממך באופן הבא:</p>
            <p>
              <li>
                <u>ניהול המערכת ואספקת שירותי הפלטפורמה:</u> אנו עשויים להשתמש
                במידע אישי ובמידע לא אישי על מנת לספק לך את שירותי הפלטפורמה ועל
                מנת לנהל, לתמוך, לפתור בעיות טכניות, להגן ולשפר את שירותי
                הפלטפורמה, לוודא תפעול נאות של השירות שלנו וכן למטרות יצירת קשר
                איתך.
              </li>
              <li>
                <u>שליחת הודעות:</u> אנו עשויים להשתמש במידע האישי על מנת לשלוח
                לך הודעות הקשורות לשירותי הפלטפורמה וכן בכפוף להסכמתך, לצורך מתן
                הוראות או עזרה הקשורות לשירותי הפלטפורמה ו/או לשימוש שלך בה. כמו
                כן, אנו נשתמש במידע האישי שלך לצורך משלוח הודעות ועדכונים לגבי
                פעילות דיבל. כל אימת שתעשה שימוש בשירותי הפלטפורמה, אנו רשאים
                להשתמש במידע האישי שלך על מנת להשלים כל פעולה רלוונטית דרושה
                וליצור איתך קשר בנוגע לשירותי הפלטפורמה ודיבל. אם בשלב כלשהו
                תעדיף להסיר את עצמך מרשימת התפוצה, על מנת שלא לקבל הודעות כאמור
                בעתיד, הינך רשאי לעשות זאת בכל עת באמצעות הפלטפורמה ובנוסף בהתאם
                להוראות המפורטות ביחס להסרה מרשימת התפוצה בתחתית כל הודעה.
              </li>
              <li>
                <u>צירוף מידע אנונימי:</u> ככל המותר על פי הדין החל, אנו רשאים
                לצרף את סוגי הנתונים השונים שאנו אוספים ממך עם סוגי נתונים אחרים
                שאנו אוספים ממך, מהלקוחות והשותפים העסקיים האחרים שלנו. איסוף זה
                יתבצע באופן אנונימי ומצטבר ובצורה שלא מזהה אותך באופן אישי לרבות
                באמצעות העברת מידע שעבר גיבוב (“hashing”). מידע אנונימי, סטטיסטי
                או מצטבר זה, ישמש רק על מנת לשפר את המוצרים והשירותים שלנו. אם
                מידע לא אישי נאסף לצורך פעילות שדורשת גם מידע אישי, אנו רשאים
                לצרף את המידע הלא אישי שלך עם המידע האישי שלך בניסיון לספק לך
                חווית משתמש טובה יותר, לשפר את ערך ואיכות שירותי הפלטפורמה ולנתח
                כיצד נעשה שימוש הפלטפורמה.
              </li>
              <br />

              {/* 3 */}
              <p>
                <p>
                  <strong>3. מסירת המידע האישי שלך</strong>
                </p>
                <br />
                <p>
                  אתה מבין ומסכים לכך שייתכן ונידרש למסור מידע אישי אם נידרש לכך
                  על פי חוק או אם נהיה סבורים כי מסירה כאמור דרושה באופן סביר על
                  מנת להימנע מחבות משפטית שלנו, לשם ציות להליכים משפטיים, לרבות,
                  ומבלי לגרוע, זימון לדין, צו חיפוש או צו בית משפט, או על מנת
                  להגן על רכוש וזכויות שלנו או של צד שלישי, להגן על בטיחות
                  הציבור או של כל אדם, או למנוע או לעצור פעילות שנמצא שהיא בלתי
                  חוקית, בלתי אתית או ברת תביעה או שיש סכנה שתהיה כזו.
                </p>
                <p>
                  בכל הנוגע לשירותי הפלטפורמה, אנו נעביר את המידע האישי שלך
                  לספקי המשלוחים וזאת, ככל שהדבר רלוונטי למתן שירותי הפלטפורמה
                  לך. מלבד לאמור במדיניות פרטיות זו, המידע האישי שתספק לא יימסר,
                  יושכר, יושאל, יוחכר, יימכר או יופץ לצדדים שלישיים ואנו לא נשתף
                  את המידע האישי אודותיך עם צדדים שלישיים ללא רשותך, אלא כפי
                  שמפורט בתנאי מדיניות פרטיות זו. אנו רשאים ועשויים לשכור חברות
                  ויחידים שהם צדדים שלישיים על מנת לסייע בביצוע שירותי הפלטפורמה
                  (לדוגמה, ללא הגבלה, שירותי תחזוקה, ניהול בסיס נתונים, ניתוח
                  אתרים ושיפור השירותים) או לסייע לנו בניתוח האופן בו נעשה שימוש
                  בפלטפורמה.
                </p>
                <p>
                  אנו רשאים ועשויים לשתף מידע אישי עם צדדים שלישיים אמינים
                  המסייעים לנו בתפעול הפלטפורמה שלנו, בניהול העסק שלנו או באספקת
                  שירותי הפלטפורמה כגון שירותי סליקה שבאמצעותם מתבצעת העברת
                  התשלומים דרך הפלטפורמה. צדדים שלישיים או סוכנים אלה רשאים
                  להשתמש במידע האישי שלך רק ככל הדרוש לבצע את השירותים שהם
                  מספקים לנו והם נדרשים לנקוט באמצעי הגנה סבירים על מנת להגן
                  ולשמור בסודיות על המידע האישי שלך.
                </p>
              </p>
              <br />

              {/* 4 */}
              <p>
                <p>
                  <strong>4. שמירת המידע שלך</strong>
                </p>
                <br />
                <p>
                  דיבל מאחסנת מידע שנאסף בקשר לשירותי הפלטפורמה על שרתים
                  חיצוניים, המספקים רמת אבטחת מידע גבוהה למידע שלך. כמו כן, אנו
                  נוקטים בצעדי הגנה על מנת להגן על המידע האישי שלך. על מנת
                  להפחית את הסיכון של גישה או העברה בלתי מאושרת, ולטובת ניהול
                  נתונים באופן מדויק ווידוא שימוש הולם במידע אישי, אנו נוקטים
                  באמצעים פיזיים, אלקטרוניים ומנהליים מתאימים על מנת לשמור ולהגן
                  על המידע אותו אנו מעבדים. במקרה של העברה רשלנית, ננקוט בצעדים
                  סבירים מבחינה מסחרית על מנת להגביל ולתקן את ההעברה. עם זאת,
                  אין אנו יכולים לערוב לכך שצדדים שלישיים בלתי מאושרים לא יצליחו
                  לעולם לעקוף הליכים אלה או לעשות שימוש במידע כלכלי או במידע
                  אישי למטרות בלתי הולמות. זוהי אחריותך להגן על כל הסיסמאות
                  ושמות המשתמש בהם אתה עושה שימוש בגישה לפלטפורמה ולהודיע לנו
                  מיד אם אתה יודע או חושד שהסודיות של כל סיסמה ו/או שם משתמש
                  הופרו. אתה האחראי הבלעדי לכל שימוש בלתי מאושר שמתבצע באמצעות
                  הסיסמה ושם המשתמש שלך.
                </p>
              </p>
              <br />

              {/* 5 */}
              <p>
                <p>
                  <strong>5. שימוש ב- Cookies</strong>
                </p>
                <br />
                <p>
                  אנו משתמשים ב- Cookies לצורך תפעולו השוטף והתקין של הפלטפורמה
                  ובכלל זה כדי לאסוף נתונים סטטיסטים אודות השימוש בפלטפורמה,
                  לאימות פרטים, כדי להתאים את הפלטפורמה להעדפותיך האישיות ולצרכי
                  אבטחת מידע. דפדפנים מודרניים כוללים אפשרות להימנע מקבלת
                  Cookies. אם אינך יודע כיצד לעשות זאת, בדוק בקובץ העזרה של
                  הדפדפן שבו אתה משתמש.
                </p>
              </p>
              <br />

              {/* 6 */}
              <p>
                <p>
                  <strong>6. אתרים של צדדים שלישיים</strong>
                </p>
                <br />
                <p>
                  אתה עשוי להיות רשאי להיכנס, לבדוק, להציג או להשתמש בשירותים,
                  במשאבים, בתכנים, במידע או בקישורים של משתמשים וצדדים שלישיים,
                  לאתרים או למשאבים אחרים (
                  <strong>"חומרי צדדים שלישיים"</strong>) באמצעות הפלטפורמה.
                  צדדים שלישיים אלו כוללים גם את אפליצ'ק גרופ (
                  <strong>"אפליצ'ק"</strong>
                  ), כאשר דיבל מאפשרת גישה לפלטפורמה של אפליצ'ק, לטובת הגשת בקשה
                  לקבלת אשראי וניהול בקשה באמצעות אפליצ'ק. לידיעתך, חומרי הצדדים
                  השלישיים אינם תחת שליטת דיבל ואתה מאשר כי הנך נוטל אחריות
                  בלעדית ונוטל את כל הסיכונים הנובעים מכניסה, שימוש או הסתמכות
                  על חומרי צדדים שלישיים כאמור. במקרה כזה, החברה לא תישא בשום
                  חבות שתיגרם לך כתוצאה מכניסה, שימוש או הסתמכות על חומרי צדדים
                  שלישיים שאינם קשורים לדיבל. לידיעתך, מדיניות פרטיות זו אינה
                  מכסה את נהלי הפרטיות והגנת המידע המוטמעים על ידי אותם צדדים
                  שלישיים ולכן אנו ממליצים לך לקרוא את מדיניות הפרטיות של הצד
                  השלישי שאליו אתה מקבל גישה, טרם מתן מידע אישי.
                </p>
              </p>
              <br />

              {/* 7 */}
              <p>
                <p>
                  <strong>7. זכות לעיין במידע או למחקו</strong>
                </p>
                <br />
                <p>
                  במידה ותרצה לבקש מאיתנו למחוק את המידע שלך שברשותנו או לעיין
                  במידע זה וכן לצורך בירור פרטים אחרים הקשורים למידע שלך שנמצא
                  ברשותנו, הינך מוזמן ליצור עימנו קשר בכתובת דוא"ל
                  <a href="mailto:office@dibble.co.il ">office@dibble.co.il</a>
                  או בדואר רגיל: שביל המרץ 4 תל אביב.
                </p>
              </p>
              <br />

              {/* 8 */}
              <p>
                <p>
                  <strong>8. תיקון המידע שלך</strong>
                </p>
                <br />
                <p>
                  אנו נשמור את המידע האישי שלך כל עוד חשבונך פעיל או לפי הצורך
                  על מנת לספק לך את שירותינו, או כנדרש למלא אחר התחייבויותינו
                  החוקיות, לפתור סכסוכים ולאכוף את ההסכמים שלנו. במידה ותרצה
                  שככל הנדרש, נתקן את הרישומים שלך במערכת שלנו, אנא צור עימנו
                  קשר ב:
                  <a href="mailto:office@dibble.co.il ">office@dibble.co.il</a>
                  ואנו ננסה למלא את בקשתך.
                </p>
              </p>
              <br />

              {/* 9 */}
              <p>
                <p>
                  <strong>9. פרטיות של קטינים </strong>
                </p>
                <br />
                <p>
                  דיבל מחויבת להגן על צרכי הפרטיות של ילדים ואנו ממליצים להורים
                  ולאפוטרופוסים לקחת תפקיד פעיל בפעילויות ובאינטרסים של ילדיהם
                  באינטרנט. אין אנו אוספים ביודעין מידע אישי מילדים מתחת לגיל 18
                  ללא הסכמה של הורה או אפוטרופוס לשימוש בשירותי הפלטפורמה.
                </p>
              </p>
              <br />

              {/* 10 */}
              <p>
                <p>
                  <strong>10. שינויים במדיניות הפרטיות</strong>
                </p>
                <br />
                <p>
                  אנו שומרים לעצמנו את הזכות לשנות מדיניות פרטיות זו מעת לעת.
                  הגרסה העדכנית ביותר של המדיניות היא הגרסה הקובעת לעניין השימוש
                  שנעשה במידע האישי ובמידע הלא אישי שלך. במקרה שאנו, לפי שיקול
                  דעתנו הבלעדי, נחליט שעדכונים של מדיניות הפרטיות מהווים שינוי
                  מהותי, אנו נודיע לך על השינויים כאמור בהודעה שתפורסם באמצעות
                  הפלטפורמה. על אף האמור לעיל, אין אנו מחויבים להודיע למשתמש על
                  שינויים במדיניות זו שאינם מהותיים ולכן על המשתמשים לבקר מעת
                  לעת בעמוד זה על מנת לעיין במדיניות הפרטיות העדכנית שלה הם
                  כפופים.
                </p>
              </p>
              <br />

              {/* 11 */}
              <p>
                <p>
                  <strong>11. שינויים במדיניות הפרטיות</strong>
                </p>
                <br />
                <p>
                  אם יש לך שאלות או חששות בנוגע למדיניות פרטיות זו או בנוגע לכל
                  נושא אחר בעניין שירותי הפלטפורמה, אנא צור עימנו קשר ב:
                  <a href="mailto:office@dibble.co.il ">office@dibble.co.il</a>
                  <br />
                  עדכון אחרון: מאי, 2023.
                </p>
              </p>
              <br />
            </p>
          </p>

          <br />
        </div>
      </div>
    </React.Fragment>
  );
}
