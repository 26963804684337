import React, {useState} from 'react';

import Obj_heb from '../utils/Obj_heb.json';

import exclamationMark from '../assets/icons/exclamationMark.svg';

function NeedCraneNav({item, onClick}) {
  const [show, setShow] = useState(null);

  return (
    <div className="need-crane" onClick={() => setShow(!show)}>
      <div className="need-crane__title">
        <img alt="" src={exclamationMark} className="need-crane__title--icon" />
        <span> {Obj_heb.thisOrderContainACrane}</span>
        <span className="need-crane__title--underline">
          {Obj_heb.whatDoesItSays}
        </span>
      </div>

      {show && (
        <>
          <div className="need-crane__info">
            <div className="need-crane__text">
              {Obj_heb.ordersThatContainAboveTonAndAHalf}
            </div>
            <div className="need-crane__text">
              {Obj_heb.craneCostAreByTheDate}
            </div>
            <div className="need-crane__text--bold">
              {Obj_heb.importantCheckAWayForCrane}
            </div>
          </div>
          <div className="need-crane__text need-crane__text--big">
            {Obj_heb.cranePossibleTimes}
          </div>
          <div className="need-crane__center">
            <table className="need-crane__table">
              <tr>
                <th>{Obj_heb.ifYouAddedAnOrder}</th>
                <th>{Obj_heb.weWillGive}</th>
              </tr>
              <tr>
                <td>{Obj_heb.till10}</td>
                <td>{Obj_heb.todayToToday}</td>
              </tr>
              <tr>
                <td>{Obj_heb.between4to10}</td>
                <td>{Obj_heb.oneBusinessDay}</td>
              </tr>
              <tr>
                <td>{Obj_heb.after4}</td>
                <td>{Obj_heb.endOfNextBusinessDay}</td>
              </tr>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default NeedCraneNav;
