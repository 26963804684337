import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import obj_heb from '../utils/Obj_heb.json';
import Title from '../components/Title';
import ButtonAdd from '../components/ButtonAdd';
import {useSelector} from 'react-redux';
import AddressItem from '../components/AddressItem';
import AddAddress from '../components/AddAddress';
import Toast from '../components/Toast';
import Loader from '../components/Loader';

export default function MyAddress() {
  const addressesList = useSelector(state => state.addressSlice.addressesList);
  const [addAddressOpen, setAddAddressOpen] = useState(false);
  const [finishText, setFinishText] = useState('');
  const [finish, setFinish] = useState(false);
  const addressListLoading = useSelector(state => state.creditCards.isLoading);

  const navigate = useNavigate();

  useEffect(() => {
    // get avible dibble coin
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      navigate('/');
    }
  }, []);

  return (
    <div className="myAddress">
      <Title title={obj_heb.My_Addresses} />
      {addressesList.length !== 0 && (
        <div className="myAddress_subTitle">{obj_heb.my_address_subTitle}</div>
      )}
      {addressListLoading ? (
        <Loader />
      ) : (
        <div className="myAddress__list">
          {addressesList.map(addr => {
            return (
              <AddressItem
                key={JSON.stringify(addr)}
                address={addr}
                setFinish={setFinish}
                setFinishText={setFinishText}
              />
            );
          })}
        </div>
      )}
      {addressesList.length === 0 ? (
        <div className="myAddress_empty">
          <div className=""></div>
          <div className="myAddress_empty_texts_box">
            <div className="myAddress__title">{obj_heb.itsEmptyHere}</div>
            <div className="myAddress_empty_subTitle">
              {obj_heb.myAddressText}
            </div>
          </div>

          <ButtonAdd
            text={obj_heb.addAddress}
            className="myAddress_empty__btn"
            onClick={() => setAddAddressOpen(true)}
          />
        </div>
      ) : (
        <ButtonAdd
          text={obj_heb.addAddress}
          className="myAddress__btn"
          onClick={() => setAddAddressOpen(true)}
        />
      )}

      {addAddressOpen && (
        <AddAddress
          isOpen={addAddressOpen}
          setIsOpen={setAddAddressOpen}
          setFinish={setFinish}
          setFinishText={setFinishText}
        />
      )}
      {finish && <Toast text={finishText} />}
    </div>
  );
}
