import React, {useEffect, useState} from 'react';
import PopUp from './general/PopUp';
import obj_heb from '../utils/Obj_heb.json';
import Button from './Button';
import Toggle from './general/Toggle';
import {useDispatch, useSelector} from 'react-redux';
import {updateAccessibilityState} from '../redux/slices/accessibilitySlice';

const AccessibilityModal = ({setIsOpen, isOpen}) => {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const userAccessibilityState = useSelector(
    state => state.accessibility.accessibilityState,
  );

  useEffect(() => {
    setToggle(userAccessibilityState);
  }, [userAccessibilityState]);

  const handleToggleChange = () => {
    setToggle(perv => !perv);
  };

  //////////// Save toggle state on redux
  const handleOnButtonClick = () => {
    dispatch(updateAccessibilityState(toggle));
    setIsOpen(false);
  };

  //////////// if the user changes the toggle state but clicks on the X button
  // load his previous state.
  const handleIsOpen = () => {
    setToggle(toggle === userAccessibilityState ? toggle : !toggle);
    setIsOpen(false);
  };
  return (
    <PopUp isOpen={isOpen} setIsOpen={handleIsOpen}>
      <div className="accessibilityModal">
        <div className="accessibilityModal__content">
          <div className="accessibilityModal__content__title">
            {obj_heb.accessibility}
          </div>
          <div className="accessibilityModal__content__toggle__container">
            <div className="">
              <div className="accessibilityModal__content__toggle__title">
                <div>{obj_heb.accessibilityTitle}</div>
              </div>
              <div className="accessibilityModal__content__toggle__text">
                <div>{obj_heb.accessibilityText}</div>
              </div>
            </div>
            <div className="accessibilityModal__content__toggle">
              <Toggle toggle={toggle} handleToggleChange={handleToggleChange} />
            </div>
          </div>
        </div>
        <Button
          text={obj_heb.confirm}
          onClick={() => handleOnButtonClick()}
          textColor="var(--white)"
          backgroundColor="var(--dibble-yellow)"
          className={'PersonalArea_popup_content_button'}
        />
      </div>
    </PopUp>
  );
};

export default AccessibilityModal;
