import React from 'react';
import obj_heb from '../../utils/Obj_heb.json';

function TimeBasedGreeting() {
  const currentHour = new Date().getHours();

  const greeting =
    currentHour >= 5 && currentHour < 12
      ? obj_heb.good_morning
      : currentHour >= 12 && currentHour < 16
      ? obj_heb.good_afternoon
      : currentHour >= 16 && currentHour < 19
      ? obj_heb.good_afternoon
      : currentHour >= 19 && currentHour < 22
      ? obj_heb.good_evening
      : obj_heb.good_night || obj_heb.hello;

  return greeting;
}

export default TimeBasedGreeting;
