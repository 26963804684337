import {createSlice} from '@reduxjs/toolkit';
import {updateLocalStorage} from '../../utils/supportFunctions';

const accessibilitySlice = createSlice({
  name: 'accessibility',
  initialState: {
    accessibilityState: false,
  },
  reducers: {
    updateAccessibilityState: (state, action) => {
      state.accessibilityState = action.payload;
      updateLocalStorage('accessibility', state.accessibilityState);
    },
  },
});

export const {updateAccessibilityState} = accessibilitySlice.actions;
export default accessibilitySlice.reducer;
