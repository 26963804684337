import React, {useEffect} from 'react';
import close from '../../assets/icons/close_icon.svg';
import classNames from 'classnames';
import {cancel_scrolling} from '../../utils/supportFunctions';

function PopUp({
  isOpen = false,
  setIsOpen,
  children,
  size,
  closeCallback = null,
  isFullPage = false,
}) {
  useEffect(() => {
    cancel_scrolling(isOpen);
    return () => {
      cancel_scrolling(false);
    };
  }, [isOpen]);
  return (
    <div
      className={classNames(
        'pop-up',
        size === 'small' && 'pop-up__small',
        !isOpen ? 'hide' : '',
      )}>
      <div
        className={classNames(
          'pop-up__content',
          size === 'small' && 'pop-up__small',
          isFullPage && 'pop-up__content--full-page',
        )}>
        <img
          src={close}
          alt="x"
          className={'pop-up__content--close'}
          onClick={() => {
            setIsOpen(false);
            if (closeCallback !== null) {
              closeCallback();
            }
          }}
        />
        {children}
      </div>
    </div>
  );
}

export default PopUp;
