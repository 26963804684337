import React from 'react';
import Obj_heb from '../../utils/Obj_heb.json';
import dibble_coin from '../../assets/icons/dibble_coin.svg';
import wheelbarrow from '../../assets/icons/wheelbarrow.svg';
import CardDelete from '../../assets/icons/removefromcart.svg';
import ShoppingCartItem from './ShoppingCartItem';
import {useState, useEffect} from 'react';
import {numberWithCommas} from '../../utils/supportFunctions';
import {useSelector, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {fetchData} from '../../utils/fetchData';
import TimeBasedGreeting from '../cart/TimeBasedGreeting';
import Button from '../Button';
import PopUp from '../general/PopUp';
import PopUpConfirm from '../PopUpConfirm';
import {ShoppingCart_removeProduct} from '../../redux/slices/ShoppingCartSlice';
import Toast from '../Toast';
export default function ShoppingCartWidget() {
  const navigate = useNavigate();
  const [shoppingCartData, setShoppingCartData] = useState([]);
  const [shoppingCartPrice, setShoppingCartPrice] = useState(0);
  const [shoppingCartWeight, setShoppingCartWeight] = useState(0);
  const [weigthText, setWeigthText] = useState('');
  const [dibbleCoins, setDibbleCoins] = useState(false);
  const [popUpisOpen, setPopUpIsOpen] = useState(false);
  const [removalAnimation, setRemovalAnimation] = useState(false);
  const [product, setProduct] = useState(false);
  const [vat, setVat] = useState(0);
  const convertStringToNumber = input => {
    const number = parseFloat(input);
    const convertedNumber = number / 100 + 1;
    return convertedNumber;
  };
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData')),
  );
  const dispatch = useDispatch();

  const loadDibbleCoin = async () => {
    if (userData && userData.token != null && userData.token != '') {
      const dataObj = {
        request: 'dibblecoin_get_available_credit',
        token: userData.token,
      };

      fetchData(dataObj).then(response => {
        if (response?.message === 'success')
          setDibbleCoins(response.available_credit);
      });
    }
  };

  useEffect(() => {
    let dataObj = {
      request: 'get_vat_rate',
    };
    fetchData(dataObj).then(response => {
      if (response?.message === 'success')
        setVat(convertStringToNumber(response.vat_rate.value));
    });
  }, []);

  useEffect(() => {
    if (userData) {
      loadDibbleCoin();
    }
  }, [userData]);

  const shoppingCartProducts = useSelector(
    state => state.shopping_cart.products,
  );

  useEffect(() => {
    setShoppingCartData(shoppingCartProducts);

    const price = Number(
      shoppingCartProducts
        .map(item => {
          if (item.sale) {
            return item.sale_price * item.quantity;
          } else {
            return item.price * item.quantity;
          }
        })
        .reduce((partialSum, a) => partialSum + a, 0),
    ).toFixed(2);

    setShoppingCartPrice(price);

    let weight = shoppingCartProducts
      .map(item => item.weight * item.quantity)
      .reduce((partialSum, a) => partialSum + a, 0);

    if (weight >= 1000000) {
      weight = weight / 1000000;
      if (Number(String(weight.toString().split('.').length) > 1)) {
        if (String(weight.toString().split('.')[1]).length > 2) {
          weight = Number(Number(weight).toFixed(2)) + 0.01;
        }
      }
      setWeigthText(Obj_heb.ton);
    } else if (weight >= 1000) {
      weight = weight / 1000;

      if (Number(String(weight.toString().split('.').length) > 1)) {
        if (String(weight.toString().split('.')[1]).length > 2) {
          weight = Number(Number(weight).toFixed(2)) + 0.01;
        }
      }

      setWeigthText(Obj_heb.kilo);
    } else if (weight < 1000) {
      if (Number(String(weight.toString().split('.').length) > 1)) {
        if (String(weight.toString().split('.')[1]).length > 2) {
          weight = Number(Number(weight).toFixed(2)) + 0.01;
        }
      }
      setWeigthText(Obj_heb.gram);
    }
    if (String(weight).length > 3) {
      weight = weight.toFixed(2);
    }
    setShoppingCartWeight(weight);
  }, [shoppingCartProducts]);

  return (
    <>
      <div className="Shopping-cart">
        <div className="Shopping-cart_inside-elements">
          <div className="Shopping-cart_gradient-top-to-bottom">
            <div className="Shopping-cart_wrapper">
              <div>
                <TimeBasedGreeting />
              </div>
              {userData ? (
                <div className="Shopping-cart_wrapper_title">
                  {userData.first_name + ' ' + userData.last_name}{' '}
                </div>
              ) : (
                <Link to={'/login'}>
                  <div className="Shopping-cart_wrapper_title_black-title">
                    {Obj_heb.login}
                  </div>
                </Link>
              )}
            </div>
            <div className="Shopping-cart__top-details">
              <div className="Shopping-cart__top-details__inner">
                <img
                  className="Shopping-cart__top-details__inner--img"
                  src={dibble_coin}
                  alt="dibble_coin"
                  title="dibble_coin"
                />
                <div>{Obj_heb.dibble_coins}</div>
              </div>
              <div>{dibbleCoins ? numberWithCommas(dibbleCoins) : 0}</div>
            </div>
            <hr className="hr-PH-16"></hr>
            <div className="Shopping-cart__top-details">
              <div className="Shopping-cart__top-details__inner">
                <img
                  className="Shopping-cart__top-details__inner--img"
                  src={wheelbarrow}
                  alt="wheelbarrow"
                  title="wheelbarrow"
                />

                <div onClick={() => navigate(`/cart`)}>{Obj_heb.my_basket}</div>
              </div>
              <div>{shoppingCartData.length}</div>
            </div>
            <hr className="hr-PH-16"></hr>
          </div>

          {shoppingCartData.length === 0 && (
            <div className="Shopping-cart_empty-cart">
              <div className="Shopping-cart_empty-cart_txt">
                {Obj_heb.emptyCartSlogen1}
              </div>
            </div>
          )}
          {shoppingCartData.length !== 0 && (
            <div className="Shopping-cart_scroll-Section">
              {shoppingCartData.length !== 0 &&
                shoppingCartData
                  .slice()
                  .reverse()
                  .map((item, index) => {
                    return (
                      <React.Fragment key={String('ShoppingCartItem' + index)}>
                        {index !== 0 && <hr className="hr-PH-16"></hr>}
                        <ShoppingCartItem
                          key={String('ShoppingCartItem' + index)}
                          item={item}
                          widget
                          setPopUpIsOpen={setPopUpIsOpen}
                          setProduct={setProduct}
                        />
                      </React.Fragment>
                    );
                  })}
            </div>
          )}
          <div className="Shopping-cart_bottom-summery">
            <div className="Shopping-cart_bottom-summery_inside-elements">
              <hr className="hr-PH-16" style={{width: '100%'}}></hr>
              <div className="Shopping-cart_bottom-summery_inside-elements_part1">
                <div className="Shopping-cart_bottom-summery_inside-elements_part1_title">
                  {Obj_heb.total_acronym}
                </div>
                <div className="Shopping-cart_bottom-summery_inside-elements_part1_txt">
                  {numberWithCommas((shoppingCartPrice * vat).toFixed(2))}
                  <div className="Shopping-cart_bottom-summery_inside-elements_part1_txt_curency">
                    ₪
                  </div>
                </div>
              </div>
              <div className="Shopping-cart_bottom-summery_inside-elements_part2">
                <div className="Shopping-cart_bottom-summery_inside-elements_part2_title">
                  {Obj_heb.weight}:
                </div>
                <div className="Shopping-cart_bottom-summery_inside-elements_part2_txt">
                  {numberWithCommas(shoppingCartWeight)} {weigthText}
                </div>
              </div>
              {shoppingCartData.length >= 1 && (
                <Button
                  text={Obj_heb.continue_for_paymant}
                  onClick={async () => {
                    // const canProceed = await verifyProductChanges();
                    // if (canProceed) {
                    userData ? navigate(`/cart`) : navigate(`/login`);
                    // }
                  }}
                  textColor="white"
                  backgroundColor="var(--dibble-yellow)"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {popUpisOpen && (
        <PopUp isOpen={popUpisOpen} setIsOpen={setPopUpIsOpen}>
          <PopUpConfirm
            img={CardDelete}
            title={Obj_heb.sureQuestion}
            subTitle={Obj_heb.removeFromCart}
            onConfirm={() => {
              dispatch(ShoppingCart_removeProduct({key: product}));
              setPopUpIsOpen(false);
              setRemovalAnimation(true);
              setTimeout(() => {
                setRemovalAnimation(false);
              }, 7800);
            }}
            onCancel={() => {
              setPopUpIsOpen(false);
            }}
          />
        </PopUp>
      )}
      {removalAnimation && (
        <Toast text={Obj_heb.productRemovedFromShoppingCart} />
      )}
    </>
  );
}
