import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import obj_heb from '../utils/Obj_heb.json';

import Loader from '../components/Loader';
import Title from '../components/Title';
import CreditCard from '../components/CreditCard';
import ButtonAdd from '../components/ButtonAdd';
import CreditCardAddition from '../components/CreditCardAddition';
import Toast from '../components/Toast';

function CreditCards() {
  const creditCards = useSelector(state => state.creditCards.creditCardsList);
  const creditListLoading = useSelector(state => state.creditCards.isLoading);
  const [addCardIsOpen, setAddCardIsOpen] = useState(false);
  const [finish, setFinish] = useState(false);
  const [finishText, setFinishText] = useState('');

  const isMobile = useSelector(state => state.mobile.isMobile);

  const navigate = useNavigate();

  useEffect(() => {
    // get avible dibble coin
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      navigate('/');
    }
  }, []);

  return (
    <div className="credit-cards full-page">
      <Title title={obj_heb.myCreditCards} />
      {creditListLoading ? (
        <Loader />
      ) : (
        <div className="credit-cards__list">
          {creditCards.map(card => {
            return (
              <CreditCard
                key={JSON.stringify(card)}
                card={card}
                setFinish={setFinish}
                setFinishText={setFinishText}
              />
            );
          })}
        </div>
      )}

      {creditCards.length === 0 ? (
        <div className="flex-column-16 credit-cards__empty">
          <div className="credit-cards__empty--title">
            {obj_heb.itsEmptyHere}
          </div>
          <div className="credit-cards__empty--sub">
            {obj_heb.hereWillBeAllYourCards}
          </div>

          <ButtonAdd
            text={obj_heb.addCreditCard}
            className="credit-cards__btn"
            onClick={() => setAddCardIsOpen(true)}
          />
        </div>
      ) : (
        <ButtonAdd
          text={obj_heb.addCreditCard}
          className="credit-cards__btn"
          onClick={() => setAddCardIsOpen(true)}
        />
      )}

      {addCardIsOpen && (
        <CreditCardAddition
          isOpen={addCardIsOpen}
          setIsOpen={setAddCardIsOpen}
          setFinish={setFinish}
          setFinishText={setFinishText}
        />
      )}

      {finish && <Toast text={finishText} />}
    </div>
  );
}

export default CreditCards;
