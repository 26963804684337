import {createSlice} from '@reduxjs/toolkit';
import {fetchData} from '../../utils/fetchData';

const initialState = {
  likedProductList: [],
};

export const likedProductSlice = createSlice({
  name: 'LikedProducts',
  initialState,
  reducers: {
    setLikedProduct: (state, action) => {
      state.likedProductList = action.payload;
    },
  },
});

export const {setLikedProduct} = likedProductSlice.actions;

// Thunk for initializing addressesList from API data
export const fetchLikedProductsAndSetInitialList = () => async dispatch => {
  let data = localStorage.getItem('userData');
  data = JSON.parse(data);

  if (!data?.token) return;

  let dataObj = {
    request: 'get_user_liked_products',
    token: data?.token,
  };

  try {
    const response = await fetchData(dataObj);
    const data = response;
    dispatch(setLikedProduct(data));
  } catch (error) {
    // Handle error
    console.error('fetchCreditCardsAndSetInitialList', error);
  }
};

export default likedProductSlice.reducer;
