import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import Button from '../components/Button.jsx';
import Title from '../components/Title';
import obj_heb from '../utils/Obj_heb.json';
import {useIntercomContext} from '../utils/IntercomContext';

import bgImage from '../assets/images/lobby_front.png';
import lobbyImage from '../assets/images/screen2.png';

const LobbyRegister = ({props}) => {
  const {openIntercom} = useIntercomContext();

  const pageTitle = obj_heb.manageWithUs;
  const isMobile = useSelector(state => state.mobile.isMobile);

  const handleOpenIntercom = () => {
    console.log(`openIntercom for lobby `);
    openIntercom(); // This will open the Intercom chat
  };

  return (
    <React.Fragment>
      <div className="addProject">
        <div className="addProject__content">
          <img
            className="addProject__content__bgImage"
            src={bgImage}
            alt="background-image"
          />
          <div className="addProject__content__section">
            <span className="addProject__content__section__title">
              לובי מנהלים
            </span>
            <span className="addProject__content__section__paragraph">
              מסך ראשון מסוגו המאפשר שליטה מלאה ומדויקת בתקציבי הפרויקט.
              <br />
              בקרה על נתונים פרוגרסיביים - הוצאות, הכנסות ויעדי משלוח.
              <br />
              מסך מותאם לפי צרכי החברה.
            </span>
          </div>
          <img
            className="addProject__content__section__image"
            src={lobbyImage}
            alt="background-image"
          />

          <Title
            title={pageTitle}
            style="addProject__container--title"
            center={true}
          />
          <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
            <div
              className="addProject__container--text--bold"
              style={{
                textAlign: 'center',
                color: !isMobile ? '#707070' : '',
              }}></div>

            <Button
              text={obj_heb.i_want_it}
              onClick={() => handleOpenIntercom()}
              textColor="white"
              backgroundColor={'#FFCA1A'}
              className="addProject--margin-horizontal-16"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LobbyRegister;
