import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import obj_heb from '../utils/Obj_heb.json';

import Banner from '../components/Banner';
import DepartmentsLists from '../components/DepartmentsLists';
import SEO from '../components/SEO';
import classNames from 'classnames';

export default function Home() {
  const isMobile = useSelector(state => state.mobile.isMobile);

  useEffect(() => {
    const handleNavigation = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener('popstate', handleNavigation);
    return () => {
      window.removeEventListener('popstate', handleNavigation);
    };
  }, []);

  return (
    <React.Fragment>
      <SEO pageName={obj_heb.siteDesc} content={obj_heb.dibbleDesc} link="/" />
      <div className="Home">
        <Banner />
        <DepartmentsLists />
      </div>
    </React.Fragment>
  );
}
