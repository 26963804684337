import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import icons from '../assets/constants/icons';

function MobileFooter({cancelFooterScreeningOnMobile}) {
  const [selected, setSelected] = useState(0);
  const [animationSide, setAnimationSide] = useState('to-left');

  const navigate = useNavigate();

  const shoppingCartProducts = useSelector(
    state => state.shopping_cart.products,
  );

  const values = [
    {
      index: 0,
      label: 'עמוד הבית',
      icon: icons.black_D,
      iconSelected: icons.yellow_D,
      url: '/',
    },

    {
      index: 2,
      label: 'חומרים בקליק',
      icon: icons.icon_footer_heavy_unselected,
      iconSelected: icons.icon_footer_heavy,
      url: '/materials',
    },
    {
      index: 3,
      label: 'העגלה שלי',
      icon: icons.wheelbarrow,
      iconSelected: icons.wheelbarrow_yellow,
      url: '/cart',
      notes:
        shoppingCartProducts?.length > 0 ? shoppingCartProducts.length : null,
    },
    {
      index: 4,
      label: 'איזור אישי',
      icon: icons.man,
      iconSelected: icons.man_yellow,
      url: '/personal-area',
    },
  ];

  const selectedLine = (
    <div
      className="mobile-footer__item--selected"
      style={{animation: `${animationSide} 350ms`}}></div>
  );

  const handleClickItem = item => {
    setAnimationSide(item.index > selected ? 'to-left' : 'to-right');
    setSelected(item.index);
    navigate(item.url);
  };

  useEffect(() => {
    values.forEach(item => {
      if (item.url === window.location.pathname) {
        setSelected(item.index);
      }
    });
  }, [window.location.pathname]);

  return (
    <div className="mobile-footer">
      {!cancelFooterScreeningOnMobile && (
        <span className="screening-bottom-to-top" />
      )}
      {values.map(item => {
        return (
          <div
            className="mobile-footer__item"
            key={'mobile-footer-' + item.label}
            onClick={() => handleClickItem(item)}>
            {selected === item.index && selectedLine}
            <div className="relative">
              {item?.notes && (
                <div className="badge-indicator ">{item.notes}</div>
              )}
              <img
                alt=""
                className="mobile-footer__item--img"
                src={selected === item.index ? item.iconSelected : item.icon}
              />
            </div>
            <span>{item.label}</span>
          </div>
        );
      })}
    </div>
  );
}

export default MobileFooter;
