import React from 'react';

function Toast({text = ''}) {
  return (
    <div className="toast">
      <span className="toast--text">{text}</span>
    </div>
  );
}

export default Toast;
