import React from 'react';

import Button from './Button';

import {icons} from '../assets/constants';
import obj_heb from '../utils/Obj_heb.json';
import {GooglePlay, AppStore} from '../utils/baseValues';

function DownloadAppBanner({setIsShown}) {
  const handleDownload = () => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isMacOS = /Macintosh/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);

    if (isIOS || isMacOS) {
      window.location.href = AppStore;
    } else if (isAndroid && !isMacOS) {
      window.location.href = GooglePlay;
    }
  };

  return (
    <div className="download-app-banner">
      <img
        src={icons.inputX}
        alt="x"
        className="download-app-banner__close"
        onClick={() => setIsShown()}
      />
      <img
        src={icons.dibbleAppIcon}
        alt="dibble"
        className="download-app-banner__icon"
      />
      <div className="download-app-banner__text">
        {obj_heb.moreComfortableInApp}
      </div>
      <Button
        backgroundColor="var(--dibble-yellow)"
        textColor="var(--paragraph-white)"
        text={obj_heb.toDownload}
        textSize={18}
        className="download-app-banner__btn"
        onClick={() => handleDownload()}
      />
    </div>
  );
}

export default DownloadAppBanner;
