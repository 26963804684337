import React, {useState, useEffect, useRef} from 'react';
import locationIcon from '../assets/icons/location.svg';
import AddAddress from './AddAddress';
import Toast from './Toast';

import {useSelector, useDispatch} from 'react-redux';
import {setSelectedAddress, deleteFromAddressesList} from '../redux/store';
import obj_heb from '../utils/Obj_heb.json';
import icons from '../assets/constants/icons';
import classNames from 'classnames';
import MyAddresses from './MyAddresses';

function AddressInput({
  boxShadow = false,
  materialOrderAddress = null,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isAddAddressOpen, setIsAddAddressOpen] = useState(false);
  const [finish, setFinish] = useState(false);

  const addressesRef = useRef(null);

  const dispatch = useDispatch();

  const addressesList = useSelector(state => state.addressSlice.addressesList);

  const isMobile = useSelector(state => state.mobile.isMobile);

  const selectedAddress = useSelector(
    state => state.addressSlice.selectedAddress,
  );

  const handleClick = () => {
    if (materialOrderAddress) {
      return;
    }
    if (addressesList.length > 0 || selectedAddress) {
      setIsOpen(prev => !prev);
    } else {
      setIsAddAddressOpen(prev => !prev);
    }
  };

  const handleValue = () => {
    if (selectedAddress) {
      return <span>{selectedAddress.address}</span>;
    }
    if (addressesList.length === 1) {
      dispatch(setSelectedAddress(addressesList[0]));
      const selectedAddressText = addressesList[0].address;
      dispatch(deleteFromAddressesList(addressesList[0].id));
      return <span>{selectedAddressText}</span>;
    } else {
      return (
        <span>
          {isMobile
            ? obj_heb.selectAddressToDelivery
            : obj_heb.whereToSendThisTime}
        </span>
      );
    }
  };

  useEffect(() => {
    // Function to handle click outside the div
    const handleClickOutside = event => {
      if (
        addressesRef.current &&
        !addressesRef.current.contains(event.target)
      ) {
        // The click is outside the div, do something here
        setIsOpen(prev => false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="address-input"
      style={{boxShadow: boxShadow ? 'var(--card-shadow)' : 'none'}}
      ref={addressesRef}>
      <div className="address-input__item" onClick={() => handleClick()}>
        <div className="address-input__item--title">
          <img
            src={locationIcon}
            alt="location"
            className="address-input__icon"
          />
          <span
            className={
              isMobile && !selectedAddress
                ? 'address-input__item--title--placeholder'
                : ''
            }>
            {handleValue()}
          </span>
        </div>
        {!materialOrderAddress && (
          <img
            className={classNames(
              'address-input__item--arrow',
              isOpen && 'flip',
            )}
            src={isMobile ? icons.arrow_head_left : icons.arrow_head_down}
            alt=""
          />
        )}
      </div>
      {isOpen && (
        <MyAddresses
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          addAddressIsOpen={isAddAddressOpen}
          setAddAddressIsOpen={setIsAddAddressOpen}
          className="address-input__list"
        />
      )}
      {isAddAddressOpen && (
        <AddAddress
          isOpen={isAddAddressOpen}
          setIsOpen={setIsAddAddressOpen}
          setFinish={setFinish}
        />
      )}
      {finish && <Toast text={obj_heb.addressSave} />}
    </div>
  );
}

export default AddressInput;
