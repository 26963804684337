import React, {useState} from 'react';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import {fetchCreditCardsAndSetInitialList} from '../redux/store';
import PopUp from '../components/general/PopUp';
import TextInput from './TextInput';
import Button from './Button';
import RadioButton from './RadioButtons';
import Loader from '../components/Loader';
import Title from './Title';
import Toggle from './general/Toggle';
import {payMe, payMeSuccess} from '../utils/baseValues';
import {fetchData} from '../utils/fetchData';
import obj_heb from '../utils/Obj_heb.json';
import {icons} from '../assets/constants';

function CreditCardAddition({
  isOpen,
  setIsOpen,
  setFinish = () => {},
  setFinishText = () => {},
  isEdit = false,
  card = null,
  setListIsLoading = () => {},
  setCardAddedId = () => {},
}) {
  const creditCardType = require('credit-card-type');
  const dispatch = useDispatch();

  const [cardDetails, setCardDetails] = useState({
    cardNumber: isEdit ? ' **** **** **** ' + card?.card_num : '',
    cvv: isEdit ? card?.cvv : '',
    valid_thru_month: isEdit ? card?.valid_thru_month : '',
    valid_thru_year: isEdit ? card?.valid_thru_year : '',
    type: isEdit ? card?.type : 1,
    name: isEdit ? card?.name : '',
    id: '',
    payment_token: '',
    notify_email: isEdit ? card?.notify_email : '',
    is_default: card?.is_default,
  });

  const [cardDetailsError, setCardDetailsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // ##################################################### focus on next input

  const handleFocus = e => {
    const {maxLength, value, name} = e.target;
    const [fieldName, fieldIndex] = name.split('-');

    // Check if they hit the max character length
    if (value.length >= maxLength) {
      const nextSibling = document.querySelector(
        `input[name=${fieldName}-${parseInt(fieldIndex, 10) + 1}]`,
      );

      // If found, focus the next field
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
  };

  // ##################################################### credit card validation

  const creditCardLength = cardNum => {
    const card = creditCardType(cardNum)[0];
    return card?.lengths[0];
  };

  const creditCardGaps = cardNum => {
    const card = creditCardType(cardNum)[0];
    return card?.gaps ? card.gaps : [];
  };

  const onChangeCreditCard = e => {
    setCardDetailsError(false);

    const value = e.target.value;
    if (value == 'E') {
      return;
    }
    setCardDetails(prev => {
      return {
        ...prev,
        cardNumber: value.replace(/[^\dA-Z]/g, ''),
      };
    });
    handleFocus(e);
  };

  // adding spaces
  const creditCardValue = () => {
    const creditCardNumber = cardDetails.cardNumber;

    // in case of edit
    if (creditCardNumber.includes('*')) return creditCardNumber;

    // adding spaces by the gaps
    let modifiedString = '';
    let currentIndex = 0;
    const gaps = creditCardGaps(creditCardNumber);

    for (let i = 0; i < creditCardNumber.length; i++) {
      modifiedString += creditCardNumber[i];

      if (currentIndex < gaps.length && i === gaps[currentIndex] - 1) {
        modifiedString += ' ';
        currentIndex++;
      }
    }
    return modifiedString.trim();
  };

  // check if the number is invalid
  const invalidCreditCard = () => {
    const cardNum = cardDetails.cardNumber;
    if (creditCardLength(cardNum) !== cardNum.length || !cardNum) {
      return true;
    }
    return false;
  };

  // ##################################################### cvv validation

  const cvvLength = cardNum => {
    return creditCardType(cardNum)[0]?.code.size;
  };

  const onChangeCvv = e => {
    setCardDetailsError(false);

    const value = e.target.value;
    if (!/^[0-9]+$/.test(value)) {
      return;
    }
    setCardDetails(prev => {
      return {
        ...prev,
        cvv: value.replace(/[^\dA-Z]/g, ''),
      };
    });

    handleFocus(e);
  };

  // check if the number is invalid
  const invalidCvv = () => {
    const cvv = cardDetails.cvv;
    if (
      (cardDetails.cardNumber &&
        cvvLength(cardDetails.cardNumber) !== cvv.length) ||
      !cvv
    ) {
      return true;
    }
    return false;
  };

  // ##################################################### expiry validation

  const monthValidation = value => {
    if (value <= 12) {
      return true;
    }
    return false;
  };

  const yearValidation = value => {
    if (value > 21) {
      return true;
    }
    return false;
  };

  const invalidExpiry = () => {
    return (
      !monthValidation(Number(cardDetails.valid_thru_month)) ||
      !yearValidation(Number(cardDetails.valid_thru_year))
    );
  };

  //Expiration Date Function
  const onChangeExpiry = e => {
    setCardDetailsError(false);

    const value = e.target.value.replace(/[^\dA-Z]/g, '');
    if (!/^[0-9]+$/.test(value)) {
      return;
    }
    if (value.length == 0) {
      setCardDetails(prev => {
        return {
          ...prev,
          valid_thru_month: '',
          valid_thru_year: '',
        };
      });
    } else if (value.length == 1) {
      if (value > 1) {
        setCardDetails(prev => {
          return {
            ...prev,
            valid_thru_month: '0' + value,
            valid_thru_year: '',
          };
        });
      } else {
        setCardDetails(prev => {
          return {
            ...prev,
            valid_thru_month: value,
            valid_thru_year: '',
          };
        });
      }
    } else if (value.length == 2) {
      setCardDetails(prev => {
        return {
          ...prev,
          valid_thru_month: value,
          valid_thru_year: '',
        };
      });
    } else {
      if (value.length > 2) {
        setCardDetails(prev => {
          return {
            ...prev,
            valid_thru_month: value.substring(0, 2).replace(/[^\dA-Z]/g, ''),
            valid_thru_year: value.substring(2, 4).replace(/[^\dA-Z]/g, ''),
          };
        });
      }
    }

    handleFocus(e);
  };

  const expiryValue = () => {
    if (!cardDetails.valid_thru_month) {
      return '';
    } else if (!cardDetails.valid_thru_year) {
      return cardDetails.valid_thru_month;
    }
    return `${cardDetails.valid_thru_month} / ${cardDetails.valid_thru_year}`;
  };

  // ##################################################### id validation

  const onChangeId = e => {
    setCardDetailsError(false);

    const value = e.target.value;
    if (!/^[0-9]+$/.test(value)) {
      return;
    }
    setCardDetails(prev => {
      return {
        ...prev,
        id: value.replace(/[^\dA-Z]/g, ''),
      };
    });
  };

  const invalidId = () => {
    let IDnum = String(cardDetails.id);
    // Validate correct input
    if (IDnum.length > 9 || IDnum.length < 5) return true;
    if (isNaN(IDnum)) return true;
    //id with just 0 char is not valid
    if (IDnum.replace(/0/g, '').length == 0) return true;

    // The number is too short - add leading 0000
    if (IDnum.length < 9) {
      while (IDnum.length < 9) {
        IDnum = '0' + IDnum;
      }
    }
    // CHECK THE ID NUMBER
    let mone = 0,
      incNum;
    for (let i = 0; i < 9; i++) {
      incNum = Number(IDnum.charAt(i));
      incNum *= (i % 2) + 1;
      if (incNum > 9) incNum -= 9;
      mone += incNum;
    }
    // return false = valid ID
    return !(mone % 10 == 0);
  };

  // ##################################################### card name
  const onChangeCardName = e => {
    const value = e.target.value;

    setCardDetails(prev => {
      return {
        ...prev,
        name: value,
      };
    });
  };

  // ##################################################### card type

  const onChangeCardType = item => {
    const value = item.index;

    setCardDetails(prev => {
      return {
        ...prev,
        type: value,
        notify_email: '',
      };
    });
  };

  const onChangeCardEmail = e => {
    const value = e.target.value;

    setCardDetails(prev => {
      return {
        ...prev,
        notify_email: value.trim(),
      };
    });
  };

  const invalidEmail = () => {
    const email = cardDetails.notify_email;
    if (!email) return false;
    const re = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    if (re.test(String(email).toLowerCase())) {
      return false;
    }
    return true;
  };

  // ##################################################### confirm

  const checkValidateValues = () => {
    if (isEdit) {
      if (invalidEmail()) return false;
      return true;
    }
    if (
      invalidCreditCard() ||
      invalidCvv() ||
      invalidExpiry() ||
      invalidId() ||
      invalidEmail()
    ) {
      return false;
    }
    return true;
  };

  const handleConfirm = async () => {
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    if (isEdit) {
      setIsLoading(true);
      setListIsLoading && setListIsLoading(true);
      editCard();
    } else {
      setIsLoading(true);
      setListIsLoading && setListIsLoading(true);
      let dataObj = {
        seller_payme_id:
          payMe[process.env.REACT_APP_ENV || 'development'].sellerKey,
        credit_card_number: cardDetails.cardNumber,
        credit_card_exp:
          cardDetails.valid_thru_month + cardDetails.valid_thru_year,
        credit_card_cvv: cardDetails.cvv,
        buyer_name: userData.first_name + ' ' + userData.last_name,
        buyer_social_id: cardDetails.id,
        buyer_email: userData.email,
        buyer_phone: userData.phone,
        buyer_is_permanent: true,
      };
      axios

        .post(
          payMe[process.env.REACT_APP_ENV || 'development'].endpoint,
          dataObj,
        )
        .then(response => {
          if (response.status === payMeSuccess) {
            addCard(response.data.buyer_key);
          }
        })
        .catch(error => {
          setCardDetailsError(true);
          setIsLoading(false);
          setListIsLoading && setListIsLoading(false);
          console.error(' show Error:', error.message);
        });
    }
  };

  const addCard = buyer_key => {
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    setFinishText(obj_heb.cardAddedToYourList);

    let dataObj = {
      request: 'add_creditcard',
      token: userData.token,
      payment_token: buyer_key,
      name: cardDetails.name,
      four_last_digits: cardDetails.cardNumber.substring(
        cardDetails.cardNumber.length - 4,
      ),
      valid_thru_month: cardDetails.valid_thru_month,
      valid_thru_year: cardDetails.valid_thru_year,
      cvv: cardDetails.cvv,
      notify_email: cardDetails.notify_email
        ? cardDetails.notify_email
        : userData.email,
      type: cardDetails.type,
      brand: creditCardType(cardDetails.cardNumber)[0]?.niceType,
    };

    fetchData(dataObj)
      .then(response => {
        dispatch(fetchCreditCardsAndSetInitialList());

        setIsLoading(false);
        setListIsLoading && setListIsLoading(false);
        setCardAddedId && setCardAddedId(response.creditcard_id);
        setIsOpen(false);
        setFinish(true);
        setTimeout(() => {
          setFinish(false);
        }, 7800);

        if (cardDetails.is_default) {
          let dataObj = {
            request: 'set_default_creditcard',
            token: userData.token,
            creditcard_id: response.creditcard_id,
          };

          fetchData(dataObj) // Don't assign the result to x, instead handle it using the 'then' method
            .then(response => {
              dispatch(fetchCreditCardsAndSetInitialList());
            })
            .catch(error => {
              console.error('set_default_creditcard:', error?.message);
            });
        }
      })
      .catch(error => {
        console.error('add_creditcard', error);
      });
  };
  const editCard = () => {
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    setFinishText(obj_heb.changesSavedSuccessfully);

    let dataObj = {
      request: 'update_creditcard',
      token: userData.token,
      name: cardDetails.name,
      creditcard_id: card.creditcard_id,
      valid_thru_month: cardDetails.valid_thru_month,
      valid_thru_year: cardDetails.valid_thru_year,
      cvv: cardDetails.cvv,
      notify_email: cardDetails.notify_email
        ? cardDetails.notify_email
        : userData.email,
      type: cardDetails.type,
      payment_token: userData.token,
    };

    fetchData(dataObj)
      .then(response => {
        dispatch(fetchCreditCardsAndSetInitialList());
        setIsLoading(false);
        setListIsLoading && setListIsLoading(false);
        setIsOpen(false);
        setFinish(true);
        setTimeout(() => {
          setFinish(false);
        }, 7800);
        if (cardDetails.is_default) {
          let dataObj = {
            request: 'set_default_creditcard',
            token: userData.token,
            creditcard_id: card.creditcard_id,
          };

          fetchData(dataObj) // Don't assign the result to x, instead handle it using the 'then' method
            .then(response => {
              dispatch(fetchCreditCardsAndSetInitialList());
            })
            .catch(error => {
              console.error('set_default_creditcard:', error?.message);
            });
        }
      })
      .catch(error => {
        console.error('update_creditcard', error);
      });
  };

  return (
    <>
      <PopUp isOpen={isOpen} setIsOpen={setIsOpen}>
        {isLoading ? (
          <div className="credit-card-add__loading">
            <Loader />
            <span>{obj_heb.waitingForConfirmation}</span>
          </div>
        ) : (
          <div className="credit-card-add">
            <span className="credit-card-add__title">
              {isEdit ? obj_heb.editCard : obj_heb.addCard}
            </span>

            {cardDetailsError && (
              <span className="credit-card-add__invalid-card">
                {obj_heb.yourCardDetailsIsNotValid}
              </span>
            )}
            <div className="credit-card-add__fields">
              <TextInput
                description={obj_heb.cardNumber}
                placeholderText={obj_heb.cardNumberExample}
                onBlur={e => {}}
                onChange={e => onChangeCreditCard(e)}
                onClear={() =>
                  setCardDetails(prev => {
                    return {
                      ...prev,
                      cardNumber: '',
                    };
                  })
                }
                className="credit-card-add__fields--number"
                value={creditCardValue()}
                errorMsg="מספר כרטיס לא תקין"
                IsError={invalidCreditCard()}
                disable={isEdit}
                isLeft={true}
                max={
                  creditCardLength(cardDetails.cardNumber) +
                  creditCardGaps(cardDetails.cardNumber).length
                }
                name="creditCardAddition-1"
                icon={
                  cardDetails.cardNumber &&
                  creditCardType(cardDetails.cardNumber)[0]?.niceType
                    ? require(`../assets/icons/CreditCards/${
                        creditCardType(cardDetails.cardNumber)[0]?.niceType
                      }.svg`)
                    : icons.creditCard
                }
                clearColor="white"
                type="tel"
              />
              <TextInput
                description={obj_heb.cvv}
                placeholderText={obj_heb.cvvExample}
                onBlur={e => {}}
                onChange={e => onChangeCvv(e)}
                value={cardDetails.cvv}
                errorMsg="CVV לא תקין"
                IsError={invalidCvv()}
                disable={isEdit}
                isLeft={true}
                max={cvvLength(cardDetails.cardNumber)}
                name="creditCardAddition-3"
                onClear={() =>
                  setCardDetails(prev => {
                    return {
                      ...prev,
                      cvv: '',
                    };
                  })
                }
                clearColor="white"
                type="number"
              />
              <TextInput
                description={obj_heb.validity}
                placeholderText={obj_heb.validityExample}
                onBlur={e => {}}
                value={expiryValue()}
                onChange={e => onChangeExpiry(e)}
                errorMsg="תוקף לא תקין"
                IsError={invalidExpiry()}
                disable={isEdit}
                isLeft={true}
                max={4 + 3}
                name="creditCardAddition-2"
                onClear={() =>
                  setCardDetails(prev => {
                    return {
                      ...prev,
                      valid_thru_month: '',
                      valid_thru_year: '',
                    };
                  })
                }
                clearColor="white"
                type="tel"
              />
              <TextInput
                description={obj_heb.IDOfOwner}
                placeholderText={obj_heb.IDOfOwnerExample}
                onBlur={e => {}}
                className="credit-card-add__fields--id"
                value={cardDetails.id}
                onChange={e => onChangeId(e)}
                errorMsg="מספר תעודת זהות לא תקין"
                IsError={invalidId()}
                disable={isEdit}
                isLeft={true}
                name="creditCardAddition-4"
                onClear={() =>
                  setCardDetails(prev => {
                    return {
                      ...prev,
                      id: '',
                    };
                  })
                }
                clearColor="white"
                type="tel"
              />
            </div>
            <div className="credit-card-add__sub-group">
              <span className="credit-card-add__sub-title">
                {obj_heb.nameOfCardOptional}
              </span>
              <TextInput
                inputColorIsBlack={true}
                description={obj_heb.nameOfCard}
                placeholderText={obj_heb.nameOfCardExample}
                onBlur={e => {}}
                value={cardDetails.name}
                onChange={e => onChangeCardName(e)}
                name="creditCardAddition-5"
                onClear={() =>
                  setCardDetails(prev => {
                    return {
                      ...prev,
                      name: '',
                    };
                  })
                }
                clearColor="white"
              />
              <span className="credit-card-add__sub-group--des">
                {obj_heb.nameOfCardExplanation}
              </span>
            </div>
            <div className="credit-card-add__sub-group">
              <span className="credit-card-add__sub-title">
                {obj_heb.cardType}
              </span>

              <RadioButton
                data={[
                  {
                    index: 1,
                    label: 'פרטי',
                  },
                  {
                    index: 2,
                    label: 'עסקי',
                  },
                ]}
                onSelect={onChangeCardType}
                defaultValue={cardDetails.type}
              />
            </div>

            {cardDetails.type == 2 && (
              <div className="credit-card-add__sub-group">
                <span className="credit-card-add__sub-title">
                  {obj_heb.emailBookkeeping}
                </span>
                <TextInput
                  description={obj_heb.emailAddress}
                  placeholderText={obj_heb.emailAddressExample}
                  onBlur={e => {}}
                  value={cardDetails.notify_email}
                  onChange={e => onChangeCardEmail(e)}
                  errorMsg="כתובת אימייל לא תקינה"
                  isLeft={true}
                  clearColor="white"
                  onClear={() =>
                    setCardDetails(prev => {
                      return {
                        ...prev,
                        notify_email: '',
                      };
                    })
                  }
                />
              </div>
            )}
            {!card?.is_default && (
              <div className="credit-card-add__favorite">
                <span>{obj_heb.defineThisCreditCardAsFavorite}</span>

                <Toggle
                  toggle={cardDetails.is_default}
                  handleToggleChange={() =>
                    setCardDetails(prev => {
                      return {
                        ...prev,
                        is_default: !prev.is_default,
                      };
                    })
                  }
                />
              </div>
            )}
            <span className="credit-card-add__dibble-coin">
              <span>{obj_heb.youCanPayAlsoWithDibbleCoin}</span>
              <span>{obj_heb.paymentIshappenAfterDeliveryArrival}</span>
            </span>
            <Button
              text={
                checkValidateValues()
                  ? isEdit
                    ? obj_heb.save
                    : obj_heb.add
                  : obj_heb.addCreditCardDetails
              }
              disable={checkValidateValues() ? false : true}
              onClick={() => {
                handleConfirm();
              }}
              textColor="white"
              backgroundColor={checkValidateValues() ? '#FFCA1A' : '#BDBCBC'}
              className="credit-card-add__btn"
              id="credit-card-add__btn"
            />
          </div>
        )}
      </PopUp>
    </>
  );
}

export default CreditCardAddition;
