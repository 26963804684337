import {configureStore} from '@reduxjs/toolkit';
import counterReducer from './slices/counterSlice';
import userInfoReducer from './slices/userInfoSlice';
import shopping_cart_Reducer from './slices/ShoppingCartSlice';
import addressSliceReducer from './slices/addressSlice';
import creditCardSliceReducer from './slices/creditCardSlice';
import likedProductsReducer from './slices/likedProductSlice';
import mobileSliceReducer from './slices/mobileSlice';
import activeOrdersReducer from './slices/activeOrdersSlice';
import accessibilityReducer from './slices/accessibilitySlice';
import generalDataSlice from './slices/generalDataSlice';

import {fetchAddressesAndSetInitialList} from './slices/addressSlice';
import {fetchCreditCardsAndSetInitialList} from './slices/creditCardSlice';
import {fetchLikedProductsAndSetInitialList} from './slices/likedProductSlice';
import {initialMobile} from './slices/mobileSlice';
import {fetchActiveOrdersAndSetInitialList} from './slices/activeOrdersSlice';

import {ShoppingCart_clearAllProducts} from './slices/ShoppingCartSlice';
import {clearAddressData} from './slices/addressSlice';
import {clearCreditCardsData} from './slices/creditCardSlice';
import {clearActiveOrdersCount} from './slices/activeOrdersSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    userInfo: userInfoReducer,
    shopping_cart: shopping_cart_Reducer,
    addressSlice: addressSliceReducer,
    creditCards: creditCardSliceReducer,
    likedProducts: likedProductsReducer,
    mobile: mobileSliceReducer,
    activeOrders: activeOrdersReducer,
    accessibility: accessibilityReducer,
    general: generalDataSlice,
  },
});

store.dispatch(fetchAddressesAndSetInitialList());
store.dispatch(fetchCreditCardsAndSetInitialList());
store.dispatch(fetchLikedProductsAndSetInitialList());
store.dispatch(initialMobile());
store.dispatch(fetchActiveOrdersAndSetInitialList());

export const deleteOnLogOut = () => {
  store.dispatch(ShoppingCart_clearAllProducts());
  store.dispatch(clearAddressData());
  store.dispatch(clearCreditCardsData());
  store.dispatch(clearActiveOrdersCount());
  localStorage.setItem('userData', null);
};

export {
  setSelectedAddress,
  addToAddressesList,
  deleteFromAddressesList,
  setAddressesList,
  clearAddressData,
  fetchAddressesAndSetInitialList,
} from './slices/addressSlice';

export {
  setCreditCardsList,
  addToCreditCardsList,
  deleteFromCreditCardList,
  clearCreditCardsData,
  fetchCreditCardsAndSetInitialList,
} from './slices/creditCardSlice';

export {initialMobile, setIsDownloadAppShown} from './slices/mobileSlice';
export {
  clearActiveOrdersCount,
  fetchActiveOrdersAndSetInitialList,
} from './slices/activeOrdersSlice';

export {fetchLikedProductsAndSetInitialList} from './slices/likedProductSlice';
