import React, {useEffect, useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import SEO from '../components/SEO';
import LobbyCreditsTab from '../components/LobbyCreditsTab';
import Title from '../components/Title';
import Projects from '../components/Projects';
import {useNavigate} from 'react-router-dom';
import Pagination from '../components/Pagination';
import classNames from 'classnames';
import GetDibbleCoinModal from '../components/GetDibbleCoinsModal';
import Toast from '../components/Toast';
import 'firebase/firestore';
import {initializeApp} from 'firebase/app';
import {fireBaseConfig, INTERCOM_APP_ID} from '../utils/baseValues';
import {getDatabase, ref, onValue} from 'firebase/database';
import {
  clearActiveOrdersCount,
  updateActiveOrders,
} from '../redux/slices/activeOrdersSlice';
import TimeBasedGreeting from '../components/cart/TimeBasedGreeting';
import {icons} from '../assets/constants';
import {clearAddressData, clearCreditCardsData} from '../redux/store';
import MyUsersTab from '../components/MyUsersTab';
import Loader from '../components/Loader';

export default function Lobby() {
  let app = initializeApp(fireBaseConfig);
  const db = getDatabase(app);
  const dataRef = ref(db, 'courier');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false); // Added loading state
  const [TabOptions, setTabOptions] = useState([
    {
      name: obj_heb.income_center,
      key: 0,
      tab_name: 'balances_and_benefits',
    },
    {
      name: obj_heb.projects,
      key: 1,
      tab_name: 'projects',
    },
    {name: obj_heb.users, key: 2, tab_name: 'my_users'},
  ]);

  const [chartData, setChartData] = useState(0);
  const [projectSummary, setProjectSummary] = useState(0);
  const [totalDibbleCoin, setTotalDibbleCoin] = useState(0);
  const [projects, setProjects] = useState([]);
  const [availableDibbleCoin, setAvailableDibbleCoin] = useState(0);
  const [openTabIndex, setOpenTabIndex] = useState(0);
  const [myActiveDiscounts, setMyActiveDiscounts] = useState([]);
  const [likedPRoduct, setlikedPRoduct] = useState([]);
  const [userOrderHistory, setUserOrderHistory] = useState([]);
  const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  let PageSize = 10;
  const isMobile = useSelector(state => state.mobile.isMobile);
  let userData = JSON.parse(localStorage.getItem('userData'));
  console.log(`userData`, userData);

  // grab the query selector
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access specific query parameters
  const tabName = queryParams.get('tab');
  const activeOrders = useSelector(state => state.activeOrders.activeOrders);

  useEffect(() => {
    // get avible dibble coin
    let userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      navigate('/login');
    }

    setIsLoading(true); // Start loading

    let dataObjSummary = {
      request: 'get_my_projects_summary',
      token: userData?.token,
    };

    fetchData(dataObjSummary)
      .then(response => {
        if (response.message === 'success') {
          setProjectSummary(response);
          setChartData(response['graphs'].length ? response['graphs'][0] : []);
        }
        setIsLoading(false); // Stop loading after data is fetched
      })
      .catch(error => {
        console.error('Error:', error.message);
        setIsLoading(false); // Stop loading in case of error
      });

    let dataObj = {
      request: 'dibblecoin_get_my_projects_balance',
      token: userData?.token,
    };
    fetchData(dataObj)
      .then(response => {
        if (response.message === 'success') {
          setTotalDibbleCoin(response.total_credit);
          setProjects(response.projects);
          setAvailableDibbleCoin(response.available_credit);
        }
      })
      .catch(error => {
        console.error('Error:', error.message);
      });

    // getting user liked items
    let likedProductDataObj = {
      request: 'get_user_liked_products',
      token: userData?.token,
    };
    fetchData(likedProductDataObj)
      .then(response => {
        const data = response;
        if (data.products) {
          setlikedPRoduct(data.products);
        }
      })
      .catch(error => {
        console.error('Error:', error.message);
      });

    let orderHistoryDataObj = {
      request: 'get_my_orders',
      token: userData?.token,
    };
    fetchData(orderHistoryDataObj)
      .then(response => {
        const data = response;
        if (data) {
          setUserOrderHistory(data);
          dispatch(updateActiveOrders(activeOrders));
          for (const element of activeOrders) {
            for (const e of data.orders) {
              if (element.order_id === e.order_id) {
                if (element.courier_phone) {
                  onValue(dataRef, snapshot => {
                    const newData = snapshot.val();
                    let orders = newData[element.courier_phone]?.orders;
                    if (orders) {
                      for (const order of orders) {
                        if (order.order_id === element.order_id) {
                          const index = response.orders.findIndex(object => {
                            return object.order_id === element.order_id;
                          });
                          data.orders[index].textInMinsClient =
                            order.textInMinsClient;
                          setUserOrderHistory(data);
                        }
                      }
                    }
                  });
                }
              }
            }
          }
        }
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  }, []);

  useEffect(() => {
    if (tabName === 'projects') {
      setOpenTabIndex(1);
    } else if (tabName === 'my_users') {
      setOpenTabIndex(2);
    } else setOpenTabIndex(0);
  }, [tabName]);

  const pickTabHandler = item => {
    setOpenTabIndex(item.key);

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tab', item.tab_name);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const logout = () => {
    if (!userData?.token) return;
    let dataObj = {
      request: 'logout',
      token: userData.token,
    };
    fetchData(dataObj).then(response => {
      localStorage.setItem('userData', null);
      dispatch(clearAddressData());
      dispatch(clearCreditCardsData());
      dispatch(clearActiveOrdersCount());
      window.Intercom('shutdown');
      window.Intercom('reset');
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
      });
      window.Intercom('update', {name: '', email: '', phone: '', userId: ''});
      window.location.reload();
      navigate('/');
    });
  };

  const SEOLoader = () => {
    const LobbyLink = `/lobby}`;
    return (
      <SEO
        pageName={obj_heb.personal_area}
        content={obj_heb.personal_area}
        link={LobbyLink}
      />
    );
  };

  const titleText = () => {
    const useNameText = userData
      ? userData.first_name + ' ' + userData.last_name
      : '';
    return useNameText;
  };

  return (
    <>
      <SEOLoader />

      <div className="PersonalArea ">
        <div className="PersonalArea_content">
          {isMobile ? (
            <>
              <div
                className="PersonalArea_content__title--mobile_part1"
                style={{
                  textAlign: 'center',
                }}>
                {TimeBasedGreeting()}
              </div>
              <Title
                center={true}
                CustomTitle={
                  <div className="PersonalArea_content__title--mobile">
                    <div className="PersonalArea_content__title--mobile_part2">
                      {titleText()}
                    </div>
                  </div>
                }
                title={titleText()}
              />
            </>
          ) : (
            <Title title={obj_heb.lobby_navbar} />
          )}
          {isLoading ? ( // Show loader while loading
            <Loader />
          ) : (
            <div className="PersonalArea_content_section1">
              <div className="PersonalArea_content_section1_tab-headers">
                {TabOptions.map(item => (
                  <div
                    className={classNames(
                      'PersonalArea_content_section1_tab-headers_tab_head',
                      openTabIndex == item.key &&
                        'PersonalArea_content_section1_tab-headers_tab_head--selected',
                    )}
                    onClick={() => {
                      pickTabHandler(item);
                    }}>
                    <div
                      className="PersonalArea_content_section1_tab-headers_tab_head_title"
                      style={
                        openTabIndex == item.key ? {fontWeight: '700'} : {}
                      }>
                      {item.name}
                    </div>
                  </div>
                ))}
              </div>
              <div className="PersonalArea_content_section1_tab-body">
                {openTabIndex == 0 ? (
                  <div className="PersonalArea_content_section1_tab_margin">
                    <LobbyCreditsTab
                      projectSummary={projectSummary}
                      chartData={chartData}
                      projects={projects}
                      totalDibbleCoin={totalDibbleCoin}
                      availableDibbleCoin={availableDibbleCoin}
                      myActiveDiscounts={myActiveDiscounts}
                      setCreditReqModalIsOpen={setIsOpen}
                    />
                  </div>
                ) : openTabIndex == 1 ? (
                  <Projects />
                ) : (
                  <>
                    <div className="PersonalArea_content_section1_colum-div">
                      <MyUsersTab
                        orderData={userOrderHistory}
                        currentPaginationPage={currentPaginationPage}
                        PageSize={PageSize}
                        onPageChange={page => setCurrentPaginationPage(page)}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {openTabIndex == 1 && isMobile && (
            <div
              onClick={() => {
                logout();
              }}
              className="PersonalSettingsTab__logOut">
              <div className="PersonalSettingsTab__logOut__titleBox">
                <span className="PersonalSettingsTab__logOut__titleBox__textRed">
                  {obj_heb.exit}
                </span>
                <span>{obj_heb.exitText}</span>
              </div>
              <img
                className="PersonalSettingsTab_item--title_arrowhead_img"
                src={icons.red_left_arrow_head}
                alt="leftArrowHead"
              />
            </div>
          )}
        </div>
        <GetDibbleCoinModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          closeCallback={() => setIsOpen(false)}
          setIsFinish={setIsFinish}
        />
        {isFinish && <Toast text={obj_heb.creditLineSuccessMessage} />}
      </div>
    </>
  );
}
