import React, {useState} from 'react';

//UTILITIES
import {numberWithCommas} from '../utils/supportFunctions';
import obj_heb from '../utils/Obj_heb.json';

//COMPONENTS
import {icons} from '../assets/constants/index';
import PieChart from './general/PieChart';
import Button from './Button';
import {useSelector} from 'react-redux';

export default function CreditsTab({
  availableDibbleCoin,
  myActiveDiscounts,
  setCreditReqModalIsOpen,
}) {
  const [myDiscountSectionOpen, setMyDiscountSectionOpen] = useState(false);
  const isMobile = useSelector(state => state.mobile.isMobile);

  return (
    <>
      <div className="creditsTab_part1">
        <div className="creditsTab_part1_item-box">
          <div className="creditsTab_part1_item-box_text-box">
            <div className="creditsTab_part1_item-box_text-box_title">
              {obj_heb.dibble_coins}
            </div>
            <div className="creditsTab_part1_item-box_text-box_sub-title">
              {obj_heb.dibbleCoinsAmount}
            </div>
          </div>
          <div className="creditsTab_part1_item-box_icon-box">
            <div className="creditsTab_part1_item-box_icon-box_txt">
              {numberWithCommas(availableDibbleCoin)}
            </div>
            <img
              src={icons.x_icon}
              className="creditsTab_part1_item-box_icon-boxx-icone"
              alt="x_icon"
            />
            <img
              src={icons.dibbleCoinIcon}
              className="creditsTab_part1_item-box_icon-box_dibbl-icon"
              alt="dibbleCoinIcon"
            />
          </div>
        </div>
        <div className="creditsTab_part1_hr">_horizintalLine</div>
        <div
          className="creditsTab_part1_item-box"
          // onClick={() => {
          //   setMyDiscountSectionOpen(prev => !prev);
          // }}
        >
          <div className="creditsTab_part1_item-box_text-box">
            <div className="creditsTab_part1_item-box_text-box_title2">
              {obj_heb.my_benefits}
            </div>
            <div className="creditsTab_part1_item-box_text-box_sub-title">
              {obj_heb.benefits_txt}
            </div>
          </div>
          <div className="creditsTab_part1_item-box_icon-box">
            {/* <img
              src={icons.top_arrow}
              className={
                myDiscountSectionOpen
                  ? 'creditsTab_part1_item-box_icon-box_arrow-head flip_180_degres'
                  : 'creditsTab_part1_item-box_icon-box_arrow-head'
              }
              alt="top_arrow-icon"
            /> */}
          </div>
        </div>
        {myDiscountSectionOpen && (
          <div className="creditsTab_part1_my-descount-open-section">
            {myActiveDiscounts.map(item => (
              <div className="creditsTab_part1_my-descount-open-section_descount-item">
                <img src={icons.present_icon} alt="present_icon" />
                <div className="creditsTab_part1_my-descount-open-section_descount-item_txt">
                  {item.text}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {isMobile && (
        <>
          <div className="creditsTab--mobile__dibbleCoinModal">
            <Button
              text={obj_heb.credit_request}
              onClick={() => setCreditReqModalIsOpen(true)}
              textColor="white"
              textSize={18}
              backgroundColor="var(--dibble-yellow)"
              className="PersonalArea_content_credit_req--mobile__btn"
            />
            <div className="creditsTab_part1_hr">_horizintalLine</div>
          </div>
        </>
      )}
      <div className="creditsTab_part2">
        <div className="creditsTab_part2_text-part">
          <div className="creditsTab_part2_text-part_item">
            <div className="creditsTab_part2_text-part_item_numbers">
              {numberWithCommas(0)}
              <div className="creditsTab_part2_text-part_item_numbers_symbol">
                ₪
              </div>
            </div>
            <div className="creditsTab_part2_text-part_item_txt">
              {obj_heb.current_frame}
            </div>
          </div>
          <div className="creditsTab_part2_text-part_item_hr">-</div>
          <div className="creditsTab_part2_text-part_item">
            <div className="creditsTab_part2_text-part_item_numbers">
              {numberWithCommas(0)}
              <div className="creditsTab_part2_text-part_item_numbers_symbol">
                ₪
              </div>
            </div>
            <div className="creditsTab_part2_text-part_item_txt">
              {obj_heb.current_balance}
            </div>
          </div>
        </div>

        <div className="graf">
          {/* <img style={{height: '12rem', width: '12rem'}} src={chart} alt="" /> */}
          <PieChart prasentageUsed={0} />
        </div>
      </div>
    </>
  );
}
