import React, {useEffect, useState} from 'react';
import icons from '../../assets/constants/icons';
import {numberWithCommas} from '../../utils/supportFunctions';
import classNames from 'classnames';

/**
 * ShoppingCartItem Component.
 *
 * @param {Object} props - Component properties.
 * @param {number} props.Amount - Value of the starting amount as a number; the number will represent a rem.
 * @param {boolean} props.isActive - Indicates if the component is active.
 * @param {Function} props.decrementFunction - Function to decrement the amount.
 * @param {Function} props.incrementFunction - Function to increment the amount.
 * @param {Function} props.insertNumberFunction - Function to insert text numbers.
 * @param {number} props.setWidth - Width value as a number; the number will represent a rem.
 * @param {number} props.setHight - Height value as a number; the number will represent a rem.
 * @param {number} props.btnSize - Button size value as a number; the number will represent a rem.
 * @param {number} props.fontSize - Font size value as a number; the number will represent a rem.
 * @param {number} props.boxShadow - box shadow value as a boolean; true will add shadow.
 * @param {string} props.className - Additional class names for the component.
 * @param {boolean} props.minusIconTurnGray - if false when product amount is 1 the minus icon will not be gray.
 * @param {number} [props.minimumValue=1] - The minimum value allowed for the amount.
 */

export default function ShoppingCartItemAmount({
  Amount,
  isActive,
  decrementFunction,
  incrementFunction,
  insertNumberFunction = null,
  setWidth,
  setHight,
  btnSize,
  fontSize,
  boxShadow,
  className,
  minusIconTurnGray = true,
  minimumValue = 1,
}) {
  const [amount, setAmount] = useState(Number(Amount));
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setAmount(Amount);
  }, [Amount]);

  const decrementLocalFunction = () => {
    if (amount > minimumValue) {
      setAmount(prev => prev - 1);
      decrementFunction(Number(amount) - 1);
      setErrorMessage('');
    }
  };

  const incrementLocalFunction = () => {
    if (amount < 999) {
      setAmount(prev => prev + 1);
      incrementFunction(Number(amount) + 1);
      setErrorMessage('');
    } else {
      setErrorMessage('לא ניתן להזמין יותר מ999');
    }
  };

  const changeInputNumber = e => {
    const forbiddenValues = ['E', 'e', '+', '-', '.', ' '];
    const value = e.target.value;
    let validUserInputNumbers = String(value)
      .split('')
      ?.filter(i => !forbiddenValues.includes(i))
      .join('');
    if (!validUserInputNumbers) {
      setAmount(minimumValue);
      insertNumberFunction(Number(minimumValue));
    } else {
      const numericValue = Number(validUserInputNumbers);
      if (
        numericValue >= minimumValue &&
        String(validUserInputNumbers).length <= 3 &&
        numericValue <= 999
      ) {
        setAmount(numericValue);
        insertNumberFunction(numericValue);
        setErrorMessage('');
      } else if (numericValue > 999) {
        setAmount(999);
        insertNumberFunction(999);
        setErrorMessage('לא ניתן להזמין יותר מ999');
      }
    }
  };

  return (
    <div
      key={JSON.stringify(className, setHight, Date.now())}
      className={classNames('ShoppingCartItemAmount', className)}
      style={{
        width: `${setWidth}rem`,
        height: `${setHight}rem`,
        boxShadow: boxShadow && 'var(--card-shadow)',
      }}>
      {isActive ? (
        <div className="ShoppingCartItemAmount_active">
          <img
            style={{width: `${btnSize}rem`, height: `${btnSize}rem`}}
            onClick={
              Number(amount) === 999 ? () => {} : () => incrementLocalFunction()
            }
            className="ShoppingCartItemAmount_active_img"
            src={
              Number(amount) === 999
                ? icons.gray_plus_btn
                : icons.yellow_plus_btn
            }
            alt="yellow_plus_btn"
          />
          <div
            className="ShoppingCartItemAmount_active_txt-amount"
            style={{fontSize: `${fontSize}rem`}}>
            {insertNumberFunction ? (
              <input
                value={amount}
                className="ShoppingCartItemAmount_active_txt-amount__input"
                onChange={changeInputNumber}
                style={{
                  width: `${btnSize * 2.2}rem`,
                  fontSize: `${fontSize}rem`,
                }}
                inputMode="numeric"
                pattern="[0-9]*"
              />
            ) : (
              <div> {numberWithCommas(Amount)}</div>
            )}
          </div>
          <img
            style={{width: `${btnSize}rem`, height: `${btnSize}rem`}}
            onClick={() => decrementLocalFunction()}
            className="ShoppingCartItemAmount_active_img"
            src={
              minusIconTurnGray && Number(amount) === minimumValue
                ? icons.grayMinus
                : icons.yellow_minus_btn
            }
            alt="minus_btn"
          />
        </div>
      ) : (
        <div className="ShoppingCartItemAmount_un-Active">
          <div className="ShoppingCartItemAmount_un-Active_txt-amount">
            {numberWithCommas(Amount)}
          </div>
        </div>
      )}
      {errorMessage && (
        <div
          className="error-message"
          style={{color: 'red', marginBottom: '0.5rem', fontSize: '12px'}}>
          {errorMessage}
        </div>
      )}
    </div>
  );
}
