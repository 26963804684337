import React, {useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import AddAndEditUserPopUp from './AddAndEditUserPopUp';
import ProjectTransactionListItem from './ProjectTransactionListItem';
export default function ProjectTransactionsTab({
  projectTransactions, // this is the prop being passed
}) {
  const [isOpen, setIsOpen] = useState(false);
  const transactions =
    projectTransactions?.projectTransactions?.transactions || [];
  return (
    <>
      <div className="MyOrdersTab">
        {projectTransactions?.transactions?.length === 0 ? (
          <div className="MyOrdersTab_empty-list">
            <div className="MyOrdersTab_empty-list_text">
              <div className="MyOrdersTab_empty-list_text_title">
                {obj_heb.noTransactionsFound}
              </div>
              <div className="MyOrdersTab_empty-list_text_sub-title">
                {obj_heb.noTransactionsFoundDetails}
              </div>
            </div>
          </div>
        ) : (
          <div>
            {projectTransactions?.transactions?.map(order => (
              <ProjectTransactionListItem
                key={order.order_id}
                item={order}
                projectOrders={transactions}
              />
            ))}
          </div>
        )}
      </div>
      <AddAndEditUserPopUp isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="qrPopUp">
          <span className="qrPopUp_txt">{obj_heb.transferToAppStroeTxt}</span>
        </div>
      </AddAndEditUserPopUp>
    </>
  );
}
