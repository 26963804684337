import React from 'react';
import PopUpConfirm from './PopUpConfirm';
import {useDispatch, useSelector} from 'react-redux';
import {fetchData} from '../utils/fetchData';

import {
  clearAddressData,
  fetchAddressesAndSetInitialList,
} from '../redux/store';
import obj_heb from '../utils/Obj_heb.json';
import classNames from 'classnames';
import deleteLocation from '../assets/icons/deleteLocation.svg';

export default function AddressDelete({
  address,
  setAddressEditIsOpen,
  setPopUpIsOpen,
  setPopUpContent,
  setFinish,
  setFinishText,
}) {
  const dispatch = useDispatch();
  const isMobile = useSelector(state => state.mobile.isMobile);

  const handleClickDelete = () => {
    setPopUpIsOpen(true);
    setPopUpContent(popUpContent());
    setAddressEditIsOpen(false);
  };

  // get the selected address and the addresses list
  const addressesList = useSelector(state => state.addressSlice.addressesList);

  const popUpContent = () => {
    return (
      <PopUpConfirm
        img={deleteLocation}
        title={obj_heb.sureToDelete}
        subTitle={obj_heb.ConfirmWillDeleteYourAddress}
        onConfirm={DeleteAddress}
        onCancel={() => {
          setPopUpIsOpen(false);
          setPopUpContent(<></>);
        }}
      />
    );
  };

  const DeleteAddress = () => {
    let data = localStorage.getItem('userData');
    data = JSON.parse(data);

    if (!data?.token) return;

    let dataObj = {
      request: 'delete_address',
      token: data.token,
      address_id: address.id,
    };

    fetchData(dataObj) // Don't assign the result to x, instead handle it using the 'then' method
      .then(response => {
        if (addressesList.length === 1) {
          dispatch(clearAddressData());
        }
        dispatch(fetchAddressesAndSetInitialList());
        setPopUpIsOpen(false);
        setPopUpContent(<></>);
        setFinishText(obj_heb.addressDeleteFromYourList);
        setFinish(true);
        setTimeout(() => {
          setFinish(false);
        }, 7800);
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  };

  return (
    <div
      onClick={handleClickDelete}
      className={classNames('address-Item__edit--field')}>
      <div
        className={classNames(
          'address-Item__edit--field--text',
          'address-Item__edit--field--text--red',
        )}>
        {obj_heb.delete_address}
        <span className="is-hover" />
      </div>
      {!isMobile && <hr className="hr" />}
    </div>
  );
}
