import heart_icon from '../../assets/icons/heart.svg';
import red_heart from '../../assets/icons/Vector_Heart.svg';
import full_heart_icon from '../../assets/icons/fullHeart.svg';
import cookies_icon from '../../assets/icons/cookie_icon.svg';
import notes_icon from '../../assets/icons/notes.svg';
import no_credit_icon from '../../assets/icons/no_credit.svg';
import black_intercom_icon from '../../assets/icons/black_intercom_icon.svg';
import location_error_icon from '../../assets/icons/location_error.svg';
import card_delete_icon from '../../assets/icons/removefromcart.svg';
import dibble_coins_x_icon from '../../assets/icons/dibble_coins_x_icon.svg';
import dibble_coins_x_icon_gray from '../../assets/icons/dibble_coins_x_icon_gray.svg';
import dibble_coins_icon from '../../assets/icons/dibbleCoins.svg';
import arrow_head_down from '../../assets/icons/arrow_head_down.svg';
import arrow_head_left from '../../assets/icons/arrow_head_left.svg';
import chat_icon from '../../assets/icons/chat_icon.svg';
import goBackIcon from '../../assets/icons/goBackIcon.svg';
import dibble_logo from '../../assets/icons/dibble_logo.svg';
import editProject from '../../assets/icons/edit_project.svg';
import exclamationMark from '../../assets/icons/exclamationMark.svg';
import dibbleCoinIcon from '../../assets/icons/dibbleCoinIcon.svg';
import present_icon from '../../assets/icons/present_icon.svg';
import x_icon from '../../assets/icons/x_icon.svg';
import yellow_minus_btn from '../../assets/icons/yellow_minus_btn.png';
import yellow_plus_btn from '../../assets/icons/yellow_plus_btn.png';
import gray_minus_btn from '../../assets/icons/gray_minus_btn.svg';
import grayMinus from '../../assets/icons/grayMinus.svg';
import black_D from '../icons/black_D.svg';
import departments from '../icons/departments.svg';
import wheelbarrow from '../icons/wheelbarrow.svg';
import man from '../icons/man.svg';
import yellow_D from '../icons/yellow_D.svg';
import departments_yellow from '../icons/departments_yellow.svg';
import wheelbarrow_yellow from '../icons/wheelbarrow_yellow.svg';
import man_yellow from '../icons/man_yellow.svg';
import productChatIcon from '../../assets/icons/productChatIcon.svg';
import dibble_person from '../../assets/icons/dibble_person.svg';
import plus_yellow from '../../assets/icons/plus_yellow.svg';
import price_was_changed from '../../assets/icons/price_was_changed.svg';
import gray_plus_btn from '../../assets/icons/gray_plus_btn.svg';
import dibbleCoinBG from '../../assets/icons/dibbleCoinBG.svg';
import dayCalender from '../icons/dayCalender.svg';
import close from '../../assets/icons/close.svg';
import scroll_to_top from '../../assets/icons/scroll_to_top.svg';
import scroll_to_top_2 from '../../assets/icons/scroll_to_top_2.svg';
import white_intercom_icon from '../../assets/icons/white_intercom_icon.svg';
import yellow_check from '../../assets/icons/yellow_check.svg';
import inputX from '../../assets/icons/inputX.svg';
import magnifying_glass from '../../assets/icons/magnifying_glass.svg';
import magnifying_glass_gray from '../../assets/icons/magnifying_glass_gray.svg';
import creditCard from '../../assets/icons/CreditCards/creditCard.svg';
import inputClearWhite from '../../assets/icons/inputClearWhite.svg';
import inputClearGray from '../../assets/icons/inputClearGray.svg';
import chat_icon_mobile from '../../assets/icons/chat_icon_mobile.svg';
import share_icon from '../../assets/icons/share_icon.svg';
import copy from '../../assets/icons/copy.svg';
import qr_code from '../../assets/icons/qr_code.svg';
import dark_dibble_logo from '../../assets/icons/dark_dibble_logo.svg';
import red_left_arrow_head from '../../assets/icons/red_left_arrow_head.svg';
import selectorEmpty from '../../assets/icons/selector_empty.svg';
import checkBoxSelected from '../../assets/icons/checkbox-selected.svg';
import dibbleAppIcon from '../../assets/icons/dibbleAppIcon.svg';
import redPerson from '../../assets/icons/redPerson.png';
import opemFile from '../../assets/icons/icon_open_file.png'
import userDoc from '../../assets/icons/icon_user_document.png'
import phoneDoc from '../../assets/icons/icon_phone_document.png'
import iconAddmodal from '../../assets/icons/icon_addmodal.png'
import icon_footer_heavy from '../../assets/icons/icon_footer_heavy.png'
import icon_footer_heavy_unselected from '../../assets/icons/icon_footer_heavy_unselected.png'
export default {
  icon_footer_heavy,
icon_footer_heavy_unselected,
  red_heart,
  iconAddmodal,
  phoneDoc,
  userDoc,
  opemFile,
  heart_icon,
  full_heart_icon,
  cookies_icon,
  notes_icon,
  no_credit_icon,
  black_intercom_icon,
  location_error_icon,
  card_delete_icon,
  dibble_coins_x_icon,
  dibble_coins_icon,
  arrow_head_down,
  arrow_head_left,
  chat_icon,
  goBackIcon,
  dibble_logo,
  exclamationMark,
  dibbleCoinIcon,
  present_icon,
  x_icon,
  dibble_coins_x_icon_gray,
  yellow_minus_btn,
  gray_minus_btn,
  yellow_plus_btn,
  grayMinus,
  black_D,
  departments,
  wheelbarrow,
  man,
  yellow_D,
  departments_yellow,
  wheelbarrow_yellow,
  man_yellow,
  productChatIcon,
  dibble_person,
  plus_yellow,
  price_was_changed,
  gray_plus_btn,
  dibbleCoinBG,
  dayCalender,
  close,
  scroll_to_top,
  scroll_to_top_2,
  white_intercom_icon,
  yellow_check,
  inputX,
  magnifying_glass,
  magnifying_glass_gray,
  creditCard,
  inputClearWhite,
  inputClearGray,
  chat_icon_mobile,
  share_icon,
  copy,
  qr_code,
  dark_dibble_logo,
  red_left_arrow_head,
  selectorEmpty,
  checkBoxSelected,
  dibbleAppIcon,
  redPerson,
  editProject
};
